import { HANDLE_MOTOBOY_MAP, OrdersContextInterface } from '../OrdersInterface';

export function handleMotoboyMap(
  state: OrdersContextInterface,
  action: HANDLE_MOTOBOY_MAP,
): OrdersContextInterface {
  return {
    ...state,
    overview: action.overview,
  };
}
