import React from 'react';
import MaskedInput from 'react-text-mask';

export const OneDigit = ({ inputRef, ...others }) => (
  <MaskedInput {...others} ref={inputRef} mask={[/\d/]} guide={false} showMask placeholder="D" />
);

const FiveDigits = ({ inputRef, ...others }) => (
  <MaskedInput
    {...others}
    ref={inputRef}
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
    guide={false}
    showMask
    placeholder="99999"
  />
);

const SixDigits = ({ inputRef, ...others }) => (
  <MaskedInput
    {...others}
    ref={inputRef}
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
    guide={false}
    showMask
    placeholder="999999"
  />
);

const SevenDigits = ({ inputRef, ...others }) => (
  <MaskedInput
    {...others}
    ref={inputRef}
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
    guide={false}
    showMask
    placeholder="9999999"
  />
);

const EightDigits = ({ inputRef, ...others }) => (
  <MaskedInput
    {...others}
    ref={inputRef}
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
    guide={false}
    showMask
    placeholder="99999999"
  />
);

const NineDigits = ({ inputRef, ...others }) => (
  <MaskedInput
    {...others}
    ref={inputRef}
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
    guide={false}
    showMask
    placeholder="999999999"
  />
);

const TenDigits = ({ inputRef, ...others }) => (
  <MaskedInput
    {...others}
    ref={inputRef}
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
    guide={false}
    showMask
    placeholder="9999999999"
  />
);

const ElevenDigits = ({ inputRef, ...others }) => (
  <MaskedInput
    {...others}
    ref={inputRef}
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
    guide={false}
    showMask
    placeholder="99999999999"
  />
);

const Sicredi = ({ inputRef, ...others }) => (
  <MaskedInput
    {...others}
    ref={inputRef}
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
    guide={false}
    showMask
    placeholder="999999D"
  />
);

const CaixaEconomica = ({ inputRef, ...others }) => (
  <MaskedInput
    {...others}
    ref={inputRef}
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
    guide={false}
    showMask
    placeholder="XXX99999999"
  />
);

const FallbackInput = ({ inputRef, ...others }) => (
  <MaskedInput {...others} ref={inputRef} mask={[]} guide={false} showMask placeholder="" />
);

export const AccountItau = props => <FiveDigits {...props} />;

export const AccountBradesco = props => <SevenDigits {...props} />;
export const AccountBancoOriginal = props => <SevenDigits {...props} />;
export const AccountNeon = props => <SevenDigits {...props} />;

export const AccountBancoBrasil = props => <EightDigits {...props} />;
export const AccountBanestes = props => <EightDigits {...props} />;
export const AccountPagSeguro = props => <EightDigits {...props} />;
export const AccountSafra = props => <EightDigits {...props} />;
export const AccountSantander = props => <EightDigits {...props} />;
export const AccountUnicred = props => <EightDigits {...props} />;

export const AccountBanrisul = props => <NineDigits {...props} />;
export const AccountBRB = props => <NineDigits {...props} />;
export const AccountInter = props => <NineDigits {...props} />;
export const AccountModal = props => <NineDigits {...props} />;
export const AccountSicoob = props => <NineDigits {...props} />;

export const AccountNubank = props => <TenDigits {...props} />;

export const AccountViaCredi = props => <ElevenDigits {...props} />;

export const AccountSicredi = props => <Sicredi {...props} />;

export const AccountCaixa = props => <CaixaEconomica {...props} />;
export const AccountBSB = props => <SixDigits {...props} />;
export const AccountFallback = props => <FallbackInput {...props} />;
