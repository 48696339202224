/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useContext, useState, useMemo } from 'react';
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from 'mui-datatables';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { useCookies } from 'react-cookie';
import { convertCentsToLocale } from '@project/components-typescript/src/helpers/LocalePriceConverter';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Status } from '@project/components';
import { capitalize } from '@project/components/src/utils/Helpers';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  getOrdersReport,
  OrdersReportInterface,
} from '../services/getOrdersReport';
import SalesToolbar from './SalesToolbar';
import { SalesPageContext } from '../contexts/SalesPageContext';

interface SalesPageTableProps {}

const NoLineBreakCell = styled.span`
  white-space: nowrap;
`;

const SalesPageTable: React.FC<SalesPageTableProps> = (): JSX.Element => {
  const [cookies] = useCookies(['token']);
  const { salesPage } = useContext(SalesPageContext);
  const { setShowBackdrop } = useContext(FeedbackContext);
  const [report, setReport] = useState<OrdersReportInterface[] | undefined>(
    undefined,
  );

  const data: any = useMemo(
    () =>
      report?.map(order => ({
        ...order,
        payment_date: order.payment_date.split(' ')[0],
        payment_hour: order.payment_date.split(' ')[1].substr(0, 5),
      })),
    [report],
  );

  useEffect(() => {
    setShowBackdrop(true);
    getOrdersReport(cookies.token, {
      dateAt: salesPage.dateAt,
      dateTo: salesPage.dateTo,
      limit: '99999',
    })
      .then(report => setReport(report.data))
      .catch(e => console.error(e))
      .finally(() => setShowBackdrop(false));
    // eslint-disable-next-line
  }, [salesPage.dateAt, salesPage.dateTo]);

  const columns: MUIDataTableColumnDef[] = useMemo(() => {
    if (!data) return [];

    return [
      {
        name: 'order_number',
        label: '#Pedido',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex: string | number) => (
            <Link to={`/vendas/${data[dataIndex]['order_number']}`}>
              #{data[dataIndex]['order_number']}
            </Link>
          ),
        },
      },
      {
        name: 'link',
        label: 'Fatura',
        options: {
          filter: false,
          sort: true,
          display: false,
          // customBodyRenderLite: dataIndex =>
          //   convertCentsToLocale(data[dataIndex]['link']),
        },
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          filter: true,
          filterType: 'dropdown',
          sort: true,
          display: false,
          // customBodyRenderLite: dataIndex =>
          //   convertCentsToLocale(data[dataIndex]['email']),
        },
      },
      {
        name: 'client_name',
        label: 'Nome do cliente',
        options: {
          filter: false,
          filterType: 'dropdown',
          sort: true,
          display: true,
          // customBodyRenderLite: dataIndex =>
          //   convertCentsToLocale(data[dataIndex]['client_name']),
        },
      },
      {
        name: 'phone',
        label: 'Telefone',
        options: {
          filter: false,
          sort: true,
          display: false,
          // customBodyRenderLite: dataIndex =>
          //   convertCentsToLocale(data[dataIndex]['phone']),
        },
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          filter: true,
          sort: true,
          customBodyRenderLite: (dataIndex: string | number) => (
            <Status status={data[dataIndex]['status']} />
          ),
        },
      },
      {
        name: 'modality',
        label: 'Modalidade',
        options: {
          filter: true,
          sort: true,
          display: true,
          customBodyRenderLite: (dataIndex: string | number) =>
            capitalize(data[dataIndex]['modality']),
        },
      },
      {
        name: 'payment_method',
        label: 'Método de pagamento',
        options: {
          filter: true,
          sort: true,
          display: true,
          customBodyRenderLite: (dataIndex: string | number) =>
            data[dataIndex]['payment_method'],
        },
      },
      {
        name: 'freight',
        label: 'Frete',
        options: {
          filter: false,
          sort: true,
          display: false,
          customBodyRenderLite: (dataIndex: string | number) =>
            convertCentsToLocale(data[dataIndex]['freight']),
        },
      },
      {
        name: 'payment_date',
        label: 'Data',
        options: {
          filter: true,
          filterType: 'dropdown',
          sort: true,
          display: true,
          customBodyRenderLite: (dataIndex: string | number): JSX.Element => (
            <NoLineBreakCell>{data[dataIndex]['payment_date']}</NoLineBreakCell>
          ),
        },
      },
      {
        name: 'payment_date_hour',
        label: 'Hora',
        options: {
          filter: false,
          sort: true,
          display: true,
          customBodyRenderLite: (dataIndex: string | number): JSX.Element => (
            <NoLineBreakCell>{data[dataIndex]['payment_hour']}</NoLineBreakCell>
          ),
        },
      },
      {
        name: 'installment',
        label: 'Parcelas',
        options: {
          filter: true,
          sort: true,
          display: false,
          // customBodyRenderLite: dataIndex =>
          //   convertCentsToLocale(data[dataIndex]['installment']),
        },
      },
      {
        name: 'fee',
        label: 'Taxa de venda (%)',
        options: {
          filter: false,
          sort: true,
          display: false,
          customBodyRenderLite: (dataIndex: string | number) =>
            data[dataIndex]['fee'],
        },
      },
      {
        name: 'total',
        label: 'Total',
        options: {
          filter: false,
          sort: true,
          display: true,
          customBodyRenderLite: (dataIndex: string | number) =>
            convertCentsToLocale(data[dataIndex]['total']),
        },
      },
      {
        name: 'receive_value',
        label: 'Valor a receber',
        options: {
          filter: false,
          sort: true,
          display: false,
          customBodyRenderLite: (dataIndex: string | number) =>
            convertCentsToLocale(data[dataIndex]['receive_value']),
        },
      },
      {
        name: 'date_to_release',
        label: 'Data prevista de liberacão',
        options: {
          filter: false,
          sort: true,
          display: false,
          customBodyRenderLite: (dataIndex: string | number): JSX.Element => (
            <NoLineBreakCell>
              {data[dataIndex]['date_to_release']}
            </NoLineBreakCell>
          ),
        },
      },
    ];
  }, [data]);

  const options: MUIDataTableOptions = {
    filterType: 'checkbox',
    responsive: 'standard',
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      try {
        const toConvertArr = ['total', 'freight', 'receive_value'];

        const indexArr = toConvertArr
          .map(el => columns.findIndex(({ name }) => name === el))
          .filter(value => value >= 0);

        const dataFormattedArr = data.map(obj => ({
          ...obj,
          data: obj.data.map((data, elIndex) =>
            indexArr.find(indexArr => indexArr === elIndex)
              ? typeof data === 'string'
                ? data
                : data / 100
              : data,
          ),
        }));

        return `${buildHead(columns)}${buildBody(dataFormattedArr)}`.trim();
      } catch (error) {
        return `${buildHead(columns)}${buildBody(data)}`;
      }
    },
    isRowExpandable: (dataIndex, expandedRows): boolean => {
      if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded
      // already (but allow those already expanded to be collapsed)
      if (
        expandedRows?.data &&
        expandedRows?.data.length > 4 &&
        expandedRows?.data?.filter(
          (d: { dataIndex: any }) => d.dataIndex === dataIndex,
        ).length === 0
      )
        return false;
      return true;
    },
    renderExpandableRow: (
      rowData: { [s: string]: unknown } | ArrayLike<unknown>,
    ): JSX.Element | null => {
      const { items } =
        report?.find(report =>
          Object.values(rowData).find(bla => bla === report.link),
        ) || {};

      if (!items) return null;

      return (
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="right">Preço</TableCell>
              <TableCell align="right">Quantidade</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map(row => (
              <>
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    {convertCentsToLocale(row.price)}
                  </TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                </TableRow>
                {/* {row.extras.map(extra => (
                  <TableRow key={extra.name}>
                    <TableCell component="th" scope="row">
                      {extra.name}
                    </TableCell>
                    <TableCell align="right">{convertCentsToLocale(extra.price)}</TableCell>
                    <TableCell align="right">{extra.quantity}</TableCell>
                  </TableRow>
                ))} */}
              </>
            ))}
          </TableBody>
        </Table>
      );
    },
    draggableColumns: {
      enabled: true,
      transitionTime: 300,
    },
    downloadOptions: {
      filename: 'vendas.csv',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    jumpToPage: true,
    textLabels: {
      body: {
        noMatch:
          'Oops, sua busca não retornou resultados, tente outros filtros',
        toolTip: 'Ordenar',
        columnHeaderTooltip: column => `Ordenar para ${column.label}`,
      },
      pagination: {
        next: 'Próxima Página',
        previous: 'Página Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
        jumpToPage: 'Ir até a página',
      },
      toolbar: {
        search: 'Pesquisa',
        downloadCsv: 'Download CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar',
      },
      filter: {
        all: 'Todos',
        title: 'Filtros',
        reset: 'Reset',
      },
      viewColumns: {
        title: 'Mostrar Colunas',
        titleAria: 'Mostrar/Esconder Colunas',
      },
      selectedRows: {
        text: 'linha(s) selecionada(s)',
        delete: 'Excluir',
        deleteAria: 'Excluir Linhas Selecionadas',
      },
    },
  };

  return (
    <>
      <SalesToolbar />
      <MUIDataTable
        title="Minhas vendas"
        data={data}
        columns={columns}
        options={options}
      />
    </>
  );
};

export default SalesPageTable;
