import React from 'react';
import bla from './master.svg';
import ResponsiveImage from '../../ResponsiveImage/ResponsiveImage';
import styled, { css } from 'styled-components';

const CustomSvg = styled(ResponsiveImage)`
  margin: -5px -4px;
  height: auto;
  ${({ width }) => css`
    width: calc(37px + 6px);
  `};
`;

const MasterCard = ({ ...props }) => <CustomSvg width="50px" height="32px" src={bla} {...props} />;

export default MasterCard;
