import { Phone } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { createPhoneLink } from '../../helpers/whatsappNumber';
import IconButton from '../../atom/IconButton';
import { ockTheme } from '../../themes';
import Typography from '../../atom/Typography';

const CustomIconButton = styled(IconButton)`
  border-radius: 12px !important;
`;

const ColoredPhoneIcon = styled(Phone)`
  /* color: ${ockTheme.colors.ockDarkBlue} !important; */
  margin-right: 5px;
`;

const FlexTypography = styled(Typography)`
  display: flex;
  align-items: center;
`;

const PhoneCall = ({
  text,
  phone,
  sizeVariant,
  analyticsShare,
  analyticsContentId,
  ...props
}) => {
  return phone ? (
    <CustomIconButton
      {...props}
      onClick={() => {
        analyticsShare('phone', analyticsContentId);
        window.open(createPhoneLink(phone), '_blank');
      }}
    >
      <FlexTypography variant={sizeVariant}>
        <ColoredPhoneIcon fontSize="small" color="iconDefault" /> {text}
      </FlexTypography>
    </CustomIconButton>
  ) : null;
};

PhoneCall.defaultProps = {
  sizeVariant: 'body2',
};

PhoneCall.propTypes = {
  text: PropTypes.string.isRequired,
  phone: PropTypes.string,
  sizeVariant: PropTypes.oneOf(['body1', 'body2', 'caption']),
};

export default React.memo(PhoneCall);
