import {
  Token,
  CustomAxiosResponse,
  axiosPOST,
} from '@project/components-typescript/src/services/axiosService';
import { CouponInterface } from './../interfaces/CouponInterface';

export interface CreateCouponInterface
  extends Omit<
    CouponInterface,
    | 'id'
    | 'qty_used'
    | 'active'
    | 'establishments_id'
    | 'createdAt'
    | 'updatedAt'
  > {}

export const createCoupon = async (
  coupon: CreateCouponInterface,
  token: Token,
): Promise<CustomAxiosResponse<CouponInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/coupons`;

    const response = await axiosPOST<CouponInterface>(endpoint, coupon, token);

    return {
      ...response,
      message: 'Cupom criado com sucesso!',
    };
  } catch (error) {
    throw new Error(error?.message || 'Ocorreu um erro, tente novamente.');
  }
};
