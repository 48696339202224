import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { AuthContext } from '@project/components/src/contexts/AuthContextProvider';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { useForm } from 'react-hook-form';
import { Button, Box } from '@project/components';
import { completeStep6Publish } from '../services/completeSteps';
import { useHistory } from 'react-router-dom';
import { VariantEnum } from '@project/components-typescript/src/services/axiosService';
import { SignupFormContext } from '../../../contexts/SignupFormContext/SignupFormContextProvider';

interface Step6ConfirmInterface {}

const Step6Confirm: React.FC<Step6ConfirmInterface> = () => {
  const history = useHistory();
  const [cookies] = useCookies(['token']);
  const { loggedUser } = useContext(AuthContext);
  const signupContext = useContext(SignupFormContext);
  const { setShowBackdrop, setShowSnackbar, setSnackbarInformation } = useContext(FeedbackContext);
  const { handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const onSubmit = (): void => {
    setShowBackdrop(true);

    completeStep6Publish(cookies.token, loggedUser)
      .then(async ({ message, variant }) => {
        setSnackbarInformation({ message, variant });
        await signupContext?.checklist.revalidate();
        history.push('/');
      })
      .catch(({ message }) => {
        setSnackbarInformation({ message, variant: VariantEnum.error });
      })
      .finally(() => {
        setShowBackdrop(false);
        setShowSnackbar(true);
      });
  };

  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button type="submit" onClick={onSubmit} color="primary">
          Finalizar
        </Button>
      </form>
    </Box>
  );
};

export default Step6Confirm;
