import { LocalShippingOutlined, LocationCityOutlined } from '@material-ui/icons';
import { ListItemSecondaryAction, SwitchInput } from '@project/components';
import ListItem from '@project/components/src/atom/List/ListItem';
import ListItemIcon from '@project/components/src/atom/List/ListItemIcon';
import ListItemText from '@project/components/src/atom/List/ListItemText';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import useDelivery from '../../../../hooks/useDelivery';
import { disableDeliveryKmLimit } from '../../../../services/settings/deliveryKmLimitService';
import { EstablishmentSettingsInterface } from '../../../../interfaces/EstablishmentSettingsInterface';
import { GlobalStateContext } from '../../../../contexts/GlobalState/GlobalStateContext';
import LimitKmSection from '../../delivery/LimitKmSection';
import { GlobalStateTypesEnum } from '../../../../contexts/GlobalState/GlobalStateInterface';
import { VariantEnum } from '@project/components-typescript/src/services/axiosService';

const ConfigGeneralDelivery: React.FC = () => {
  const [openKmDialog, setOpenKmDialog] = useState<boolean>(false);
  const [settings, setSettings] = useState<EstablishmentSettingsInterface | undefined>(undefined);
  const { setShowBackdrop, setShowSnackbar, setSnackbarInformation } = useContext(FeedbackContext);
  const [cookies] = useCookies(['token']);
  const history = useHistory();
  const { isLimitedByCities, isLimitedByKm } = useDelivery(settings);
  const { stateGlobalState, dispatchGlobalState } = useContext(GlobalStateContext);

  const fieldsDisabled = !stateGlobalState.settings?.flag_delivery;

  useEffect(() => {
    if (!stateGlobalState.settings) return;

    setSettings(stateGlobalState.settings);
  }, [stateGlobalState.settings]);

  const handleCitiesSwitch = async (): Promise<void> => {
    if (!settings) return;

    try {
      if (isLimitedByKm) {
        setShowBackdrop(true);

        const response = await disableDeliveryKmLimit(cookies.token);
        dispatchGlobalState({
          type: GlobalStateTypesEnum.UPDATE_SETTINGS,
          settings: {
            delivery_km_limit: response.data.delivery_km_limit,
          },
        });

        setSnackbarInformation({
          message: 'Sucesso!',
          variant: VariantEnum.success,
        });

        setShowBackdrop(false);
      }

      if (isLimitedByCities) {
        setSnackbarInformation({
          message: 'Para desabilitar a limitação por cidades, ative a entrega por quilômetro ou desabilite o delivery',
          variant: VariantEnum.warning,
        });
      }
    } catch (error) {
      setSnackbarInformation({
        message: 'Para desabilitar a limitação por cidades, ative a entrega por quilômetro ou desabilite o delivery',
        variant: VariantEnum.warning,
      });
    } finally {
      setShowSnackbar(true);
    }
  };

  const handleLimitKmSwitch = async (): Promise<void> => {
    if (!settings) return;

    try {
      if (isLimitedByKm) {
        setShowBackdrop(true);
        disableDeliveryKmLimit(cookies.token);
      }

      if (isLimitedByCities) {
        setOpenKmDialog(true);
      }
    } catch (error) {}
  };

  return (
    <>
      <ListItem disabled={fieldsDisabled}>
        <ListItemIcon>
          <LocalShippingOutlined color="primary" />
        </ListItemIcon>
        <ListItemText primary="Limitar por cidades" />
        <ListItemSecondaryAction>
          <SwitchInput
            edge="end"
            data-testid="switch-notification-input"
            onChange={handleCitiesSwitch}
            checked={isLimitedByCities}
          />
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem disabled={fieldsDisabled}>
        <ListItemIcon>
          <LocalShippingOutlined color="primary" />
        </ListItemIcon>
        <ListItemText
          primary="Limitar por distância"
          secondary={isLimitedByKm && `Distância máxima: ${settings?.delivery_km_limit} km`}
        />
        <ListItemSecondaryAction>
          <SwitchInput
            edge="end"
            data-testid="switch-notification-input"
            onChange={handleLimitKmSwitch}
            checked={isLimitedByKm}
          />
        </ListItemSecondaryAction>
      </ListItem>

      {!isLimitedByKm && (
        <ListItem
          disabled={!isLimitedByCities || fieldsDisabled}
          button
          onClick={(): void => history.push('/configuracoes/delivery/whitelist')}
        >
          <ListItemIcon>
            <LocationCityOutlined color="primary" />
          </ListItemIcon>
          <ListItemText style={{ overflowWrap: 'break-word' }} primary="Cidades atendidas" />
        </ListItem>
      )}

      {!isLimitedByCities && (
        <ListItem disabled={!isLimitedByKm} button onClick={(): void => setOpenKmDialog(true)}>
          <ListItemIcon>
            <VpnLockIcon color="primary" />
          </ListItemIcon>
          <ListItemText
            style={{ overflowWrap: 'break-word' }}
            primary="Alcance da entrega"
            secondary="Configure a distância máxima de entrega"
          />
        </ListItem>
      )}

      <LimitKmSection openDialog={openKmDialog} setOpenDialog={setOpenKmDialog} />
    </>
  );
};

export default ConfigGeneralDelivery;
