import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../../atom/TextField';
import CurrencyInput from './types/Currency';

const MaskedTextField = ({ customType = CurrencyInput, mask, ...props }) => {
  return (
    <div>
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputComponent: customType,
        }}
        {...props}
      />
    </div>
  );
};

MaskedTextField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default MaskedTextField;
