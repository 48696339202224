import SnackbarInterface from "../../components/Snackbar/SnackbarInterface";
import BackdropInterface from "../../interfaces/BackdropInterface";
import { CLOSE_SNACKBAR, HIDE_BACKDROP, SHOW_BACKDROP, SHOW_SNACKBAR, ViewInterface } from "./ViewReducer";

export const snackbarInitialState: SnackbarInterface = {
  duration: 6000,
  onBottom: true,
  showRevert: false,
  showRevertButton: false,
  revertButtonAction: (): void => { },
  variant: 'default',
  message: '',
  show: false,
  bottomMargin: '50px'
};

export const backdropInitialState: BackdropInterface = false;

export const viewInitialState: ViewInterface = {
  snackbar: snackbarInitialState,
  showBackdrop: backdropInitialState
}

export function viewActionShowSnackbar(
  state: ViewInterface,
  action: SHOW_SNACKBAR,
): ViewInterface {
  return {
    ...state,
    snackbar: {
      ...state.snackbar,
      ...action.snackbar,
      show: true
    }
  }
}

export function viewActionCloseSnackbar(
  state: ViewInterface,
  action: CLOSE_SNACKBAR,
): ViewInterface {
  return {
    ...state,
    snackbar: {
      ...state.snackbar,
      message: '',
      show: false
    }
  }
}

export function viewActionShowBackdrop(
  state: ViewInterface,
  action: SHOW_BACKDROP,
): ViewInterface {
  return {
    ...state,
    showBackdrop: true
  }
}

export function viewActionHideBackdrop(
  state: ViewInterface,
  action: HIDE_BACKDROP,
): ViewInterface {
  return {
    ...state,
    showBackdrop: false
  }
}
