import axios from 'axios';

/**
 * A facade axios PUT implementation
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {object} body the body of the request
 * @param {string} token the authorization token (undefined if none)
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 */
export const axiosPUT = async (endpoint, body, token, otherHeaders = {}) => {
  const response = await axios.put(endpoint, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...otherHeaders,
    },
  });

  return response;
};

/**
 * Use to get
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {string} token the authorization token (undefined if none)
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 */
export const axiosGET = async (endpoint, token, otherHeaders = {}) => {
  try {
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...otherHeaders,
      },
    });

    return response;
  } catch (error) {}
};

/**
 * Use to post
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {object} body
 * @param {string} token the authorization token (undefined if none)
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 */
export const axiosDeprecatedPOST = async (
  endpoint,
  body,
  token,
  otherHeaders = {},
) => {
  const response = await axios.post(endpoint, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...otherHeaders,
    },
  });

  return response;
};

/**
 * Use to delete
 *
 * @param {string} endpoint the endpoint to be called on request
 * @param {string} token the authorization token (undefined if none)
 * @param {object} otherHeaders use this if there is more headers than Authorization. Default: {}
 * @param {object} otherData
 */
export const axiosDelete = async (
  endpoint,
  token,
  otherHeaders = {},
  otherData = {},
) => {
  const response = await axios.delete(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...otherHeaders,
    },
    data: otherData,
  });

  return response;
};
