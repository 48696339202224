import { EmailOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { createEmailLink } from '../../helpers/whatsappNumber';
import IconButton from '../../atom/IconButton';
import Typography from '../../atom/Typography';

const CustomIconButton = styled(IconButton)`
  border-radius: 12px !important;
`;

const CustomEmailOutlined = styled(EmailOutlined)`
  margin-right: 5px;
`;

const FlexTypography = styled(Typography)`
  display: flex;
  align-items: center;
  /* font-size: 12px !important;   */
`;

const EmailMessage = ({
  mailTo,
  subject,
  body,
  text,
  sizeVariant,
  analyticsShare,
  ...props
}) => {
  return mailTo ? (
    <CustomIconButton
      {...props}
      onClick={() => {
        analyticsShare('email', 'minhasVendas-detalhe');
        window.open(createEmailLink(mailTo, subject, body), '_blank');
      }}
    >
      <FlexTypography variant={sizeVariant}>
        <CustomEmailOutlined fontSize="small" color="iconDefault" /> {text}
      </FlexTypography>
    </CustomIconButton>
  ) : null;
};

EmailMessage.defaultProps = {
  sizeVariant: 'body2',
};

EmailMessage.propTypes = {
  mailTo: PropTypes.string.isRequired,
  subject: PropTypes.string,
  body: PropTypes.string,
  text: PropTypes.string,
  sizeVariant: PropTypes.oneOf(['body1', 'body2', 'caption']),
};

export default React.memo(EmailMessage);
