import {
  ExpansionPanel as MaterialExpansionPanel,
  ExpansionPanelSummary as MaterialExpansionPanelSummary,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import Typography from '../../atom/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const CustomExpansionPanelSummary = styled.div`
  display: block !important;
`;

const CustomMaterialExpansionPanelDetails = styled.div`
  display: contents !important;
`;

const ExpansionPanel = ({ children, id, title, subtitle }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <MaterialExpansionPanel
        expanded={expanded === `panel${id}`}
        onChange={handleChange(`panel${id}`)}
      >
        <MaterialExpansionPanelSummary
          expandIcon={children && <ExpandMoreIcon />}
          aria-controls={`panel${id}bh-content`}
          id={`panel${id}bh-header`}
        >
          <CustomExpansionPanelSummary>
            <Typography component="p" gutterBottom display="block">
              {title}
            </Typography>
            {subtitle && subtitle}
          </CustomExpansionPanelSummary>
        </MaterialExpansionPanelSummary>
        {children && (
          <CustomMaterialExpansionPanelDetails>
            {children}
          </CustomMaterialExpansionPanelDetails>
        )}
      </MaterialExpansionPanel>
    </>
  );
};

ExpansionPanel.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  children: PropTypes.node,
};

export default ExpansionPanel;
