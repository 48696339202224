/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import 'firebase/remote-config';
import {
  AvailableCreditCardInterface,
  AvailableSocialMediaInterface,
  FirebaseRemoteConfigProviderInterface,
  layoutTelaPedidosKanbanEnum as LayoutTelaPedidosKanbanEnum,
  RemoteConfigInterface,
  RemoteConfigKeys,
  ShowOtherOptionsItemCrudType,
  ShowSpeedDialMenuType,
} from './FirebaseRemoteConfigInterfaces';
import { remoteConfigInitialState } from './FirebaseRemoteConfigInit';

export const FirebaseRemoteConfigContext = React.createContext<RemoteConfigInterface>(remoteConfigInitialState);

const FirebaseRemoteConfigProvider: React.FC<FirebaseRemoteConfigProviderInterface> = ({ children, remoteConfig }) => {
  const [showSpeedDialMenu, setShowSpeedDialMenu] = useState<ShowSpeedDialMenuType>(
    remoteConfigInitialState.showSpeedDialMenu,
  );

  const [layoutTelaPedidosKanban, setLayoutTelaPedidosKanban] = useState<LayoutTelaPedidosKanbanEnum>(
    remoteConfigInitialState.layoutTelaPedidosKanban,
  );

  const [showOtherOptionsItemCrud, setShowOtherOptionsItemCrud] = useState<ShowOtherOptionsItemCrudType>(
    remoteConfigInitialState.showOtherOptionsItemCrud,
  );

  const [availableSocialMedia, setAvailableSocialMedia] = useState<AvailableSocialMediaInterface>(
    remoteConfigInitialState.availableSocialMedia,
  );
  const [availableCreditCards, setAvailableCreditCards] = useState<AvailableCreditCardInterface>(
    remoteConfigInitialState.availableCreditCards,
  );

  remoteConfig.settings = {
    minimumFetchIntervalMillis: 10000,
    fetchTimeoutMillis: 60000,
  };

  remoteConfig.defaultConfig = {};

  useEffect(() => {
    function setValues(): void {
      setShowSpeedDialMenu(remoteConfig.getBoolean(RemoteConfigKeys.showSpeedDialMenu));
      setShowOtherOptionsItemCrud(remoteConfig.getBoolean(RemoteConfigKeys.showOtherOptionsItemCrud));

      const availableMediaFromFirebase = remoteConfig.getString(RemoteConfigKeys.availableSocialMedia);
      setAvailableSocialMedia(JSON.parse(availableMediaFromFirebase));

      const availableCreditCardsFromFirebase = remoteConfig.getString(RemoteConfigKeys.availableCreditCards);
      setAvailableCreditCards(JSON.parse(availableCreditCardsFromFirebase));

      const str: string = remoteConfig.getString(RemoteConfigKeys.layoutTelaPedidosKanban);
      const layout: LayoutTelaPedidosKanbanEnum =
        str in LayoutTelaPedidosKanbanEnum ? (str as LayoutTelaPedidosKanbanEnum) : LayoutTelaPedidosKanbanEnum.new;

      setLayoutTelaPedidosKanban(layout);
    }

    function fetchConfig(): void {
      remoteConfig
        .fetchAndActivate()
        .then(() => setValues())
        .catch(err => console.error(err));
    }

    remoteConfig
      .ensureInitialized()
      .then(() => fetchConfig())
      .catch(err => console.error('Firebase Remote Config failed to initialize', err));
  }, [remoteConfig]);

  const globals = {
    showSpeedDialMenu,
    layoutTelaPedidosKanban,
    showOtherOptionsItemCrud,
    availableSocialMedia,
    availableCreditCards,
  };

  return <FirebaseRemoteConfigContext.Provider value={globals}>{children}</FirebaseRemoteConfigContext.Provider>;
};

export default FirebaseRemoteConfigProvider;
