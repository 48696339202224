import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { FreightContext } from '../contexts/FreightContext';
import { getFreightIntegrations } from '../services/getFreightIntegrations';
import { Typography, PaperSection } from '@project/components';
import ConfigFreightIntegration from './ConfigFreightIntegration';

interface ListFreightIntegrationsInterface {}

const ListFreightIntegrations: React.FC<ListFreightIntegrationsInterface> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cookies] = useCookies(['token']);
  const { stateFreight, dispatchFreight } = useContext(FreightContext);

  useEffect(() => {
    setIsLoading(true);
    getFreightIntegrations(cookies.token)
      .then(({ data }) => {
        dispatchFreight({ freightIntegrations: data, type: 'ADD_FREIGHT_INTEGRATIONS' });
      })
      .finally(() => setIsLoading(false));
  }, [stateFreight.freightMethods, cookies.token, dispatchFreight]);

  return (
    <PaperSection title="Integrações instaladas" isLoading={isLoading}>
      {stateFreight.freightIntegrations.length === 0 && (
        <Typography>Nenhuma integração de frete instalada ainda</Typography>
      )}

      {stateFreight.freightIntegrations.map(integration => (
        <ConfigFreightIntegration key={`integration-${integration.id}`} integration={integration} />
      ))}
    </PaperSection>
  );
};

export default ListFreightIntegrations;
