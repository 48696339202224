import { FreightIntegrationsInterface } from './../interfaces/FreightIntegrationsInterface';
import {
  Token,
  CustomAxiosResponse,
  axiosGET,
  VariantEnum,
} from '@project/components-typescript/src/services/axiosService';

export const getFreightIntegrations = async (
  token: Token,
): Promise<CustomAxiosResponse<FreightIntegrationsInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/freight/integrations`;

    const response = await axiosGET<FreightIntegrationsInterface[]>(endpoint, token);

    return {
      ...response,
      message: 'Pronto!',
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
