import React from 'react';
import PropTypes from 'prop-types';

import { ListItem as MaterialListItem } from '@material-ui/core';

const ListItem = ({
  alignItems = 'flex-start',
  center = 'center',
  autoFocus = false,
  button = false,
  children,
  classes,
  component,
  ContainerComponent = 'li',
  ContainerProps = {},
  dense = false,
  disabled = false,
  disableGutters = false,
  divider = false,
  selected = false,
  ...props
}) => {
  return (
    <MaterialListItem
      alignItems={alignItems}
      center={center}
      autoFocus={autoFocus}
      button={button}
      children={children}
      classes={classes}
      component={component}
      ContainerComponent={ContainerComponent}
      ContainerProps={ContainerProps}
      dense={dense}
      disabled={disabled}
      disableGutters={disableGutters}
      divider={divider}
      selected={selected}
      {...props}
    />
  );
};

ListItem.propTypes = {
  props: PropTypes.node,
};

export default ListItem;
