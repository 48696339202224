import { Switch as MaterialSwitchInput } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import React from 'react';
import Box from '../Box';

const SwitchInput = ({
  checked,
  variant,
  fullWidth,
  label,
  value,
  color,
  type,
  InputLabelProps,
  mt,
  mb,
  ...props
}) => {
  return (
    <Box>
      <FormControlLabel
        control={
          <MaterialSwitchInput checked={checked} value={value} {...props} />
        }
        label={label}
      />
    </Box>
    // <SwitchInput
    //   checked={state.checkedA}
    //   onChange={handleChange('checkedA')}
    //   value='checkedA'
    //   label='Bla'
    //   inputProps={{ 'aria-label': 'secondary checkbox' }}
    // />
  );
};

SwitchInput.defaultProps = {
  checked: false,
  variant: 'outlined',
  fullWidth: true,
  color: 'primary',
  type: 'number',
};

SwitchInput.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  InputLabelProps: PropTypes.shape({
    shrink: PropTypes.bool,
  }),
};

export default SwitchInput;
