import axios from 'axios';

export async function setDeliveryKmLimit(token, value) {
  const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/settings`;

  const intValue = parseInt(value);

  const response = await axios.put(
    endpoint,
    { delivery_km_limit: intValue },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );

  return response;
}

export async function disableDeliveryKmLimit(token) {
  const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/settings`;

  const response = await axios.put(
    endpoint,
    { delivery_km_limit: 0 },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );

  return response;
}
