import { Box, Button, Grid, TextField } from '@project/components';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Backdrop from '@project/components/src/atom/Backdrop';
import ResponsiveImage from '../atom/ResponsiveImage';
import { doAuthentication } from '../services/authentication';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { InputAdornment } from '@material-ui/core';
import { AccountCircle, Lock } from '@material-ui/icons';
import Typography from '../atom/Typography';
import styled from 'styled-components';

const CustomLink = styled(Typography)`
  text-decoration: underline !important;
`;

const GenericLoginPage = ({ setloggedUser, redirect, showLogo, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies([
    'token',
    'restaurantId',
    'restaurantUid',
  ]);
  const [openbackdrop, setOpenbackdrop] = React.useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleSubmit = event => {
    event.preventDefault();
    handleLogin();
  };

  const handleLogin = () => {
    setOpenbackdrop(true);

    doAuthentication(username, password).then(data => {
      const { user, token, status } = data;
      if (status.status === 200) {
        setCookie('token', token, {
          maxAge: '86000',
          path: '/',
        });
        setloggedUser({ ...user });
        history.push(redirect);
      } else {
        // TODO: Tirar alert e colocar no forms
        alert('Usuário errado, tente novamente');
      }

      setOpenbackdrop(false);
      //setSnackbar(true)
    });
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      {...props}
    >
      <Grid item xs={12} sm={6}>
        <Box p={4}>
          <Box mb={4}>
            {showLogo ? (
              <ResponsiveImage src="/logo-ockpay-blue-gradient-shadow.png" />
            ) : (
              <>
                <Grid align="center">
                  <Typography color="primary" variant="h4">
                    Login
                  </Typography>
                  <Box mt={1}>
                    <Typography variant="body2">Não tem cadastro?</Typography>
                    <CustomLink
                      onClick={() => history.push('/cadastro')}
                      color="primary"
                      variant="body2"
                    >
                      Clique e faça já o seu!
                    </CustomLink>
                  </Box>
                </Grid>
              </>
            )}
          </Box>

          <form onSubmit={handleSubmit}>
            <Box mb={1}>
              <TextField
                id="username"
                value={username}
                onChange={event => setUsername(event.target.value)}
                placeholder="Usuário"
                variant="standard"
                fullWidth
                data-testid="login-input-username"
                required
                autoComplete="username"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <TextField
              id="password"
              value={password}
              onChange={event => setPassword(event.target.value)}
              placeholder="Senha"
              variant="standard"
              data-testid="login-input-password"
              fullWidth
              type="password"
              required
              autoComplete="current-password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock color="primary" />
                  </InputAdornment>
                ),
              }}
            />

            <Box mt={6}>
              <Button
                color="primary"
                fullWidth
                data-testid="login-button-submit"
                size="large"
                type="submit"
              >
                Entrar
              </Button>
            </Box>
          </form>
        </Box>
      </Grid>

      {openbackdrop && <Backdrop open={openbackdrop}></Backdrop>}
    </Grid>
  );
};

GenericLoginPage.defaultProps = {
  redirect: '/',
  showLogo: true,
};

GenericLoginPage.propTypes = {
  redirect: PropTypes.string,
  setloggedUser: PropTypes.func,
  showLogo: PropTypes.bool,
};

export default GenericLoginPage;
