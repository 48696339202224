import React from 'react';
import PropTypes from 'prop-types';

import '@firebase/performance';

// TODO: implements this
// eslint-disable-next-line no-unused-vars
import { useFirebasePerformance } from '@project/components/src/hooks/useFirebasePerformance';

export const FirebasePerformanceContext = React.createContext(undefined);

const FirebasePerformanceProvider = ({ children, performance }) => {
  const globals = {
    performance,
  };

  return <FirebasePerformanceContext.Provider value={globals}>{children}</FirebasePerformanceContext.Provider>;
};

FirebasePerformanceProvider.propTypes = {
  children: PropTypes.node.isRequired,
  performance: PropTypes.any.isRequired,
};

export default FirebasePerformanceProvider;
