import { UPDATE_ORDER, OrdersContextInterface } from '../OrdersInterface';

export function updateOrderAction(
  state: OrdersContextInterface,
  action: UPDATE_ORDER,
): OrdersContextInterface {
  return {
    ...state,
    orders: state.orders.map(stateOrder =>
      stateOrder.order.id === action.order.id
        ? { order: action.order, isNew: false }
        : stateOrder,
    ),
  };
}
