import React, { useState } from 'react';

import PropTypes from 'prop-types';

export const AppBarContext = React.createContext(undefined);

const AppBarContextProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('Ockpay');
  const [showGoBack, setShowGoBack] = useState(false);
  const [rightIcon, setRightIcon] = useState({});
  const [rightText, setRightText] = useState(undefined);
  const [logoHeader, setLogoHeader] = useState(false);
  const [showClientLogo, setShowClientLogo] = useState(false);
  const [clientLogoUrl, setClientLogoUrl] = useState(false);
  const [position, setPosition] = useState('fixed');
  const [backgroundColor, setBackgroundColor] = useState('white');
  const [customShowGoback, setCustomShowGoback] = useState(undefined);
  const [showBottomNavigation, setShowBottomNavigation] = useState(true);

  const globals = {
    pageTitle,
    setPageTitle,
    showGoBack,
    setShowGoBack,
    rightIcon,
    setRightIcon,
    rightText,
    setRightText,
    logoHeader,
    setLogoHeader,
    showClientLogo,
    setShowClientLogo,
    clientLogoUrl,
    setClientLogoUrl,
    position,
    setPosition,
    backgroundColor,
    setBackgroundColor,
    customShowGoback,
    setCustomShowGoback,
    showBottomNavigation,
    setShowBottomNavigation,
  };

  return (
    <AppBarContext.Provider value={globals}>{children}</AppBarContext.Provider>
  );
};

AppBarContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppBarContextProvider;
