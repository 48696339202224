/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { LoggedUserInterface } from '../interfaces/LoggedUserInterface';
import CompleteRegistration from '../pages/completeRegistration/CompleteRegistration';
import SignupFormContextProvider from '../contexts/SignupFormContext/SignupFormContextProvider';
import { useChecklist } from '../pages/completeRegistration/services/getChecklist';
import { ChecklistEnum } from '../pages/completeRegistration/interfaces/ChecklistInterface';

interface SignUpedMiddlewareInterface {
  component: React.ReactElement;
  loggedUser?: LoggedUserInterface;
}

const SignUpedMiddleware: React.FC<SignUpedMiddlewareInterface> = ({ children, component }) => {
  const [cookies] = useCookies(['token']);
  const { data } = useChecklist(cookies.token);

  const allOk: boolean = useMemo(() => {
    if (!data) return true;

    return Object.values(data || {}).every(val => val === ChecklistEnum.ok);
  }, [data]);

  return (
    <Route
      render={(): React.ReactNode =>
        allOk ? (
          <Switch>{component || children}</Switch>
        ) : (
          <SignupFormContextProvider>
            <CompleteRegistration />
          </SignupFormContextProvider>
        )
      }
    />
  );
};

export default SignUpedMiddleware;
