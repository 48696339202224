import React from 'react';
import PropTypes from 'prop-types';

const Generic = ({ children, width, height }) => {
  // TODO: Component logic

  return (
    <svg
      width={width}
      height={height}
      enable-background="new 0 0 780 500"
      version="1.1"
      viewBox="0 0 780 500"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
};

Generic.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
};

Generic.defaultProps = {
  height: '50px',
  width: '50px',
};

export default React.memo(Generic);
