import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import List from '../../atom/List';
import { useForm } from 'react-hook-form';
import Skeleton from '../../atom/Skeleton';
import { RHFInput } from 'react-hook-form-input';
import { Box, Container } from '../../..';
import getFreightPrediction from '../../services/getFreightPrediction';
import styled from 'styled-components';
import Divider from '../../atom/Divider';
import Search from '../../assets/Search.svg';
import LocalizationCard from '../LocalizationCard/LocalizationCard';

const messages = {
  twoWords: 'Quase lá, não esqueça nome da rua e número!',
  searching: 'Procurando pelo endereço...',
  nothing: 'Digite o endereço',
  needMore: 'Informe o endereço e número',
  result: '',
  noNumber: 'Digite o número do endereco',
  label: 'Endereço e número para entrega',
  placeholder: 'Endereço e número',
  error: 'Ooops, tente novamente!',
  notfound: 'Endereço não encontrado, verifique novamente!',
  // success: 'Iremos entregar no endereço acima!',
  success: '',
  initial: '',
};

const KEY_PRESS_TIMEOUT = 1000;
const googleImage =
  'https://storage.googleapis.com/ockpay-static/images/originals/powered_by_google_on_white.png';

const addressRegexRules = [
  {
    message: messages.twoWords,
    rule: /\s+/,
  },
  {
    message: messages.twoWords,
    rule: /[a-záéíóúàèìòùäëïöüç]+/i,
  },
  {
    message: messages.noNumber,
    rule: /\s+[0-9]+/,
  },
];

const WrapperHelperText = styled.div`
  .MuiFormHelperText-root {
    font-size: 15px !important;
  }
`;

const RoundedDiv = styled.div`
  div {
    border-radius: 18px !important;
  }
`;

const SearchInput = styled(TextField)`
  color: #d4d4d4;

  svg {
    color: #383d6b;
  }

  input {
    padding: 12px !important;
  }
`;

const MapsAutocomplete = ({ skeletonHeight, setChosenAddress }) => {
  const inputForm = useRef(undefined);
  const { register, setValue, formState, errors, watch } = useForm();
  const formAddress = watch('autocomplete_address');
  const { dirty } = formState;
  const [isFetchingAddress, setIsFetchingAddress] = useState(false);
  const [addressSugestions, setAddressSugestions] = useState([]);
  const [keypressDelay, setKeypressDelay] = useState(() => {});
  const [blockReload, setBlockReload] = useState(false);

  const [helperMessage, setHelperMessage] = useState(messages.initial);

  const [ruleTimeout, setRuleTimeout] = useState(false);
  const [ruleAllRegex, setRuleAllRegex] = useState(false);

  // const stopTimeout = () =>
  const startTimeout = () => {
    setRuleTimeout(false);
    clearTimeout(keypressDelay);
    setKeypressDelay(setTimeout(() => setRuleTimeout(true), KEY_PRESS_TIMEOUT));
  };

  const showText = useCallback(
    (text, width = '115px') =>
      isFetchingAddress ? (
        <Skeleton width={width} height={skeletonHeight} />
      ) : (
        text
      ),
    [isFetchingAddress, skeletonHeight],
  );

  useEffect(() => {
    if (ruleTimeout && ruleAllRegex && !blockReload) {
      setHelperMessage(messages.searching);
      setIsFetchingAddress(true);

      getFreightPrediction(formAddress)
        .then(({ predictions, status }) => {
          if (status === 'OK') {
            setAddressSugestions(predictions);
            setHelperMessage(messages.result);
          } else {
            setHelperMessage(messages.notfound);
            setAddressSugestions([]);
          }
        })
        .catch(err => {
          setHelperMessage(err.message);
          setAddressSugestions([]);
        })
        .finally(() => setIsFetchingAddress(false));
    }

    setBlockReload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formAddress, ruleAllRegex, ruleTimeout]);

  const handleOnChange = value => {
    setRuleAllRegex(true);

    addressRegexRules.forEach(set => {
      if (!value.match(set.rule)) {
        setHelperMessage(set.message);
        setRuleAllRegex(false);
      }
    });

    if (dirty && value === '') setAddressSugestions([]);
  };

  return (
    <>
      <Container maxWidth="md">
        <WrapperHelperText>
          <RoundedDiv>
            <RHFInput
              as={
                <SearchInput
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Search} alt="Icone de busca" />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  inputRef={inputForm}
                  type="address"
                  autoComplete
                  fullWidth
                />
              }
              register={register({
                required: 'Esse campo é obrigatório',
              })}
              onKeyUp={startTimeout}
              onKeyDown={() => setHelperMessage(messages.twoWords)}
              onChange={event => handleOnChange(event.target.value)}
              name="autocomplete_address"
              placeholder={messages.placeholder}
              setValue={setValue}
              error={errors.autocomplete_address}
              helperText={
                (errors.autocomplete_address &&
                  errors.autocomplete_address.message) ||
                helperMessage
              }
            />
          </RoundedDiv>
        </WrapperHelperText>

        <Box align="right" mt={1}>
          <img
            src={googleImage}
            alt="Powered by Google"
            width="116"
            height="14"
          />
        </Box>
      </Container>

      <List component="nav" aria-label="main mailbox folders">
        {addressSugestions.map(
          ({ id, fullAddress, mainText, secondaryText }) => (
            <>
              <LocalizationCard
                key={id}
                onClick={() => {
                  setBlockReload(true);
                  setValue('autocomplete_address', fullAddress);
                  setChosenAddress(fullAddress);
                  setAddressSugestions([]);
                  setHelperMessage(messages.success);
                }}
                primary={showText(mainText)}
                secondary={showText(secondaryText, '250px')}
              />
              <Divider />
            </>
          ),
        )}
      </List>
    </>
  );
};

MapsAutocomplete.propTypes = {
  skeletonHeight: PropTypes.string,
};

MapsAutocomplete.defaultProps = {
  skeletonHeight: '22px',
};

export default MapsAutocomplete;
