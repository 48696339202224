/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { LocalAtm } from '@material-ui/icons';
import { EstablishmentSettingsInterface } from '../../../interfaces/EstablishmentSettingsInterface';
import updateFlagServiceFee, { UpdateFlagServiceFeeInterface } from '../../../services/settings/updateFlagServiceFee';
import { useCookies } from 'react-cookie';
import ConfigSwitch from '../../../components/ConfigSwitch';
import { CustomAxiosResponse } from '@project/components-typescript/src/services/axiosService';

interface PresentialServiceFeeInterface {
  settings: EstablishmentSettingsInterface;
}

const PresentialServiceFee: React.FC<PresentialServiceFeeInterface> = ({ settings }) => {
  const [flagServiceFee, setFlagServiceFee] = useState<boolean>(settings.flag_service_fee);
  const [cookies] = useCookies(['token']);

  const handleOpenClose = async (): Promise<CustomAxiosResponse<UpdateFlagServiceFeeInterface>> =>
    updateFlagServiceFee(!flagServiceFee, cookies.token);

  return (
    <ConfigSwitch
      checked={flagServiceFee}
      onChange={handleOpenClose}
      text="Cobrar taxa de serviço?"
      subtitle='10% somente para vendas em modo "Presencial"'
      icon={<LocalAtm color="primary" />}
      setChecked={setFlagServiceFee}
    />
  );
};

export default PresentialServiceFee;
