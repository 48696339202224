import { axiosDeprecatedPOST } from './axiosService';
export interface DiscordGenericNotificationInterface {
  allowed_mentions: AllowedMentions;
  embeds: Embed[];
}

export enum ParseEnum {
  everyone = 'everyone',
}

export enum ColorEnum {
  success = '51281',
  error = '16729156',
}

export interface AllowedMentions {
  parse: ParseEnum[];
}

export interface Embed {
  author: Author;
  title: string;
  color: ColorEnum;
  fields: Field[];
}

export interface Author {
  name: string;
}

export interface Field {
  name: string;
  value: string;
  inline: boolean;
}

export const emitDiscordNotification = (
  webhook: string,
  payload: DiscordGenericNotificationInterface,
) => {
  try {
    axiosDeprecatedPOST<void>(webhook, payload);
  } catch (error) {
    console.error(error);
  }
};
