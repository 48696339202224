import React from 'react';
import styled from 'styled-components';
import Typography from '@project/components/src/atom/Typography';
import {
  TextField,
  MaskedTextField,
  SelectField,
  Box,
  Checkbox,
} from '@project/components';
import { ockTheme } from '@project/components/src/themes';
import Fab from '@project/components/src/atom/Fab';
import { ArrowForwardIosRounded } from '@material-ui/icons';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { ifProp } from 'styled-tools';
import FormHelperText from '@material-ui/core/FormHelperText';

export const WhiteTypography = styled(Typography)`
  /* color: white; */
`;

export const BlueTypography = styled(Typography)`
  /* color: ${ockTheme.colors.ockLightBlue}; */
  /* font-weight: bold !important; */
`;

export const WhiteTextField = ({ ...props }) => {
  return (
    <>
      {/* <GlobalStyle /> */}
      <TextField
        error
        variant="standard"
        InputLabelProps={{
          shrink: true,
        }}
        {...props}
      />
    </>
  );
};

export const WhiteMaskedTextField = ({ ...props }) => {
  return (
    <>
      {/* <GlobalStyle /> */}
      <MaskedTextField variant="standard" {...props} />
    </>
  );
};

export const WhiteSelectField = ({ ...props }) => {
  return <SelectField variant="standard" {...props} />;
  // Legacy:
  // return (
  //   <SelectField>
  //     <GlobalStyle />
  //     <MaskedCssSelectField variant='standard' {...props} />
  //   </SelectField>
  // )
};

export const TitleSection = ({ title, description, ...props }) => (
  <Box mb={2}>
    {title && <Typography variant="h5">{title}</Typography>}
    {description && <Typography variant="body2">{description}</Typography>}
  </Box>
);

const CustomFab = styled(Fab)`
  bottom: 1em !important;
  background-color: ${ockTheme.colors.ockLightBlue} !important;
  color: white !important;
`;

export const SubmitFab = ({ ...props }) => (
  <CustomFab aria-label="próxima etapa" type="submit" {...props}>
    <ArrowForwardIosRounded />
  </CustomFab>
);

export const CustomFormLabel = styled(FormLabel)`
  /* color: white !important; */
`;

export const CustomRadioGroup = styled(RadioGroup)`
  /* flex-direction: row !important; */
`;

export const CustomFormControlLabel = styled(FormControlLabel)`
  /* color: white !important; */
`;

export const CustomRadio = styled(Radio)`
  color: ${ifProp('error', '#f44336', ockTheme.colors.ockLightBlue)} !important;
`;

export const BlueCheckbox = styled(Checkbox)`
  color: ${ifProp('error', '#f44336', ockTheme.colors.ockLightBlue)} !important;
`;

export const ErrorFormHelperText = styled(FormHelperText)`
  color: #f44336 !important;
`;
