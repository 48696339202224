import { TextField as MaterialTextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const CustomMaterialTextField = styled(MaterialTextField)`
  ${({ hidden }) =>
    hidden &&
    css`
      display: none !important;
    `}
`;

const TextField = ({ variant, hidden, fullWidth, ...props }) => {
  return <CustomMaterialTextField hidden={hidden} variant={variant} fullWidth={fullWidth} {...props} />;
};

TextField.propTypes = {
  props: PropTypes.node,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  hidden: PropTypes.bool,
};

TextField.defaultProps = {
  variant: 'outlined',
  fullWidth: true,
  hidden: false,
};

export default TextField;
