import { axiosGET, axiosPUT, CustomAxiosResponse } from '@project/components-typescript/src/services/axiosService';
import { EstablishmentInterface } from '../interfaces/EstablishmentInterface';

export async function getEstablishment(token): Promise<EstablishmentInterface | undefined> {
  try {
    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/establishment/info`;

    const { data } = await axiosGET<EstablishmentInterface>(endpoint, token);

    return data;
  } catch (e) {
    console.error(e);
  }
}

export const updateEstablishment = async <T>(payload, token): Promise<CustomAxiosResponse<null>> => {
  try {
    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/establishment`;

    const response = await axiosPUT<null>(endpoint, payload, token);

    return response;
  } catch (e) {
    throw new Error('Tivemos um problema ao atualizar o seu estabelecimento');
  }
};
