import { LocalShippingOutlined } from '@material-ui/icons';
import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import ConfigSwitch from '../../../../components/ConfigSwitch';
import { GlobalStateContext } from '../../../../contexts/GlobalState/GlobalStateContext';
import { GlobalStateTypesEnum } from '../../../../contexts/GlobalState/GlobalStateInterface';
import { updateDeliveryConfiguration } from '../../../../services/configs';

interface ConfigDeliveryInterface {}

const ConfigDelivery: React.FC<ConfigDeliveryInterface> = () => {
  const { stateGlobalState, dispatchGlobalState } = useContext(GlobalStateContext);
  const [cookies] = useCookies(['token']);
  const deliveryEnabled = stateGlobalState.settings?.flag_delivery;

  const handleChangeFlagDelivery = async (): Promise<void> => {
    try {
      await updateDeliveryConfiguration(!deliveryEnabled, cookies.token);
      dispatchGlobalState({
        type: GlobalStateTypesEnum.UPDATE_SETTINGS,
        settings: {
          flag_delivery: !deliveryEnabled,
        },
      });
    } catch (error) {}
  };

  return (
    <ConfigSwitch
      checked={deliveryEnabled}
      onChange={handleChangeFlagDelivery}
      text="Delivery"
      subtitle="Você entrega"
      icon={<LocalShippingOutlined color="primary" />}
    />
  );
};

export default ConfigDelivery;
