import React from 'react';
import PropTypes from 'prop-types';

import { Box as MaterialBox } from '@material-ui/core';

const Box = ({ children, ...props }) => {
  return <MaterialBox {...props}>{children}</MaterialBox>;
};

Box.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(Box);
