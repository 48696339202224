import {
  DialogContent,
  Dialog as MaterialDialog,
  DialogActions as MaterialDialogActions,
  DialogContent as MaterialDialogContent,
  DialogContentText as MaterialDialogContentText,
  DialogTitle as MaterialDialogTitle,
  CircularProgress as MaterialCircularProgress,
  Slide as MaterialSlide,
} from '@material-ui/core';

import Button from '@project/components/src/atom/Button';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import Grid from '../../atom/Grid';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <MaterialSlide direction="up" ref={ref} {...props} />;
});

const CustomMaterialDialogContent = styled(MaterialDialogContent)`
  ${({ fullScreen }) =>
    fullScreen &&
    css`
      padding: 0 !important;
    `}
`;

const Dialog = ({
  children,
  onDisagree = () => {},
  onAgree = () => {},
  contentTitle = 'Confirmação',
  contentText = 'Você realmente deseja realizar essa operação?',
  disagreText = 'Cancelar',
  agreeText = 'Confirmar',
  agreeColor = 'primary',
  disagreeColor = 'default',
  variant = 'text',
  loading = false,
  fullScreen = false,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = onClickActions => {
    setOpen(false);
    onClickActions();
  };

  return (
    <MaterialDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullScreen={fullScreen}
      {...props}
    >
      <MaterialDialogTitle disableTypography id="alert-dialog-slide-title">
        {contentTitle}
      </MaterialDialogTitle>
      <CustomMaterialDialogContent
        fullScreen={fullScreen}
        dividers={fullScreen}
      >
        {!loading ? (
          <>
            <MaterialDialogContentText id="alert-dialog-slide-description">
              {contentText}
            </MaterialDialogContentText>
            {children && (
              <CustomMaterialDialogContent fullScreen={fullScreen}>
                {children}
              </CustomMaterialDialogContent>
            )}
          </>
        ) : (
          <DialogContent>
            <Grid align="center">
              <MaterialCircularProgress color="secondary" />
            </Grid>
          </DialogContent>
        )}
      </CustomMaterialDialogContent>
      {!loading && (disagreText || agreeText) && (
        <MaterialDialogActions>
          {disagreText && (
            <Button
              variant={variant}
              onClick={() => handleClose(onDisagree)}
              color={disagreeColor}
            >
              {disagreText}
            </Button>
          )}
          {agreeText && (
            <Button
              variant={variant}
              onClick={() => handleClose(onAgree)}
              color={agreeColor}
            >
              {agreeText}
            </Button>
          )}
        </MaterialDialogActions>
      )}
    </MaterialDialog>
  );
};

Dialog.propTypes = {
  onDisagree: PropTypes.func,
  onAgree: PropTypes.func,
  contentTitle: PropTypes.string,
  contentText: PropTypes.string,
  disagreText: PropTypes.string,
  agreeText: PropTypes.string,
};

export default Dialog;
