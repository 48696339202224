/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';
import { Paper } from '@project/components';
import { Stepper } from '@material-ui/core';
import { SignupFormContext } from '../../../contexts/SignupFormContext/SignupFormContextProvider';
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { SignupStepEnum } from '../../../contexts/SignupFormContext/SignupFormContextInterface';
import { ChecklistEnum } from '../interfaces/ChecklistInterface';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps(): string[] {
  return ['Confirmação', 'Tipo de negócio', 'Endereço', 'Conta bancária', 'Planos', 'Publique'];
}

interface ChecklistInterface {}

const Checklist: React.FC<ChecklistInterface> = () => {
  const classes = useStyles();
  const steps = getSteps();
  const signupForm = useContext(SignupFormContext);

  useEffect(() => {
    if (signupForm?.checklist.data?.published === ChecklistEnum.nok) signupForm?.setSignupStep(SignupStepEnum.publish6);

    if (signupForm?.checklist.data?.plan === ChecklistEnum.nok) signupForm?.setSignupStep(SignupStepEnum.plans5);

    if (signupForm?.checklist.data?.bankAccount === ChecklistEnum.nok)
      signupForm?.setSignupStep(SignupStepEnum.bankAccount4);

    if (signupForm?.checklist.data?.address === ChecklistEnum.nok) signupForm?.setSignupStep(SignupStepEnum.address3);

    if (signupForm?.checklist.data?.businessType === ChecklistEnum.nok)
      signupForm?.setSignupStep(SignupStepEnum.businessType2);

    if (signupForm?.checklist.data?.gatewaySignup === ChecklistEnum.nok)
      signupForm?.setSignupStep(SignupStepEnum.confirmation1);
  }, [signupForm]);

  return (
    <Paper>
      <div className={classes.root}>
        <Stepper activeStep={signupForm?.signupStep} orientation="vertical">
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {signupForm?.signupStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - you&apos;re finished</Typography>
          </Paper>
        )}
      </div>
    </Paper>
  );
};

export default Checklist;
