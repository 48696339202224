import React, { useRef } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Box, Container } from '@project/components';
import FaqSearch from '../sections/FaqSearch';
import FaqList from '../sections/FaqList';
import Footer from '@project/components-typescript/src/components/Footer';

interface PublicFaqPageInterface {}

const GlobalStyle = createGlobalStyle`
  body {
    font-size: 16px;
    line-height: 1.6;
    color: #565867;
    font-weight: 400;
    background: #f3f5f7;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    -moz-osx-font-smoothing: grayscale;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    height: 100%;
    min-height: 100%;
  }
`;

const PublicFaqPage: React.FC<PublicFaqPageInterface> = () => {
  const ref = useRef<HTMLInputElement>(null);
  const headerHeight = ref.current ? `${Math.round(ref.current.getBoundingClientRect().height)}px` : '250px';

  return (
    <>
      <GlobalStyle />
      <div ref={ref}>
        <Box mb={2}>
          <FaqSearch isPublic />
        </Box>
      </div>

      <Box minHeight={`calc(96vh - ${headerHeight})`}>
        <Container maxWidth="md">
          <Box mb={8}>
            <FaqList />
          </Box>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default PublicFaqPage;
