import { ArrowForwardIosOutlined } from '@material-ui/icons';
import Button from '../../atom/Button';
import Grid from '../../atom/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { ockTheme } from '../../themes';
import Chip from '../../atom/Chip';
import BottomButton from '../../molecule/BottomButton';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

const CustomBottomButton = styled(BottomButton)`
  ${ifProp(
    { isBottomNavigateActivated: false },
    css`
      margin-bottom: 0 !important;
    `,
  )}
`;

const CheckoutBar = ({
  sizeTotal,
  total,
  disabled,
  redirect,
  text,
  icon,
  isBottomNavigateActivated,
  type,
  ...props
}) => {
  return (
    <CustomBottomButton
      disabled={disabled}
      isBottomNavigateActivated={isBottomNavigateActivated}
      data-testid="checkoutBar"
      {...props}
    >
      {total !== undefined && (
        <Grid item xs={sizeTotal}>
          <Chip
            data-testid="checkoutBar-total"
            backgroundColor={ockTheme.colors.ockGreenDark}
            textColor="white"
            shape="squareRounded"
            label={(total / 100).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          />
        </Grid>
      )}
      <Grid item xs>
        <Button
          data-testid="checkoutBar-text"
          fullWidth
          disabled={disabled}
          textColor="white"
          variant="text"
          onClick={redirect}
          endIcon={icon}
          type={type}
        >
          {text}
        </Button>
      </Grid>
    </CustomBottomButton>
  );
};

CheckoutBar.propTypes = {
  isBottomNavigateActivated: PropTypes.bool,
  sizeTotal: PropTypes.number,
  total: PropTypes.number,
  disabled: PropTypes.bool,
  redirect: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.node,
};

CheckoutBar.defaultProps = {
  isBottomNavigateActivated: true,
  sizeTotal: 5,
  total: undefined,
  disabled: true,
  redirect: () => {},
  icon: <ArrowForwardIosOutlined />,
  text: 'Salvar',
};

export default React.memo(CheckoutBar);
