import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as R from 'ramda';

import Typography from '../../atom/Typography';
import Box from '../../atom/Box';
import { parseCurrencyToABNT } from '../../utils/Parser';
import { createMapsAddressIframe } from '../../utils/maps';

const DeliveryInformation = ({ delivery, freightInformation, showMaps }) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setShouldRender(!R.isEmpty(delivery) && delivery);
  }, [delivery]);

  return shouldRender ? (
    <>
      <Typography variant="subtitle2">Endereço de entrega</Typography>
      <Typography variant="caption">
        {delivery.address}, {delivery.number}{' '}
        {delivery.complement && ` - ${delivery.complement}`}
      </Typography>
      <br />
      <Typography variant="caption">
        {`${delivery.neighborhood}, ${delivery.city} - ${delivery.uf}`}
      </Typography>

      <Box mt={2}>
        <Typography variant="subtitle2">Observações da entrega</Typography>
        <Typography variant="caption">
          {delivery.observation ? delivery.observation : 'Sem observações'}
        </Typography>
        {delivery.phone && (
          <>
            <br />
            <Typography variant="caption">
              {delivery.phone && delivery.phone}
            </Typography>
          </>
        )}
      </Box>

      {freightInformation && (
        <Box mt={2}>
          <Typography variant="subtitle2">Taxa de entrega</Typography>
          <Typography variant="caption">
            {freightInformation.delivery_total !== 0
              ? parseCurrencyToABNT(freightInformation.delivery_total)
              : 'Grátis'}
          </Typography>
          <br />
          <Typography variant="caption">
            {freightInformation.distance} de distância
          </Typography>
          <br />
          <Typography variant="caption">
            Aproximadamente {freightInformation.duration}
          </Typography>
        </Box>
      )}

      {showMaps && (
        <Box mb={2}>
          <iframe
            id={`mapa-${delivery.zip_code}`}
            title={`Mapa para ${delivery.address}`}
            src={createMapsAddressIframe(
              delivery.address,
              delivery.number,
              delivery.city,
              delivery.zip_code,
            )}
            height="300"
            width="100%"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </Box>
      )}
    </>
  ) : null;
};

DeliveryInformation.propTypes = {
  order: PropTypes.node.isRequired,
  freightInformation: PropTypes.node,
  showMaps: PropTypes.bool,
};

DeliveryInformation.defaultProps = {
  showMaps: true,
};

export default memo(DeliveryInformation);
