import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import Reducer, { OrdersAction } from './OrdersReducer';
import { ordersInitialState } from './OrdersInitializer';
import { OrdersContextInterface, OrdersTypesEnum } from './OrdersInterface';
import { AuthContext } from '@project/components/src/contexts/AuthContextProvider';

export type OrdersDispatchType = React.Dispatch<OrdersAction>;

interface OrdersProviderInterface {
  stateOrders: OrdersContextInterface;
  dispatchOrders: OrdersDispatchType;
  hasNewOrders: boolean;
}

export const OrdersContext = createContext<OrdersProviderInterface>({
  stateOrders: ordersInitialState,
  dispatchOrders: () => console.warn('OrdersDispatch not ready'),
  hasNewOrders: false,
});

const OrdersProvider: React.FC = ({ children }) => {
  const { loggedUser } = useContext(AuthContext);
  const [stateOrders, dispatchOrders] = useReducer(Reducer, ordersInitialState);
  const [hasNewOrders, setHasNewOrders] = useState<boolean>(false);

  useEffect(
    () =>
      setHasNewOrders(
        stateOrders.orders.find(({ isNew }) => isNew) !== undefined,
      ),
    [stateOrders],
  );

  useEffect(() => {
    return (): void => {
      // Remove all orders when user logout
      dispatchOrders({ type: OrdersTypesEnum.POPULATE_ORDERS, orders: [] });
      dispatchOrders({ type: OrdersTypesEnum.UNSELECT_ORDER });
    };
  }, [loggedUser]);

  const globals = {
    stateOrders,
    dispatchOrders,
    hasNewOrders,
  };

  return (
    <OrdersContext.Provider value={globals}>{children}</OrdersContext.Provider>
  );
};

export default OrdersProvider;
