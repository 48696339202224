import {
  ADD_SETTINGS,
  GlobalContextInterface,
  UPDATE_SETTINGS,
  ADD_LOGGED_USER,
  GlobalStateTypesEnum,
} from './GlobalStateInterface';
import {
  globalStateActionAddLoggedUserMethods,
  globalStateActionAddSettingsMethods,
  globalStateActionUpdateSettings,
} from './GlobalStateActions';

export type GlobalStateAction = ADD_SETTINGS | ADD_LOGGED_USER | UPDATE_SETTINGS;

function globalStateReducer(state: GlobalContextInterface, action: GlobalStateAction): GlobalContextInterface {
  switch (action.type) {
    case GlobalStateTypesEnum.ADD_SETTINGS:
      return globalStateActionAddSettingsMethods(state, action);

    case GlobalStateTypesEnum.UPDATE_SETTINGS:
      return globalStateActionUpdateSettings(state, action);

    case GlobalStateTypesEnum.ADD_LOGGED_USER:
      return globalStateActionAddLoggedUserMethods(state, action);

    default:
      return state;
  }
}

export default globalStateReducer;
