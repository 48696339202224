import React from 'react';
import PropTypes from 'prop-types';

import { Collapse as MaterialCollapse } from '@material-ui/core';

const Collapse = ({ ...props }) => {
  return <MaterialCollapse {...props} />;
};

Collapse.propTypes = {
  props: PropTypes.node,
};

export default Collapse;
