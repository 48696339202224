import { FreightContextInterface } from '../interfaces/FreightContextInterface';
import {
  ADD_FREIGHT_INTEGRATIONS,
  ADD_FREIGHT_METHODS,
  SELECT_FREIGHT_INTEGRATIONS,
  UPDATE_FREIGHT_INTEGRATIONS,
  UPDATE_FREIGHT_METHODS,
} from './FreightReducer';

export const freightInitialState: FreightContextInterface = {
  freightMethods: [],
  freightIntegrations: [],
  selectedIntegration: null,
};

export function freightActionAddFreightMethods(
  state: FreightContextInterface,
  action: ADD_FREIGHT_METHODS,
): FreightContextInterface {
  return {
    ...state,
    freightMethods: action.freightMethods,
  };
}

export function freightActionAddFreightIntegrations(
  state: FreightContextInterface,
  action: ADD_FREIGHT_INTEGRATIONS,
): FreightContextInterface {
  return {
    ...state,
    freightIntegrations: action.freightIntegrations,
  };
}

export function freightActionUpdateFreightMethods(
  state: FreightContextInterface,
  action: UPDATE_FREIGHT_METHODS,
): FreightContextInterface {
  const freightMethods = state.freightMethods.map(freight =>
    freight.id === action.freightMethod.id ? action.freightMethod : freight,
  );

  return {
    ...state,
    freightMethods,
  };
}

export function freightActionUpdateFreightIntegrations(
  state: FreightContextInterface,
  action: UPDATE_FREIGHT_INTEGRATIONS,
): FreightContextInterface {
  const freightIntegrations = state.freightIntegrations.map(freight =>
    freight.id === action.freightIntegration.id ? action.freightIntegration : freight,
  );

  return {
    ...state,
    freightIntegrations: freightIntegrations,
  };
}

export function freightActionSelectFreightIntegrations(
  state: FreightContextInterface,
  action: SELECT_FREIGHT_INTEGRATIONS,
): FreightContextInterface {
  return {
    ...state,
    selectedIntegration: action.freightIntegration,
  };
}

export function freightActionUnselectFreightIntegrations(state: FreightContextInterface): FreightContextInterface {
  return {
    ...state,
    selectedIntegration: null,
  };
}
