import React, { useContext } from 'react';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { AuthContext } from '@project/components/src/contexts/AuthContextProvider';
import { useCookies } from 'react-cookie';
import { SignupFormContext } from '../../../contexts/SignupFormContext/SignupFormContextProvider';
import { SignupStepEnum } from '../../../contexts/SignupFormContext/SignupFormContextInterface';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { WhiteSelectField } from '../components/Fields';
import { Button } from '@project/components';
import { completeStep5Plans } from '../services/completeSteps';
import { VariantEnum } from '@project/components-typescript/src/services/axiosService';

interface Step5PlansInterface {}

type FormData = {
  plan: string;
};

const Step5Plans: React.FC<Step5PlansInterface> = () => {
  const [cookies] = useCookies(['token']);
  const { setShowBackdrop, setShowSnackbar, setSnackbarInformation } = useContext(FeedbackContext);
  const { loggedUser } = useContext(AuthContext);
  const signupContext = useContext(SignupFormContext);

  const { register, setValue, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const onSubmit = (values: FormData): void => {
    setShowBackdrop(true);

    completeStep5Plans(values, cookies.token, loggedUser)
      .then(async ({ message, variant }) => {
        await signupContext?.setSignupStep(SignupStepEnum.publish6);
        signupContext?.checklist.revalidate();
        setSnackbarInformation({ message, variant });
      })
      .catch(({ message }) => {
        setSnackbarInformation({
          message,
          variant: VariantEnum.error,
        });
      })
      .finally(() => {
        setShowSnackbar(true);
        setShowBackdrop(false);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RHFInput
          name="plan"
          as={
            <WhiteSelectField
              id="plan"
              variant="standard"
              label="Tipo de negócio"
              title="Clique para selecionar"
              options={[
                { value: 'free', label: 'Grátis' },
                { value: 'essential', label: 'Essêncial' },
                { value: 'premium', label: 'Premium' },
              ]}
              placeholder="Selecione um tipo de negócio"
              error={errors.plan}
              helperText={errors.plan && errors.plan.message}
            />
          }
          register={register}
          rules={{ required: true }}
          setValue={setValue}
        />

        <Button type="submit" color="primary">
          Confirmar
        </Button>
      </form>
    </div>
  );
};

export default Step5Plans;
