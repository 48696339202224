import { Stepper as MaterialStepper } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Typography from '../../atom/Typography';

const CustomMaterialStepper = styled(MaterialStepper)`
  padding: 0 !important;
  padding-right: 0 !important;
`;

const Stepper = ({ step, steps, ...props }) => {
  return (
    <CustomMaterialStepper activeStep={step} {...props}>
      {steps &&
        steps.map((label, index) => {
          return (
            <Step data-testid={`stepper-step-${index}`} key={label}>
              <StepLabel data-testid={`stepper-stepLabel-${index}`}>
                <Typography
                  data-testid={`stepper-typography-${index}`}
                  variant="caption"
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
    </CustomMaterialStepper>
  );
};

Stepper.defaultProps = {
  step: 0,
  steps: [],
};

Stepper.propTypes = {
  step: PropTypes.number,
  steps: PropTypes.array,
};

export default React.memo(Stepper);
