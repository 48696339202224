import Box from '../../atom/Box';
import Skeleton from '../../atom/Skeleton';
import Paper from '../../atom/Paper';
import PageTitle from '../PageTitle';
import PropTypes from 'prop-types';
import React from 'react';

const PaperSection = ({
  children,
  title,
  subtitle,
  isLoading,
  first = false,
  hideMobile = true,
  hideBrowser = false,
}) => {
  return (
    <>
      <Box mt={!first ? 3 : 0} mb={2}>
        {title && <PageTitle title={title} subtitle={subtitle} />}
        <Paper hideMobile={hideMobile} hideDesktop={hideBrowser}>
          {isLoading && (
            <Box m="-1em">
              <Skeleton
                variant="squareRounded"
                height="250px"
                animation="wave"
                width="100%"
              />
            </Box>
          )}
          {!isLoading && <Box p={1}>{children}</Box>}
        </Paper>
      </Box>
    </>
  );
};

PaperSection.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  subtitle: PropTypes.string,
  mb: PropTypes.number,
  mt: PropTypes.number,
  variant: PropTypes.string,
  component: PropTypes.string,
};

export default PaperSection;
