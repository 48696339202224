export const translateValidate = status => {
  switch (status) {
    case 'validate':
      return 'Em validação';

    case 'hiring':
      return 'Aguardando contratação';

    case 'hired':
      return 'Contratado';

    case 'wait':
      return 'Aguardando';

    case 'canceled':
      return 'Cancelado';

    default:
      return 'Sem status';
  }
};

export const getInitials = str => {
  const ignore = ['do', 'da', 'o', 'a', 'na', 'no'];

  const formatedArr = str.split(' ').filter(str => !ignore.includes(str));

  const initials =
    formatedArr.length <= 1
      ? `${formatedArr[0][0]}${formatedArr[0][1]}`
      : `${formatedArr[0][0]}${formatedArr[1][0]}`;

  return initials.toUpperCase();
};

export function capitalize(string = '') {
  return string.charAt(0).toUpperCase() + string.substr(1).toLowerCase();
}

export function formatName(string = '') {
  return string
    .split(' ')
    .map(str => capitalize(str))
    .join(' ');
}

export function slugify(text = '', separator = '-') {
  text = text
    .toString()
    .toLowerCase()
    .trim();

  const sets = [
    { to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
    { to: 'c', from: '[ÇĆĈČ]' },
    { to: 'd', from: '[ÐĎĐÞ]' },
    { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
    { to: 'g', from: '[ĜĞĢǴ]' },
    { to: 'h', from: '[ĤḦ]' },
    { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
    { to: 'j', from: '[Ĵ]' },
    { to: 'ij', from: '[Ĳ]' },
    { to: 'k', from: '[Ķ]' },
    { to: 'l', from: '[ĹĻĽŁ]' },
    { to: 'm', from: '[Ḿ]' },
    { to: 'n', from: '[ÑŃŅŇ]' },
    { to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
    { to: 'oe', from: '[Œ]' },
    { to: 'p', from: '[ṕ]' },
    { to: 'r', from: '[ŔŖŘ]' },
    { to: 's', from: '[ßŚŜŞŠ]' },
    { to: 't', from: '[ŢŤ]' },
    { to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
    { to: 'w', from: '[ẂŴẀẄ]' },
    { to: 'x', from: '[ẍ]' },
    { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
    { to: 'z', from: '[ŹŻŽ]' },
    { to: '-', from: "[·/_,:;']" },
  ];

  sets.forEach(set => {
    text = text.replace(new RegExp(set.from, 'gi'), set.to);
  });

  text = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    // eslint-disable-next-line no-useless-escape
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    // eslint-disable-next-line no-useless-escape
    .replace(/\--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

  if (typeof separator !== 'undefined' && separator !== '-') {
    text = text.replace(/-/g, separator);
  }

  return text;
}

// export function slugify(text = '', separator = '-') {
//   const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
//   const to = 'aaaaaeeeeeiiiiooooouuuunc------'

//   text
//     .split('')
//     .map((letter, i) => console.log(letter.replace(new RegExp(from.charAt(i), 'g')), to.charAt(i)))

//   const newText = text
//     .split('')
//     .map((letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

//   return newText
//     .toString() // Cast to string
//     .toLowerCase() // Convert the string to lowercase letters
//     .trim() // Remove whitespace from both sides of a string
//     .replace(/\s+/g, separator) // Replace spaces with -
//     .replace(/&/g, '-y-') // Replace & with 'and'
//     .replace(/[^/\w/-]+/g, '') // Remove all non-word chars
//     .replace(/--+/g, separator) // Replace multiple - with single -
// }
