import React, { createContext, useReducer } from 'react';
import Reducer, { FaqAction } from './FaqReducer';
import { faqInitialState } from './FaqInitializer';
import { FaqContextInterface } from './FaqInterface';

export type FaqDispatchType = React.Dispatch<FaqAction>;

interface FaqProviderInterface {
  stateFaq: FaqContextInterface;
  dispatchFaq: FaqDispatchType;
}

export const FaqContext = createContext<FaqProviderInterface>({
  stateFaq: faqInitialState,
  dispatchFaq: () => console.warn('FaqDispatch not ready'),
});

const FaqProvider: React.FC = ({ children }) => {
  const [stateFaq, dispatchFaq] = useReducer(Reducer, faqInitialState);

  const globals = {
    stateFaq,
    dispatchFaq,
  };

  return <FaqContext.Provider value={globals}>{children}</FaqContext.Provider>;
};

export default FaqProvider;
