const defaultHeaders = {
  'Content-Type': 'application/json',
};

export const serviceCreate = async (
  path = '',
  data = {},
  method = 'POST',
  headers = defaultHeaders,
) => {
  try {
    const response = await fetch(`${path}`, {
      headers: {
        ...headers,
      },
      method: method,
      body: JSON.stringify(data),
    });

    const status = await response;
    const body = await response.json();
    return {
      status,
      response: body,
    };
  } catch (e) {
    console.error(e);
  }
};

export const serviceEdit = async (
  path = '',
  data = {},
  method = 'PUT',
  headers = defaultHeaders,
) => {
  try {
    const response = await fetch(`${path}`, {
      headers: {
        ...headers,
      },
      method: method,
      body: JSON.stringify(data),
    });

    return response;
  } catch (e) {
    console.error(e);
  }
};

export const serviceGet = async (path = '', headers = defaultHeaders) => {
  try {
    const response = await fetch(`${path}`, {
      headers: {
        ...defaultHeaders,
        ...headers,
      },
      method: 'GET',
    });

    return response.json();
  } catch (e) {
    console.error(e);
  }
};

export const serviceDelete = async (path = '', headers = defaultHeaders) => {
  try {
    const response = await fetch(`${path}`, {
      headers: {
        ...headers,
      },
      method: 'DELETE',
    });

    return response;
  } catch (e) {
    console.error(e);
  }
};

// NEW

export const servicePUT = async (
  path = '',
  data = {},
  headers = defaultHeaders,
  method = 'PUT',
) => {
  try {
    const response = await fetch(path, {
      headers: {
        ...defaultHeaders,
        ...headers,
      },
      method: method,
      body: JSON.stringify(data),
    });

    const statusResponse = await response;
    const body = await response.json();
    return {
      statusResponse,
      ...body,
    };
  } catch (e) {
    console.error(e);
  }
};

export const serviceGET = async (
  path = '',
  headers = defaultHeaders,
  method = 'GET',
) => {
  try {
    const response = await fetch(path, {
      headers: {
        ...defaultHeaders,
        ...headers,
      },
      method: method,
    });

    const statusResponse = await response;
    const body = await response.json();
    return {
      statusResponse,
      ...body,
    };
  } catch (e) {
    console.error(e);
  }
};

export const servicePOST = async (
  path = '',
  data = {},
  headers = defaultHeaders,
  method = 'POST',
) => {
  try {
    const response = await fetch(path, {
      headers: {
        ...defaultHeaders,
        ...headers,
      },
      method: method,
      body: JSON.stringify(data),
    });

    const statusResponse = await response;
    const body = await response.json();
    return {
      statusResponse,
      ...body,
    };
  } catch (e) {
    console.error(e);
  }
};
