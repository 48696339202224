import { EditableCell } from '@project/components-typescript/src/components/MUITSDatatable';
import { ExtendableCellInterface } from '@project/components-typescript/src/components/MUITSDatatable/EditableCell';
import { HTMLElementInputTypeEnum } from '@project/components-typescript/src/components/MUITSDatatable/MUITSDatatableInterface';
import { parseISO } from 'date-fns';

import React from 'react';
import { useCookies } from 'react-cookie';
import { editCoupon } from '../services/editCoupon';

interface EndDateCellInterface extends ExtendableCellInterface {}

const EndDateCell: React.FC<EndDateCellInterface> = ({
  tableMeta,
  updateValue,
  // value,
}): JSX.Element => {
  const [cookies] = useCookies(['token']);
  const id = tableMeta.rowData[0];

  const handleUpdate = async (valueToUpdate: string): Promise<void> => {
    const date = parseISO(valueToUpdate);
    editCoupon(id, { due_date: date }, cookies.token);
  };

  // const date = new Date(value).toISOString().split('T')[0];
  return (
    <EditableCell
      type={HTMLElementInputTypeEnum.date}
      value={'2021-01-01'}
      tableMeta={tableMeta}
      updateValue={updateValue}
      handleUpdate={handleUpdate}
      rules={{
        required: false,
      }}
    />
  );
};

export default EndDateCell;
