import React, { createContext, useContext, useEffect, useReducer } from 'react';
import globalStateReducer, { GlobalStateAction } from './GlobalStateReducer';
import { globalStateInitialState } from './GlobalStateActions';
import { GlobalContextInterface, GlobalStateTypesEnum } from './GlobalStateInterface';
import { AuthContext } from '@project/components/src/contexts/AuthContextProvider';
import { getAllSettings } from '../../services/settings/settings';
import { useCookies } from 'react-cookie';
import { LoggedUserInterface } from '../../interfaces/LoggedUserInterface';

export type GlobalStateDispatchType = React.Dispatch<GlobalStateAction>;

interface GlobalStateProviderInterface {
  stateGlobalState: GlobalContextInterface;
  dispatchGlobalState: GlobalStateDispatchType;
}

export const GlobalStateContext = createContext<GlobalStateProviderInterface>({
  stateGlobalState: globalStateInitialState,
  dispatchGlobalState: () => console.warn('GlobalStateDispatch not ready'),
});

const GlobalStateProvider: React.FC = ({ children }) => {
  const [cookies] = useCookies(['token']);
  const context = useContext<unknown>(AuthContext);
  const [stateGlobalState, dispatchGlobalState] = useReducer(globalStateReducer, globalStateInitialState);

  useEffect(() => {
    if (typeof context === 'object' && context && context['loggedUser'] !== undefined) {
      const loggedUser: LoggedUserInterface = context['loggedUser'];
      dispatchGlobalState({ type: GlobalStateTypesEnum.ADD_LOGGED_USER, loggedUser });

      getAllSettings(cookies.token)
        .then(settings => dispatchGlobalState({ type: GlobalStateTypesEnum.ADD_SETTINGS, settings }))
        .catch(error => console.error(error));
    }
  }, [context, cookies.token]);

  const globals = {
    stateGlobalState,
    dispatchGlobalState,
  };

  return <GlobalStateContext.Provider value={globals}>{children}</GlobalStateContext.Provider>;
};

export default GlobalStateProvider;
