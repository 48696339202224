export enum ChecklistEnum {
  ok = 'ok',
  nok = 'nok',
}

export interface ChecklistInterface {
  businessType: ChecklistEnum;
  address: ChecklistEnum;
  gatewaySignup: ChecklistEnum;
  bankAccount: ChecklistEnum;
  plan: ChecklistEnum;
  published: ChecklistEnum;
}

export enum SignupStepsEnum {
  step1 = 'Step 1 - Confirmation',
  step2 = 'Step 2 - BusinessType',
  step3 = 'Step 3 - Address',
  step4 = 'Step 4 - Bank account',
  step5 = 'Step 5 - Plans',
  step6 = 'Step 6 - Publish',
}
