import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, Typography } from '@project/components';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { SignupFormContext } from '../../../contexts/SignupFormContext/SignupFormContextProvider';
import { SignupStepEnum } from '../../../contexts/SignupFormContext/SignupFormContextInterface';
import getBusinessType from '../services/getBusinessType';
import { BusinessTypeInterface } from '@project/components-typescript/src/interfaces/BusinessTypeInterface';
import { WhiteSelectField } from '../components/Fields';
import { CompleteStep2BusinessTypeInterface, completeStep2BusinessType } from '../services/completeSteps';
import { AuthContext } from '@project/components/src/contexts/AuthContextProvider';
import { VariantEnum } from '@project/components-typescript/src/services/axiosService';

interface Step2ConfirmationInterface {}

interface FormData {
  id_business_type: number;
}

const Step2Confirmation: React.FC<Step2ConfirmationInterface> = () => {
  const [cookies] = useCookies(['token']);
  const [businessType, setBusinessType] = useState<BusinessTypeInterface[]>([]);
  const { setShowBackdrop, setShowSnackbar, setSnackbarInformation } = useContext(FeedbackContext);
  const signupContext = useContext(SignupFormContext);
  const { loggedUser } = useContext(AuthContext);

  const { register, setValue, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    getBusinessType()
      .then(response => setBusinessType(response))
      .catch(() => console.error('opa. deu ruim'));
  }, []);

  const onSubmit = (values: FormData): void => {
    setShowBackdrop(true);

    const data: CompleteStep2BusinessTypeInterface = values;
    completeStep2BusinessType(data, cookies.token, loggedUser)
      .then(async ({ message, variant }) => {
        await signupContext?.checklist.revalidate();
        signupContext?.setSignupStep(SignupStepEnum.address3);
        setSnackbarInformation({ message, variant });
      })
      .catch(({ message }) => {
        setSnackbarInformation({
          message: message,
          variant: VariantEnum.error,
        });
      })
      .finally(() => {
        setShowSnackbar(true);
        setShowBackdrop(false);
      });
  };

  if (!businessType) return <Typography>Carregando...</Typography>;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RHFInput
          name="id_business_type"
          as={
            <WhiteSelectField
              id="id_business_type"
              variant="standard"
              label="Tipo de negócio"
              title="Clique para selecionar"
              options={businessType.map(bt => {
                return {
                  value: bt.id,
                  label: bt.name,
                };
              })}
              placeholder="Selecione um tipo de negócio"
              error={errors.id_business_type}
              helperText={errors.id_business_type && errors.id_business_type.message}
            />
          }
          register={register}
          rules={{ required: true }}
          setValue={setValue}
        />

        <Button type="submit" color="primary">
          Confirmar
        </Button>
      </form>
    </div>
  );
};

export default Step2Confirmation;
