import { FreightMethodsInterface } from './../interfaces/FreightMethodsInterface';
import { FreightIntegrationsInterface } from './../interfaces/FreightIntegrationsInterface';
import {
  Token,
  CustomAxiosResponse,
  axiosPUT,
  axiosDeprecatedPOST,
  axiosDelete,
  VariantEnum,
} from '@project/components-typescript/src/services/axiosService';

interface UpdateObjInterface {
  api_key?: string;
  username?: string;
  active?: boolean;
}

export const updateFreightIntegration = async (
  integration: FreightIntegrationsInterface,
  updateObj: UpdateObjInterface,
  token: Token,
): Promise<CustomAxiosResponse<FreightIntegrationsInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/freight/integrations/${integration.id}`;

    const response = await axiosPUT<void>(endpoint, updateObj, token);

    return {
      ...response,
      data: {
        ...integration,
        ...updateObj,
      },
      message: 'Pronto!',
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};

export const installFreightMethod = async (
  integration: FreightMethodsInterface,
  token: Token,
): Promise<CustomAxiosResponse<FreightMethodsInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/freight/integrations?methodId=${integration.id}`;

    if (integration.installed)
      throw new Error(`A integração "${integration.label}" já esta instalada.`);

    const response = await axiosDeprecatedPOST<void>(endpoint, {}, token);

    return {
      ...response,
      data: {
        ...integration,
        installed: true,
      },
      message: `Integração "${integration.label} instalada!"`,
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};

export const uninstallFreightMethod = async (
  integration: FreightMethodsInterface,
  token: Token,
): Promise<CustomAxiosResponse<FreightMethodsInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/freight/integrations/${integration.id}`;

    if (!integration.installed)
      throw new Error(
        `A integração "${integration.label}" já esta desinstalada.`,
      );

    const response = await axiosDelete<void>(endpoint, token);

    return {
      ...response,
      data: {
        ...integration,
        installed: false,
      },
      message: `Integração "${integration.label} desinstalada!"`,
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
