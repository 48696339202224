import { MUIDataTableProps } from 'mui-datatables';

export interface MUIColumnOptionsInterface {
  filter: boolean;
  sort: boolean;
}

export enum MUIResponsiveEnum {
  stacked = 'stacked',
  vertical = 'vertical',
  standard = 'standard',
  simple = 'simple',
}

export enum MUIFilterTypeEnum {
  checkbox = 'checkbox',
  dropdown = 'dropdown',
  multiselect = 'multiselect',
  textField = 'textField',
  custom = 'custom',
}

export interface MUITranslationInterface {
  body: MUITranslationBodyInterface;
  pagination: MUITranslationPaginationInterface;
  toolbar: MUITranslationToolbarInterface;
  filter: MUITranslationFilterInterface;
  viewColumns: MUITranslationViewColumnsInterface;
  selectedRows: MUITranslationSelectedRowsInterface;
}

interface MUITranslationBodyInterface {
  noMatch: string;
  toolTip: string;
}

interface MUITranslationFilterInterface {
  all: string;
  title: string;
  reset: string;
}

interface MUITranslationPaginationInterface {
  next: string;
  previous: string;
  rowsPerPage: string;
  displayRows: string;
  jumpToPage: string;
}

interface MUITranslationSelectedRowsInterface {
  text: string;
  delete: string;
  deleteAria: string;
}

interface MUITranslationToolbarInterface {
  search: string;
  downloadCsv: string;
  print: string;
  viewColumns: string;
  filterTable: string;
}

interface MUITranslationViewColumnsInterface {
  title: string;
  titleAria: string;
}

export interface CustomMUIDataTableProps
  extends Omit<MUIDataTableProps, 'columns' | 'data' | 'options' | 'title'> {}

export enum HTMLElementInputTypeEnum {
  text = 'text',
  color = 'color',
  checkbox = 'checkbox',
  date = 'date',
  datetime = 'datetime-local',
  email = 'email',
  number = 'number',
  password = 'password',
  range = 'range',
  phone = 'phone',
  url = 'url',
}
