import { axiosGET } from '@project/components-typescript/src/services/axiosService';
import { ChecklistInterface } from '../interfaces/ChecklistInterface';
import useRequest, { Return } from '@project/components-typescript/src/services/useRequest';

const ENDPOINT = `${process.env.REACT_APP_ESTABLISHMENT_URL}/establishment/checklist`;

async function getChecklist(token: string): Promise<ChecklistInterface> {
  const response = await axiosGET<ChecklistInterface>(ENDPOINT, token);

  return {
    ...response?.data,
  };
}

export type UseChecklistInterface = Return<ChecklistInterface, any>;

export function useChecklist(token: string): UseChecklistInterface {
  const response = useRequest<ChecklistInterface, any>(ENDPOINT, token);

  return {
    ...response,
  };
}

export default getChecklist;
