import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox as MaterialCheckbox } from '@material-ui/core';

const Checkbox = ({ children, ...props }) => {
  return <MaterialCheckbox {...props}>{children}</MaterialCheckbox>;
};

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Checkbox;
