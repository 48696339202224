import React from 'react';
import { PrismicFormattedElementInterface, PrismicSpanTypeEnum } from '../../interfaces/PrismicInterfaces';
import { Box, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';

interface PrismicRenderTypeInterface {
  prismicElement: PrismicFormattedElementInterface;
}

const PrismicRenderType: React.FC<PrismicRenderTypeInterface> = ({ prismicElement }) => {
  const { text, spans } = prismicElement;

  const FallbackReturn = (): JSX.Element => (
    <Typography variant="body1" component="p">
      {text}
    </Typography>
  );

  if (text === '') return <Box component="br" mb={1} />;

  try {
    if (spans[0].type === PrismicSpanTypeEnum.strong) {
      return (
        <Typography variant="body1" component="p">
          <strong>{text}</strong>
        </Typography>
      );
    }

    if (spans[0].type === PrismicSpanTypeEnum.hyperlink) {
      return (
        <Link href={spans[0].data?.url || '#'} target={spans[0].data?.target} color="primary">
          <strong>{text}</strong>
        </Link>
      );
    }
  } catch (error) {
    return <FallbackReturn />;
  }

  return <FallbackReturn />;
};

export default React.memo(PrismicRenderType);
