import { Badge as MaterialBadge } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const CustomBadge = styled(MaterialBadge)`
  ${({ blink }) =>
    blink &&
    css`
      .MuiBadge-badge {
        animation: blinker 1.25s linear infinite;

        @keyframes blinker {
          50% {
            opacity: 50%;
          }
        }
      }
    `}
`;

const Badge = ({ children, color, variant, show, blink, ...props }) => {
  return !show ? (
    children
  ) : (
    <CustomBadge blink={blink} color={color} variant={variant} {...props}>
      {children}
    </CustomBadge>
  );
};

Badge.defaultProps = {
  show: true,
  color: 'secondary',
  variant: 'standard',
};

Badge.propTypes = {
  children: PropTypes.node,
  props: PropTypes.node,
};

export default Badge;
