import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Grid from '../../atom/Grid';
import Box from '../../atom/Box';
import Status from '../../atom/Status';

import { convertCentsToMoney } from '../../utils/CreditCardValidators';
import OrderHeader from '../../molecule/OrderHeader';
import Paper from '../../atom/Paper';
import OrderPreview from '../OrderPreview';

const OrderDetail = ({ order }) => {
  const { serviceTax, status, order_number, createdAt } = order;
  const {
    client_name,
    table,
    delivery_information,
    formattedPedido,
    subTotal,
    installments,
  } = order.order_details;

  return (
    <>
      <Box mb={2}>
        <Grid item xs={12} align="center">
          <Status size="medium" status={status} />
        </Grid>
      </Box>

      <OrderHeader
        clientName={client_name}
        createdAt={createdAt}
        orderNumber={order_number}
        table={table}
        variant="body2"
      />

      {console.debug(order)}
      <Box mt={2} mb={2}>
        <Paper elevation={1}>
          <OrderPreview
            formattedPedido={formattedPedido}
            subTotal={subTotal}
            serviceTax={serviceTax}
            total={convertCentsToMoney(order.grand_total)}
            showDelivery
            totalText="Valor da compra"
            deliveryFeeValue={
              delivery_information && delivery_information.deliveryFeeValue
            }
            moreInformation={
              installments && installments.months > 1
                ? `Compra parcelada em ${installments.months} vezes`
                : `Compra feita à vista (crédito)`
            }
          />
        </Paper>
      </Box>

      {/* <Box mt={2} mb={2}>
          <OrderList order={order} />

          <Box mt={3}>
            <Typography
              data-testid='order-detail-grand-total'
              variant='h5'
              align='right'
            >
              {parseCurrencyToABNT(convertCentsToMoney(grand_total))}
            </Typography>
          </Box>
        </Paper>
      </Box> */}
    </>
  );
};

OrderDetail.propTypes = {
  order: PropTypes.node.isRequired,
};

export default memo(OrderDetail);
