import {
  Token,
  CustomAxiosResponse,
  axiosGET,
  VariantEnum,
} from '@project/components-typescript/src/services/axiosService';
import { CouponInterface } from './../interfaces/CouponInterface';

export const listCoupons = async (
  token: Token,
): Promise<CustomAxiosResponse<CouponInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/coupons`;

    const response = await axiosGET<CouponInterface[]>(endpoint, token);

    return {
      ...response,
      message: 'Cupons carregado com sucesso!',
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
