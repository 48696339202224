import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { Box } from '@project/components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PrismicResultsInterface } from '../../../interfaces/PrismicInterfaces';
import { PrismicArticlesResponseInterface } from '../interfaces/PrismicArticleInterface';
import styled from 'styled-components';
import PrismicRender from '../../../components/PrismicRender/PrismicRender';

interface FaqAccordionInterface {
  faq: PrismicResultsInterface<PrismicArticlesResponseInterface>;
  expanded: string | boolean;
  setExpanded: React.Dispatch<React.SetStateAction<string | boolean>>;
}

const CustomAccordionDetails = styled(AccordionDetails)`
  flex-direction: column !important;
`;

const FaqAccordion: React.FC<FaqAccordionInterface> = ({ faq, expanded, setExpanded }) => {
  const handleChange = panel => (_event, isExpanded): void => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box mb={2}>
      <Accordion expanded={expanded === faq.id} onChange={handleChange(faq.id)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Box display="flex" flexDirection="column">
            <PrismicRender data={faq.data.nome_do_artigo} />
            <PrismicRender data={faq.data.descricao_do_artigo} />
          </Box>
        </AccordionSummary>
        <CustomAccordionDetails>
          <PrismicRender data={faq.data.corpo_do_artigo} />
        </CustomAccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FaqAccordion;
