import React from 'react';
import PropTypes from 'prop-types';

import Elo from './components/Elo';
import MasterCard from './components/Mastercard';
import Amex from './components/Amex';
import Visa from './components/Visa';
import styled from 'styled-components';
import Diners from './components/diners';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2px;
`;

const Separator = styled.div`
  margin-right: 5px;
`;

const CreditCardFlags = ({ width, height }) => {
  return (
    <Wrapper>
      <Separator>
        <MasterCard width={width} height={height} />
      </Separator>

      <Separator>
        <Elo width={width} height={height} />
      </Separator>

      <Separator>
        <Visa width={width} height={height} />
      </Separator>

      <Separator>
        <Amex width={width} height={height} />
      </Separator>

      <Separator>
        <Diners width={width} height={height} />
      </Separator>
    </Wrapper>
  );
};

CreditCardFlags.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

CreditCardFlags.defaultProps = {
  width: '37px',
  height: '24px',
};

export default React.memo(CreditCardFlags);
