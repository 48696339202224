import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
// import { AppBarContext } from '@project/components/src/contexts/AppBarContext';
import { RHFInput } from 'react-hook-form-input';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
// import { useHistory, useParams } from 'react-router-dom';
import {
  SwitchInput,
  Grid,
  TextField,
  PaperSection,
  Box,
} from '@project/components';
// import { CouponInterface } from '../interfaces/CouponInterface';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DateFormatEnum } from '../../configurations/sales/contexts/SalesPageInit';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { createCoupon, CreateCouponInterface } from '../services/createCoupon';
import { useCookies } from 'react-cookie';
import { VariantEnum } from '@project/components-typescript/src/services/axiosService';

interface CouponCrudInterface {}

interface FormData extends CreateCouponInterface {}

const CouponCrud: React.FC<CouponCrudInterface> = () => {
  const [limitCoupons, setLimitCoupons] = useState<boolean>(false);
  const [limitDate, setLimitDate] = useState<boolean>(false);
  const [cookies] = useCookies(['token']);
  // const history = useHistory();
  const {
    setShowBackdrop,
    setShowSnackbar,
    setSnackbarInformation,
  } = useContext(FeedbackContext);
  const [startDate, setStartDate] = React.useState<ParsableDate>(null);
  const [dueDate] = React.useState<ParsableDate>(null);
  // const { setPageTitle } = useContext(AppBarContext);
  // const { id } = useParams();
  // const isEditPage: boolean = id !== 'novo';

  const { register, handleSubmit, setValue, errors } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  // useLayoutEffect(() => {
  //   setPageTitle(isEditPage ? 'Editar cupom' : 'Criar cupom');
  // }, [setPageTitle]);

  const onSubmit = async (values: FormData): Promise<void> => {
    setShowBackdrop(true);
    createCoupon(values, cookies.token)
      .then(({ message, variant }) => {
        setSnackbarInformation({ message, variant });
        // history.push('/cupons');
      })
      .catch(({ message }) =>
        setSnackbarInformation({ message, variant: VariantEnum.error }),
      )
      .finally(() => {
        setShowSnackbar(true);
        setShowBackdrop(false);
      });
  };

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <PaperSection first title="Sobre o cupom">
              <RHFInput
                name="name"
                as={
                  <TextField
                    id="form-name"
                    autoFocus={false}
                    label="Nome do cupom"
                    disabled={false}
                    placeholder="Para lembrar do que é o cupom"
                    error={errors.name}
                    helperText={errors.name?.message}
                  />
                }
                register={register}
                rules={{ required: false }}
                setValue={setValue}
              />

              <RHFInput
                name="coupon_code"
                as={
                  <TextField
                    id="form-coupon_code"
                    autoFocus={false}
                    label="Código do cupom"
                    disabled={false}
                    placeholder="#"
                    error={errors.coupon_code}
                    helperText={errors.coupon_code?.message}
                  />
                }
                register={register}
                rules={{ required: 'O cupom é obrigatório' }}
                setValue={setValue}
              />

              <RHFInput
                name="description"
                as={
                  <TextField
                    id="form-description"
                    autoFocus={false}
                    label="Descrição do cupom"
                    disabled={false}
                    // placeholder="Placeholder"
                    error={errors.description}
                    helperText={errors.description?.message}
                  />
                }
                register={register}
                rules={{ required: false }}
                setValue={setValue}
              />
              <RHFInput
                name="discount"
                as={
                  <TextField
                    type="number"
                    id="form-discount"
                    autoFocus={false}
                    label="Porcentagem do desconto"
                    disabled={false}
                    placeholder="%"
                    error={errors.discount}
                    helperText={errors.discount?.message}
                  />
                }
                register={register}
                rules={{
                  required: 'É necessário informar uma porcentagem',
                  max: {
                    message: 'O máximo de desconto é 100%',
                    value: 100,
                  },
                  min: {
                    value: 1,
                    message: 'O mínimo de desconto é 1%',
                  },
                }}
                setValue={setValue}
              />
            </PaperSection>
          </Grid>

          <Grid item xs={12} md={6}>
            <PaperSection first title="Quantidade cupons">
              <SwitchInput
                label="Limitar a quantidade de cupons disponíveis?"
                name="limit-qty_available"
                onChange={(): void => setLimitCoupons(!limitCoupons)}
                checked={limitCoupons}
              />
              {limitCoupons && (
                <Box mt={2}>
                  <RHFInput
                    name="qty_available"
                    as={
                      <TextField
                        id="form-qty_available"
                        type="number"
                        autoFocus={false}
                        label="Quantidade de cupons disponível"
                        disabled={false}
                        placeholder="Deixe vazio para infinito"
                        error={errors.qty_available}
                        helperText={errors.qty_available?.message}
                      />
                    }
                    register={register}
                    rules={{ required: false }}
                    setValue={setValue}
                  />
                </Box>
              )}
            </PaperSection>

            <PaperSection first title="Programação de data">
              <SwitchInput
                name="limit-date"
                label="Ativar a programação de data no cupom?"
                onChange={(): void => setLimitDate(!limitDate)}
                checked={limitDate}
              />
              {limitDate && (
                <Box
                  mt={2}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <RHFInput
                    name="start_date"
                    as={
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        minDateMessage="Data não pode ser anterior ao dia de hoje"
                        autoOk
                        disablePast
                        format={DateFormatEnum.DayMonthYearPtBr}
                        inputVariant="outlined"
                        // minDate={new Date()}
                        id="date-fim-inline"
                        value={startDate}
                        helperText="Inicio do cupom"
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'Trocar data',
                        }}
                      />
                    }
                    register={register}
                    rules={{ required: false }}
                    setValue={setValue}
                  />

                  <Box ml={2}>
                    <RHFInput
                      name="due_date"
                      as={
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          invalidLabel="Data inválida"
                          minDateMessage="Data não pode ser anterior ao dia de hoje"
                          autoOk
                          disablePast
                          format={DateFormatEnum.DayMonthYearPtBr}
                          inputVariant="outlined"
                          minDate={startDate}
                          id="date-fim-inline"
                          value={dueDate}
                          helperText="Fim do cupom"
                          onChange={handleStartDateChange}
                          // error={dueDate === null ? false : errors.due_date !== undefined}
                          KeyboardButtonProps={{
                            'aria-label': 'Trocar data',
                          }}
                        />
                      }
                      register={register}
                      rules={{ required: false }}
                      setValue={setValue}
                    />
                  </Box>
                </Box>
              )}
            </PaperSection>
          </Grid>
          <Grid item xs={12}>
            <button type="submit">submeter</button>
          </Grid>
        </Grid>
      </form>
    </MuiPickersUtilsProvider>
  );
};

export default CouponCrud;
