import { serviceGet } from '@project/components/src/services/service';
import * as R from 'ramda';

const insertZero = date => (`${date}`.length === 1 ? `0${date}` : `${date}`);

export const getTodayFormatedDate = date => {
  date.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
  const year = insertZero(date.getFullYear());
  const month = insertZero(date.getMonth() + 1);
  const day = insertZero(date.getDate());

  return `${year}-${month}-${day} 23:59:59`;
};

export const getYesterdayFormatedDate = date => {
  date.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
  date.setDate(date.getDate() - 1);

  const year = insertZero(date.getFullYear());
  const month = insertZero(date.getMonth() + 1);
  const day = insertZero(date.getDate());

  return `${year}-${month}-${day} 00:00:00`;
};

export const getOrdersEmPreparo = async (token, queryParams = '') => {
  const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/establishment/list/orders?status=EM%20PREPARO${queryParams}`;

  try {
    const response = await serviceGet(endpoint, {
      Authorization: `Bearer ${token}`,
    });

    return response.length === 0 ? [] : formatPedidos(response);
  } catch (error) {
    return [];
  }
};

export const getOrdersConcluido = async (token, queryParams = '') => {
  const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/establishment/list/orders?status=CONCLUIDO${queryParams}`;

  try {
    const response = await serviceGet(endpoint, {
      Authorization: `Bearer ${token}`,
    });

    return response.length === 0 ? [] : formatPedidos(response);
  } catch (error) {
    return [];
  }
};

export const getOrdersConcluidoAndEmPreparo = async token => {
  const response = await Promise.all([
    getOrdersEmPreparo(token, '&orderBy=desc'),
    getOrdersConcluido(token, '&orderBy=desc'),
  ]);

  const flattenArrayResponse = R.flatten(response);

  return flattenArrayResponse;
};

/**
 * @deprecated
 * @param {*} token
 */
export const getAllOrders = async token => {
  const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/establishment/list/orders?orderBy=DESC&limit=50`;

  const response = await serviceGet(endpoint, {
    Authorization: `Bearer ${token}`,
  });

  return formatPedidos(response);
};

/**
 * @deprecated
 * @param {*} param0
 */
export function formatPedidos({ orders, ...others }) {
  // eslint-disable-next-line array-callback-return
  const bla = orders.map(order => {
    return order;
    // if (order && order.order_details && order.order_details.version > 2) {
    // }

    // // try {
    // const items = order.order_details.items;
    // const byItem = R.groupBy(item => item.name);

    // const groupedPedidos = Object.entries(byItem(items)).map(item => {
    //   return {
    //     name: R.head(item),
    //     qty: R.length(item[1]),
    //   };
    // });

    // return {
    //   ...order,
    //   orderNumber: order.order_number,
    //   table: order.order_details.table,
    //   clientName: order.order_details.client_name,
    //   pedidos: groupedPedidos,
    //   createdAt: blablue(order.createdAt),
    //   status: order.status,
    //   total: convertCentsToMoney(order.grand_total),
    // };
    // // } catch (error) {
    // // console.error('formatPedidos', error);
    // // }
  });

  return bla;
}
