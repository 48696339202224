import {
  salesPageActionAddDateAt,
  salesPageActionAddDateTo,
  salesPageActionChangeVisualizationDate,
} from './SalesPageActions';
import { SalesPageAction, SalesPageInterface } from './SalesPageInterface';

function SalesPageReducer(
  state: SalesPageInterface,
  action: SalesPageAction,
): SalesPageInterface {
  switch (action.type) {
    case 'ADD_DATE_TO':
      return salesPageActionAddDateTo(state, action);

    case 'ADD_DATE_AT':
      return salesPageActionAddDateAt(state, action);

    case 'CHANGE_VISUALIZATION_DATA':
      return salesPageActionChangeVisualizationDate(state, action);

    default:
      return state;
  }
}

export default SalesPageReducer;
