/* eslint-disable @typescript-eslint/camelcase */
import PropTypes from 'prop-types';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useLocalStorage } from '@project/components/src/hooks/useLocalStorage';
import { SignupFormContextInterface, SignupStepEnum } from './SignupFormContextInterface';
import { useChecklist } from '../../pages/completeRegistration/services/getChecklist';

export const SignupFormContext = React.createContext<SignupFormContextInterface | undefined>(undefined);

const SignupFormContextProvider: React.FC = ({ children }) => {
  const [cookies] = useCookies(['token']);
  const checklist = useChecklist(cookies.token);

  const [signupStep, setSignupStep] = useLocalStorage<SignupStepEnum>('signup_step', SignupStepEnum.confirmation1);
  const [companyName, setCompanyName] = useLocalStorage('signup_form_companyName', undefined, false);
  const [url, setUrl] = useLocalStorage('signup_form_url', '', false);
  const [isOnRevision, setIsOnRevision] = useLocalStorage('signup_form_isOnRevision', false, false);
  const [comefrom, setComefrom] = useLocalStorage('signup_form_comefrom', false, false);
  const [terms, setTerms] = useLocalStorage('signup_form_terms', false, false);
  const [lastStep, setLastStep] = useLocalStorage('signup_form_lastStep', 1, false);
  const [vat_number, setCpfCnpj] = useLocalStorage('signup_form_vat_number', undefined, false);
  const [personType, setPersonType] = useLocalStorage('signup_form_personType', undefined, false);
  const [responsibleName, setResponsibleName] = useLocalStorage('signup_form_responsibleName', undefined, false);
  const [fantasyName, setFantasyName] = useLocalStorage('signup_form_fantasyName', undefined, false);
  const [averageTicket, setAverageTicket] = useLocalStorage('signup_form_averageTicket', undefined, false);
  const [businessType, setBusinessType] = useLocalStorage('signup_form_businessType', undefined, false);
  const [asDigitalProduct, setAsDigitalProduct] = useLocalStorage('signup_form_asDigitalProduct', undefined, false);
  const [zipCode, setZipCode] = useLocalStorage('signup_form_zip_code', undefined, false);
  const [street, setStreet] = useLocalStorage('signup_form_street', undefined, false);
  const [number, setNumber] = useLocalStorage('signup_form_number', undefined, false);
  const [neighborhood, setNeighborhood] = useLocalStorage('signup_form_neighborhood', undefined, false);
  const [city, setCity] = useLocalStorage('signup_form_city', undefined, false);
  const [fullAddress, setFullAddress] = useLocalStorage('signup_form_full_address', undefined, false);
  const [state, setState] = useLocalStorage('signup_form_state', undefined, false);
  const [country, setCountry] = useLocalStorage('signup_form_country', undefined, false);
  const [phone, setPhone] = useLocalStorage('signup_form_phone', undefined, false);
  const [email, setEmail] = useLocalStorage('signup_form_email', undefined, false);
  const [bankName, setBankName] = useLocalStorage('signup_form_bankName', undefined, false);
  const [agency, setAgency] = useLocalStorage('signup_form_agency', undefined, false);
  const [accountType, setAccountType] = useLocalStorage('signup_form_accountType', undefined, false);
  const [accountDigit, setAccountDigit] = useLocalStorage('signup_form_accountDigit', undefined, false);
  const [accountNumber, setAccountNumber] = useLocalStorage('signup_form_accountNumber', undefined, false);

  const resetForm = () => {
    localStorage.removeItem('signup_form_companyName');
    localStorage.removeItem('signup_form_url');
    localStorage.removeItem('signup_form_isOnRevision');
    localStorage.removeItem('signup_form_comefrom');
    localStorage.removeItem('signup_form_terms');
    localStorage.removeItem('signup_form_vat_number');
    localStorage.removeItem('signup_form_personType');
    localStorage.removeItem('signup_form_responsibleName');
    localStorage.removeItem('signup_form_fantasyName');
    localStorage.removeItem('signup_form_averageTicket');
    localStorage.removeItem('signup_form_businessType');
    localStorage.removeItem('signup_form_asDigitalProduct');
    localStorage.removeItem('signup_form_zip_code');
    localStorage.removeItem('signup_form_street');
    localStorage.removeItem('signup_form_number');
    localStorage.removeItem('signup_form_neighborhood');
    localStorage.removeItem('signup_form_city');
    localStorage.removeItem('signup_form_full_address');
    localStorage.removeItem('signup_form_state');
    localStorage.removeItem('signup_form_country');
    localStorage.removeItem('signup_form_phone');
    localStorage.removeItem('signup_form_email');
    localStorage.removeItem('signup_form_bankName');
    localStorage.removeItem('signup_form_agency');
    localStorage.removeItem('signup_form_accountType');
    localStorage.removeItem('signup_form_accountDigit');
    localStorage.removeItem('signup_form_accountNumber');
  };

  const globals: SignupFormContextInterface = {
    checklist,
    responsibleName,
    signupStep,
    setSignupStep,
    setResponsibleName,
    fantasyName,
    setFantasyName,
    averageTicket,
    setAverageTicket,
    businessType,
    setBusinessType,
    asDigitalProduct,
    setAsDigitalProduct,
    zipCode,
    setZipCode,
    street,
    setStreet,
    number,
    setNumber,
    neighborhood,
    setNeighborhood,
    city,
    setCity,
    state,
    setState,
    country,
    setCountry,
    phone,
    setPhone,
    email,
    setEmail,
    bankName,
    setBankName,
    agency,
    setAgency,
    accountType,
    setAccountType,
    accountNumber,
    setAccountNumber,
    lastStep,
    setLastStep,
    vat_number,
    setCpfCnpj,
    personType,
    setPersonType,
    terms,
    setTerms,
    comefrom,
    setComefrom,
    isOnRevision,
    setIsOnRevision,
    url,
    setUrl,
    companyName,
    setCompanyName,
    accountDigit,
    setAccountDigit,
    fullAddress,
    setFullAddress,
    resetForm,
  };

  return <SignupFormContext.Provider value={globals}>{children}</SignupFormContext.Provider>;
};

SignupFormContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SignupFormContextProvider;
