import useSWR, { ConfigInterface, responseInterface } from 'swr'
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { axiosGET, Endpoint, Otherheaders, Token } from '@project/components-typescript/src/services/axiosService';

export type GetRequest = AxiosRequestConfig

export interface Return<Data, Error>
  extends Pick<
  responseInterface<AxiosResponse<Data>, AxiosError<Error>>,
  'isValidating' | 'revalidate' | 'error'
  > {
  data: Data | undefined
  response: AxiosResponse<Data> | undefined
  requestKey: string
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<
  ConfigInterface<AxiosResponse<Data>, AxiosError<Error>>,
  'initialData'
  > {
  initialData?: Data
}

export default function useRequest<Data = unknown, Error = unknown>(
  endpoint: Endpoint,
  token: Token,
  otherHeaders: Otherheaders = {},
  { initialData, ...config }: Config<Data, Error> = {}
): Return<Data, Error> {
  const requestKey = endpoint && JSON.stringify(endpoint);

  const { data: response, error, isValidating, revalidate } = useSWR<
    AxiosResponse<Data>,
    AxiosError<Error>
  >(requestKey, () => axiosGET(endpoint, token, otherHeaders), {
    ...config,
    initialData: initialData && {
      status: 200,
      statusText: 'InitialData',
      config: otherHeaders,
      headers: {},
      data: initialData
    }
  })

  return {
    data: response?.data,
    requestKey,
    response,
    error,
    isValidating,
    revalidate
  }
}
