import React, { useContext, useEffect, useState } from 'react';
import CouponTable from './containers/CouponTable';
// import OrdersInactive from '../orders/assets/OrdersInactive.svg';
import OrdersInactive from './assets/coupon-svgrepo-com.svg';
import {
  CustomAxiosResponse,
  VariantEnum,
} from '@project/components-typescript/src/services/axiosService';
import { Grid } from '@project/components';
import { useCookies } from 'react-cookie';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { CouponInterface } from './interfaces/CouponInterface';
import { listCoupons } from './services/listCoupons';
import EmptyPlaceholder from '../orders/components/EmptyPlaceholder';

interface CouponPageInterface {}

const CouponPage: React.FC<CouponPageInterface> = () => {
  const [cookies] = useCookies(['token']);
  const {
    setShowBackdrop,
    setShowSnackbar,
    setSnackbarInformation,
  } = useContext(FeedbackContext);
  const [coupons, setCoupons] = useState<CouponInterface[] | undefined>(
    undefined,
  );

  const handleListCouponsSuccessful = (
    response: CustomAxiosResponse<CouponInterface[]>,
  ): void => {
    const { data, message, variant } = response;
    setCoupons(data);
    setSnackbarInformation({ message, variant });
  };

  const handleListCouponsError = (message: string): void => {
    setSnackbarInformation({ message, variant: VariantEnum.error });
  };

  const handleListCouponsFinally = (): void => {
    setShowBackdrop(false);
    setShowSnackbar(true);
  };

  useEffect(() => {
    setShowBackdrop(true);
    listCoupons(cookies.token)
      .then(response => handleListCouponsSuccessful(response))
      .catch(({ message }) => handleListCouponsError(message))
      .finally(handleListCouponsFinally);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container spacing={4}>
        {!coupons && (
          <Grid item xs={12} md={12}>
            <EmptyPlaceholder
              title="Nenhum cupom no momento!"
              subtitle="Quando criar um, ele aperecerá aqui!"
              icon={OrdersInactive}
            />
          </Grid>
        )}

        {coupons && (
          <Grid item xs={12}>
            <CouponTable coupons={coupons} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CouponPage;
