import { OrderStatusEnum } from '@project/components-typescript/src/interfaces/OrderStatusEnum';
import { HANDLE_STATUS_CHANGE } from './../OrdersInterface';
import { OrdersContextInterface } from '../OrdersInterface';

export function handleStatusChangeAction(
  state: OrdersContextInterface,
  action: HANDLE_STATUS_CHANGE,
): OrdersContextInterface {
  const shouldRemove: boolean =
    action.newStatus === OrderStatusEnum.entregue ||
    action.newStatus === OrderStatusEnum.cancelado;

  const newOrdersArr = shouldRemove
    ? state.orders.filter(({ order }) => order.id !== action.order.id)
    : state.orders.map(({ order, isNew }) =>
        order.id === action.order.id
          ? {
              order: {
                ...order,
                status: action.newStatus,
              },
              isNew: false,
            }
          : { order, isNew },
      );

  return {
    ...state,
    orders: newOrdersArr,
  };
}
