import React from 'react';

import { Skeleton as MaterialSkeleton } from '@material-ui/lab';

import * as R from 'ramda';

import PropTypes, { number, string } from 'prop-types';
import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';

const CustomSkeleton = styled(MaterialSkeleton)`
  ${switchProp('variant', {
    squareRounded: css`
      border-radius: 4px !important;
    `,
  })}
`;

const Skeleton = ({ animation, variant, width, height, repeat, ...props }) => {
  return R.repeat(
    <CustomSkeleton
      animation={animation}
      variant={variant}
      width={width}
      height={height}
      {...props}
    />,
    repeat,
  );
};

Skeleton.propTypes = {
  animation: PropTypes.oneOf(['pulse', 'wave', false]),
  variant: PropTypes.oneOf(['text', 'rect', 'circle', 'squareRounded'])
    .isRequired,
  width: PropTypes.oneOfType([number, string]),
  height: PropTypes.oneOfType([number, string]),
  props: PropTypes.node,
  repeat: PropTypes.number,
};

Skeleton.defaultProps = {
  animation: 'pulse',
  repeat: 1,
};

export default React.memo(Skeleton);
