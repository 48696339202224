import { Tabs as MaterialTabs, Tab } from '@material-ui/core';
import React, { useCallback } from 'react';
import { ifProp, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import Box from '../Box';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { Toolbar, AppBar } from '@material-ui/core';
import ScrollTop from '../ScrollTop';

const CustomAppBar = styled(AppBar)`
  box-shadow: none !important;
  background-color: white !important;
`;

const CustomMaterialTabs = styled(MaterialTabs)`
  ${({ customIndicatorColor }) =>
    customIndicatorColor &&
    css`
      .MuiTabs-scroller.MuiTabs-scrollable > span {
        background-color: ${customIndicatorColor} !important;
      }
    `}

  ${ifProp(
    { originalCss: false },
    css`
      left: 0;
      top: 52px;
      position: fixed;

      ${switchProp('variant', {
        fullWidth: css`
          width: calc(100vw + 1em);
        `,
        scrollable: css`
          width: 100%;
        `,
      })}
    `,
  )}
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ overflow: 'hidden' }} pt={2}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Tabs = ({
  children,
  tabs,
  tabIndex,
  variant,
  indicatorColor,
  customIndicatorColor,
  centered,
  scrollButtons,
  textColor,
  originalCss,
  setValueCallback,
  memo = false,
  sticky = false,
  id,
  ...props
}) => {
  const [value, setValue] = useLocalStorage(
    'tab_' + id,
    tabIndex,
    memo ? 60 * 5 : 0, // 5 minutes or 0
  );

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
    setValueCallback(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RenderTabs = useCallback(
    () => (
      <CustomMaterialTabs
        originalCss={originalCss}
        value={value}
        indicatorColor={indicatorColor}
        customIndicatorColor={customIndicatorColor}
        textColor={textColor}
        variant={variant}
        onChange={handleChange}
        {...props}
      >
        {tabs.map(({ label, icon }) => (
          <Tab label={label} icon={icon} />
        ))}
      </CustomMaterialTabs>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabs, value],
  );

  return (
    <>
      {sticky ? (
        <React.Fragment>
          <CustomAppBar position="sticky">
            <Toolbar disableGutters>
              <RenderTabs />
            </Toolbar>
          </CustomAppBar>
        </React.Fragment>
      ) : (
        <RenderTabs />
      )}

      {sticky && <ScrollTop />}
      <SwipeableViews index={value} onChangeIndex={index => handleChange(undefined, index)}>
        {tabs.map(({ component }, index) => (
          <TabPanel value={value} index={index}>
            {component}
          </TabPanel>
        ))}
      </SwipeableViews>
    </>
  );
};

Tabs.defaultProps = {
  tabIndex: 0,
  centered: true,
  variant: 'fullWidth',
  indicatorColor: 'primary',
  textColor: 'primary',
  scrollButtons: 'auto',
  originalCss: false,
  setValueCallback: () => {},
};

Tabs.propTypes = {
  children: PropTypes.node,
  tabIndex: PropTypes.number,
  centered: PropTypes.bool,
  originalCss: PropTypes.bool,
  variant: PropTypes.string,
  indicatorColor: PropTypes.string,
  textColor: PropTypes.string,
  scrollButtons: PropTypes.string,
  props: PropTypes.node,
  setValueCallback: PropTypes.func,
};

export default Tabs;
