/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { isIOS, isSafari, isMobileSafari } from 'react-device-detect';
import { AuthContext } from '@project/components/src/contexts/AuthContextProvider';
import Sidebar, {
  SectionInterface,
  ListInterface,
} from '@project/components-typescript/src/components/Sidebar/Sidebar';
import {
  ExitToAppOutlined,
  LocalShippingOutlined,
  StorefrontOutlined,
  CreditCardOutlined,
  ListAltOutlined,
  LocalAtmOutlined,
  DnsOutlined,
  Notifications,
  LabelOutlined,
  AddBoxOutlined,
  ShareOutlined,
  // CardGiftcardOutlined,
  ChatBubbleOutlineOutlined,
} from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { OrdersContext } from '../orders/OrdersContext/OrdersContext';

interface SidebarDesktopInterface {
  title: string;
}

const SidebarDesktop: React.FC<SidebarDesktopInterface> = ({
  children,
  title,
}) => {
  const location = useLocation();
  const { hasNewOrders } = useContext(OrdersContext);

  const { logout } = useContext(AuthContext);
  const history = useHistory();

  const handleLogout = (): void => {
    logout();
    history.push('/login');
  };

  const isActive = (str: string): boolean => location.pathname.includes(str);

  const StoreSection: SectionInterface[] = [
    {
      text: 'Pedidos',
      onClick: (): void => history.push('/pedidos'),
      active: isActive('/pedidos'),
      icon: <DnsOutlined />,
      showDot: hasNewOrders,
    },
    {
      text: 'Catálogo',
      onClick: (): void => history.push('/catalogo'),
      active: isActive('/catalogo'),
      icon: <ListAltOutlined />,
    },
    {
      text: 'Vendas',
      onClick: (): void => history.push('/vendas'),
      active: isActive('/vendas'),
      icon: <LocalAtmOutlined />,
    },
    {
      text: 'Categorias',
      onClick: (): void => history.push('/configuracoes/categorias'),
      active: isActive('/configuracoes/categorias'),
      icon: <LabelOutlined />,
    },
    {
      text: 'Adicionais',
      onClick: (): void => history.push('/adicionais'),
      active: isActive('/adicionais'),
      icon: <AddBoxOutlined />,
    },
    {
      text: 'Compartilhar',
      onClick: (): void => history.push('/compartilhar'),
      active: isActive('/compartilhar'),
      icon: <ShareOutlined />,
    },
    // {
    //   text: 'Cupom',
    //   onClick: (): void => history.push('/cupons'),
    //   active: isActive('/cupons'),
    //   icon: <CardGiftcardOutlined />,
    // },
  ];

  const StoreList: ListInterface = {
    list: StoreSection,
    sectionName: 'Loja',
    shortSectionName: 'Loja',
  };

  const ConfigSection: SectionInterface[] = [
    {
      text: 'Pagamento',
      onClick: (): void => history.push('/configuracoes/valores'),
      active: isActive('/configuracoes/valores'),
      icon: <CreditCardOutlined />,
    },
    {
      text: 'Presencial',
      onClick: (): void => history.push('/configuracoes/presencial'),
      active: isActive('/configuracoes/presencial'),
      icon: <LocalAtmOutlined />,
    },
    {
      text: 'Delivery',
      onClick: (): void => history.push('/configuracoes/delivery'),
      active: isActive('/configuracoes/delivery'),
      icon: <LocalShippingOutlined />,
    },
    {
      text: 'Loja',
      active: isActive('/configuracoes/loja'),
      onClick: (): void => history.push('/configuracoes/loja'),
      icon: <StorefrontOutlined />,
    },
    // { text: 'Configurações', onClick: (): void => history.push('/configuracoes'), icon: <SettingsOutlined /> },
    {
      text: 'Notificações',
      active: isActive('/configuracoes/notificacoes'),
      onClick: (): void => history.push('/configuracoes/notificacoes'),
      disabled: isIOS || isSafari || isMobileSafari,
      icon: <Notifications />,
    },
  ];

  const ConfigList: ListInterface = {
    list: ConfigSection,
    sectionName: 'Configurações',
    shortSectionName: 'Config',
  };

  const HelpSection: SectionInterface[] = [
    {
      text: 'Ajuda',
      // onClick: (): any => (window.location.href = `/faq`),
      // eslint-disable-next-line
      onClick: (): any => history.push('/faq'),
      active: isActive('/faq'),
      icon: <ChatBubbleOutlineOutlined />,
    },
  ];

  const HelpList: ListInterface = {
    list: HelpSection,
    sectionName: 'Ajuda',
    shortSectionName: 'Ajuda',
  };

  const SairSection: SectionInterface[] = [
    {
      text: '',
      onClick: handleLogout,
      active: false,
      icon: <ExitToAppOutlined />,
    },
  ];

  const SairList: ListInterface = {
    list: SairSection,
    sectionName: 'Sair',
    shortSectionName: 'Sair',
  };

  const list: ListInterface[] = [StoreList, ConfigList, HelpList, SairList];

  return (
    <Sidebar listSections={list} title={title}>
      {children}
    </Sidebar>
  );
};

export default SidebarDesktop;
