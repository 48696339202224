import ThemeProvider from '@material-ui/styles/ThemeProvider';
import FirebaseRemoteConfigProvider from './contexts/FirebaseRemoteConfig/FirebaseRemoteConfigProvider';
import FirebaseAnalyticsProvider from '@project/components/src/contexts/FirebaseAnalyticsProvider';
import FirebasePerformanceProvider from '@project/components/src/contexts/FirebasePerformanceProvider';
import AppBarContext from '@project/components/src/contexts/AppBarContext';
import AuthContext from '@project/components/src/contexts/AuthContextProvider';
import FeedbackContext from '@project/components/src/contexts/FeedbackContext';
import OverlayContext from '@project/components/src/contexts/OverlayContext';
import firebase from 'firebase/app';
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import firebaseConfig from './firebaseConfig';
import RootPage from './pages/RootPage';
import theme from './theme';
import GlobalStateProvider from './contexts/GlobalState/GlobalStateContext';
import OrdersProvider from './pages/orders/OrdersContext/OrdersContext';

firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();
const remoteConfig = firebase.remoteConfig();
const performance = firebase.performance();

const App = () => {
  return (
    <AuthContext>
      <GlobalStateProvider>
        <FirebaseAnalyticsProvider analytics={analytics}>
          <FirebaseRemoteConfigProvider remoteConfig={remoteConfig}>
            <FirebasePerformanceProvider performance={performance}>
              <OrdersProvider>
                <AppBarContext>
                  <FeedbackContext>
                    <BrowserRouter>
                      <ThemeProvider theme={theme}>
                        <OverlayContext>
                          <Route component={RootPage} />
                        </OverlayContext>
                      </ThemeProvider>
                    </BrowserRouter>
                  </FeedbackContext>
                </AppBarContext>
              </OrdersProvider>
            </FirebasePerformanceProvider>
          </FirebaseRemoteConfigProvider>
        </FirebaseAnalyticsProvider>
      </GlobalStateProvider>
    </AuthContext>
  );
};

export default App;
