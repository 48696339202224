import { axiosGET } from '@project/components/src/services/axiosService';

const SESSION_TOKEN = 'ockpay-clients-sessionTokenMaps';

/**
 *
 * @param {string} address
 * @param {string} sessionToken
 */
const getFreightPrediction = async address => {
  try {
    const sessionToken = sessionStorage.getItem(SESSION_TOKEN);
    const sessionTokenEndpoint = sessionToken ? `&${sessionToken}` : '';
    const endpoint = `${process.env.REACT_APP_FREIGHT_URL}/freight/prediction?addresses=${address}${sessionTokenEndpoint}`;
    const response = await axiosGET(endpoint);
    const filteredResponse = (response && response.data) || {};
    const { predictions, status, sessiontoken } = filteredResponse;

    sessionStorage.setItem(SESSION_TOKEN, sessiontoken);

    const formattedPredictions = predictions.map(({ id, description, structured_formatting }) => ({
      id,
      fullAddress: description,
      mainText: structured_formatting.main_text,
      secondaryText: structured_formatting.secondary_text,
    }));

    return {
      predictions: formattedPredictions || [],
      status,
    };
  } catch (error) {
    throw new Error('Não conseguimos achar o seu endereço, tente novamente');
  }
};

export default getFreightPrediction;
