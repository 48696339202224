import {
  Dialog as MaterialDialogForm,
  DialogActions as MaterialDialogFormActions,
  DialogContent as MaterialDialogFormContent,
  DialogContentText as MaterialDialogFormContentText,
  DialogTitle as MaterialDialogFormTitle,
  Slide as MaterialSlide,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { Button, Typography } from '@project/components';
import PropTypes from 'prop-types';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <MaterialSlide direction="up" ref={ref} {...props} />;
});

const DialogForm = ({
  children,
  onDisagree = () => {},
  onAgree = () => {},
  contentTitle = 'Confirmação',
  contentText = '',
  cancelText = 'Cancelar',
  submitText = 'Confirmar',
  submitColor = 'primary',
  cancelColor = 'default',
  handleSubmit,
  fullScreen = false,
  submitMethod,
  showCancelButton = true,
  showSubmitButton = true,
  loading = false,
  maxWidth = 'sm',
  ...props
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = onClickActions => {
    setOpen(false);
    onClickActions();
  };

  const RenderLoading = () => (
    <Grid align="center">
      <CircularProgress color="secondary" />
    </Grid>
  );

  return (
    <MaterialDialogForm
      open={open}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      keepMounted
      maxWidth={maxWidth}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-DialogForm-slide-title"
      aria-describedby="alert-DialogForm-slide-description"
      {...props}
    >
      <MaterialDialogFormTitle id="alert-DialogForm-slide-title">
        <Typography>{contentTitle}</Typography>
      </MaterialDialogFormTitle>
      <MaterialDialogFormContent>
        {!loading ? (
          <>
            {contentText && (
              <MaterialDialogFormContentText id="alert-DialogForm-slide-description">
                {contentText}
              </MaterialDialogFormContentText>
            )}
            <form onSubmit={handleSubmit(submitMethod)} noValidate>
              {children}
              <MaterialDialogFormActions>
                {showCancelButton && (
                  <Button variant="" onClick={() => handleClose(onDisagree)}>
                    {cancelText}
                  </Button>
                )}
                {showSubmitButton && (
                  <Button type="submit" color="secondary" variant="textPrimary">
                    {submitText}
                  </Button>
                )}
              </MaterialDialogFormActions>
            </form>
          </>
        ) : (
          <RenderLoading />
        )}
      </MaterialDialogFormContent>
    </MaterialDialogForm>
  );
};

DialogForm.propTypes = {
  children: PropTypes.node.isRequired,
  onDisagree: PropTypes.func,
  onAgree: PropTypes.func,
  contentTitle: PropTypes.string,
  contentText: PropTypes.string,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  submitColorod: PropTypes.func.isRequired,
};

export default DialogForm;
