import React from 'react';
import styled from 'styled-components';
import { Box, Typography, Container } from '@project/components';
import { FaqSearch as FaqComponentSearch } from '../components/FaqSearch';
import { OpenInNewRounded } from '@material-ui/icons';

interface FaqSearchInterface {
  isPublic?: boolean;
}

const CustomSearch = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  input {
    width: 100%;
    padding: 20px 16px 21px 16px !important;
    border: none;
    outline: none;
    font-size: 18px;
    border-radius: 4px;
    -webkit-transition: background 0.4s, -webkit-box-shadow 0.2s;
    transition: background 0.4s, -webkit-box-shadow 0.2s;
    -o-transition: background 0.4s, box-shadow 0.2s;
    transition: background 0.4s, box-shadow 0.2s;
    transition: background 0.4s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
  }

  input:hover {
    background: #ffffff;
    color: #383d6b;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
  }
`;

const BlueSearch = styled.div`
  width: 100%;
  input {
    width: 100%;
    background: rgba(255, 255, 255, 1);
    color: rgba(22, 24, 42, 1);
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
  }
  input:hover {
    background: #ffffff;
    color: #383d6b;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
  }
`;

const WhiteSearch = styled.div`
  input {
    background: rgba(255, 255, 255, 0.27);
    background: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.7);
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
  }

  input:hover {
    background: #ffffff;
    color: #383d6b;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
  }
`;

const Background = styled(Box)`
  background-color: #383d6b;
  /* width: 100%; */
  /* display: flex; */
  color: white;
  margin-bottom: 7px;
  /* flex: none;
  position: relative; */
  /* z-index: 10; */
  background-size: cover;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  background-position: 50%;
`;

const CustomLink = styled.a`
  color: #ffffff;
  font-weight: bold;
  font-family: 'Roboto';
  text-decoration: none;
`;

const FaqSearch: React.FC<FaqSearchInterface> = ({ isPublic }) => {
  return (
    <>
      {isPublic ? (
        <Background padding="35px">
          <CustomSearch>
            <Container maxWidth="md">
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <img width="auto" height="35px" src="ockpay-logo-branco-gradient.png" alt="Logo Ockpay" />
                  <Box display="flex" flexDirection="row" alignItens="center">
                    <Box mr={1}>
                      <OpenInNewRounded />
                    </Box>
                    <CustomLink target="_blank" href="https://site.ockpay.com.br">
                      Conhecer Ockpay
                    </CustomLink>
                  </Box>
                </Box>
                <Box my={3}>
                  <Typography gutterBottom variant="h1">
                    Como podemos ajudar?
                  </Typography>
                </Box>
                <WhiteSearch>
                  <FaqComponentSearch isPublicRoute={true} />
                </WhiteSearch>
              </Box>
            </Container>
          </CustomSearch>
        </Background>
      ) : (
        <CustomSearch>
          <BlueSearch>
            <FaqComponentSearch
              iconStyle={{
                width: '30px',
                marginRight: '15px',
              }}
              isPublicRoute={false}
            />
          </BlueSearch>
        </CustomSearch>
      )}
    </>
  );
};

export default FaqSearch;
