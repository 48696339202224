import Box from '../../atom/Box';
import CardapioCard from '../../molecule/CardapioCard';
import { Edit } from '@material-ui/icons';
import IconButton from '../../atom/IconButton';
import List from '../../atom/List';
import ListSubheader from '../../atom/List/ListSubheader';
import React from 'react';
import Text from '../../atom/Text';
import { loadThumb, loadImage } from '../../services/imageHandler';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
// import { useItems } from '../../../../apps/restaurants/src/hooks/useItems'
import { convertCentsToLocale } from '@project/components-typescript/src/helpers/LocalePriceConverter';

const SubHeaderUl = styled.ul`
  list-style-type: none !important;
  padding-left: 0 !important;
`;

export const StockQuantity = styled.div`
  color: ${({ qty }) => qty <= 2 && 'red'} !important;
  color: ${({ qty }) => qty >= 2 && qty < 5 && '#E65100'} !important;
`;

const CustomListSubheader = styled(ListSubheader)`
  top: 0 !important;
  text-align: center;
`;

const Cardapio = ({ items, disabled = false }) => {
  const history = useHistory();
  // const { getIsPaused } = useItems({})

  return (
    <Box mt={2}>
      <List subheader={<li />}>
        <li key={`section-1`}>
          <SubHeaderUl>
            {items.map((category, idx) => {
              return (
                <>
                  <CustomListSubheader
                    disabled={disabled}
                    key={`subheader-${idx}`}
                  >
                    {category.name}
                  </CustomListSubheader>
                  {category.items.length === 0 && (
                    <Box pl={'16px'} pr={'16px'}>
                      <Text color="textSecondary" variant="caption">
                        Sem itens
                      </Text>
                    </Box>
                  )}

                  {category.items.map((item, index) => {
                    const RenderCardDescription = () => (
                      <>
                        {item.stock !== null && (
                          <StockQuantity qty={item.stock}>
                            {item.stock} Produtos restantes
                          </StockQuantity>
                        )}

                        {/* {getIsPaused(item) && <PausedItem>Venda pausada</PausedItem>} */}
                      </>
                    );

                    return (
                      <CardapioCard
                        onClick={() => history.push(`/catalogo/${item.id}`)}
                        key={`item-${idx}-${item.id}`}
                        name={item.name}
                        price={convertCentsToLocale(item.price)}
                        image={`${loadThumb(
                          (item.itemImages &&
                            item.itemImages.images &&
                            item.itemImages.images[0] &&
                            item.itemImages.images[0].original) ||
                            '',
                        )}?${item.updatedAt}`}
                        imageOriginal={`${loadImage(
                          (item.itemImages &&
                            item.itemImages &&
                            item.itemImages.images[0] &&
                            item.itemImages.images[0].original) ||
                            '',
                        )}?${(item &&
                          item.itemImages &&
                          item.itemImages.updatedAt) ||
                          ''}`}
                        showDivider={false}
                        description={<RenderCardDescription />}
                        action={
                          <IconButton
                            onClick={() => history.push(`/catalogo/${item.id}`)}
                            edge="end"
                            aria-label="editar"
                          >
                            <Edit color="iconDefault" />
                          </IconButton>
                        }
                      />
                    );
                  })}
                </>
              );
            })}
          </SubHeaderUl>
        </li>
      </List>
    </Box>
  );
};

Cardapio.propTypes = {
  // TODO declare props
  // propName: propType
  // example: PropTypes.bool
};

export default Cardapio;
