import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { memo } from 'react';
import { useCallback } from 'react';

export const OverlayContext = React.createContext(undefined);

const OVERLAY_HEIGHT = '12em';

const OverlayProvider = ({ children }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayHeight, setOverlayHeight] = useState(OVERLAY_HEIGHT);

  const resetOverlayHeight = useCallback(() => {
    setOverlayHeight(OVERLAY_HEIGHT);
  }, []);

  const globals = {
    showOverlay,
    setShowOverlay,
    overlayHeight,
    setOverlayHeight,
    resetOverlayHeight,
    OVERLAY_HEIGHT,
  };

  return (
    <OverlayContext.Provider value={globals}>
      {children}
    </OverlayContext.Provider>
  );
};

OverlayProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(OverlayProvider);
