import Box from '../../atom/Box';
import Typography from '../../atom/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const CustomPageTitle = styled(Typography)`
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 0.875rem !important;
  box-sizing: border-box !important;
  list-style: none !important;
  font-family: Montserrat !important;
  font-weight: 500 !important;
  /* line-height: 48px !important; */
`;

const PageTitle = ({
  title,
  subtitle,
  gutterBottom = true,
  variant,
  component = 'h2',
}) => {
  return (
    <>
      <Box mb={1.5}>
        <CustomPageTitle variant={variant} component={component}>
          {title}
        </CustomPageTitle>
        {(subtitle || subtitle === '') && (
          <Typography gutterBottom={gutterBottom} variant="caption">
            {subtitle || ' '}
          </Typography>
        )}
      </Box>
    </>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  mb: PropTypes.number,
  mt: PropTypes.number,
  variant: PropTypes.string,
  component: PropTypes.string,
};

export default PageTitle;
