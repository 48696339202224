import React from 'react';

import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({ children, component, ...props }) => {
  return (
    <Route
      {...props}
      render={({ location }) =>
        // loggedUser !== undefined ? (
        true ? (
          component || children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PublicRoute;
