import React from 'react';
import PropTypes from 'prop-types';

import styled, { createGlobalStyle, css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { Paper } from '@material-ui/core';
import { ockTheme } from '@project/components/src/themes';

const GlobalStyle = createGlobalStyle`
  ${ifProp(
    'showOverlay',
    css`
      body {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        background-color: #fcfcfc;
      }
    `,
  )}
`;

const CustomEffect = styled(Paper)`
  border-radius: 12px !important;
  margin-top: -16px !important;
  background-color: #fcfcfc !important;
`;
const CustomBackground = styled.div`
  background-color: ${ockTheme.colors.ockDarkBlue};
  height: ${({ overlayHeight }) => (overlayHeight ? overlayHeight : '12em')};
`;

const OverlayPage = ({ showOverlay, overlayHeight }) => {
  return (
    showOverlay && (
      <>
        <GlobalStyle showOverlay />
        <CustomBackground overlayHeight={overlayHeight} />
        <CustomEffect elevation={0} />
      </>
    )
  );
};

OverlayPage.defaultProps = {
  showOverlay: true,
  overlayHeight: '12em',
};

OverlayPage.propTypes = {
  showOverlay: PropTypes.bool,
  overlayHeight: PropTypes.string,
};

export default OverlayPage;
