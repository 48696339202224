import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Backdrop as MaterialBackdrop } from '@material-ui/core';
import { CircularProgress as MaterialCircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const Backdrop = ({ open, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const [showBackdrop, setShowBackdrop] = useState(open);
  const CustomBackdrop = styled(MaterialBackdrop)`
    z-index: 2000 !important;
  `;

  const CustomMaterialCircularProgress = styled(MaterialCircularProgress)`
    z-index: 2100 !important;
  `;

  useEffect(() => {
    return () => {
      setShowBackdrop(false);
    };
  }, []);

  return (
    <CustomBackdrop open={open} data-testid="backdrop" {...props}>
      <CustomMaterialCircularProgress
        data-testid="backdrop-spinner"
        color="secondary"
      />
    </CustomBackdrop>
  );
};

Backdrop.propTypes = {
  children: PropTypes.node,
};

export default Backdrop;
