import React, { useEffect, useState } from 'react';
import { serviceCreate, serviceEdit, serviceGet } from '../../services/service';
import { useHistory, useParams } from 'react-router-dom';
import Backdrop from '@project/components/src/atom/Backdrop';
import PageTitle from '@project/components/src/molecule/PageTitle';
import { RHFInput } from 'react-hook-form-input';
import Snackbar from '../../atom/Snackbar';
import { useForm } from 'react-hook-form';
import SlideAnimation from '../../molecule/SlideAnimation';
import CheckoutBar from '../../organism/CheckoutBar';
import PaperSection from '../../molecule/PaperSection';
import { SaveRounded } from '@material-ui/icons';

export const getArrayFromFields = (values, key) =>
  values.map(value => value[key]);

const FormBuilder = ({
  fields = [],
  submit = {
    textOnEditPage: 'Editar',
    textOnCreatePage: 'Criar novo',
    routeAfterSubmit: '/dashboard',
    endpoint: '',
    endpointEdit: '',
    endpointGetAll: '',
  },
  page = {
    name: '',
    textOnEditPage: 'Editar',
    textOnCreatePage: 'Criar',
    showLoading: true,
    showSnackbar: true,
  },
  token,
}) => {
  const domRef = React.useRef();
  const { register, setValue, handleSubmit, errors } = useForm();
  const history = useHistory();

  const [useSnackbar] = useState(page.showSnackbar);
  const [useLoading] = useState(page.showLoading);
  // const [ObjectName, setObjectName] = useState(page.name)

  const [openbackdrop, setOpenbackdrop] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [message] = useState('');
  const [variant] = useState('');
  const [EditPage, setEditPage] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [EditObject, setEditObject] = useState({});

  const { id } = useParams();

  useEffect(() => {
    setEditPage(!!id);

    if (EditPage) {
      setOpenbackdrop(true);
      serviceGet(`${submit.endpointEdit}${id}`, {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      })
        .then(object => {
          setEditObject(object);
          getArrayFromFields([...fields], 'name').forEach(field =>
            setValue(field, object[field]),
          );
        })
        .finally(() => setOpenbackdrop(false));
    }
  }, [
    EditPage,
    fields,
    id,
    setValue,
    submit.endpoint,
    submit.endpointEdit,
    token,
  ]);

  const onSubmit = async data => {
    setOpenbackdrop(true);

    const action = EditPage
      ? serviceEdit(`${submit.endpointEdit}${id}`, data, 'PUT', {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        })
      : serviceCreate(submit.endpointCreate, data, 'POST', {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        });

    action
      .then(response => {
        setOpenbackdrop(false);
        history.push(submit.routeAfterSubmit);
      })
      .catch(e => console.error(e))
      .finally(() => setOpenSnackbar(true));
  };

  return (
    <>
      <PageTitle
        pageTitle={EditPage ? page.textOnEditPage : page.textOnCreatePage}
      />

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <PaperSection
          title="Categorias"
          subtitle="Organize sua loja separando os itens por categorias"
        >
          {fields.map((field, index) => {
            return (
              <RHFInput
                key={`${index}-${field.name}`}
                as={field.type}
                register={register({
                  ...field.validators,
                })}
                label={field.label}
                name={field.name}
                setValue={setValue}
                onChange={event => console.log(event.target.value)}
                defaultValue={field.defaultValue}
                error={errors[field.name]}
                helperText={
                  errors[field.name]
                    ? field.errorHelperText
                    : field.helperText || ''
                }
                {...field.props}
              />
            );
          })}
        </PaperSection>

        <SlideAnimation domRef={domRef} show={true}>
          <CheckoutBar
            text={EditPage ? submit.textOnEditPage : submit.textOnCreatePage}
            type="submit"
            disabled={false}
            icon={<SaveRounded />}
          />
        </SlideAnimation>
      </form>

      {useLoading && <Backdrop open={openbackdrop} />}
      {useSnackbar && (
        <Snackbar message={message} variant={variant} open={openSnackbar} />
      )}
    </>
  );
};

FormBuilder.propTypes = {
  // fields: propType.array,
};

export default FormBuilder;
