import React, { useContext, useEffect, useState } from 'react';
import { getEstablishment } from '../../../services/getEstablishment';
import { AuthContext } from '@project/components/src/contexts/AuthContextProvider';
import { useCookies } from 'react-cookie';
import { EstablishmentInterface } from '../../../interfaces/EstablishmentInterface';
import { Button, Typography, TextField } from '@project/components';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { SignupFormContext } from '../../../contexts/SignupFormContext/SignupFormContextProvider';
import { SignupStepEnum } from '../../../contexts/SignupFormContext/SignupFormContextInterface';
import { completeStep1Confirmation } from '../services/completeSteps';

interface Step1ConfirmationInterface {}

interface FormData {
  cpf_cnpj: string;
  email: string;
  name: string;
}

const Step1Confirmation: React.FC<Step1ConfirmationInterface> = () => {
  const [cookies] = useCookies(['token']);
  const [establishment, setEstablishment] = useState<EstablishmentInterface | undefined>(undefined);
  const { setShowBackdrop, setShowSnackbar, setSnackbarInformation } = useContext(FeedbackContext);
  const signupContext = useContext(SignupFormContext);
  const { loggedUser } = useContext(AuthContext);

  const { register, setValue, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    getEstablishment(cookies.token)
      .then(response => setEstablishment(response))
      .catch(() => console.error('opa. deu ruim'));
  }, [cookies.token]);

  const onSubmit = async (values: any): Promise<any> => {
    setShowBackdrop(true);
    const val = values as FormData;

    completeStep1Confirmation(val, cookies.token, loggedUser)
      .then(async () => {
        await signupContext?.checklist.revalidate();
        signupContext?.setSignupStep(SignupStepEnum.businessType2);
      })
      .catch(() => {
        setSnackbarInformation({
          message: 'Ocorreu um erro, revise seus dados e tente novamente.',
          variant: 'error',
        });
        setShowSnackbar(true);
      })
      .finally(() => setShowBackdrop(false));
  };

  if (!establishment) return <Typography>Carregando...</Typography>;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RHFInput
          as={
            <TextField
              id="name"
              autoFocus
              variant="standard"
              label="Nome do seu negócio"
              disabled={establishment.fantasy_name !== undefined}
              placeholder="Digite aqui"
              error={errors.fantasyName}
              helperText={errors.fantasyName && errors.fantasyName.message}
            />
          }
          register={register}
          rules={{ required: true }}
          defaultValue={establishment.fantasy_name}
          value={establishment.fantasy_name}
          name="name"
          setValue={setValue}
        />

        <RHFInput
          as={
            <TextField
              id="cpf_cnpj"
              autoFocus
              variant="standard"
              label="CPF/CNPJ"
              disabled={establishment.vat_number !== undefined}
              placeholder="Digite aqui"
              error={errors.vat_number}
              helperText={errors.vat_number && errors.vat_number.message}
            />
          }
          register={register}
          rules={{
            required: true,
          }}
          defaultValue={establishment.vat_number}
          value={establishment.vat_number}
          name="cpf_cnpj"
          setValue={setValue}
        />

        <RHFInput
          as={
            <TextField
              id="email"
              autoFocus
              type="email"
              variant="standard"
              label="Email"
              disabled={establishment.settings.email !== undefined}
              error={errors.email}
              helperText={errors.email && errors.email.message}
            />
          }
          register={register}
          rules={{ required: true }}
          defaultValue={establishment.settings.email || ''}
          value={establishment.settings.email || ''}
          name="email"
          setValue={setValue}
        />

        <Button type="submit" color="primary">
          Confirmar
        </Button>
      </form>
    </div>
  );
};

export default Step1Confirmation;
