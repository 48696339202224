import usePrismic from '../../hooks/usePrismic';
import { PrismicBodyInterface, PrismicPathEnum } from '../../interfaces/PrismicInterfaces';
import { OckpayPrismicTypesEnum } from '../../interfaces/OckpayPrismicInterfaces';
import { UsePrismicResponseInterface } from '../../hooks/usePrismic';

export enum PrismicPageID {
  help = 'page-help',
}

export interface PrismicPageInterface extends PrismicBodyInterface {
  primary: Primary;
}

export interface PrismicPageInterface {
  nome_da_pagina: NomeDaPagina[];
  body: PrismicPageInterface[];
}

export interface Primary {
  titulo_da_secao: NomeDaPagina[];
  descricao_da_secao: null;
  secao_ativa_: boolean;
  id_da_secao: null;
}

export interface NomeDaPagina {
  type: string;
  text: string;
  spans: any[];
}

const PRISMIC_PAGE_PATH = 'id_da_pagina';

const usePrismicPage: (page: PrismicPageID) => UsePrismicResponseInterface<PrismicPageInterface> = page => {
  const response = usePrismic<PrismicPageInterface>({
    at: [
      {
        path: PrismicPathEnum.document,
        value: OckpayPrismicTypesEnum.page,
      },
    ],
    any: {
      path: OckpayPrismicTypesEnum.page,
      fieldKey: PRISMIC_PAGE_PATH,
      values: [page],
    },
  });

  return response;
};

export default usePrismicPage;
