import { FreightMethodsInterface } from './../interfaces/FreightMethodsInterface';
import {
  Token,
  CustomAxiosResponse,
  axiosGET,
  VariantEnum,
} from '@project/components-typescript/src/services/axiosService';

export const getfreightMethods = async (token: Token): Promise<CustomAxiosResponse<FreightMethodsInterface[]>> => {
  try {
    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/freight/methods`;

    const response = await axiosGET<FreightMethodsInterface[]>(endpoint, token);

    return {
      ...response,
      message: 'Pronto!',
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(error?.message ? error.message : 'Ocorreu um erro, tente novamente.');
  }
};
