import { StorefrontOutlined } from '@material-ui/icons';
import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import ConfigSwitch from '../../../../components/ConfigSwitch';
import { GlobalStateContext } from '../../../../contexts/GlobalState/GlobalStateContext';
import { GlobalStateTypesEnum } from '../../../../contexts/GlobalState/GlobalStateInterface';
import updateSettings from '../../../../services/settings/updateSettings';

interface ConfigRetiradaLocalInterface {}

const ConfigRetiradaLocal: React.FC<ConfigRetiradaLocalInterface> = () => {
  const { stateGlobalState, dispatchGlobalState } = useContext(GlobalStateContext);
  const [cookies] = useCookies(['token']);

  const handleChangeFlagTakeaway = async (): Promise<void> => {
    try {
      await updateSettings({ flag_takeaway: !stateGlobalState.settings?.flag_takeaway }, cookies.token);
      dispatchGlobalState({
        type: GlobalStateTypesEnum.UPDATE_SETTINGS,
        settings: {
          flag_takeaway: !stateGlobalState.settings?.flag_takeaway,
        },
      });
    } catch (error) {}
  };

  return (
    <ConfigSwitch
      checked={stateGlobalState.settings?.flag_takeaway}
      onChange={handleChangeFlagTakeaway}
      text="Retirada no local"
      subtitle="Seu cliente vem buscar"
      icon={<StorefrontOutlined color="primary" />}
    />
  );
};

export default ConfigRetiradaLocal;
