import { PrismicResponseInterface } from '../../../../interfaces/PrismicInterfaces';
import { PrismicArticlesResponseInterface } from './../../interfaces/PrismicArticleInterface';
export interface FaqContextInterface {
  list: PrismicResponseInterface<PrismicArticlesResponseInterface> | undefined;
  defaultList: PrismicResponseInterface<PrismicArticlesResponseInterface> | undefined;
}

export enum FaqTypesEnum {
  UPDATE_FAQ_LIST,
  ADD_DEFAULT_FAQ_LIST,
}

export type UPDATE_FAQ_LIST = {
  type: FaqTypesEnum.UPDATE_FAQ_LIST;
  list: PrismicResponseInterface<PrismicArticlesResponseInterface> | undefined;
};

export type ADD_DEFAULT_FAQ_LIST = {
  type: FaqTypesEnum.ADD_DEFAULT_FAQ_LIST;
  list: PrismicResponseInterface<PrismicArticlesResponseInterface> | undefined;
};
