import { CustomAxiosResponse } from '@project/components-typescript/src/services/axiosService';
import { LoggedUserInterface } from './../../../interfaces/LoggedUserInterface';
import { DiscordWebhooksEnum } from './../../../configuration/discordWebhooks';
import {
  ColorEnum,
  DiscordGenericNotificationInterface,
  emitDiscordNotification,
  Field,
  ParseEnum,
} from '@project/components-typescript/src/services/genericDiscordNotification';
import { SignupStepsEnum } from '../interfaces/ChecklistInterface';

export const emitSignupError = (
  error: unknown,
  userErrorShow: string,
  loggedUser: LoggedUserInterface,
  step: SignupStepsEnum,
  fields?: Field[],
): void => {
  const webhook: DiscordWebhooksEnum = DiscordWebhooksEnum.signupNotification;
  try {
    const statusCode: string =
      (typeof error === 'object' && error && error['status'] && error['status'].toString()) || 'S/ Info';

    const statusText: string =
      (typeof error === 'object' && error && error['statusText'] && error['statusText'].toString()) || 'S/ Info';

    const errorBackend: string =
      (typeof error === 'object' && error && error['data'] && JSON.stringify(error['data'], undefined, 2)) || 'S/ Info';

    const payload: DiscordGenericNotificationInterface = {
      allowed_mentions: {
        parse: [ParseEnum.everyone],
      },
      embeds: [
        {
          author: {
            name: 'Error on complete',
          },
          color: ColorEnum.error,
          title: step,
          fields: [
            {
              name: 'Estabelecimento',
              inline: true,
              value: loggedUser.name,
            },
            {
              name: 'Slug',
              inline: true,
              value: loggedUser.username,
            },
            { name: 'Status code', value: statusCode, inline: true },
            { name: 'Status text', value: statusText, inline: true },
            { name: 'Erro exibido ao cliente', value: userErrorShow, inline: false },
            {
              name: 'Erro backend',
              value: errorBackend,
              inline: false,
            },
            ...(fields || []),
          ],
        },
      ],
    };

    emitDiscordNotification(webhook, payload);
  } catch (error) {
    emitDiscordNotification(webhook, {
      allowed_mentions: {
        parse: [ParseEnum.everyone],
      },
      embeds: [
        {
          author: {
            name: step,
          },
          color: ColorEnum.error,
          title: 'Error to send error on Discord (Fucked)',
          fields: [
            {
              name: 'Estabelecimento',
              inline: true,
              value: loggedUser.name,
            },
          ],
        },
      ],
    });
  }
};

export const emitSignupSuccess = <T>(
  response: CustomAxiosResponse<T>,
  loggedUser: LoggedUserInterface,
  step: SignupStepsEnum,
  fields?: Field[],
): void => {
  const webhook: DiscordWebhooksEnum = DiscordWebhooksEnum.signupNotification;
  try {
    const statusCode: string = response.status.toString() || 'S/ Info';
    const statusText: string = response.statusText || 'S/ Info';
    const userMessage: string = response.message || 'S/ Mensagem';

    const payload: DiscordGenericNotificationInterface = {
      allowed_mentions: {
        parse: [ParseEnum.everyone],
      },
      embeds: [
        {
          author: {
            name: 'Step completed',
          },
          color: ColorEnum.success,
          title: step,
          fields: [
            {
              name: 'Estabelecimento',
              inline: true,
              value: loggedUser.name,
            },
            {
              name: 'Slug',
              inline: true,
              value: loggedUser.username,
            },
            { name: 'Status code', value: statusCode, inline: true },
            { name: 'Status text', value: statusText, inline: true },
            { name: 'Mensagem exibida p/ o cliente', value: userMessage, inline: false },
            ...(fields || []),
          ],
        },
      ],
    };

    emitDiscordNotification(webhook, payload);
  } catch (error) {
    emitDiscordNotification(webhook, {
      allowed_mentions: {
        parse: [ParseEnum.everyone],
      },
      embeds: [
        {
          author: {
            name: step,
          },
          color: ColorEnum.error,
          title: 'Error to send error on Discord (Fucked)',
          fields: [
            {
              name: 'Estabelecimento',
              inline: true,
              value: loggedUser.name,
            },
          ],
        },
      ],
    });
  }
};
