import React, { useState } from 'react';
import { Dialog, Fab } from '@project/components';
import SalesToolbar from '../layouts/SalesToolbar';
import { CloudDownloadOutlined } from '@material-ui/icons';

interface SalesPageReportMobileInterface {}

const SalesPageReportMobile: React.FC<SalesPageReportMobileInterface> = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  return (
    <>
      <Dialog
        fullWidth
        agreeText={false}
        open={showDialog}
        contentTitle="Download do excel"
        disagreText="Fechar"
        fullScreen
        onDisagree={(): void => setShowDialog(false)}
        contentText={<SalesToolbar />}
      />

      <Fab
        size="small"
        onClick={(): void => setShowDialog(!showDialog)}
        color="primary"
      >
        <CloudDownloadOutlined />
      </Fab>
    </>
  );
};

export default SalesPageReportMobile;
