import { convertMoneyToCents } from '@project/components/src/utils/CreditCardValidators';
import { getEstablishment } from './getEstablishment';
import { updateSettings } from './settings/updateSettings';
import { parseCurrencyToFloat } from '@project/components/src/utils/Parser';

/**
 * @deprecated
 *
 * use getEstablishment instead
 *
 * @param {*} uid
 * @param {*} token
 */
export const getAllRestaurantConfigs = async (uid, token) => {
  const response = await getEstablishment(uid, token);

  return response;
};

export const getDeliveryConfiguration = async (uid, token) => {
  const { delivery } = await getEstablishment(uid, token);

  return delivery;
};

// export const setRestaurantConfigs = async (uid, config) => {
//   const { service_fee } = await serviceCreate()

//   return service_fee
// }

export const updateDeliveryConfiguration = async (value, token) => {
  try {
    const response = await updateSettings({ flag_delivery: value }, token);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateDeliveryKmFeeValue = async (value, token) => {
  try {
    const valueCents = convertMoneyToCents(parseCurrencyToFloat(value));

    const response = await updateSettings(
      { delivery_km_fee: valueCents },
      token,
    );

    return response.delivery_km_fee;
  } catch (error) {
    console.error(error);
  }
};
