import React from 'react';
import PropTypes from 'prop-types';

import { List as MaterialList } from '@material-ui/core';

const List = ({ ...props }) => {
  return <MaterialList {...props} />;
};

List.propTypes = {
  props: PropTypes.node,
};

export default List;
