import { PrivateRoute } from '@project/components';
import { AuthContext } from '@project/components/src/contexts/AuthContextProvider';
import PublicRoute from '@project/components/src/utils/PublicRoute';
import React, { lazy, Suspense, useContext } from 'react';
import { Switch } from 'react-router-dom';
import SignUpedMiddleware from '../middlewares/SignUpedMiddleware';
import SalesRootPage from '../pages/configurations/sales/SalesRootPage';
import LoginPage from '../pages/LoginPage';
import SharePage from '../pages/share/SharePage';
import PresentialRootPage from '../pages/presential/PresentialRootPage';
import FreightRootPage from '../pages/configurations/freight/FreightRootPage';
import CouponRootPage from '../pages/coupons/CouponRootPage';
import CouponCrud from '../pages/coupons/containers/CouponCrud';
import FaqRootPage from '../pages/faq/FaqRootPage';

const ItemCrud = lazy(() => import('../pages/menu/item/ItemCrud'));
const MenuPage = lazy(() => import('../pages/menu/MenuPage'));
const CategoriasCrud = lazy(() =>
  import('../pages/menu/categorias/CategoriasCrud'),
);
const CategoriasPage = lazy(() =>
  import('../pages/menu/categorias/CategoriasPage'),
);
const AdditionalsCrud = lazy(() =>
  import('../pages/menu/additionals/AdditionalsCrud'),
);
const AdditionalsPage = lazy(() =>
  import('../pages/menu/additionals/AdditionalsPage'),
);

const OrdersRootPage = lazy(() => import('../pages/orders/OrdersRootPage'));

const Page404 = lazy(() => import('../pages/Page404'));

// const DeliveryMenu = lazy(() => import('../pages/configurations/delivery/DeliveryMenu'));
const InformacoesCrud = lazy(() =>
  import('../pages/configurations/informacoes/InformacoesCrud'),
);
const NotificationsRootPage = lazy(() =>
  import('../pages/configurations/notifications/NotificationsRootPage'),
);
const PerfilPage = lazy(() => import('../pages/configurations/PerfilPage'));
const OrderPreviewRootPage = lazy(() =>
  import('../pages/configurations/orderPreview/OrderPreviewRootPage'),
);
const ValoresMenupage = lazy(() =>
  import('../pages/configurations/taxes/ValoresMenuPage'),
);
const DeliveryWhitelistPage = lazy(() =>
  import('../pages/configurations/delivery/DeliveryWhitelistPage'),
);
const DeliveryTakeaway = lazy(() =>
  import('../pages/configurations/delivery/TakeawaySection'),
);

const AppRouter = () => {
  const { loggedUser } = useContext(AuthContext);

  return (
    <Suspense fallback={<div>Carregando...</div>}>
      <Switch>
        <PublicRoute exact path="/login" component={<LoginPage />} />

        <PublicRoute exact path="/ajuda" component={<FaqRootPage isPublic />} />

        <SignUpedMiddleware>
          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/faq"
            component={<FaqRootPage isPublic={false} />}
          />
          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/"
            component={<OrdersRootPage />}
          />

          {/* Taxas */}
          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/configuracoes/valores"
          >
            <ValoresMenupage />
          </PrivateRoute>

          <PrivateRoute loggedUser={loggedUser} exact path="/pedidos">
            <OrdersRootPage />
          </PrivateRoute>

          {/* Profile */}

          <PrivateRoute loggedUser={loggedUser} exact path="/compartilhar/">
            <SharePage />
          </PrivateRoute>

          {/* Information */}

          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/configuracoes/loja"
          >
            <InformacoesCrud />
          </PrivateRoute>

          <PrivateRoute loggedUser={loggedUser} exact path="/configuracoes/">
            <PerfilPage />
          </PrivateRoute>

          {/* Information */}

          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/configuracoes/informacoes"
          >
            <InformacoesCrud />
          </PrivateRoute>

          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/configuracoes/presencial"
          >
            <PresentialRootPage />
          </PrivateRoute>

          <PrivateRoute loggedUser={loggedUser} exact path="/cupons">
            <CouponRootPage />
          </PrivateRoute>

          <PrivateRoute loggedUser={loggedUser} exact path="/cupom/:id">
            <CouponCrud />
          </PrivateRoute>

          {/* Sales */}

          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/vendas/:orderNumber"
          >
            <OrderPreviewRootPage />
          </PrivateRoute>

          <PrivateRoute loggedUser={loggedUser} exact path="/vendas">
            <SalesRootPage />
          </PrivateRoute>

          {/* Categories  */}

          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/configuracoes/categoria/novo"
          >
            <CategoriasCrud />
          </PrivateRoute>

          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/configuracoes/categoria/:id"
          >
            <CategoriasCrud />
          </PrivateRoute>

          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/configuracoes/categorias"
          >
            <CategoriasPage />
          </PrivateRoute>

          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/catalogo/item/novo"
            component={<ItemCrud />}
          />
          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/catalogo/:itemId"
            component={<ItemCrud />}
          />

          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/catalogo/"
            component={<MenuPage />}
          />

          {/* Additionals */}
          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/adicionais/novo"
            component={<AdditionalsCrud />}
          />
          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/adicionais/:id"
            component={<AdditionalsCrud />}
          />

          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/adicionais"
            component={<AdditionalsPage />}
          />

          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/configuracoes/notificacoes"
          >
            <NotificationsRootPage />
          </PrivateRoute>

          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/configuracoes/delivery/whitelist"
          >
            <DeliveryWhitelistPage />
          </PrivateRoute>

          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/configuracoes/delivery/takeaway"
          >
            <DeliveryTakeaway />
          </PrivateRoute>

          <PrivateRoute
            loggedUser={loggedUser}
            exact
            path="/configuracoes/delivery"
          >
            {/* <DeliveryMenu /> */}
            <FreightRootPage />
          </PrivateRoute>
        </SignUpedMiddleware>

        <PublicRoute path="*">
          <Page404 />
        </PublicRoute>
      </Switch>
    </Suspense>
  );
};

export default AppRouter;
