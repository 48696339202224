import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';
import styled, { css } from 'styled-components';
import { Badge } from '@project/components';
import { useLocalStorage } from '@project/components/src/hooks/useLocalStorage';
import Tooltip from '@material-ui/core/Tooltip';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    // marginRight: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    paddingRight: '0px',
    paddingLeft: '10px',
    overflowX: 'hidden',
    width: theme.spacing(7) + 10,
    textAlign: 'center',
    marginBottom: -10,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 10,
      paddingRight: '10px',
    },
    [theme.breakpoints.only('sm')]: {
      width: theme.spacing(9) + 10,
      paddingRight: '10px',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    minHeight: 'initial',
    // marginBottom: '24px',
    padding: theme.spacing(0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    overflowX: 'hidden',
    marginTop: '52px',
    paddingTop: '24px',
    padding: theme.spacing(3),
  },
}));

const Logo = styled.img`
  height: 20px;
  width: auto;
`;

interface CustomListInterface {
  listSize: number;
  open: boolean;
}

interface ActiveItemInterface {
  active: boolean;
}

const CustomList = styled(List)<CustomListInterface>`
  overflow: hidden !important;
  min-height: ${props => `${props.listSize * (props.open ? 47 : 42)}px`};
`;

const CustomListItemIcon = styled(ListItemIcon)<ActiveItemInterface>`
  min-width: 35px !important;
  ${props =>
    props.active &&
    css`
      color: #383d6b !important;
      font-weight: 500 !important;
    `}
`;

const CustomListItemText = styled(ListItemText)<ActiveItemInterface>`
  span {
    /* color: rgba(000, 000, 000, 0.54) !important; */
    font-size: 14px !important;
    line-height: 20px !important;
    ${props =>
      props.active &&
      css`
        color: #383d6b !important;
        font-weight: 500 !important;
      `}
  }
`;

const CustomTitle = styled(Typography)`
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  margin-bottom: 2px !important;
`;

export interface SectionInterface {
  text: String;
  icon?: React.ReactElement;
  onClick: Function;
  disabled?: boolean;
  active: boolean;
  showDot?: boolean;
}

export interface ListInterface {
  list: SectionInterface[];
  sectionName: String;
  shortSectionName: String;
}

export interface SidebarInterface {
  listSections: ListInterface[];
  title: String;
}

const Sidebar: React.FC<SidebarInterface> = ({
  children,
  listSections,
  title,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useLocalStorage(
    'desktop_sidebar_open_default',
    true,
    false,
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        elevation={open ? 1 : 4}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h3" noWrap>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            {open && <Logo src="/ockpay-logo-azul-gradient.png" alt="Ockpay" />}
            <div>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
          </Box>
        </div>
        {listSections.map((children, listIndex) => {
          return (
            <>
              {open ? <Divider /> : listIndex !== 0 && <Divider />}
              <Box mt={2} ml={open ? '18px' : 0}>
                <CustomTitle variant="h3">
                  {open ? children.sectionName : children.shortSectionName}
                </CustomTitle>
              </Box>
              <CustomList listSize={children.list.length} open={open}>
                {children.list.map((section, sectionIndex) => (
                  <>
                    <ListItem
                      disabled={section.disabled}
                      button
                      key={`section-${listIndex}-${sectionIndex}`}
                      onClick={(): void => section.onClick()}
                    >
                      {section.icon &&
                        (!open ? (
                          <Tooltip title={section.text} placement="right">
                            <CustomListItemIcon active={section.active}>
                              {section.icon}
                            </CustomListItemIcon>
                          </Tooltip>
                        ) : (
                          <CustomListItemIcon active={section.active}>
                            {section.icon}
                          </CustomListItemIcon>
                        ))}
                      {open && (
                        <CustomListItemText
                          active={section.active}
                          primary={section.text}
                        />
                      )}
                      {section.showDot && (
                        <Badge blink variant="dot" color="secondary" />
                      )}
                    </ListItem>
                  </>
                ))}
              </CustomList>
            </>
          );
        })}
      </Drawer>
      <main className={classes.content}>
        {/* <div className={classes.toolbar}>
          <Alert>teste</Alert>
        </div> */}
        {children}
      </main>
    </div>
  );
};

export default Sidebar;
