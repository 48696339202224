/* eslint-disable react/prop-types */
import React from 'react';
import { Typography, Skeleton } from '@project/components';
import styled from 'styled-components';

interface OpenClosedTextInterface {
  isOpen: boolean | undefined;
}

const CustomOpenClosedText = styled(Typography)<OpenClosedTextInterface>`
  font-size: 11pt !important;
  color: ${({ isOpen }): string => (isOpen ? '#45B97C' : '#E92828')};
  font-weight: bold;
`;

const OpenClosedText: React.FC<OpenClosedTextInterface> = ({ isOpen }) => {
  if (isOpen === undefined) return <Skeleton variant="text" width="41px" height="22px" />;

  return <CustomOpenClosedText isOpen={isOpen}>{isOpen ? 'Aberto' : 'Fechado'}</CustomOpenClosedText>;
};

export default OpenClosedText;
