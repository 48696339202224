import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { Zoom, Fab } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(16),
    right: theme.spacing(2),
    zIndex: 99999999,
  },
}));

function ScrollTop() {
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    // const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // if (anchor) {
    //   anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    // }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        <Fab color="secondary" size="small" aria-label="Voltar ao topo">
          <KeyboardArrowUpIcon />
        </Fab>
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default ScrollTop;
