import React, { useContext, useEffect, useState } from 'react';
import ShareDeliverySection from '../configurations/delivery/ShareDeliverySection';
import { useCookies } from 'react-cookie';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { AppBarContext } from '@project/components/src/contexts/AppBarContext';
import { getEstablishment } from '../../services/getEstablishment';
import { InputAdornment, Input } from '@material-ui/core';
import {
  CardComplex,
  Grid,
  Box,
  PageTitle,
  FormControl,
  IconButton,
} from '@project/components';
import EstablishmentInfoSection from '@project/components-typescript/src/components/EstablishmentInfoSection/EstablishmentInfoSection';
import { EstablishmentInterface } from '../../interfaces/EstablishmentInterface';
import { Link } from '@material-ui/icons';

const SharePage: React.FC = () => {
  const [cookies] = useCookies(['token']);
  const [shareURL, setShareURL] = useState<string>('');
  const [establishment, setEstablishment] = useState<
    EstablishmentInterface | undefined
  >(undefined);
  const {
    setShowBackdrop,
    setShowSnackbar,
    setSnackbarInformation,
  } = useContext(FeedbackContext);
  const { setPageTitle, setShowGoBack } = useContext(AppBarContext);

  function copyShareURL(): void {
    const url = document.getElementById(
      'share-url-content',
    ) as HTMLInputElement;

    url?.select();
    url?.setSelectionRange(0, 99999);
    document.execCommand('copy');

    setSnackbarInformation({
      message: `O seu link foi copiado!`,
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setShowSnackbar(true);
  }

  useEffect(() => {
    setPageTitle('Compartilhar');

    setShowGoBack(true);
    setShowBackdrop(true);

    getEstablishment(cookies.token)
      .then(establishment => {
        setEstablishment(establishment);
        setShareURL(
          `https://ockpay.com.br/${establishment?.slug.slug}/delivery`,
        );
      })
      .catch(error => console.error(error))
      .finally(() => setShowBackdrop(false));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <PageTitle title="Link do seu estabelecimento" />
          <CardComplex
            button1={{
              text: <div onClick={copyShareURL}>Copiar link</div>,
            }}
            preview={
              <>
                <EstablishmentInfoSection
                  fantasy_name={establishment?.fantasy_name}
                  flag_delivery={establishment?.settings.flag_delivery}
                  flag_takeaway={establishment?.settings.flag_takeaway}
                  flag_open={establishment?.settings.flag_open}
                  full_address={establishment?.address.city}
                />
                <Box mt={4}>
                  <FormControl
                    fullWidth
                    size="medium"
                    variant="filled"
                    margin="none"
                  >
                    {/* <InputLabel htmlFor="standard-adornment-password">Password</InputLabel> */}
                    <Input
                      id="share-url-content"
                      type="text"
                      value={shareURL}
                      fullWidth
                      readOnly
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={copyShareURL}
                            // onMouseDown={handleMouseDownPassword}
                          >
                            <Link />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              </>
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <PageTitle title="Compartilhe nas suas redes sociais" />
          <ShareDeliverySection shareURL={shareURL} />
        </Grid>
      </Grid>
    </>
  );
};

export default SharePage;
