import React, { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { FreightContext } from '../contexts/FreightContext';
import { getfreightMethods } from '../services/getFreightMethods';
import ConfigFreightMethods from './ConfigFreightMethods';

interface ListFreightMethodsInterface {}

const ListFreightMethods: React.FC<ListFreightMethodsInterface> = () => {
  const [cookies] = useCookies(['token']);
  const { stateFreight, dispatchFreight } = useContext(FreightContext);

  useEffect(() => {
    getfreightMethods(cookies.token).then(({ data }) => {
      dispatchFreight({ freightMethods: data, type: 'ADD_FREIGHT_METHODS' });
    });
  }, [dispatchFreight, cookies.token]);

  return (
    <div>
      {stateFreight.freightMethods.map(method => (
        <ConfigFreightMethods key={`method-${method.id}`} method={method} />
      ))}
    </div>
  );
};

export default ListFreightMethods;
