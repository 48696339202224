export enum FreightMethodsSlugEnum {
  proprio = 'proprio',
  motoboydotcom = 'motoboydotcom',
  ockdelivery = 'ockdelivery',
  boxdelivery = 'boxdelivery',
}

export enum FreightMethodsIdEnum {
  proprio = 1,
  motoboydotcom = 2,
  ockdelivery = 3,
  boxdelivery = 5,
}
