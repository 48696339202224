import React, { useCallback, useContext, useMemo } from 'react';
import { FreightContext } from '../contexts/FreightContext';
import { PaperSection } from '@project/components';
import { Dialog } from '@project/components';
import { isMobile } from 'react-device-detect';
import MotoboyDotComConfig from '../sections/MotoboyDotComConfig';
import SelfFreightConfig from '../sections/SelfFreightConfig';
import { FreightMethodsIdEnum } from '@project/components-typescript/src/interfaces/FreightInterface';

interface GenericFreightConfigurationInterface {}

const GenericFreightConfiguration: React.FC<GenericFreightConfigurationInterface> = () => {
  const { stateFreight, dispatchFreight } = useContext(FreightContext);

  const isOpen = useMemo(() => stateFreight.selectedIntegration !== null, [stateFreight.selectedIntegration]);

  const Interface = useCallback(() => {
    switch (stateFreight.selectedIntegration?.freight_methods_id) {
      case FreightMethodsIdEnum.proprio:
        return (
          <PaperSection first title="Configurações de delivery próprio">
            <SelfFreightConfig />
          </PaperSection>
        );

      case FreightMethodsIdEnum.motoboydotcom:
        return (
          <PaperSection first title="Configurações de Motoboy.com">
            <MotoboyDotComConfig />
          </PaperSection>
        );

      default:
        return (
          <PaperSection first title="Nenhuma integração selecionada">
            Para habilitar esse menu, instale alguma integração e clique em configurar!
          </PaperSection>
        );
    }
  }, [stateFreight.selectedIntegration]);

  return (
    <>
      {!isMobile ? (
        <Interface />
      ) : (
        <Dialog
          fullScreen
          contentTitle={false}
          open={isOpen}
          contentText={``}
          onDisagree={(): void => dispatchFreight({ type: 'UNSELECT_FREIGHT_INTEGRATIONS' })}
          agreeText={false}
          disagreText="Fechar"
        >
          <Interface />
        </Dialog>
      )}
    </>
  );
};

export default GenericFreightConfiguration;
