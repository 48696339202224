import {
  AccountBancoBrasil,
  AccountBancoOriginal,
  AccountBanestes,
  AccountBanrisul,
  AccountBradesco,
  AccountBRB,
  AccountCaixa,
  AccountFallback,
  AccountInter,
  AccountItau,
  AccountModal,
  AccountNeon,
  AccountNubank,
  AccountPagSeguro,
  AccountSafra,
  AccountSantander,
  AccountSicoob,
  AccountSicredi,
  AccountUnicred,
  AccountViaCredi,
  AccountBSB,
} from '@project/components/src/molecule/MaskedTextField/types/BankAccount';
import {
  AgencyBancoBrasil,
  AgencyBancoOriginal,
  AgencyBanestes,
  AgencyBanrisul,
  AgencyBradesco,
  AgencyBRB,
  AgencyCaixa,
  AgencyFallback,
  AgencyInter,
  AgencyItau,
  AgencyModal,
  AgencyNeon,
  AgencyNubank,
  AgencyPagSeguro,
  AgencySafra,
  AgencySantander,
  AgencySicoob,
  AgencySicredi,
  AgencyUnicred,
  AgencyViaCredi,
  AgencyBSB,
} from '@project/components/src/molecule/MaskedTextField/types/BankAgency';

export default [
  'Banco do Brasil',
  'Banco Original',
  'Banestes',
  'Banrisul',
  'Bradesco',
  'BRB',
  'Caixa Econômica',
  'Inter',
  'Itaú',
  'Modal',
  'Neon',
  'Nubank',
  'PagSeguro',
  'Safra',
  'Santander',
  'Sicoob (Bancoob)',
  'Sicredi',
  'Unicred',
  'Via Credi',
];

export const bankSelect = [
  { value: 'Banco do Brasil', label: 'Banco do Brasil' },
  { value: 'Banco Original', label: 'Banco Original' },
  { value: 'Banestes', label: 'Banestes' },
  { value: 'Banrisul', label: 'Banrisul' },
  { value: 'Bradesco', label: 'Bradesco' },
  { value: 'BRB', label: 'BRB' },
  { value: 'Caixa Econômica', label: 'Caixa Econômica' },
  { value: 'Inter', label: 'Inter' },
  { value: 'Itaú', label: 'Itaú' },
  { value: 'Modal', label: 'Modal' },
  { value: 'Neon', label: 'Neon' },
  { value: 'Nubank', label: 'Nubank' },
  { value: 'PagSeguro', label: 'PagSeguro' },
  { value: 'Safra', label: 'Safra' },
  { value: 'Santander', label: 'Santander' },
  { value: 'Sicoob (Bancoob)', label: 'Sicoob (Bancoob)' },
  { value: 'Sicredi', label: 'Sicredi' },
  { value: 'Unicred', label: 'Unicred' },
  { value: 'Via Credi', label: 'Via Credi' },
  { value: 'BS2', label: 'BS2' },
];

export const bankAccountTypes = [
  { value: 'Corrente', label: 'Conta Corrente' },
  { value: 'Poupança', label: 'Conta Poupança' },
];

export function isAgencySizeNumberCorrectly(bankInput, numberInput) {
  const { agencyLengthValidator } = bankMask.find(({ bank }) => bank === bankInput);

  return agencyLengthValidator === parseInt(numberInput);
}

export function isAccountSizeNumberCorrectly(bankInput, numberInput) {
  const { accountLengthValidator } = bankMask.find(({ bank }) => bank === bankInput);

  return accountLengthValidator === parseInt(numberInput);
}

export const bankMask = [
  {
    bank: 'Banco do Brasil',
    maskAgency: AgencyBancoBrasil,
    maskAccount: AccountBancoBrasil,
    agencyLengthValidator: 6,
    accountLengthValidator: 10,
  },
  {
    bank: 'Banco Original',
    maskAgency: AgencyBancoOriginal,
    maskAccount: AccountBancoOriginal,
    agencyLengthValidator: 4,
    accountLengthValidator: 9,
  },
  {
    bank: 'Banestes',
    maskAgency: AgencyBanestes,
    maskAccount: AccountBanestes,
    agencyLengthValidator: 4,
    accountLengthValidator: 10,
  },
  {
    bank: 'Banrisul',
    maskAgency: AgencyBanrisul,
    maskAccount: AccountBanrisul,
    agencyLengthValidator: 4,
    accountLengthValidator: 11,
  },
  {
    bank: 'Bradesco',
    maskAgency: AgencyBradesco,
    maskAccount: AccountBradesco,
    agencyLengthValidator: 6,
    accountLengthValidator: 9,
  },
  {
    bank: 'BRB',
    maskAgency: AgencyBRB,
    maskAccount: AccountBRB,
    agencyLengthValidator: 4,
    accountLengthValidator: 11,
  },
  {
    bank: 'Caixa Econômica',
    maskAgency: AgencyCaixa,
    maskAccount: AccountCaixa,
    agencyLengthValidator: 4,
    accountLengthValidator: 13,
  },
  {
    bank: 'Inter',
    maskAgency: AgencyInter,
    maskAccount: AccountInter,
    agencyLengthValidator: 4,
    accountLengthValidator: 11,
  },
  {
    bank: 'Itaú',
    maskAgency: AgencyItau,
    maskAccount: AccountItau,
    agencyLengthValidator: 4,
    accountLengthValidator: 7,
  },
  {
    bank: 'Modal',
    maskAgency: AgencyModal,
    maskAccount: AccountModal,
    agencyLengthValidator: 4,
    accountLengthValidator: 11,
  },
  {
    bank: 'Neon',
    maskAgency: AgencyNeon,
    maskAccount: AccountNeon,
    agencyLengthValidator: 4,
    accountLengthValidator: 9,
  },
  {
    bank: 'Nubank',
    maskAgency: AgencyNubank,
    maskAccount: AccountNubank,
    agencyLengthValidator: 4,
    accountLengthValidator: 12,
  },
  {
    bank: 'PagSeguro',
    maskAgency: AgencyPagSeguro,
    maskAccount: AccountPagSeguro,
    agencyLengthValidator: 4,
    accountLengthValidator: 10,
  },
  {
    bank: 'Safra',
    maskAgency: AgencySafra,
    maskAccount: AccountSafra,
    agencyLengthValidator: 4,
    accountLengthValidator: 10,
  },
  {
    bank: 'Santander',
    maskAgency: AgencySantander,
    maskAccount: AccountSantander,
    agencyLengthValidator: 4,
    accountLengthValidator: 10,
  },
  {
    bank: 'Sicoob (Bancoob)',
    maskAgency: AgencySicoob,
    maskAccount: AccountSicoob,
    agencyLengthValidator: 4,
    accountLengthValidator: 11,
  },
  {
    bank: 'Sicredi',
    maskAgency: AgencySicredi,
    maskAccount: AccountSicredi,
    agencyLengthValidator: 4,
    accountLengthValidator: 7,
  },
  {
    bank: 'Unicred',
    maskAgency: AgencyUnicred,
    maskAccount: AccountUnicred,
    agencyLengthValidator: 4,
    accountLengthValidator: 10,
  },
  {
    bank: 'Via Credi',
    maskAgency: AgencyViaCredi,
    maskAccount: AccountViaCredi,
    agencyLengthValidator: 4,
    accountLengthValidator: 13,
  },
  {
    bank: 'BS2',
    maskAgency: AgencyBSB,
    maskAccount: AccountBSB,
    agencyLengthValidator: 4,
    accountLengthValidator: 7,
  },
  {
    bank: '',
    maskAgency: AgencyFallback,
    maskAccount: AccountFallback,
    agencyLengthValidator: 4,
    accountLengthValidator: 13,
  },
];
