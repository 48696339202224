import React, { useContext } from 'react';
import { PaperSection, Grid } from '@project/components';
import ListFreightMethods from './containers/ListFreightMethods';
import ListFreightIntegrations from './containers/ListFreightIntegrations';
import GenericFreightConfiguration from './layouts/GenericFreightConfiguration';
import ModeloNegociosSection from '../informacoes/sections/ModeloNegociosSection';
import { GlobalStateContext } from '../../../contexts/GlobalState/GlobalStateContext';
import ConfigGeneralDelivery from './containers/ConfigGeneralDelivery';

interface FreightConfigurationInterface {}

const FreightConfiguration: React.FC<FreightConfigurationInterface> = () => {
  const { stateGlobalState } = useContext(GlobalStateContext);

  const isLoading: boolean = stateGlobalState.settings === undefined;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={5}>
        <Grid item xs={12}>
          <PaperSection first isLoading={isLoading} title="Como você entregará seus produtos">
            {stateGlobalState.settings && <ModeloNegociosSection />}
          </PaperSection>
        </Grid>
        <Grid item xs={12}>
          <PaperSection first isLoading={isLoading} title="Configurações gerais">
            <ConfigGeneralDelivery />
          </PaperSection>
        </Grid>
        <Grid item xs={12}>
          <PaperSection title="Integrações disponíveis" isLoading={isLoading}>
            <ListFreightMethods />
          </PaperSection>
        </Grid>
        <Grid item xs={12}>
          <ListFreightIntegrations />
        </Grid>
      </Grid>
      {/* <Divider orientation="vertical" flexItem /> */}
      <Grid direction="column" alignItems="center" justify="center" item xs={12} md={7}>
        <GenericFreightConfiguration />
      </Grid>
    </Grid>
  );
};

export default FreightConfiguration;
