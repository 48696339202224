import { createMuiTheme } from '@material-ui/core/styles';

const colors = {
  ockLightBlue: '#00add4',
  ockDarkBlue: '#383d6b',
  ockDarkenBlue: '#1d234e',
  ockGreenLight: '#63c591',
  ockGreen: '#45b97c',
  ockGreenDark: '#3da46d',
  ockGrey: '#808285',
  ockTeal: '#00b9ad',
  ockGradient:
    'linear-gradient(90deg, rgba(69,185,124,1) 0%, rgba(34,179,168,1) 50%, rgba(0,173,212,1) 100%)',
};

export const ockTheme = {
  colors: colors,
};

export const cleanTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: ockTheme.colors.ockDarkBlue,
      main: ockTheme.colors.ockDarkBlue,
      dark: ockTheme.colors.ockLightBlue,
      contrastText: '#fff',
    },
    secondary: {
      light: ockTheme.colors.ockGreenLight,
      main: ockTheme.colors.ockGreen,
      dark: ockTheme.colors.ockGreenDark,
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    button: {
      borderColor: '#c7c7c7',
    },
    common: {
      black: '#000000ed',
      white: '#fff',
    },
    icon: {
      alert: '#f44336',
      delete: '#f44336',
      success: ockTheme.colors.ockGreenDark,
      default: '#c7c7c7',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#c7c7c7',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#c7c7c7',
          borderWidth: 2,
        },
        '&$focused $notchedOutline': {
          borderColor: '#c7c7c7',
        },
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 1000px black inset',
        },
      },
      notchedOutline: {},
    },
    MuiFormControl: {
      root: {
        marginBottom: '1em',
      },
    },
    MuiSnackbar: {
      root: {
        'margin-bottom': '4.4em',
        'background-color': 'inherit',
        transition:
          'opacity 2225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
    MuiSelect: {
      icon: {
        fill: ockTheme.colors.ockTeal,
      },
    },
    SelectInput: {
      minWidth: '200px',
    },

    MuiBottomNavigation: {
      root: {
        'border-top': '1px solid #eee',
        'z-index': 4,
        left: 0,
      },
    },
    MuiPaper: {
      root: {
        padding: '1em',
      },
    },
    MuiCard: {
      root: {
        padding: '.25em',
      },
    },
    MuiListSubheader: {
      sticky: {
        'background-color': '#fff',
        top: '3.6em',
        'z-index': 3,
      },
    },
    MuiToolbar: {
      regular: {
        'min-height': '50px',
      },
    },
    MuiAppBar: {
      root: {
        padding: 0,
      },
      colorPrimary: {
        'background-color': '#fff',
        color: '#000000c7',
        border: '1px solid #eee',
      },
    },
    MuiFab: {
      extended: {
        right: '16px',
        bottom: '5.3em',
        position: 'absolute',
      },
      root: {
        right: '16px',
        bottom: '5.3em',
        position: 'fixed',
        'z-index': 4,
      },
    },
    MuiButtonBase: {
      root: {
        'z-index': 2,
      },
    },
    MuiTabs: {
      root: {
        'background-color': '#fff',
        'padding-bottom': '.5em',
        'z-index': 10,
      },
    },
  },
  shape: {},
  breakpoints: {},
  direction: {},
  mixins: {},
  props: {},
  // shadows: {},
  // spacing: {},
  transitions: {},
  typography: {
    fontFamily: `Montserrat`,
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  zIndex: {},
});
