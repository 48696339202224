import PropTypes from 'prop-types';
import { CircularProgress, Avatar } from '@material-ui/core';
import AddAPhotoRounded from '@material-ui/icons/AddAPhotoRounded';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import styled, { css } from 'styled-components';
import Slider from '../../atom/Slider';
import Box from '../../atom/Box';
import Button from '../../atom/Button';
import Grid from '../../atom/Grid';
import Typography from '../../atom/Typography';
import { ifProp } from 'styled-tools';
import { isMobile } from 'react-device-detect';

const CropContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: 300px;
  min-width: 300px;
  position: relative;
  ${ifProp(
    { showPreviewImage: false },
    css`
      min-height: ${isMobile ? '300px' : '300px'};
    `,
  )}
`;

const Controls = styled.div``;

const Dropzone = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const LogoImage = styled(Avatar)`
  width: ${isMobile ? '4em' : '300px'} !important;
  height: ${isMobile ? '4em' : '300px'} !important;
`;

const BoxThumbs = styled(Box)`
  user-select: auto !important;
  touch-action: auto !important;
`;

const LogoImageCrop = ({
  showZoom = false,
  imagePreview = null,
  updatedAt,
  setHasImage,
  imagePreviewVariant = 'rounded',
  setFile,
  setInfo = () => {},
  ...props
}) => {
  // eslint-disable-next-line no-unused-vars
  const [openPreview, setOpenPreview] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imageDataFile, setImageDataFile] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [croppedArea, setCroppedArea] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [showPreviewImage, setShowPreviewImage] = useState(false);
  const [showDropZone, setShowDropZone] = useState(true);
  const [showCrop, setShowCrop] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showControls, setShowControls] = useState(showZoom);
  const [base64, setBase64] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: async acceptedFiles => {
      setIsUploading(true);
      const rawImage = acceptedFiles[0];
      setFile(rawImage);
      // const compressedImage = await handleCompressImage(rawImage)
      const imageDataFile = await toBase64(rawImage);

      setBase64(imageDataFile);
      setImageDataFile(imageDataFile);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
    },
  });

  useEffect(() => {
    setIsUploading(false);
    setShowPreviewImage(false);
    setShowDropZone(false);
    setShowCrop(true);
    setHasImage(true);

    if (imageDataFile === undefined || imageDataFile === null) {
      setShowDropZone(true);
      setShowCrop(false);
      setHasImage(false);
    }
  }, [imageDataFile, setHasImage]);

  useEffect(() => {
    const doesNotHaveImage = !!imagePreview;
    setShowPreviewImage(doesNotHaveImage);
    setShowDropZone(!doesNotHaveImage);
    setHasImage(doesNotHaveImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagePreview]);

  const ThumbsLayout = () => (
    <BoxThumbs align="center">
      {/* <Thumb> */}
      {/* <ThumbInner> */}
      <LogoImage
        onClick={() => setOpenPreview(true)}
        variant={imagePreviewVariant}
        src={imagePreview}
      ></LogoImage>
      {/* <CustomResponsiveImage
        src={`${loadImage(imagePreview)}?${updatedAt}`}
        alt='Imagem para fazer upload'
      /> */}
      {/* </ThumbInner> */}
      {/* </Thumb> */}
    </BoxThumbs>
  );

  useEffect(() => {
    setInfo(croppedAreaPixels);
  }, [croppedAreaPixels, setInfo]);

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedArea(croppedArea);
      setCroppedAreaPixels(croppedAreaPixels);

      props.onChange({
        image_name: 'teste',
        base64: base64,
        ...croppedAreaPixels,
      });
    },
    [base64, props],
  );

  return (
    <>
      {showCrop && (
        <>
          <CropContainer showPreviewImage={showPreviewImage}>
            <Cropper
              image={imageDataFile}
              crop={crop}
              zoom={zoom}
              aspect={1}
              showGrid={false}
              cropShape="round"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </CropContainer>

          <Controls>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              classes={{ container: 'slider' }}
            />
          </Controls>
        </>
      )}

      {showPreviewImage && <ThumbsLayout />}

      <Box
        mt={isMobile ? 2 : 0}
        mb={2}
        /* value={props.imageDetails} */ onChange={onCropComplete}
      >
        {showDropZone && (
          <Dropzone {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <Box pt={2}>
              <>
                {isUploading}
                <Typography align="center">
                  {isUploading ? (
                    <CircularProgress />
                  ) : (
                    <AddAPhotoRounded fontSize="large" />
                  )}
                </Typography>
                <Typography align="center">
                  {isUploading
                    ? 'Carregando imagem'
                    : 'Toque para adicionar uma foto'}
                </Typography>
              </>
            </Box>
          </Dropzone>
        )}
      </Box>

      {(showPreviewImage || showCrop) && (
        <Box mt={1} mb={1}>
          <Grid align="center">
            <Button variant="text" onClick={() => setImageDataFile(undefined)}>
              Alterar foto
            </Button>
          </Grid>
        </Box>
      )}
    </>
  );
};

LogoImageCrop.propTypes = {
  imagePreview: PropTypes.any,
  setHasImage: PropTypes.func,
  showZoom: PropTypes.bool,
  updatedAt: PropTypes.any,
};

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default LogoImageCrop;
