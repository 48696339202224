/**
 *
 * @param {*} phone
 */
export const hasPrefix = phone => {
  try {
    return (
      phone
        .toString()
        .trim()
        .substr(0, 2) === '55'
    );
  } catch (error) {
    console.error(error);
  }
};

export const hasDDD = phone => {
  try {
    const formattedPhone = phone
      .toString()
      .trim()
      .replace(/[^a-zA-Z0-9]"/, '')
      .replace(/[ \])}[{(/-]/g, '');

    return hasPrefix(formattedPhone)
      ? formattedPhone.substr(2, 2) === '11'
      : formattedPhone.substr(0, 2) === '11';
  } catch (error) {
    console.error(error);
  }
};

export const formatPhone = phone => {
  try {
    return phone
      .toString()
      .trim()
      .replace(/[^a-zA-Z0-9]"/, '')
      .replace(/[ \])}[{(/-]/g, '');
  } catch (error) {
    console.error(error);
  }
};

export const addPrefix = phone => {
  try {
    return hasPrefix(phone) ? phone : `55${phone}`;
  } catch (error) {
    console.error(error);
  }
};

export const buildPhoneNumber = phone => {
  try {
    const number = formatPhone(phone);
    const phoneWithPrefix = addPrefix(number);

    return phoneWithPrefix;
  } catch (error) {
    console.error(error);
  }
};

export const encodeMessage = message => {
  try {
    return encodeURI(message);
  } catch (error) {
    console.error(error);
  }
};

/**
 * Creates a whatsapp ready to send message link
 *
 * @param {string} phone
 * @param {string} message
 */
export const createWhatsappPhoneLink = (phone, message) => {
  try {
    const endpoint = 'https://api.whatsapp.com';

    const formattedPhone = buildPhoneNumber(phone);
    const formattedText = encodeMessage(message);

    return `${endpoint}/send?phone=${formattedPhone}&text=${formattedText}`;
  } catch (error) {
    console.error(error);
  }
};

export const createWhatsappShareLink = message => {
  try {
    const endpoint = 'whatsapp://send';

    const formattedText = encodeMessage(message);

    return `${endpoint}?text=${formattedText}`;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Creates a whatsapp ready to send message link
 *
 * @param {string} phone
 * @param {string} message
 */
export const createPhoneLink = phone => {
  try {
    const endpoint = 'tel';

    const formattedPhone = buildPhoneNumber(phone);

    return `${endpoint}:${formattedPhone}`;
  } catch (error) {
    console.error(error);
  }
};

export const createEmailLink = (mailTo, subject = '', body = '') =>
  `mailto:${mailTo}?subject=${subject}&body=${body}`;
