import { addNewOrderAction } from './actions/addNewOrderAction';
import { handleMotoboyMap } from './actions/handleMotoboyMap';
import { handleStatusChangeAction } from './actions/handleStatusChangeAction';
import { populateOrdersAction } from './actions/populateOrdersAction';
import { selectOrderAction } from './actions/selectOrderAction';
import { unselectOrderAction } from './actions/unselectOrderAction';
import { updateOrderAction } from './actions/updateOrderAction';
import {
  OrdersContextInterface,
  OrdersTypesEnum,
  POPULATE_ORDERS,
  SELECT_ORDER,
  UNSELECT_ORDER,
  ADD_NEW_ORDER,
  HANDLE_STATUS_CHANGE,
  HANDLE_MOTOBOY_MAP,
  UPDATE_ORDER,
} from './OrdersInterface';

export type OrdersAction =
  | POPULATE_ORDERS
  | SELECT_ORDER
  | UNSELECT_ORDER
  | HANDLE_STATUS_CHANGE
  | HANDLE_MOTOBOY_MAP
  | UPDATE_ORDER
  | ADD_NEW_ORDER;

function ordersReducer(
  state: OrdersContextInterface,
  action: OrdersAction,
): OrdersContextInterface {
  switch (action.type) {
    case OrdersTypesEnum.POPULATE_ORDERS:
      return populateOrdersAction(state, action);

    case OrdersTypesEnum.UPDATE_ORDER:
      return updateOrderAction(state, action);

    case OrdersTypesEnum.SELECT_ORDER:
      return selectOrderAction(state, action);

    case OrdersTypesEnum.UNSELECT_ORDER:
      return unselectOrderAction(state);

    case OrdersTypesEnum.ADD_NEW_ORDER:
      return addNewOrderAction(state, action);

    case OrdersTypesEnum.HANDLE_STATUS_CHANGE:
      return handleStatusChangeAction(state, action);

    case OrdersTypesEnum.HANDLE_MOTOBOY_MAP:
      return handleMotoboyMap(state, action);

    default:
      return state;
  }
}

export default ordersReducer;
