import React, { useContext, useState } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  SwitchInput,
} from '@project/components';
import { VariantEnum } from '@project/components-typescript/src/services/axiosService';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';

interface ConfigSwitchInterface<T> {
  icon?: JSX.Element | null;
  disabled?: boolean;
  text?: string;
  button?: boolean;
  subtitle?: string;
  checked: boolean | undefined;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: () => Promise<T>;
}

const ConfigSwitch: <T>(
  props: ConfigSwitchInterface<T>,
) => React.ReactElement = ({
  icon,
  disabled,
  text,
  subtitle,
  checked,
  button,
  onChange,
  setChecked,
}) => {
  const [internal, setInternal] = useState<boolean>(!!checked);
  const {
    setShowBackdrop,
    setShowSnackbar,
    setSnackbarInformation,
  } = useContext(FeedbackContext);

  const handleOnChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const toChangeValue: boolean = event.target.checked;
    setShowBackdrop(true);
    onChange()
      .then((response: unknown): void => {
        setInternal(toChangeValue);
        setChecked && setChecked(toChangeValue);
        const message =
          (typeof response === 'object' && response && response['message']) ||
          'Sucesso';
        setSnackbarInformation({ message, variant: VariantEnum.success });
      })
      .catch(() =>
        setSnackbarInformation({
          message:
            'Ooops, não foi possível realizar a operação, tente novamente!',
          variant: VariantEnum.error,
        }),
      )
      .finally(() => {
        setShowBackdrop(false);
        setShowSnackbar(true);
      });
  };

  const SwitchInputElement = (): JSX.Element => (
    <SwitchInput
      disabled={disabled}
      edge="end"
      onChange={handleOnChange}
      checked={internal}
    />
  );

  return (
    <ListItem button={button}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      {text && (
        <ListItemText
          style={{ overflowWrap: 'break-word' }}
          primary={text}
          secondary={subtitle}
        />
      )}
      {text ? (
        <ListItemSecondaryAction>
          <SwitchInputElement />
        </ListItemSecondaryAction>
      ) : (
        <SwitchInputElement />
      )}
    </ListItem>
  );
};

export default ConfigSwitch;
