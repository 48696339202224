import { Chip } from '@material-ui/core';
import React from 'react';

interface QtyUsedCellInterface {
  value: string | number | null;
}

const QtyUsedCell: React.FC<QtyUsedCellInterface> = ({
  value,
}): JSX.Element => {
  const renderValue: string = value?.toString() || '0';

  return <Chip label={renderValue} variant="outlined" />;
};

export default QtyUsedCell;
