import React, { useContext, useEffect, useState } from 'react';
import { AppBarContext } from '@project/components/src/contexts/AppBarContext';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { useCookies } from 'react-cookie';
import { getAllSettings } from '../../services/settings/settings';
import { EstablishmentSettingsInterface } from '../../interfaces/EstablishmentSettingsInterface';
import PresentialServiceFee from './sections/PresentialServiceFee';
import { PaperSection } from '@project/components';

interface PresentialRootPageInterface {}

const PresentialRootPage: React.FC<PresentialRootPageInterface> = () => {
  const [settings, setSettings] = useState<
    EstablishmentSettingsInterface | undefined
  >(undefined);
  const { setPageTitle, setShowGoBack } = useContext(AppBarContext);
  const { setShowBackdrop } = useContext(FeedbackContext);

  const [cookies] = useCookies(['token']);

  useEffect(() => {
    setPageTitle('Presencial');
    setShowGoBack(true);

    getAllSettings(cookies.token)
      .then(settings => setSettings(settings))
      .catch(error => console.error(error))
      .finally(() => setShowBackdrop(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PaperSection title="Cobranças opcionais">
        {settings && <PresentialServiceFee settings={settings} />}
      </PaperSection>
    </>
  );
};

export default PresentialRootPage;
