import { EditableCellInterface } from '@project/components-typescript/src/components/MUITSDatatable/EditableCell';
import React from 'react';
import { useCookies } from 'react-cookie';
import ConfigSwitch from '../../../components/ConfigSwitch';
import { editCoupon } from '../services/editCoupon';

interface ActiveCellInterface extends EditableCellInterface {}

const ActiveCell: React.FC<ActiveCellInterface> = ({
  tableMeta,
  updateValue,
  value,
}): JSX.Element => {
  const [cookies] = useCookies(['token']);
  const id = tableMeta.rowData[0];

  const handleSwitch = async (): Promise<void> => {
    const boolValue = !!value; // Transform to bool
    const toUpdateValue = !boolValue; // Reverse bool

    editCoupon(id, { active: toUpdateValue }, cookies.token);
    updateValue(toUpdateValue.toString());
  };

  return <ConfigSwitch checked={!!value} onChange={handleSwitch} />;
};

export default ActiveCell;
