import React, { useContext, useState } from 'react';
import { AuthContext } from '@project/components/src/contexts/AuthContextProvider';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@project/components/src/atom/Button';
import { doAuthentication } from '@project/components/src/services/authentication';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { InputAdornment } from '@material-ui/core';
import { PersonOutlineRounded, LockOutlined } from '@material-ui/icons';
import styled from 'styled-components';
import { RHFInput } from 'react-hook-form-input';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/browser';

const Logo = styled.img`
  height: 40px;
  width: auto;
`;

const CustomLink = styled(Link)``;

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/collection/1511425)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const FlexGrid = styled(Grid)`
  display: flex !important;
  flex-direction: column !important;
`;

const LoginPage = () => {
  const [buttonText, setButtonText] = useState('Entrar');
  const {
    setShowBackdrop,
    setShowSnackbar,
    setSnackbarInformation,
  } = useContext(FeedbackContext);
  const { register, setValue, handleSubmit, errors } = useForm();
  const { setloggedUser } = useContext(AuthContext);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies([
    'token',
    'restaurantId',
    'restaurantUid',
  ]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const classes = useStyles();

  const onSubmit = event => {
    handleLogin();
  };

  const handleLogin = () => {
    setButtonText('Aguarde...');
    setShowBackdrop(true);
    doAuthentication(username.trim(), password.trim()).then(data => {
      const { user, token, slug, status } = data;
      if (status.status === 200) {
        setCookie('token', token, {
          maxAge: '86000',
          path: '/',
        });
        setSnackbarInformation({
          message: 'Bem vindo à Ockpay!',
          variant: 'success',
        });
        setloggedUser({ ...user, slug: slug.slug });
        Sentry.setUser(user);
        history.push('/pedidos');
      } else {
        // TODO: Tirar alert e colocar no forms
        setButtonText('Entrar');
        setSnackbarInformation({
          message: 'Usuário ou senha errado, tente novamente.',
          variant: 'error',
        });
      }

      setShowBackdrop(false);
      setShowSnackbar(true);
    });
  };

  return (
    <>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <FlexGrid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          alignContent="center"
          justify="center"
          alignItems="center"
        >
          <Box display="flex" flexGrow={1}>
            <div className={classes.paper}>
              <Box mt={-4} mb={4} textAlign="center">
                <Box mb={3}>
                  <Logo src="/ockpay-logo-azul-gradient.png" alt="Ockpay" />
                </Box>
                <Typography component="h1" variant="h5">
                  Acesse sua Conta
                </Typography>
              </Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <RHFInput
                  id="signin-form-input-username"
                  data-testid="signin-form-input-login"
                  placeholder="Usuário"
                  as={<TextField />}
                  register={register({
                    required: 'Informe o login',
                  })}
                  disabled={buttonText !== 'Entrar'}
                  name="username"
                  setValue={setValue}
                  variant="outlined"
                  fullWidth
                  onChange={e => setUsername(e.target.value)}
                  error={errors.username}
                  helperText={errors.username && errors.username.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonOutlineRounded color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  id="password"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  placeholder="Senha"
                  data-testid="login-input-password"
                  disabled={buttonText !== 'Entrar'}
                  variant="outlined"
                  fullWidth
                  type="password"
                  required
                  autoComplete="current-password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlined color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />

                {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
                <Button
                  type="submit"
                  fullWidth
                  shape="squareRounded"
                  color="primary"
                  className={classes.submit}
                  disabled={buttonText !== 'Entrar'}
                >
                  {buttonText}
                </Button>
                <Grid container>
                  {/* <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid> */}
                  <Grid item xs>
                    <Box textAlign="right">
                      <Link href="https://site.ockpay.com.br" variant="body2">
                        Não tem uma conta?
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="baseline">
            <CustomLink
              color="textSecondary"
              target="_blank"
              href="https://site.ockpay.com.br/termos-e-condicoes"
            >
              Termos de Uso
            </CustomLink>
            <Box mx={1}>
              <Typography color="textSecondary" size="small">
                -
              </Typography>
            </Box>
            <CustomLink
              color="textSecondary"
              size="small"
              target="_blank"
              href="https://site.ockpay.com.br/politica-de-privacidade"
            >
              Política de Privacidade
            </CustomLink>
          </Box>
        </FlexGrid>
      </Grid>
    </>
  );
};

export default LoginPage;
