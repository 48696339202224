import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from '@material-ui/core';
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const CustomFormControl = styled(FormControl)`
  width: 100%;
`;

const SelectField = ({
  options,
  variant,
  fullWidth,
  showEmptyOption = true,
  title,
  value,
  selectedValue,
  ...props
}) => {
  const [idSelect, setIdSelect] = useState(0); // <--------------(Like this).

  const handleChange = event => {
    setIdSelect(event.target.value);
  };

  return (
    <CustomFormControl variant={variant} {...props}>
      <InputLabel htmlFor="outlined-age-native-simple">{title}</InputLabel>
      <Select
        native
        value={value || idSelect}
        onChange={handleChange}
        inputProps={{
          name: 'idSelect',
          id: 'outlined-age-native-simple',
        }}
      >
        {showEmptyOption && <option />}
        {options &&
          options.map((option, index) => (
            <option key={`option-${index}`} value={option.value}>
              {option.label}
            </option>
          ))}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </CustomFormControl>
  );
};

SelectField.propTypes = {
  fullWidth: PropTypes.bool,
  options: PropTypes.array,
  selectedValue: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.string,
};

SelectField.defaultProps = {
  options: [],
  variant: 'outlined',
  fullWidth: true,
  title: '',
  selectedValue: false,
};

export default SelectField;
