import Button from '../../atom/Button';
import Grid from '../../atom/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { CreditCard as CreditCardIcon } from '@material-ui/icons';
import BottomButton from '../../molecule/BottomButton';

const PaymentBar = ({ disabled, text, icon }) => {
  return (
    <BottomButton offset="0">
      <Grid item xs align="center">
        <Button
          type="submit"
          fullWidth
          disabled={disabled}
          textColor="white"
          variant="text"
          startIcon={icon}
        >
          {text}
        </Button>
      </Grid>
    </BottomButton>
  );
};

PaymentBar.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
  icon: PropTypes.node,
};

PaymentBar.defaultProps = {
  text: 'PAGAR E FAZER PEDIDO',
  disabled: false,
  icon: <CreditCardIcon />,
};

export default PaymentBar;
