/* eslint-disable react/prop-types */
import React from 'react';

import ListItem from '@project/components/src/atom/List/ListItem';
import ListItemIcon from '@project/components/src/atom/List/ListItemIcon';
import ListItemText from '@project/components/src/atom/List/ListItemText';
import IconButton from '@project/components/src/atom/IconButton';
import { ArrowForwardIos, Check, Undo } from '@material-ui/icons';
import { Avatar, ListItemSecondaryAction } from '@project/components';
import { LegacyGestorOrderInterface } from '../../../../interfaces/LegacyGestorOrderInterface';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';
import { convertCentsToLocale } from '@project/components-typescript/src/helpers/LocalePriceConverter';

const CustomAvatar = styled(Avatar)`
  background-color: ${ifProp(
    { status: 'CANCELADO' },
    '#d33131b8',
    '#81C784',
  )} !important;
`;

interface LegacyGestorOrderPreviewInterface {
  order: LegacyGestorOrderInterface;
}

const LegacyGestorOrderListItem: React.FC<LegacyGestorOrderPreviewInterface> = ({
  order,
}) => {
  const history = useHistory();

  return (
    <ListItem
      button
      onClick={(): void => history.push(`/vendas/${order.order_number}`)}
    >
      <ListItemIcon>
        <CustomAvatar status={order.status} fontSize="12px" variant="rounded">
          {order.status !== 'CANCELADO' ? <Check /> : <Undo />}
        </CustomAvatar>
      </ListItemIcon>
      <ListItemText
        primary={`#${order.order_number} - ${order.order_details.client_name} `}
        secondary={`${convertCentsToLocale(order.grand_total)} - ${
          order.createdAt
        } `}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete">
          <ArrowForwardIos />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
export default LegacyGestorOrderListItem;
