import React from 'react';
import PropTypes from 'prop-types';
import { isBrowser } from 'react-device-detect';
import Backdrop from '@material-ui/core/Backdrop';
import {
  SpeedDial as MaterialSpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
} from '@material-ui/lab';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

const CustomMaterialSpeedDial = styled(MaterialSpeedDial)`
  align-items: flex-end;
  pointer-events: none;
  position: fixed;
  right: 16px;
  bottom: 78px;

  ${isBrowser &&
    css`
      .MuiSpeedDialAction-staticTooltipLabel {
        max-width: 20em !important;
      }
    `}
`;

const Overlay = styled.div`
  ${ifProp(
    { overlay: true, open: false, isMobile: true },
    css`
      width: 56px;
      height: 56px;
      background-color: white;
      border-radius: 50%;
      position: fixed;
      padding: 4px;
      right: 12px;
      bottom: 74px;
    `,
  )};

  ${ifProp(
    { overlay: true, open: true, isMobile: true },
    css`
      width: 56px;
      height: 56px;
      background-color: white;
      border-radius: 50%;
      position: fixed;
      padding: 2px;
      right: 14px;
      bottom: 76px;
    `,
  )};

  transition: all 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const SpeedDial = ({ actions, overlay, onClose, onOpen, ...props }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    onOpen();
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <div>
      <Backdrop open={open} />
      <CustomMaterialSpeedDial
        open={open}
        overlay={overlay}
        ariaLabel="SpeedDial tooltip example"
        hidden={false}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        <Overlay open={open} overlay={overlay} />
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.onClick}
          />
        ))}
      </CustomMaterialSpeedDial>
    </div>
  );
};

SpeedDial.propTypes = {
  actions: PropTypes.array,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  overlay: PropTypes.bool,
};

SpeedDial.defaultProps = {
  actions: [],
  overlay: false,
  onClose: () => {},
  onOpen: () => {},
};

export default SpeedDial;
