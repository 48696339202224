import React from 'react';
import ListItem from '../../atom/List/ListItem';
import styled from 'styled-components';
import ListItemText from '../../atom/List/ListItemText';
import Localization from '../../assets/Localization.svg';
import { Box } from '../../..';

const CustomListItemText = styled(ListItemText)`
  .MuiListItemText-primary {
    font-weight: 400 !important;
    color: #383d6b !important;
  }

  .MuiListItemText-secondary {
    color: #808285 !important;
  }
`;

const CustomListItem = styled(ListItem)`
  padding: 17px 30px 16px 31px !important;
`;

const LocalizationCard = ({ primary = '', secondary = '', showIcon = true, onClick = () => {}, ...props }) => {
  return (
    <CustomListItem button onClick={onClick} {...props}>
      {showIcon && (
        <Box mr="16px">
          <img src={Localization} alt="Icone de localização" />
        </Box>
      )}
      <CustomListItemText primary={primary} secondary={secondary} />
    </CustomListItem>
  );
};

LocalizationCard.propTypes = {};

export default LocalizationCard;
