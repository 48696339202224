import Grid from '@project/components/src/atom/Grid';
import Typography from '../../atom/Typography';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { capitalize } from '../../utils/Helpers';

// TODO: codigo duplicado, remover isso e implementar momentum
const insertZero = date => (`${date}`.length === 1 ? `0${date}` : `${date}`);

const OrderHeader = ({
  orderNumber,
  clientName,
  table,
  createdAt,
  variant,
  paymentMethod,
  paymentType,
  paymentStatus,
}) => {
  const formatedDate = useCallback(date => {
    const parseDate = new Date(date);
    const day = insertZero(parseDate.getDate());
    const month = insertZero(parseDate.getMonth() + 1);
    const hours = insertZero(parseDate.getHours());
    const minutes = insertZero(parseDate.getMinutes());

    return `${day}/${month} às ${hours}:${minutes}`;
  }, []);

  return (
    <>
      <Grid container xs={12} spacing={3}>
        <Grid item xs align="left">
          <Typography variant={variant}>Pedido #{orderNumber}</Typography>
        </Grid>

        <Grid item xs align="right">
          <Typography variant={variant}>{clientName}</Typography>
        </Grid>
      </Grid>

      <Grid container xs={12} spacing={3}>
        <Grid item xs align="left">
          <Typography variant={variant}>{capitalize(table)}</Typography>
        </Grid>
        <Grid item xs align="right">
          <Typography variant={variant}>{formatedDate(createdAt)}</Typography>
        </Grid>
      </Grid>

      <Grid container xs={12} spacing={3}>
        {paymentMethod && (
          <Grid item xs align="left">
            <Typography variant={variant}>{paymentMethod}</Typography>
          </Grid>
        )}
        {paymentType && (
          <Grid item xs align="right">
            <Typography variant={variant}>{paymentType}</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

OrderHeader.propTypes = {
  orderNumber: PropTypes.string,
  client_name: PropTypes.string,
  table: PropTypes.string,
  createdAt: PropTypes.string,
  variant: PropTypes.string,
};

export default OrderHeader;
