import { Map as MapIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import IconButton from '../../atom/IconButton';
import Typography from '../../atom/Typography';
import {
  createMapsAddressLink,
  createMapsFullAddressLink,
} from '../../utils/maps';

const CustomIconButton = styled(IconButton)`
  border-radius: 12px !important;
`;

const CustomMapIcon = styled(MapIcon)`
  margin-right: 5px;
`;

const FlexTypography = styled(Typography)`
  display: flex;
  align-items: center;
  /* font-size: 12px !important;   */
`;

const GoogleMapsOpen = ({
  text,
  address,
  city,
  zipcode,
  number,
  sizeVariant,
  analyticsShare,
  analyticsContentId,
  fullAddress,
  ...props
}) => {
  return (
    <CustomIconButton
      onClick={() => {
        // analyticsShare('maps', analyticsContentId);
        window.open(
          fullAddress
            ? createMapsFullAddressLink(fullAddress)
            : createMapsAddressLink(address, number, city, zipcode),
          '_blank',
        );
      }}
      {...props}
    >
      <FlexTypography variant={sizeVariant}>
        <CustomMapIcon fontSize="small" color="iconDefault" /> {text}
      </FlexTypography>
    </CustomIconButton>
  );
};

GoogleMapsOpen.defaultProps = {
  sizeVariant: 'body2',
  text: 'Abrir no Google Maps',
  fullAddress: undefined,
};

GoogleMapsOpen.propTypes = {
  text: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  phone: PropTypes.string,
  fullAddress: PropTypes.string,
  sizeVariant: PropTypes.oneOf(['body1', 'body2', 'caption']),
};

export default React.memo(GoogleMapsOpen);
