import React from 'react';
import ConfigDelivery from '../containers/ConfigDelivery';
import ConfigRetiradaLocal from '../containers/ConfigRetiradaLocal';

interface ModeloNegociosSectionInterface {}

export const ModeloNegociosSection: React.FC<ModeloNegociosSectionInterface> = () => {
  return (
    <>
      <ConfigRetiradaLocal />
      <ConfigDelivery />
    </>
  );
};

export default ModeloNegociosSection;
