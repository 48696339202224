import PropTypes from 'prop-types';
import React from 'react';
import Typography from '../Typography';
import styled from 'styled-components';

const CustomText = styled(Typography)`
  ${({ disabled }) => disabled && `color: 'rgba(0, 0, 0, 0.26);`}
`;

const Text = ({ children, disabled = false, ...props }) => {
  return (
    <CustomText disabled={disabled} {...props}>
      {children}
    </CustomText>
  );
};

Text.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.func,
  props: PropTypes.object,
};

export default Text;
