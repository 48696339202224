import React from 'react';
import { Typography, Grid, Box } from '@project/components';

interface EmptyPlaceholderInterface {
  title: string;
  subtitle?: string;
  icon: string;
  width?: string;
  height?: string;
  minHeight?: string;
}

const EmptyPlaceholder: React.FC<EmptyPlaceholderInterface> = ({
  title,
  subtitle,
  icon,
  width = '50px',
  height = '85px',
  minHeight = '75vh',
}) => {
  return (
    <Grid item xs>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        style={{ minHeight }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <img width={width} height={height} src={icon} alt="Nada por aqui!" />
          <Typography color="textSecondary" variant="h2">
            {title}
          </Typography>
          {subtitle && (
            <Box mb={0.5}>
              <Typography color="textSecondary" variant="h4">
                {subtitle}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default EmptyPlaceholder;
