import React, { useContext } from 'react';
import { DialogForm, Box, TextField } from '@project/components';
import Typography from '@project/components/src/atom/Typography';
import Alert from '@project/components/src/atom/Alert';
import { RHFInput } from 'react-hook-form-input';
import { useForm } from 'react-hook-form';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { setDeliveryKmLimit } from '../../../services/settings/deliveryKmLimitService';
import { useCookies } from 'react-cookie';
import { GlobalStateContext } from '../../../contexts/GlobalState/GlobalStateContext';
import { GlobalStateTypesEnum } from '../../../contexts/GlobalState/GlobalStateInterface';

const LimitKmSection = ({ setOpenDialog, openDialog }) => {
  const { dispatchGlobalState } = useContext(GlobalStateContext);
  const { setShowBackdrop, setShowSnackbar, setSnackbarInformation } = useContext(FeedbackContext);
  const { register, setValue, handleSubmit, errors } = useForm();
  const [cookies] = useCookies();

  const handleSuccess = ({ data }) => {
    const { delivery_km_limit } = data;
    setSnackbarInformation({
      message: `Seu delivery agora está limitado a um raio máximo de ${delivery_km_limit}`,
      variant: 'success',
    });

    setShowSnackbar(true);
    dispatchGlobalState({
      type: GlobalStateTypesEnum.UPDATE_SETTINGS,
      settings: {
        delivery_km_limit,
      },
    });
  };

  const handleError = error => {
    setSnackbarInformation({
      message: `Ocorreu um erro ao tentar mudar o limitação de distância, tente novamente`,
      variant: 'error',
    });
    console.error(error);
  };

  const handleFinally = () => {
    setOpenDialog(false);
    setShowBackdrop(false);
    setShowSnackbar(true);
  };

  const handleUpdateKm = async ({ delivery_km_limit }) => {
    setShowBackdrop(true);

    setDeliveryKmLimit(cookies.token, delivery_km_limit)
      .then(response => handleSuccess(response))
      .catch(error => handleError(error))
      .finally(() => handleFinally());
  };

  return (
    <>
      <DialogForm
        open={openDialog}
        onDisagree={() => setOpenDialog(false)}
        contentTitle="Limite de entrega"
        showCancelButton
        contentText={
          <>
            <Box mb={2}>
              <Typography variant="body2">
                Escolha um raio em km para delimitar a sua área de atendimento de delivery.
              </Typography>
            </Box>
            <Box mb={4}>
              <Alert title="Como funciona?" severity="info">
                Define a máxima distância de entrega de um pedido. Acima deste valor, o pedido não será realizado
              </Alert>
            </Box>
            <RHFInput
              as={<TextField type="tel" />}
              register={register({ required: true })}
              label="Distância máxima em km"
              name="delivery_km_limit"
              placeholder="km "
              setValue={setValue}
              error={errors.delivery_km_limit}
              helperText={errors.delivery_km_limit && `Informe um valor para a limitação`}
            />
          </>
        }
        maxWidth="xs"
        handleSubmit={handleSubmit}
        submitMethod={handleUpdateKm}
      />
    </>
  );
};

LimitKmSection.propTypes = {
  // TODO declare props
  // propName: propType
  // example: PropTypes.bool
};

export default LimitKmSection;
