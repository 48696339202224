import {
  serviceGET,
  serviceGet,
  servicePOST,
} from '@project/components/src/services/service';
import * as R from 'ramda';

export const getSettingsByKey = async (settingsKey, token) => {
  const settings = await getAllSettings(token);

  const specificSettings = R.find(R.propEq('key', settingsKey))(settings);

  return specificSettings || {};
};

export const getSettingsById = async token => {
  const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/settings`;

  const response = await serviceGET(endpoint, {
    Authorization: `Bearer ${token}`,
  });

  return response;
};

export const getAllSettings = async (token, headers = {}) => {
  const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/settings`;

  const response = await serviceGet(endpoint, {
    Authorization: `Bearer ${token}`,
    ...headers,
  });

  return response;
};

export const createSettings = async (key, value, description, token) => {
  const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/settings`;

  const response = await servicePOST(
    endpoint,
    {
      key: key,
      value: value,
      description: description,
    },
    { Authorization: `Bearer ${token}` },
  );

  return response;
};
