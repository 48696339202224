import { OrderStatusEnum } from '@project/components-typescript/src/interfaces/OrderStatusEnum';
import GestorOrderInterface from '@project/components-typescript/src/interfaces/Order/GestorOrderInterface';
import { BoxDeliveryStatusInterface } from '../interfaces/BoxDeliveryStatusInterface';

interface OrdersArrInterface {
  order: GestorOrderInterface;
  isNew: boolean;
}

export interface OrdersContextInterface {
  orders: OrdersArrInterface[];
  selectOrder: GestorOrderInterface | undefined;
  overview?: BoxDeliveryStatusInterface | null;
}

export enum SelectOrderEnum {
  openOrderDetails,
  openMotoboyMap,
}

export enum OrdersTypesEnum {
  POPULATE_ORDERS,
  SELECT_ORDER,
  UNSELECT_ORDER,
  HANDLE_STATUS_CHANGE,
  ADD_NEW_ORDER,
  HANDLE_MOTOBOY_MAP,
  UPDATE_ORDER,
}

export type UPDATE_ORDER = {
  type: OrdersTypesEnum.UPDATE_ORDER;
  order: GestorOrderInterface;
};

export type POPULATE_ORDERS = {
  type: OrdersTypesEnum.POPULATE_ORDERS;
  orders: GestorOrderInterface[];
};

export type SELECT_ORDER = {
  type: OrdersTypesEnum.SELECT_ORDER;
  order: GestorOrderInterface;
};

export type ADD_NEW_ORDER = {
  type: OrdersTypesEnum.ADD_NEW_ORDER;
  order: GestorOrderInterface;
};

export type HANDLE_STATUS_CHANGE = {
  type: OrdersTypesEnum.HANDLE_STATUS_CHANGE;
  order: GestorOrderInterface;
  newStatus: OrderStatusEnum;
};

export type UNSELECT_ORDER = {
  type: OrdersTypesEnum.UNSELECT_ORDER;
};

export type HANDLE_MOTOBOY_MAP = {
  type: OrdersTypesEnum.HANDLE_MOTOBOY_MAP;
  overview: BoxDeliveryStatusInterface | null;
};
