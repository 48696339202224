import {
  Token,
  CustomAxiosResponse,
  VariantEnum,
  axiosPUT,
} from '@project/components-typescript/src/services/axiosService';
import { CouponInterface, CouponID } from './../interfaces/CouponInterface';

type EditableFields = Exclude<CouponInterface, 'id'>;
export interface EditCouponInterface extends Partial<EditableFields> {}

export const editCoupon = async (
  id: CouponID,
  coupon: EditCouponInterface,
  token: Token,
): Promise<CustomAxiosResponse<CouponInterface>> => {
  try {
    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/coupons/${id}`;

    const response = await axiosPUT<CouponInterface>(endpoint, coupon, token);

    return {
      ...response,
      message: 'Cupom editado com sucesso!',
      variant: VariantEnum.success,
    };
  } catch (error) {
    throw new Error(
      error?.message ? error.message : 'Ocorreu um erro, tente novamente.',
    );
  }
};
