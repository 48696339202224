import React from 'react';
import PropTypes from 'prop-types';

import { Fab as MaterialFab } from '@material-ui/core';
import styled from 'styled-components';

const CustomFab = styled(MaterialFab)`
  right: 16px !important;
  bottom: 5.3em !important;
  position: fixed !important;
  z-index: 1050 !important;
`;

const Fab = ({ children, ...props }) => {
  return <CustomFab {...props}>{children}</CustomFab>;
};

Fab.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Fab;
