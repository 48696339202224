import {
  BottomNavigation as MaterialBottomNavigation,
  BottomNavigationAction as MaterialBottomNavigationAction,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import Badge from '../../atom/Badge';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { ifProp } from 'styled-tools';

const CustomMaterialBottomNavigationAction = styled(
  MaterialBottomNavigationAction,
)`
  ${ifProp(
    { disabled: true },
    css`
      color: #e0e0e0 !important;
    `,
  )}
`;

const CustomBottomNavigation = styled(MaterialBottomNavigation)`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

const BottomNavigation = ({
  showLabels = true,
  actions,
  showInBottom = true,
  disabled = false,
  show = true,
}) => {
  const [value, setValue] = React.useState(0);
  const location = useLocation();

  useEffect(() => {
    const matchedPath = actions
      .map(action => action.path)
      .find(({ urls }) => urls.find(url => location.pathname.includes(url)));

    setValue(matchedPath ? matchedPath.value : 0);
  }, [actions, location.pathname]);

  return show ? (
    <CustomBottomNavigation
      value={value}
      showLabels={showLabels}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      selected={true}
    >
      {actions.map(
        (action, index) =>
          !action.hidden && (
            <CustomMaterialBottomNavigationAction
              data-testid={`bottomNavigation-${index}`}
              color="secondary"
              disabled={action.disabled}
              key={index}
              onClick={action.onClick}
              label={action.label}
              icon={
                <>
                  <Badge
                    show={action && action.showDot}
                    blink
                    variant="dot"
                    color="secondary"
                  >
                    {action.icon}
                  </Badge>
                </>
              }
            />
          ),
      )}
    </CustomBottomNavigation>
  ) : null;
};

BottomNavigation.propTypes = {
  actions: PropTypes.shape({
    map: PropTypes.func,
  }),
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  showInBottom: PropTypes.bool,
  showLabels: PropTypes.bool,
  showMenuIcon: PropTypes.bool,
  title: PropTypes.string,
};

export default BottomNavigation;
