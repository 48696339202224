/* eslint-disable react/prop-types */
import React, { createContext, useReducer } from 'react';
import freightReducer, { FreightAction } from './FreightReducer';
import { freightInitialState } from './FreightActions';
import { FreightContextInterface } from '../interfaces/FreightContextInterface';

export type FreightDispatchType = React.Dispatch<FreightAction>;

interface FreightProviderInterface {
  stateFreight: FreightContextInterface;
  dispatchFreight: FreightDispatchType;
}

export const FreightContext = createContext<FreightProviderInterface>({
  stateFreight: freightInitialState,
  dispatchFreight: () => console.warn('FreightDispatch not ready'),
});

const FreightProvider: React.FC = ({ children }) => {
  const [stateFreight, dispatchFreight] = useReducer(freightReducer, freightInitialState);

  const globals = {
    stateFreight,
    dispatchFreight,
  };

  return <FreightContext.Provider value={globals}>{children}</FreightContext.Provider>;
};

export default FreightProvider;
