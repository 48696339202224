import React from 'react';
import PropTypes from 'prop-types';

import { IconButton as MaterialIconButton } from '@material-ui/core';

const IconButton = ({ children, color = 'default', onClick, ...props }) => {
  return (
    <MaterialIconButton onClick={onClick} color={color} {...props}>
      {children}
    </MaterialIconButton>
  );
};

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  props: PropTypes.node,
  onClick: PropTypes.func,
};

export default IconButton;
