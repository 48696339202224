/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Paper, Box, Typography } from '@project/components';
import { Lock, Done } from '@material-ui/icons';
import { SignupStepEnum } from '../../../contexts/SignupFormContext/SignupFormContextInterface';
import { SignupFormContext } from '../../../contexts/SignupFormContext/SignupFormContextProvider';

interface SignupStepInterface {
  title: string;
  subtitle?: string;
  step: SignupStepEnum;
}

const SignupStep: React.FC<SignupStepInterface> = ({ children, title, subtitle, step }) => {
  const signupForm = useContext(SignupFormContext);

  const isDisabled = (signupForm?.signupStep || SignupStepEnum.confirmation1) < step;
  const isActive = (signupForm?.signupStep || SignupStepEnum.confirmation1) === step;
  const isCompleted = (signupForm?.signupStep || SignupStepEnum.confirmation1) > step;

  return (
    <Box mb={3}>
      <Paper display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography gutterBottom={!isCompleted} variant="body1">
            {title}
          </Typography>
          {(isDisabled || isCompleted) && <>{isCompleted ? <Done color="secondary" /> : <Lock />}</>}
        </Box>

        {!isCompleted && (
          <Typography gutterBottom variant="body2">
            {isDisabled && 'Complete a etapa acima para desbloquear'}
            {!isDisabled && subtitle}
          </Typography>
        )}

        {isActive && <Box>{children}</Box>}
      </Paper>
    </Box>
  );
};

export default SignupStep;
