import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Localization from './assets/Localization.svg';
import Delivery from './assets/Delivery.svg';
import OpenClosedText from '../../components/OpenClosedText/OpenClosedText';
import OrdersActive from './assets/OrdersActive.svg';

const City = styled.div`
  margin-right: 35px;
  font-size: 13px;
  font-weight: 300;
`;

const DeliveryLabel = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #383d6b;
`;

const SVG = styled.div`
  margin-right: 7px;
  width: 15px;
`;

const EstablishmentName = styled(Typography)`
  font-size: 18px;
  font-weight: bold !important;
`;

interface EstablishmentInfoSectionInterface {
  full_address: string | undefined;
  fantasy_name: string | undefined;
  flag_delivery: boolean | undefined;
  flag_takeaway: boolean | undefined;
  flag_open: boolean | undefined;
  typeOfServiceIdentifier?: string | null;
}

const EstablishmentInfoSection: React.FC<EstablishmentInfoSectionInterface> = ({
  full_address,
  fantasy_name,
  flag_delivery,
  flag_takeaway,
  flag_open,
  typeOfServiceIdentifier,
}) => {
  const address = useMemo(() => {
    try {
      if (full_address) {
        const address = full_address || '';
        return address.split(',')[2].trim();
      }
    } catch (error) {
      return full_address;
    }
  }, [full_address]);

  return (
    <section>
      <Box mb="9px">
        {fantasy_name ? (
          <EstablishmentName variant="h2" color="primary">
            {fantasy_name}
          </EstablishmentName>
        ) : (
          <Skeleton width="150px" height="21px" />
        )}
      </Box>

      <Box mb="2px" display="flex" flexDirection="row" alignSelf="flex-end" alignItems="center">
        <SVG>
          <img alt="Icone de localidade" src={Localization} />
        </SVG>

        {address ? <City>{address}</City> : <Skeleton width="75px" height="15px" />}

        {flag_open === undefined ? (
          <Box ml="32px">
            <Skeleton width="41px" height="16px" />
          </Box>
        ) : (
          <OpenClosedText isOpen={flag_open} />
        )}
      </Box>

      <Box display="flex" flexDirection="row" alignSelf="flex-end" alignItems="end" mt={0.5}>
        <SVG>
          <img
            width={typeOfServiceIdentifier ? '12px' : '15px'}
            height={typeOfServiceIdentifier ? '16px' : '11px'}
            src={typeOfServiceIdentifier ? OrdersActive : Delivery}
            alt={`Icone de ${typeOfServiceIdentifier ? 'atendimento no local' : 'entrega'}`}
          />
        </SVG>
        {fantasy_name ? (
          <>
            {!typeOfServiceIdentifier ? (
              <DeliveryLabel>
                {flag_delivery && !flag_takeaway && 'Entrega'}
                {!flag_delivery && flag_takeaway && 'Retirada'}
                {flag_delivery && flag_takeaway && 'Delivery e retirada'}
              </DeliveryLabel>
            ) : (
              <DeliveryLabel>No local - {typeOfServiceIdentifier}</DeliveryLabel>
            )}
          </>
        ) : (
          <Skeleton width="55px" height="15px" />
        )}
      </Box>
    </section>
  );
};

export default EstablishmentInfoSection;
