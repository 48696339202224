// TODO: convert to styledComponents
import React, { useEffect, useState, useContext } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import { RHFInput } from 'react-hook-form-input';
import TextField from '../../atom/TextField';

import CEP from '../../molecule/MaskedTextField/types/CEP';
import CellPhone from '../../molecule/MaskedTextField/types/CellPhone';
import MaskedTextField from '../../molecule/MaskedTextField';
import { cellPhoneWithDDD } from '../../forms/Validators';
import { getDataFromCEP } from '../../services/getCEP';
import { FeedbackContext } from '../../contexts/FeedbackContext';
import Grid from '../../atom/Grid';
import Box from '../../atom/Box';
import { Paper } from '../../..';
import Typography from '../../atom/Typography';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';
import { parseCurrencyToABNT, parseCurrencyToFloat } from '../../utils/Parser';
// import { getFreightCost } from '../../../../apps/clients/src/services/delivery/getFreightCost'

const Bla = styled.div`
  ${ifProp({ show: false }, 'display: none')};
`;

const DeliveryForm = ({
  register,
  setValue,
  errors,
  triggerValidation,
  watch,
  deliveryFeeValue,
  restaurantUID,
  setDeliveryFeeValue,
  deliveryInformation,
  setFreightInformation,
  setDeliveryInformation,
  analyticsLogEvent,
  analyticsAddShippingInfo,
  ...props
}) => {
  // TODO: remove this state
  // eslint-disable-next-line no-unused-vars
  const [userKnowsZipCode, setUserKnowsZipCode] = useState(true);
  const {
    setShowBackdrop,
    setShowSnackbar,
    setSnackbarInformation,
  } = useContext(FeedbackContext);
  const watchedAddress = watch('address');
  const watchedNumber = watch('number');

  useEffect(() => {
    document.getElementById('delivery-number-input').focus();
  }, []);

  const checkCEP = async (cep, address, number, city) => {
    const response = {};

    if (response.statusResponse.ok) {
      setDeliveryFeeValue(response.delivery_total);
      setFreightInformation({
        delivery_total: parseCurrencyToABNT(response.delivery_total),
        duration: response.duration,
        distance: response.distance,
      });
      analyticsLogEvent('calculateFreight', {
        category: 'Delivery',
        zipcode: cep,
        store: restaurantUID,
        value: response.delivery_total,
        status: 'success',
      });
      return true;
    } else {
      analyticsLogEvent('calculateFreight', {
        category: 'Delivery',
        zipcode: cep,
        store: restaurantUID,
        status: 'error',
        message: response && response.error && response.error.message,
      });

      if (!response.error.message) {
        setSnackbarInformation({
          message:
            'Ooops, aconteceu algum problema em calcular seu CEP, tente novamente ou altere o endereço',
          variant: 'error',
          duration: 20000,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        });
        setShowSnackbar(true);
        return false;
      }

      switch (response.error.message) {
        case 'City is not in the whitelist':
          setSnackbarInformation({
            message:
              'Oops, infelizmente não entregamos nessa localidade, tente outro CEP',
            variant: 'error',
            duration: 20000,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
          setShowSnackbar(true);
          break;

        case 'Invalid client address':
          setSnackbarInformation({
            message: 'Endereço inválido, revise e tente novamente',
            variant: 'error',
            duration: 20000,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
          setShowSnackbar(true);
          break;

        case 'Out of reach':
          setSnackbarInformation({
            message:
              'Oops, infelizmente não entregamos nessa localidade, tente outro CEP',
            variant: 'error',
            duration: 20000,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
          setShowSnackbar(true);
          break;

        case 'Destination address not found':
          setSnackbarInformation({
            message: 'Destino não encontrado, revise e tente novamente',
            variant: 'error',
            duration: 20000,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
          setShowSnackbar(true);
          break;

        case "Sorry, we can't complete your request":
          setSnackbarInformation({
            message:
              'Desculpe, tivemos um problema! Revise os dados e tente novamente',
            variant: 'error',
            duration: 20000,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
          setShowSnackbar(true);
          break;

        case "client's address not found":
          setSnackbarInformation({
            message: 'Endereço não encontrado, revise e tente novamente',
            variant: 'error',
            duration: 20000,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
          setShowSnackbar(true);
          break;

        case 'Zipcode not found':
          setSnackbarInformation({
            message:
              'Por enquanto este estabelecimento não está aceitando delivery',
            variant: 'error',
            duration: 20000,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
          setShowSnackbar(true);
          break;

        default:
          setSnackbarInformation({
            message:
              'Ooops, aconteceu algum problema em calcular seu CEP, tente novamente ou altere o endereço',
            variant: 'error',
            duration: 20000,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
          setShowSnackbar(true);
          break;
      }

      return false;
    }
  };

  const handleCEPChange = async ({ target }) => {
    triggerValidation();
    if (target.value.length === 9) {
      try {
        setShowBackdrop(true);
        setShowSnackbar(false);

        const response = await getDataFromCEP(target.value);

        if (response.erro || response.address === undefined) {
          setSnackbarInformation({
            message: 'Oops, o CEP informado é inválido, tente novamente',
            variant: 'error',
            duration: 20000,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
          });
          setShowSnackbar(true);
          analyticsLogEvent('calculateFreight', {
            category: 'Delivery',
            zipcode: target.value,
            store: restaurantUID,
            status: 'error',
            message: 'Invalid CEP from viacep',
          });
          return;
        }

        const validCEP = await checkCEP(
          response.zip_code,
          response.address,
          watchedNumber,
          response.city,
        );

        if (!validCEP) return;

        if (response.zip_code !== undefined) {
          setValue('zip_code', response.zip_code, true);
          setValue('city', response.city);
          setValue('address', response.address);
          setValue('complement', response.complement);
          setValue('neighborhood', response.neighborhood);
          setValue('city', response.city);
          setValue('uf', response.uf);
          setValue('observations', response.observations);
          setValue('phone', response.phone);

          setSnackbarInformation({
            message: 'Carregamos o endereço para entrega',
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
          document.getElementById('delivery-number-input').focus();
          // triggerValidation()
        } else {
          // triggerValidation('zip_code')
          setSnackbarInformation({
            message: 'CEP inválido, verifique e tente novamente',
            variant: 'error',
          });
          document.getElementById('delivery-zip-code-input').focus();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setShowBackdrop(false);
        setShowSnackbar(true);
        analyticsAddShippingInfo(
          parseCurrencyToFloat(deliveryFeeValue),
          'self_delivery',
        );
      }
    }
  };

  useEffect(() => {
    if (!userKnowsZipCode) {
      document.getElementById('delivery-address-input').focus();
      triggerValidation('zip_code');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userKnowsZipCode]);

  return (
    <>
      <Paper>
        <Box p={1}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <RHFInput
                id="delivery-number-input"
                as={<TextField type="tel" variant="standard" />}
                register={register({
                  required: 'Obrigatório',
                })}
                label="Número"
                name="number"
                InputLabelProps={{ shrink: true }}
                placeholder="Número"
                onChange={() => triggerValidation()}
                setValue={setValue}
                error={errors.number}
                helperText={errors.number && errors.number.message}
              />
            </Grid>
            <Grid item xs>
              <RHFInput
                as={
                  <MaskedTextField
                    customType={CEP}
                    type="tel"
                    autocomplete="postal-code"
                    variant="standard"
                  />
                }
                id="delivery-zip-code-input"
                register={register({
                  required: {
                    value: userKnowsZipCode,
                    message: 'Por favor, informe um CEP para entrega',
                  },
                })}
                label="CEP da entrega"
                name="zip_code"
                placeholder="CEP de onde será entregue"
                setValue={setValue}
                onChange={e => handleCEPChange(e)}
                error={errors.zip_code}
                disabled={watchedNumber === '' || watchedNumber === undefined}
                helperText={errors.zip_code && errors.zip_code.message}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Bla show={watchedAddress !== undefined || !userKnowsZipCode}>
        <Box mt={3}>
          <Paper>
            {userKnowsZipCode && (
              <>
                <Box mb={1}>
                  <Typography variant="subtitle2">{`${watch(
                    'address',
                  )}`}</Typography>
                  <Typography variant="caption">
                    {`${watch('neighborhood')}, ${watch('city')} - ${watch(
                      'uf',
                    )}`}
                  </Typography>
                </Box>
              </>
            )}

            <Box mb={1}>
              <Typography variant="subtitle2">Custo para entrega</Typography>
              <Typography variant="caption">
                {`${
                  deliveryFeeValue !== 0
                    ? parseCurrencyToABNT(deliveryFeeValue)
                    : 'Grátis'
                }`}
              </Typography>
            </Box>
          </Paper>
          <br></br>
          <Paper>
            <RHFInput
              id="delivery-address-input"
              style={{ display: userKnowsZipCode ? 'none' : '' }}
              as={
                <TextField variant="standard" autocomplete="street-address" />
              }
              register={register({
                required: 'Esse campo é obrigatório',
              })}
              mode="onChange"
              label="Endereço"
              name="address"
              placeholder="Endereço onde será entregue"
              setValue={setValue}
              onChange={() => triggerValidation()}
              error={errors.address}
              helperText={errors.address && errors.address.message}
              disabled={userKnowsZipCode}
            />

            <Grid container spacing={2}>
              <Grid item xs>
                <RHFInput
                  style={{ display: userKnowsZipCode ? 'none' : '' }}
                  as={
                    <TextField
                      autocomplete="street-address"
                      variant="standard"
                    />
                  }
                  register={register({
                    required: 'Esse campo é obrigatório',
                  })}
                  label="Cidade"
                  name="city"
                  setValue={setValue}
                  onChange={() => triggerValidation()}
                  error={errors.city}
                  helperText={errors.city && errors.city.message}
                  disabled={userKnowsZipCode}
                />
              </Grid>

              <Grid item xs={4}>
                <RHFInput
                  style={{ display: userKnowsZipCode ? 'none' : '' }}
                  as={<TextField variant="standard" />}
                  register={register({
                    required: 'Obrigatório',
                  })}
                  label="Estado"
                  name="uf"
                  setValue={setValue}
                  onChange={() => triggerValidation()}
                  error={errors.uf}
                  helperText={errors.uf && errors.uf.message}
                  disabled={userKnowsZipCode}
                />
              </Grid>
            </Grid>

            <RHFInput
              style={{ display: userKnowsZipCode ? 'none' : '' }}
              as={<TextField variant="standard" />}
              register={register({
                required: 'Esse campo é obrigatório',
              })}
              label="Bairro"
              name="neighborhood"
              setValue={setValue}
              onChange={() => triggerValidation()}
              error={errors.neighborhood}
              helperText={errors.neighborhood && errors.neighborhood.message}
              disabled={userKnowsZipCode}
            />

            <Grid container spacing={2}>
              <Grid item xs>
                <RHFInput
                  id="delivery-complement-input"
                  as={<TextField variant="standard" />}
                  register={register}
                  label="Complemento"
                  name="complement"
                  placeholder="Bloco, apartamento, etc..."
                  setValue={setValue}
                  InputLabelProps={{ shrink: true }}
                  error={errors.complement}
                  onChange={() => triggerValidation()}
                  helperText={errors.complement && errors.complement.message}
                />
              </Grid>
            </Grid>

            <RHFInput
              id="delivery-observation-input"
              as={<TextField variant="standard" />}
              register={register}
              label="Observações"
              name="observation"
              placeholder={
                restaurantUID === '5e3d6a43720dd9020a401b69'
                  ? `Informe aqui o dia e o período (manhã ou tarde)  para entrega do seu produto. Se desejar, a mensagem para o cartão. 
                  \nObs. Nosso delivery está disponível de segunda a sábado das 8:00 as 18:00 e aos Domingos das 8:00 às 12:00 (exceto feriados).`
                  : 'Tem alguma observação em seu pedido?'
              }
              onChange={() => triggerValidation()}
              multiline
              InputLabelProps={{ shrink: true }}
              rows={restaurantUID === '5e3d6a43720dd9020a401b69' ? '11' : '3'}
              rowsMax={
                restaurantUID === '5e3d6a43720dd9020a401b69' ? '11' : '4'
              }
              setValue={setValue}
              error={errors.observation}
              helperText={errors.observation && errors.observation.message}
            />

            <RHFInput
              id="delivery-phone-input"
              as={
                <MaskedTextField
                  customType={CellPhone}
                  autocomplete="tel"
                  type="tel"
                  variant="standard"
                />
              }
              register={register({
                required: 'Informe um celular para contato',
                pattern: {
                  value: cellPhoneWithDDD,
                  message: 'Telefone inválido',
                },
              })}
              label="Celular para contato"
              name="phone"
              onChange={() => triggerValidation()}
              setValue={setValue}
              error={errors.phone}
              helperText={errors.phone && errors.phone.message}
            />
          </Paper>

          <Box mb={16} />
        </Box>
      </Bla>
    </>
  );
};

export default DeliveryForm;
