import {
  EstablishmentSettingsInterface,
  OptionalEstablishmentSettingsInterface,
} from './../../interfaces/EstablishmentSettingsInterface';
import { LoggedUserInterface } from './../../interfaces/LoggedUserInterface';

export interface GlobalContextInterface {
  loggedUser: LoggedUserInterface | undefined;
  settings: EstablishmentSettingsInterface | undefined;
}

export enum GlobalStateTypesEnum {
  ADD_SETTINGS,
  UPDATE_SETTINGS,
  ADD_LOGGED_USER,
}

export type ADD_SETTINGS = {
  type: GlobalStateTypesEnum.ADD_SETTINGS;
  settings: EstablishmentSettingsInterface | undefined;
};

export type UPDATE_SETTINGS = {
  type: GlobalStateTypesEnum.UPDATE_SETTINGS;
  settings: OptionalEstablishmentSettingsInterface;
};

export type ADD_LOGGED_USER = {
  type: GlobalStateTypesEnum.ADD_LOGGED_USER;
  loggedUser: LoggedUserInterface;
};
