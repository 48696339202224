import { WhatsApp as WhatsAppIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {
  createWhatsappPhoneLink,
  createWhatsappShareLink,
} from '../../helpers/whatsappNumber';
import IconButton from '../../atom/IconButton';
import Typography from '../../atom/Typography';

const CustomIconButton = styled(IconButton)`
  border-radius: 12px !important;
`;

const ColoredWhatsAppMaterialIcon = styled(WhatsAppIcon)`
  color: #25d366 !important;
  margin-right: 5px;
`;

const FlexTypography = styled(Typography)`
  display: flex;
  align-items: center;
  /* font-size: 12px !important;   */
`;

const WhatsappMessage = ({
  text,
  message,
  phone,
  sizeVariant,
  analyticsShare,
  analyticsContentId,
  ...props
}) => {
  return (
    <CustomIconButton
      onClick={() => {
        // analyticsShare('whatsapp', analyticsContentId);
        window.open(
          phone
            ? createWhatsappPhoneLink(phone, message)
            : createWhatsappShareLink(message),
          '_blank',
        );
      }}
      {...props}
    >
      <FlexTypography variant={sizeVariant}>
        <ColoredWhatsAppMaterialIcon fontSize="small" color="iconDefault" />{' '}
        {text || null}
      </FlexTypography>
    </CustomIconButton>
  );
};

WhatsappMessage.defaultProps = {
  sizeVariant: 'body2',
};

WhatsappMessage.propTypes = {
  text: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  phone: PropTypes.string,
  sizeVariant: PropTypes.oneOf(['body1', 'body2', 'caption']),
};

export default React.memo(WhatsappMessage);
