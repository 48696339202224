const format = (str = '') => str.replace(/ /g, '+');

export const createMapsAddressLink = (address, number, city, zip_code) => {
  try {
    return `http://maps.google.com.br/maps?q=${format(address)},${format(
      number,
    )},${format(city)},${format(zip_code)}`;
  } catch (e) {
    return '';
  }
};

export const createMapsFullAddressLink = address => {
  try {
    return `http://maps.google.com.br/maps?q=${format(address)}}`;
  } catch (e) {
    return '';
  }
};

export const createMapsAddressIframe = (address, number, city, zip_code) => {
  try {
    return `https://maps.google.com/maps?q=${address}, ${number}, ${city}, ${zip_code}&t=&z=15&ie=UTF8&iwloc=&output=embed`;
  } catch (e) {
    return '';
  }
};

export const createMapsAddressOnlyIframe = address => {
  try {
    return `https://maps.google.com/maps?q=${address}&t=&z=15&ie=UTF8&iwloc=&output=embed`;
  } catch (e) {
    return '';
  }
};
