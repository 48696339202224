import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import Box from '../../atom/Box';
import Grid from '../../atom/Grid';
import { ockTheme } from '../../themes';
import { isMobile, isBrowser } from 'react-device-detect';

const CheckoutFixedButton = styled(Box)`
  ${isMobile &&
    css`
      width: calc(100vw - 2em);
    `}
  ${isBrowser &&
    css`
      width: calc(100vw - 2.8em);
    `}
  position: fixed;
  bottom: 0;
  z-index: 4;
  cursor: pointer !important;
  color: white !important;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  position: fixed;
  left: 0;
  background-color: ${ifProp(
    { disabled: true },
    ockTheme.colors.ockGreen,
    ockTheme.colors.ockGreen,
  )};
`;

const CustomContainerGrid = styled(Grid)`
  ${ifProp(
    { disabled: true },
    css`
      opacity: 0.5;
      pointer-events: none;
      transition: opacity 0.25s ease-out;
      -moz-transition: opacity 0.25s ease-out;
      -webkit-transition: opacity 0.25s ease-out;
    `,
    css`
      opacity: 1;
      pointer-events: all;
      transition: opacity 0.25s ease-in;
      -moz-transition: opacity 0.25s ease-in;
      -webkit-transition: opacity 0.25s ease-in;
    `,
  )}
`;

const BottomButton = ({
  children,
  offset,
  offsetDesktop,
  spacing,
  boxShadow,
  onClick,
  disabled,
  type,
  ...props
}) => {
  return (
    <CheckoutFixedButton
      pt={1}
      pb={1}
      pl={2}
      pr={2}
      mb={isMobile ? offset : offsetDesktop}
      boxShadow={boxShadow}
      // disabled={disabled}
      onClick={!disabled && onClick}
      {...props}
      type={type}
    >
      <CustomContainerGrid
        container
        justify="center"
        alignItems="center"
        data-testid="checkoutBar-inside"
        spacing={spacing}
        disabled={disabled}
      >
        {children && children}
      </CustomContainerGrid>
    </CheckoutFixedButton>
  );
};

BottomButton.propTypes = {
  offset: PropTypes.string,
  offsetDesktop: PropTypes.string,
  spacing: PropTypes.number,
  boxShadow: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

BottomButton.defaultProps = {
  offset: '56px',
  offsetDesktop: '0px',
  spacing: 2,
  boxShadow: 0,
  onClick: () => {},
  disabled: false,
};

export default BottomButton;
