import React, { useContext, useEffect, useState } from 'react';
import Search from '@project/components-typescript/src/components/Search/Search';
import usePrismic from '../../../hooks/usePrismic';
import { PrismicPathEnum } from '../../../interfaces/PrismicInterfaces';
import { OckpayPrismicTypesEnum } from '../../../interfaces/OckpayPrismicInterfaces';
import { PrismicArticlesResponseInterface } from '../interfaces/PrismicArticleInterface';
import { FaqContext } from '../contexts/FaqContext/FaqContext';
import { FaqTypesEnum } from '../contexts/FaqContext/FaqInterface';

interface FaqSearchInterface {
  isPublicRoute: boolean;
  iconStyle?: React.CSSProperties;
}

export const FaqSearch: React.FC<FaqSearchInterface> = ({ isPublicRoute, iconStyle }) => {
  const { dispatchFaq } = useContext(FaqContext);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data, isValidating } = usePrismic<PrismicArticlesResponseInterface>({
    at: [
      {
        path: PrismicPathEnum.document,
        value: OckpayPrismicTypesEnum.article,
      },
    ],
    fulltext: {
      fragment: 'document',
      value: searchTerm,
    },
  });

  useEffect(() => setIsLoading(isValidating), [isValidating]);

  useEffect(() => {
    if (searchTerm !== '') dispatchFaq({ type: FaqTypesEnum.UPDATE_FAQ_LIST, list: data });
  }, [data, dispatchFaq, searchTerm]);

  useEffect(() => {
    if (searchTerm === '') dispatchFaq({ type: FaqTypesEnum.UPDATE_FAQ_LIST, list: undefined });
  }, [dispatchFaq, searchTerm]);

  return (
    <>
      <Search
        showEndAdornment={!isPublicRoute}
        showStartAdornment={!isPublicRoute}
        fullWidth
        iconStyle={iconStyle}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setSearchTerm={setSearchTerm}
        placeholder="Busque aqui..."
      />
    </>
  );
};

export default FaqSearch;
