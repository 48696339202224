import SnackbarInterface from "@project/components-typescript/src/components/Snackbar/SnackbarInterface";
import BackdropInterface from "../../interfaces/BackdropInterface";
import { viewActionCloseSnackbar, viewActionHideBackdrop, viewActionShowBackdrop, viewActionShowSnackbar } from "./ViewActions";

export type SHOW_SNACKBAR = {
  type: 'SHOW_SNACKBAR';
  snackbar: SnackbarInterface;
};

export type CLOSE_SNACKBAR = {
  type: 'CLOSE_SNACKBAR';
};

export type SHOW_BACKDROP = {
  type: 'SHOW_BACKDROP';
};

export type HIDE_BACKDROP = {
  type: 'HIDE_BACKDROP'
};

export type ViewAction =
  | SHOW_SNACKBAR
  | CLOSE_SNACKBAR
  | SHOW_BACKDROP
  | HIDE_BACKDROP;

export interface ViewInterface {
  snackbar?: SnackbarInterface;
  showBackdrop?: BackdropInterface;
}

function viewReducer(state: ViewInterface, action: ViewAction): ViewInterface {
  switch (action.type) {
    case 'SHOW_SNACKBAR':
      return viewActionShowSnackbar(state, action);

    case 'CLOSE_SNACKBAR':
      return viewActionCloseSnackbar(state, action);

    case 'SHOW_BACKDROP':
      return viewActionShowBackdrop(state, action);

    case 'HIDE_BACKDROP':
      return viewActionHideBackdrop(state, action);

    default:
      return state;
  }
}

export default viewReducer;
