import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { useCookies } from 'react-cookie';
import { RHFInput } from 'react-hook-form-input';
import { Button, TextField, Alert, Box } from '@project/components';
import { FreightContext } from '../contexts/FreightContext';
import { updateFreightIntegration } from '../services/updateFreightIntegration';
import { VariantEnum } from '@project/components-typescript/src/services/axiosService';

interface MotoboyDotComConfigInterface {}

interface FormData {
  api_key: string;
  username: string;
}

const MotoboyDotComConfig: React.FC<MotoboyDotComConfigInterface> = (): JSX.Element => {
  const [cookies] = useCookies(['token']);
  const { setShowBackdrop, setShowSnackbar, setSnackbarInformation } = useContext(FeedbackContext);
  const { stateFreight, dispatchFreight } = useContext(FreightContext);
  const { register, setValue, handleSubmit, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      api_key: stateFreight.selectedIntegration?.api_key,
      username: stateFreight.selectedIntegration?.username,
    },
  });

  if (!stateFreight.selectedIntegration)
    return (
      <Alert title="Ooops!" severity="warning">
        Nenhuma integração foi selecionada, tente novamente.
      </Alert>
    );

  const onSubmit = async (data: unknown): Promise<void> => {
    if (!stateFreight.selectedIntegration) return;

    const settings = data as FormData;

    setShowBackdrop(true);
    updateFreightIntegration(stateFreight.selectedIntegration, settings, cookies.token)
      .then(({ message, variant, data }) => {
        dispatchFreight({ type: 'UPDATE_FREIGHT_INTEGRATIONS', freightIntegration: data });
        dispatchFreight({ type: 'SELECT_FREIGHT_INTEGRATIONS', freightIntegration: data });
        setSnackbarInformation({ message, variant });
      })
      .catch(({ message }) => setSnackbarInformation({ message, variant: VariantEnum.error }))
      .finally(() => {
        setShowBackdrop(false);
        setShowSnackbar(true);
      });
  };

  return (
    <div key="bla">
      <Box mb={2}>
        {' '}
        <Alert title="Leia antes de continuar" severity="info">
          É preciso ter uma conta motoboy.com para utilizar este serviço.
          <br />
          <ol>
            <li>{'Crie sua conta Motoboy.com'}</li>
            <li>{'Faça login em Motoboy.com e clique em "Integração via API"'}</li>
            <li>{'Selecione e copie o código Api formado por números e letras'}</li>
            <li>{'Cole este código no campo "API Key" em seu Gestor Ockpay'}</li>
            <li>{'Ainda no gestor adicione seu email cadastrado na Motoboy.com'}</li>
          </ol>
          Pronto!
          <br />
          <br />
          Não tem uma conta?{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://www.motoboy.com/cadastro">
            Clique aqui
          </a>{' '}
          para criar a sua.
        </Alert>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <RHFInput
          as={
            <TextField
              id="api_key"
              variant="standard"
              label="API key"
              placeholder="Fornecido dentro da motoboy.com"
              error={errors?.api_key}
              helperText={errors?.api_key && errors?.api_key.message}
            />
          }
          register={register}
          rules={{ required: 'O API key é obrigatório!' }}
          name="api_key"
          setValue={setValue}
        />

        <RHFInput
          as={
            <TextField
              id="username"
              type="email"
              variant="standard"
              label="Email cadastrado"
              placeholder="O mesmo que você criou a conta na Motoboy.com"
              error={errors?.username}
              helperText={errors?.username && errors?.username.message}
            />
          }
          register={register}
          rules={{ required: 'O email é obrigatório!' }}
          name="username"
          setValue={setValue}
        />

        <Button type="submit" color="primary">
          Salvar
        </Button>
      </form>
    </div>
  );
};

export default MotoboyDotComConfig;
