import { createMuiTheme } from '@material-ui/core/styles';
import { ockTheme } from '@project/components/src/themes';

// import red from '@material-ui/core/colors/red'

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: ockTheme.colors.ockDarkBlue,
      main: ockTheme.colors.ockDarkBlue,
      dark: ockTheme.colors.ockLightBlue,
      contrastText: '#fff',
    },
    secondary: {
      light: ockTheme.colors.ockGreenLight,
      main: ockTheme.colors.ockGreen,
      dark: ockTheme.colors.ockGreenDark,
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
      empty: '#F9F9F9',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    button: {
      borderColor: '#c7c7c7',
      danger: '#f44336',
    },
    common: {
      black: '#000000ed',
      white: '#fff',
    },
    icon: {
      alert: '#f44336',
      delete: '#f44336',
      success: ockTheme.colors.ockGreenDark,
      default: '#c7c7c7',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#c7c7c7',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#c7c7c7',
          borderWidth: 2,
        },
        '&$focused $notchedOutline': {
          borderColor: '#c7c7c7',
        },
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 1000px black inset',
        },
      },
      notchedOutline: {},
    },
    MuiFormControl: {
      root: {
        marginBottom: '1em',
      },
    },
    MuiSelect: {
      icon: {
        fill: '#90caf9',
      },
    },
    SelectInput: {
      minWidth: '200px',
    },
    MuiBackdrop: {
      root: {
        'z-index': 10,
      },
    },
    MuiBottomNavigation: {
      root: {
        'border-top': '1px solid #eee',
        'z-index': 4,
        left: 0,
      },
    },
    MuiDialog: {
      paper: {
        'z-index': 10,
      },
    },
    MuiPaper: {
      root: {
        padding: '1em',
      },
    },
    MuiCard: {
      root: {
        padding: '.25em',
      },
    },
    // MuiSvgIcon: {
    //   root: {
    //     color: '#A9A9A9',
    //   },
    // },
    MuiListSubheader: {
      // sticky: {
      //   'background-color': '#fff',
      //   top: '3.6em',
      //   'z-index': 3,
      // },
    },
    MuiToolbar: {
      regular: {
        height: '50px',
        'min-height': '50px !important',
      },
    },
    MuiInputLabel: {
      outlined: {
        'background-color': '#FFFFFF',
        'padding-left': '7px',
        'padding-right': '7px',
        left: '-4px',
      },
    },
    MuiAppBar: {
      root: {
        padding: 0,
      },
      colorPrimary: {
        'background-color': '#fff',
        color: '#000000c7',
        border: '1px solid #eee',
      },
    },
    MuiSnackbar: {
      root: {
        'margin-bottom': '4.4em',
        'background-color': 'inherit',
        transition:
          'opacity 2225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
      anchorOriginBottomLeft: {
        'margin-right': '5em',
      },
    },
    MuiSnackbarContent: {
      root: {
        'background-color': 'inherit',
        transition: `opacity 2225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      },
    },
    // MuiFab: {
    //   extended: {
    //     right: '16px',
    //     bottom: '5.3em',
    //     // position: 'absolute',
    //   },
    //   root: {
    //     right: '16px',
    //     bottom: '5.3em',
    //     position: 'fixed',
    //   },
    // },
    // MuiSpeedDial: {
    //   root: {
    //     display: 'flex',
    //     'z-index': '1050',
    //     'align-items': 'flex-end',
    //     'pointer-events': 'none',
    //     position: 'fixed',
    //     right: '16px',
    //     bottom: '5.3em',
    //   },
    //   fab: {
    //     position: 'relative',
    //   },
    // },
    MuiSpeedDialAction: {
      staticTooltipLabel: {
        'text-align': 'center',
        'max-width': '170px',
        width: '100vw',
      },
    },
    MuiButton: {
      root: {
        'border-radius': '20px',
      },
    },
    MuiButtonBase: {
      root: {
        'z-index': 2,
      },
    },
  },
  shape: {},
  breakpoints: {},
  direction: {},
  mixins: {},
  props: {},
  // shadows: {},
  // spacing: {},
  transitions: {},
  typography: {
    h1: {
      fontSize: '22px',
    },
    h2: {
      fontSize: '18px',
    },
    h3: {
      fontSize: '16px',
    },
    h4: {
      fontSize: '14px',
    },
    h5: {
      fontSize: '12px',
    },
    h6: {
      fontSize: '10px',
    },
    fontFamily: `Montserrat`,
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  zIndex: {},
});

export default theme;
