export type CouponID = number;

export interface CouponInterface {
  name: string;
  coupon_code: string;
  description: string;
  qty_available: number;
  discount: number;
  start_date: null | Date;
  due_date: null | Date | string;
  id: CouponID;
  qty_used: null | number;
  active: boolean;
  establishments_id: number;
  createdAt: Date;
  updatedAt: Date;
}

export enum CouponKeyEnum {
  id = 'id',
  code = 'coupon_code',
  name = 'name',
  description = 'description',
  discount = 'discount',
  startDate = 'start_date',
  endDate = 'due_date',
  limit = 'qty_available',
  timesUsed = 'qty_used',
  active = 'active',
  establishments_id = 'establishments_id',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export enum CouponLabelEnum {
  id = 'ID',
  code = 'Código',
  name = 'Nome',
  description = 'Descrição',
  discount = 'Desconto (%)',
  startDate = 'Inicio',
  endDate = 'Fim',
  limit = 'Limite',
  timesUsed = 'Usos',
  active = 'Ativo',
  establishments_id = 'ID do estabelecimento',
  createdAt = 'Criado em',
  updatedAt = 'Editado em',
}
