export const parseCurrencyToFloat = price => {
  if (typeof price === 'string') {
    const strPrice = price
      .split('R$')[1]
      .replace(/\./g, '')
      .replace(',', '.');

    return parseFloat(strPrice);
  } else {
    return price;
  }
};

const addRS = price => (price.includes('R$') ? price : `R$ ${price}`);

/**
 * @deprecated
 * @param {string} price
 */
export const parseCurrencyToABNT = (price = '') => {
  const stringPrice = price.toString().replace(/[R$ ]+/, '');
  const formattedPrice = addRS(
    parseFloat(stringPrice)
      .toLocaleString('pt-BR', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
        currency: 'BRL',
      })
      .slice(0, -1)
      .replace('.', ','),
  );

  return formattedPrice.split(',').length > 2
    ? formattedPrice.replace(',', '.')
    : formattedPrice;
};
