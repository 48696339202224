import React from 'react';
import { Box } from '@project/components';
import FaqList from '../sections/FaqList';
import FaqSearch from '../sections/FaqSearch';

interface PrivateFaqPageInterface {}

const PrivateFaqPage: React.FC<PrivateFaqPageInterface> = () => {
  return (
    <>
      <Box mb={2}>
        <FaqSearch />
      </Box>

      <FaqList />
    </>
  );
};

export default PrivateFaqPage;
