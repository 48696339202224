import React from 'react';
import PropTypes from 'prop-types';

import { ListItemSecondaryAction as MaterialListItemSecondaryAction } from '@material-ui/core';

const ListItemSecondaryAction = ({ children, classes = {}, ...props }) => {
  return (
    <MaterialListItemSecondaryAction
      classes={classes}
      children={children}
      {...props}
    />
  );
};

ListItemSecondaryAction.propTypes = {
  props: PropTypes.node,
};

export default ListItemSecondaryAction;
