import React from 'react';
import PropTypes from 'prop-types';

import { EmailShareButton, EmailIcon } from 'react-share';

const ShareButtonEmail = ({ url, subject, body, ...props }) => {
  return (
    <EmailShareButton url={url} subject={subject} body={body} {...props}>
      <EmailIcon size="36px" borderRadius="12px" />
    </EmailShareButton>
  );
};

ShareButtonEmail.propTypes = {
  url: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  props: PropTypes.node,
};

export default ShareButtonEmail;
