import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Box,
  Grid,
  ShareButtonWhatsapp,
  ShareButtonFacebook,
  ShareButtonEmail,
  ShareButtonTwitter,
  ShareButtonTelegram,
} from '@project/components';
import Typography from '@project/components/src/atom/Typography';
import { slugify } from '@project/components/src/utils/Helpers';
import { AuthContext } from '@project/components/src/contexts/AuthContextProvider';
import { FirebaseAnalyticsContext } from '@project/components/src/contexts/FirebaseAnalyticsProvider';

const ShareDeliverySection = ({ shareURL }) => {
  const { loggedUser } = useContext(AuthContext);
  const { analyticsShare } = useContext(FirebaseAnalyticsContext);

  const shareButtons = useMemo(
    () => [
      <ShareButtonEmail
        onClick={() => analyticsShare('email', slugify(loggedUser.name))}
        subject={`Compre na ${loggedUser.name} online via Ockpay`}
        body={`Através desse link, você pode fazer a compra dos produtos da ${loggedUser.name} online do conforto da sua casa utilizando a plataforma Ockpay.`}
        url={shareURL}
      />,
      <ShareButtonFacebook
        onClick={() => analyticsShare('facebook', slugify(loggedUser.name))}
        quote={`Compre os produtos da ${loggedUser.name} na nossa plataforma online`}
        hashtag={`#Ockpay #Delivery #${loggedUser.name}`}
        url={shareURL}
      />,
      <ShareButtonWhatsapp
        onClick={() => analyticsShare('whatsapp', slugify(loggedUser.name))}
        url={shareURL}
        title={`Compre os produtos da ${loggedUser.name} na nossa plataforma online`}
      />,
      <ShareButtonTwitter
        onClick={() => analyticsShare('twitter', slugify(loggedUser.name))}
        url={shareURL}
        title={`Compre os produtos da ${loggedUser.name} na nossa plataforma online`}
      />,
      <ShareButtonTelegram
        onClick={() => analyticsShare('telegram', slugify(loggedUser.name))}
        url={shareURL}
      />,
    ],
    [analyticsShare, loggedUser.name, shareURL],
  );

  return (
    <>
      <Paper>
        <Box p={2}>
          <Grid container spacing={0}>
            {shareButtons.map((shareButton, index) => (
              <Grid item xs align="center" key={index}>
                {shareButton}
              </Grid>
            ))}

            <Grid item xs={12} align="center">
              <Box mt={1}>
                <Typography variant="caption">
                  Clique para compartilhar
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
};

ShareDeliverySection.propTypes = {
  shareURL: PropTypes.string,
};

ShareDeliverySection.defaultProps = {
  shareURL: '',
};

export default ShareDeliverySection;
