import React from 'react';
import PropTypes from 'prop-types';

import { Grid as MaterialGrid } from '@material-ui/core';

const Grid = ({ children, ...props }) => {
  return <MaterialGrid {...props}>{children}</MaterialGrid>;
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Grid;
