import React, { useContext } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { AuthContext } from '@project/components/src/contexts/AuthContextProvider';
import { SalesPageContext } from '../contexts/SalesPageContext';
import { Box, SelectField } from '@project/components';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { formatDate, DateFormatEnum, VisualizationDateEnum } from '../contexts/SalesPageInit';
import { addDays, parseISO } from 'date-fns';

interface DateRangeSelectorInterface {}

const DateRangeSelector: React.FC<DateRangeSelectorInterface> = () => {
  const { loggedUser } = useContext(AuthContext);
  const { salesPage, dispatchSalesPage } = useContext(SalesPageContext);

  const handleDateToOnChange = (date: MaterialUiPickersDate): void => {
    dispatchSalesPage &&
      dispatchSalesPage({
        type: 'ADD_DATE_TO',
        dateTo: date,
      });
  };

  const handleDateAtOnChange = (date: MaterialUiPickersDate): void => {
    dispatchSalesPage &&
      dispatchSalesPage({
        type: 'ADD_DATE_AT',
        dateAt: date,
      });
  };

  const handleVisualizationDateChange = (event): void => {
    const visualizationDate = parseInt(event.target.value) as VisualizationDateEnum;
    dispatchSalesPage &&
      dispatchSalesPage({
        type: 'CHANGE_VISUALIZATION_DATA',
        visualizationDate,
      });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box mr={1}>
        <SelectField
          variant="outlined"
          value={salesPage.visualizationDate}
          title="Período"
          showEmptyOption={false}
          margin="dense"
          onChange={handleVisualizationDateChange}
          options={[
            { value: VisualizationDateEnum.Today, label: 'Hoje' },
            { value: VisualizationDateEnum.Yesterday, label: 'Ontem' },
            { value: VisualizationDateEnum.SevenDays, label: '7 dias' },
            { value: VisualizationDateEnum.FifteenDays, label: '15 dias' },
            { value: VisualizationDateEnum.ThirdDays, label: '30 dias' },
            { value: VisualizationDateEnum.SixtyDays, label: '60 dias' },
            { value: VisualizationDateEnum.NinetyDays, label: '90 dias' },
          ]}
        />
      </Box>

      <Box mr={1}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          autoOk
          disableFuture
          // minDate={formatDate(addDays(new Date(loggedUser.createdAt), 1))}
          maxDate={formatDate(addDays(new Date(), 1))}
          format={DateFormatEnum.DayMonthYearPtBr}
          margin="dense"
          id="date-inicio-inline"
          label="Início"
          value={parseISO(salesPage.dateAt)}
          onChange={handleDateAtOnChange}
          KeyboardButtonProps={{
            'aria-label': 'Trocar data',
          }}
        />
      </Box>
      <Box mr={1}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          autoOk
          disableFuture
          inputVariant="outlined"
          format={DateFormatEnum.DayMonthYearPtBr}
          minDate={formatDate(addDays(new Date(loggedUser.createdAt), 1))}
          maxDate={formatDate(addDays(new Date(), 1))}
          margin="dense"
          id="date-fim-inline"
          label="Fim"
          value={parseISO(salesPage.dateTo)}
          onChange={handleDateToOnChange}
          KeyboardButtonProps={{
            'aria-label': 'Trocar data',
          }}
        />
      </Box>
    </MuiPickersUtilsProvider>
  );
};

export default DateRangeSelector;
