import {
  Snackbar as MaterialSnackbar,
  SnackbarContent as MaterialSnackbarContent,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { switchProp, ifProp } from 'styled-tools';

import Button from '../Button';
import { Close as CloseIcon } from '@material-ui/icons';
import { FeedbackContext } from '../../contexts/FeedbackContext';
import IconButton from '../IconButton';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const HiddenSnackbar = styled(MaterialSnackbar)`
  display: none;

  ${ifProp(
    { onBottom: true },
    css`
      margin-bottom: 8px !important;
    `,
  )}
`;

const ColoredSnackbarContent = styled(MaterialSnackbarContent)`
  background-color: ${switchProp('variant', {
    default: 'rgba(0, 0, 0, 0.95)',
    error: 'rgba(244, 67, 54, 0.95)',
    warning: 'rgba(255, 152, 0, 0.95)',
    info: 'rgba(0, 150, 136, 0.95)',
    success: 'rgba(76, 175, 80, 0.95)',
  })} !important;
`;

const CustomActionButton = styled(IconButton)`
  /* position: absolute !important;
  top: 11px !important;
  right: 11px !important; */
`;

const Snackbar = ({
  duration = 6000,
  showRevert = false,
  revertButtonAction = () => {},
  showRevertButton = false,
  onBottom = false,
  ...props
}) => {
  const [buttonPressTimer, setButtonPressTimer] = useState(() => {});
  // TODO: remove this from context
  const { showSnackbar, setShowSnackbar } = useContext(FeedbackContext);

  useEffect(() => {
    clearTimeout(buttonPressTimer);
    setButtonPressTimer(setTimeout(() => setShowSnackbar(false), duration));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSnackbar]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  return (
    <HiddenSnackbar
      onBottom={onBottom}
      data-testid="component-snackbar"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={showSnackbar}
      onClose={handleClose}
      {...props}
    >
      <ColoredSnackbarContent
        variant="default"
        message="Notificação"
        action={
          <>
            {showRevertButton && (
              <Button
                color="secondary"
                variant="text"
                size="small"
                onClick={() => revertButtonAction()}
              >
                DESFAZER
              </Button>
            )}
            <CustomActionButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </CustomActionButton>
          </>
        }
        {...props}
      />
    </HiddenSnackbar>
  );
};

Snackbar.propTypes = {
  duration: PropTypes.number,
  props: PropTypes.node,
};

export default Snackbar;
