import React from 'react';

import { Typography as MaterialTypography } from '@material-ui/core';
import styled, { css } from 'styled-components';

const CustomMaterialTypography = styled(MaterialTypography)`
  ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${fontWeight} !important;
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color} !important;
    `}
`;

const Typography = ({ fontWeight, color, ...props }) => {
  return (
    <CustomMaterialTypography fontWeight={fontWeight} color={color} {...props}>
      {props.children}
    </CustomMaterialTypography>
  );
};

export default React.memo(Typography);
