import { axiosDeprecatedPOST, axiosPUT } from './axiosService';

/**
 * @deprecated
 * @param {*} urlImgOriginal
 */
export function extractImageUUID(urlImgOriginal) {
  if (process.env.NODE_ENV === 'production') {
    return urlImgOriginal
      .replace(
        'https://storage.googleapis.com/ockpay-static/images/originals/',
        '',
      )
      .replace('.jpeg', '');
  } else {
    return urlImgOriginal
      .replace('http://localhost:5000/', '')
      .replace('.jpeg', '');
  }
}

/**
 * @Deprecated
 * @param {*} file
 * @param {*} info
 * @param {*} urlImgOriginal
 * @param {*} token
 */
export const handleUploadEstablishmentLogo = async (
  file,
  info,
  urlImgOriginal,
  token,
) => {
  const imageUUID = !!urlImgOriginal ? extractImageUUID(urlImgOriginal) : null;

  try {
    const endpoint = process.env.REACT_APP_UPLOAD_IMAGE_ENDPOINT;
    const config = {
      'content-type': 'multipart/form-data',
    };

    const formData = new FormData();

    formData.append('image', file);

    if (imageUUID !== null) {
      formData.append('uuid', imageUUID);
    }

    formData.append('width', info.width);
    formData.append('height', info.height);
    formData.append('x', info.x);
    formData.append('y', info.y);

    if (imageUUID === null) {
      return await axiosDeprecatedPOST(
        `${endpoint}/establishment/logo`,
        formData,
        token,
        config,
      );
    } else {
      return await axiosPUT(
        `${endpoint}/establishment/logo`,
        formData,
        token,
        config,
      );
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * @deprecated
 */
export const handleUploadEstablishmentBanner = async (
  file,
  info,
  urlImgOriginal,
  token,
) => {
  const imageUUID = !!urlImgOriginal ? extractImageUUID(urlImgOriginal) : null;
  try {
    const endpoint = process.env.REACT_APP_UPLOAD_IMAGE_ENDPOINT;
    const config = {
      'content-type': 'multipart/form-data',
    };

    const formData = new FormData();

    formData.append('image', file);

    if (imageUUID !== null) {
      formData.append('uuid', imageUUID);
    }

    formData.append('width', info.width);
    formData.append('height', info.height);
    formData.append('x', info.x);
    formData.append('y', info.y);

    if (imageUUID === null) {
      return await axiosDeprecatedPOST(
        `${endpoint}/establishment/banner`,
        formData,
        token,
        config,
      );
    } else {
      return await axiosPUT(
        `${endpoint}/establishment/banner`,
        formData,
        token,
        config,
      );
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * @deprecated
 * @param {*} file
 * @param {*} info
 * @param {*} id
 * @param {*} imageUUID
 * @param {*} token
 */
export const handleUploadImage = async (file, info, id, imageUUID, token) => {
  try {
    const endpoint = process.env.REACT_APP_UPLOAD_IMAGE_ENDPOINT;
    const config = {
      'content-type': 'multipart/form-data',
    };

    const formData = new FormData();

    formData.append('image', file);
    if (imageUUID !== null) {
      formData.append('uuid', imageUUID);
    }
    formData.append('width', info.width);
    formData.append('height', info.height);
    formData.append('x', info.x);
    formData.append('y', info.y);

    if (imageUUID === null) {
      return await axiosDeprecatedPOST(
        `${endpoint}/item/${id}`,
        formData,
        token,
        config,
      );
    } else {
      return await axiosPUT(`${endpoint}/item/${id}`, formData, token, config);
    }
  } catch (error) {
    console.error(error);
  }
};

// export const imageNameNormalizator = (uid_restaurant, id_item) => `${uid_restaurant}_${id_item}`;

export const loadImage = param => {
  try {
    return param;
  } catch (error) {
    return '';
  }
};

export const loadThumb = param => {
  try {
    return param;
  } catch (error) {
    return '';
  }
};
