import { Avatar as AvatarMaterial } from '@material-ui/core';
import Button from '../../atom/Button';
import Card from '../../molecule/Card';
import CardActions from '../../molecule/Card/CardActions';
import CardContent from '../../molecule/Card/CardContent';
import CardHeader from '../../molecule/Card/CardHeader';
import Collapse from '../../atom/Collapse';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '../../atom/Typography';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

//TODO: remove this and put on a separeted component
const ExpandedButton = styled(Button)`
  margin-left: auto !important;

  transform: ${ifProp({ expanded: true }, 'rotate(180deg)', 'rotate(0deg)')};
  transition-timing-function: ${ifProp(
    { expanded: true },
    'ease-in',
    'ease-out',
  )} !important;
  transition: 0.25s !important;
`;

const CardComplex = ({
  children,
  header,
  content,
  preview,
  button1,
  button2,
  ...props
}) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <Card {...props}>
      {header && (
        <CardHeader
          avatar={
            <AvatarMaterial aria-label="recipe">{header.avatar}</AvatarMaterial>
          }
          title={header.title}
          subheader={header.subheader}
        />
      )}
      {preview && (
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {preview}
          </Typography>
        </CardContent>
      )}
      <CardActions disableSpacing>
        {button1 && (
          <Button variant="text" {...button1.props} onClick={button1.onClick}>
            {button1.text}
          </Button>
        )}
        {button2 && (
          <Button variant="text" {...button2.props} onClick={button2.onClick}>
            {button2.text}
          </Button>
        )}
        {children && (
          <ExpandedButton
            variant="text"
            onClick={() => setExpanded(!expanded)}
            expanded={expanded}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandedButton>
        )}
      </CardActions>
      {children && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>{children}</CardContent>
        </Collapse>
      )}
    </Card>
  );
};

CardComplex.propTypes = {
  props: PropTypes.node,
  header: PropTypes.shape({
    avatar: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
    title: PropTypes.string,
    subheader: PropTypes.string,
  }),
};

export default CardComplex;
