import React from 'react';
import { PrismicFormattedElementInterface } from '../../../interfaces/PrismicInterfaces';

interface PrismicImageInterface {
  prismicElement: PrismicFormattedElementInterface;
}

const PrismicImage: React.FC<PrismicImageInterface> = ({ prismicElement }) => {
  return <img width="100%" height="auto" src={prismicElement.url} alt={prismicElement.alt} />;
};

export default PrismicImage;
