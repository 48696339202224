import List from '@project/components/src/atom/List';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import * as R from 'ramda';
import React, { useContext, useEffect, useState } from 'react';
import { getAllOrders } from '../../../../services/getOrders';
import { useCookies } from 'react-cookie';
import GestorOrderListItem from '../layouts/GestorOrderListItem';
import LegacyGestorOrderListItem from '../layouts/LegacyGestorOrderListItem';
import {
  OptimizedSortedArrInterface,
  MixedOrderInterface,
} from '../interfaces/SalesOrderInterface';
import { LegacyGestorOrderInterface } from '../../../../interfaces/LegacyGestorOrderInterface';
import GestorOrderInterface from '@project/components-typescript/src/interfaces/Order/GestorOrderInterface';
import SalesSubHeader from '../layouts/SalesSubHeader';
import SalesPageReportMobile from '../layouts/SalesPageReportMobile';

const SalesPageList: React.FC = () => {
  const { setShowBackdrop } = useContext(FeedbackContext);
  const [cookies] = useCookies(['token']);
  const [subheadersOrders, setSubheadersOrders] = useState<
    OptimizedSortedArrInterface[]
  >([]);

  // TODO: Remove any in the future
  function funcOrderBydate(a: any, b: any): number {
    const reversedA = a[0].split('-').join('');
    const reversedB = b[0].split('-').join('');

    return reversedB - reversedA;
  }

  function groupOrder(orders: MixedOrderInterface[]): void {
    const byDate = R.groupBy(
      (order: MixedOrderInterface) => order.createdAt.split('T')[0],
    );
    const ordersByDate = Object.entries(byDate(orders));
    const sorted = R.sort(funcOrderBydate, ordersByDate);
    const optimizedSortedArr = (sorted.map(order => ({
      date: order[0],
      orders: order[1],
    })) as unknown) as OptimizedSortedArrInterface[];

    setSubheadersOrders(optimizedSortedArr);
  }

  useEffect(() => {
    setShowBackdrop(true);
    getAllOrders(cookies.token)
      .then(orders => groupOrder(orders))
      .catch(e => console.error(e))
      .finally(() => setShowBackdrop(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <List component="nav" aria-label="main mailbox folders">
        {subheadersOrders.map(header => (
          <SalesSubHeader key={header.date} header={header}>
            {header?.orders?.map(order =>
              order.order_details.hasOwnProperty('version') ? (
                <GestorOrderListItem order={order as GestorOrderInterface} />
              ) : (
                <LegacyGestorOrderListItem
                  order={order as LegacyGestorOrderInterface}
                />
              ),
            )}
          </SalesSubHeader>
        ))}
      </List>

      <SalesPageReportMobile />
    </>
  );
};

export default SalesPageList;
