import { ADD_NEW_ORDER, OrdersContextInterface } from '../OrdersInterface';
import { bellRingSFX } from '@project/components/src/sfx/Notification';

export function addNewOrderAction(
  state: OrdersContextInterface,
  action: ADD_NEW_ORDER,
): OrdersContextInterface {
  try {
    bellRingSFX().play();
  } catch (error) {
    console.error('Error to play new order sound [bellRing]', error);
  }

  return {
    ...state,
    orders: [
      {
        order: action.order,
        isNew: true,
      },
      ...state.orders,
    ],
  };
}
