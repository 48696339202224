import React from 'react';
import PropTypes from 'prop-types';

import { WhatsappShareButton, WhatsappIcon } from 'react-share';

const ShareButtonWhatsapp = ({ children, url, title, ...props }) => {
  return (
    <WhatsappShareButton url={url} title={title} {...props}>
      <WhatsappIcon size="36px" borderRadius="12px" />
    </WhatsappShareButton>
  );
};

ShareButtonWhatsapp.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  props: PropTypes.node,
};

export default ShareButtonWhatsapp;
