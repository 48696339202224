/* eslint-disable react/prop-types */
import React, { createContext, useReducer } from 'react';
import { viewInitialState } from './ViewActions';
import viewReducer, { ViewAction, ViewInterface } from './ViewReducer';

export type ViewDispatchType = React.Dispatch<ViewAction>;

interface ViewContextInterface {
  view: ViewInterface;
  dispatchView: ViewDispatchType;
}

export const ViewContext = createContext<ViewContextInterface>({
  view: viewInitialState,
  dispatchView: () => console.warn('dispatchView not ready'),
});

const ViewProvider: React.FC = ({ children }) => {
  const [view, dispatchView] = useReducer(viewReducer, viewInitialState);

  const globals = {
    view,
    dispatchView,
  };

  return <ViewContext.Provider value={globals}>{children}</ViewContext.Provider>;
};

export default ViewProvider;
