import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const CustomResponsiveImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: ${borderRadius => borderRadius};
`;

const ResponsiveImage = ({ src, alt, borderRadius = '2px', ...props }) => {
  return (
    <CustomResponsiveImage
      src={src}
      alt={alt}
      borderRadius={borderRadius}
      {...props}
    />
  );
};

ResponsiveImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  props: PropTypes.node,
};

export default ResponsiveImage;
