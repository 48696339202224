// import ItemInterface from '../../../interfaces/ItemInterface';
// import { OrderItemValue } from '@project/components-typescript/src/interfaces/Order/StateOrderInterface';
import { ADD_DATE_AT, ADD_DATE_TO, CHANGE_VISUALIZATION_DATA, SalesPageInterface } from './SalesPageInterface';
import { formatDate } from './SalesPageInit';
import { subDays } from 'date-fns';

export function salesPageActionAddDateTo(state: SalesPageInterface, action: ADD_DATE_TO): SalesPageInterface {
  if (action.dateTo === null) return state;

  return {
    ...state,
    dateTo: formatDate(action.dateTo),
  };
}

export function salesPageActionAddDateAt(state: SalesPageInterface, action: ADD_DATE_AT): SalesPageInterface {
  if (action.dateAt === null) return state;

  return {
    ...state,
    dateAt: formatDate(action.dateAt),
  };
}

export function salesPageActionChangeVisualizationDate(
  state: SalesPageInterface,
  action: CHANGE_VISUALIZATION_DATA,
): SalesPageInterface {
  const actualDate = new Date();

  return {
    ...state,
    visualizationDate: action.visualizationDate,
    dateTo: formatDate(actualDate),
    dateAt: formatDate(subDays(actualDate, action.visualizationDate)),
  };
}
