import React, { useContext, useEffect } from 'react';
import { Alert, MaskedTextField, Button, Box } from '@project/components';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { GlobalStateContext } from '../../../../contexts/GlobalState/GlobalStateContext';
import { RHFInput } from 'react-hook-form-input';
import { useForm } from 'react-hook-form';
import updateSettings from '../../../../services/settings/updateSettings';
import { parseCurrencyToFloat } from '@project/components/src/utils/Parser';
import { useCookies } from 'react-cookie';
import { GlobalStateTypesEnum } from '../../../../contexts/GlobalState/GlobalStateInterface';
import {
  convertCentsToFloat,
  convertMoneyToCents,
} from '@project/components-typescript/src/helpers/LocalePriceConverter';
import { VariantEnum } from '@project/components-typescript/src/services/axiosService';

interface SelfFreightConfigInterface {}

enum FreightTypeEnum {
  free = 'free',
  paid = 'paid',
}

interface FormData {
  delivery_km_fee: number | string;
}

const SelfFreightConfig: React.FC<SelfFreightConfigInterface> = (): JSX.Element => {
  const {
    setShowBackdrop,
    setShowSnackbar,
    setSnackbarInformation,
  } = useContext(FeedbackContext);
  const { stateGlobalState, dispatchGlobalState } = useContext(
    GlobalStateContext,
  );
  const [cookies] = useCookies(['token']);
  const [freightType, setFreightType] = React.useState<FreightTypeEnum>(
    FreightTypeEnum.free,
  );
  const { register, setValue, handleSubmit, errors } = useForm<FormData>({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (stateGlobalState.settings) {
      const deliveryKmFee = stateGlobalState.settings.delivery_km_fee;
      setFreightType(
        deliveryKmFee === 0 ? FreightTypeEnum.free : FreightTypeEnum.paid,
      );

      const convertedPriceStr = convertCentsToFloat(deliveryKmFee)
        .toString()
        .replace('.', ',');

      try {
        const str =
          convertedPriceStr.split(',')[1].length === 1
            ? convertedPriceStr + '0'
            : convertedPriceStr;
        setValue('delivery_km_fee', str);
      } catch (error) {
        setValue('delivery_km_fee', '0');
      }
    }
  }, [stateGlobalState, setValue]);

  const handleChange = (event): void => {
    setFreightType(event.target.value);
  };

  const onSubmit = async (values: FormData): Promise<void> => {
    try {
      setShowBackdrop(true);
      const deliveryKmFee: number =
        freightType === FreightTypeEnum.free
          ? 0
          : convertMoneyToCents(parseCurrencyToFloat(values.delivery_km_fee));

      updateSettings<FormData>(
        { delivery_km_fee: deliveryKmFee },
        cookies.token,
      )
        .then(({ message, variant, data }) => {
          dispatchGlobalState({
            type: GlobalStateTypesEnum.UPDATE_SETTINGS,
            settings: {
              delivery_km_fee:
                typeof data.delivery_km_fee === 'string'
                  ? parseInt(data.delivery_km_fee)
                  : data.delivery_km_fee,
            },
          });
          setSnackbarInformation({ message, variant });
        })
        .catch(({ message }) =>
          setSnackbarInformation({ message, variant: VariantEnum.error }),
        )
        .finally(() => {
          setShowBackdrop(false);
          setShowSnackbar(true);
        });
    } catch (error) {
      setSnackbarInformation({
        message: 'Ooops! Aconteceu um problema, tente novamente',
        variant: VariantEnum.error,
      });
      setShowSnackbar(true);
      setShowBackdrop(false);
    }
  };

  return (
    <div>
      <Alert title="Como funciona?">
        Quando for calculado a distância do seu estabelecimento para o
        destinatário de entrega, será cobrado o valor em R$ colocado abaixo
        vezes a distância em KMs do seu destinatário
      </Alert>
      {/* {JSON.stringify(freightType, undefined, 2)} */}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={2} mb={1}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Valor do frete por KM</FormLabel>
            <RadioGroup
              aria-label="Tipo de frete"
              name="freightType"
              value={freightType}
              onChange={handleChange}
            >
              <FormControlLabel
                value={FreightTypeEnum.free}
                control={<Radio />}
                label="Frete grátis"
              />
              <FormControlLabel
                value={FreightTypeEnum.paid}
                control={<Radio />}
                label="Frete pago"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <RHFInput
          as={
            <MaskedTextField
              hidden={freightType === FreightTypeEnum.free}
              id="delivery_km_fee"
              autoFocus={false}
              label="Valor do frete por KM"
              disabled={false}
              error={errors.delivery_km_fee}
              placeholder="R$"
              helperText={
                errors.delivery_km_fee && errors.delivery_km_fee.message
              }
            />
          }
          name="delivery_km_fee"
          register={register}
          rules={{
            required: freightType === FreightTypeEnum.paid,
            validate: {
              paidFreightMoreThanZero: (price: string | number) => {
                if (typeof price === 'number' && price <= 0)
                  return 'Insira um preço por KM diferente de R$ 0';
                return true;
              },
            },
          }}
          setValue={setValue}
          // value={stateGlobalState.settings?.delivery_km_fee?.toString()}
        />
        {/* )} */}

        <Button type="submit" color="primary">
          Salvar
        </Button>
      </form>
    </div>
  );
};

export default SelfFreightConfig;
