import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useFirebaseMessaging } from '@project/components/src/hooks/useFirebaseMessaging';

export const FirebaseCloudMessagingContext = React.createContext(undefined);

const FirebaseCloudMessagingProvider = ({ children, messaging }) => {
  const [deleteToken, hasPermission, requestPermission, getToken, hasBlockedNotifications] = useFirebaseMessaging(
    messaging,
  );

  useEffect(() => {
    // requestPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messaging]);

  const globals = {
    deleteToken,
    hasPermission,
    requestPermission,
    getToken,
    hasBlockedNotifications,
  };

  return <FirebaseCloudMessagingContext.Provider value={globals}>{children}</FirebaseCloudMessagingContext.Provider>;
};

FirebaseCloudMessagingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FirebaseCloudMessagingProvider;
