import { SELECT_ORDER, OrdersContextInterface } from '../OrdersInterface';

export function selectOrderAction(
  state: OrdersContextInterface,
  action: SELECT_ORDER,
): OrdersContextInterface {
  return {
    ...state,
    orders: state.orders.map(ordersContext =>
      ordersContext.order.id === action.order.id
        ? {
            ...ordersContext,
            isNew: false,
          }
        : ordersContext,
    ),
    selectOrder: action.order,
    overview: null,
  };
}
