import { MUIDataTableOptions } from 'mui-datatables';

export const MUI_DATABLE_DEFAULT_OPTIONS: MUIDataTableOptions = {
  fixedHeader: true,
  selectableRows: 'none',
  textLabels: {
    body: {
      noMatch: 'Oops, sua busca não retornou resultados, tente outros filtros',
      toolTip: 'Ordenar',
    },
    pagination: {
      next: 'Próxima Página',
      previous: 'Página Anterior',
      rowsPerPage: 'Linhas por página:',
      displayRows: 'de',
      jumpToPage: 'Ir até a página',
    },
    toolbar: {
      search: 'Pesquisa',
      downloadCsv: 'Download CSV',
      print: 'Imprimir',
      viewColumns: 'Ver Colunas',
      filterTable: 'Filtrar',
    },
    filter: {
      all: 'Todos',
      title: 'Filtros',
      reset: 'Reset',
    },
    viewColumns: {
      title: 'Mostrar Colunas',
      titleAria: 'Mostrar/Esconder Colunas',
    },
    selectedRows: {
      text: 'linha(s) selecionada(s)',
      delete: 'Excluir',
      deleteAria: 'Excluir Linhas Selecionadas',
    },
  },
};
