import { serviceCreate } from './service';

export const doAuthentication = async (username, password) => {
  return serviceCreate(process.env.REACT_APP_RESTAURANTS_AUTH_ENDPOINT, {
    username,
    password,
  })
    .then(({ response, status }) => ({
      ...response,
      status,
    }))
    .catch(error => {
      console.error(error);
    });
};
