import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { format, subDays } from 'date-fns';
import { SalesPageInterface } from './SalesPageInterface';
// import { convertToLocalTime } from 'date-fns-timezone';

export enum DateFormatEnum {
  YearMonthDay = 'yyyy-MM-dd',
  DayMonthYearPtBr = 'dd/MM/yyyy',
}

export const DEFAULT_DATE_FORMAT = DateFormatEnum.YearMonthDay;

/**
 * Format a date to a string
 *
 * @param date
 */
export const formatDate = (date: ParsableDate): string => {
  if (!date) return new Date().toLocaleString();

  // Use this if the output from server does not have timezone
  // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // const localDate = convertToLocalTime(date.toString(), {
  //   timeZone: timezone,
  // });

  const localDate = new Date(date.toString());

  return format(localDate, DEFAULT_DATE_FORMAT);
};

export enum VisualizationDateEnum {
  Today = 0,
  Yesterday = 1,
  SevenDays = 7,
  FifteenDays = 14,
  ThirdDays = 30,
  SixtyDays = 60,
  NinetyDays = 90,
}

export const SalesPageInitialState: SalesPageInterface = {
  dateTo: formatDate(new Date()),
  dateAt: formatDate(subDays(new Date(), VisualizationDateEnum.SevenDays)),
  visualizationDate: VisualizationDateEnum.SevenDays,
};

export function SalesPageInitializeState(): SalesPageInterface {
  return SalesPageInitialState;
}
