import React from 'react';
import PropTypes from 'prop-types';

import { Avatar as MaterialAvatar } from '@material-ui/core';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

const CustomAvatar = styled(MaterialAvatar)`
  font-size: ${ifProp('fontSize', ({ fontSize }) => `${fontSize}`)} !important;
`;

const Avatar = ({ ...props }) => {
  return <CustomAvatar {...props} />;
};

Avatar.propTypes = {
  props: PropTypes.node,
};

export default Avatar;
