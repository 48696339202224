import { EditableCell } from '@project/components-typescript/src/components/MUITSDatatable';
import { ExtendableCellInterface } from '@project/components-typescript/src/components/MUITSDatatable/EditableCell';
import { HTMLElementInputTypeEnum } from '@project/components-typescript/src/components/MUITSDatatable/MUITSDatatableInterface';

import React from 'react';
import { useCookies } from 'react-cookie';
import { editCoupon } from '../services/editCoupon';

interface DescriptionCellInterface extends ExtendableCellInterface {}

const DescriptionCell: React.FC<DescriptionCellInterface> = ({
  tableMeta,
  updateValue,
  value,
}): JSX.Element => {
  const [cookies] = useCookies(['token']);
  const id = tableMeta.rowData[0];

  const handleUpdate = async (valueToUpdate: string): Promise<void> => {
    editCoupon(id, { description: valueToUpdate }, cookies.token);
  };

  return (
    <EditableCell
      type={HTMLElementInputTypeEnum.text}
      value={value}
      tableMeta={tableMeta}
      updateValue={updateValue}
      handleUpdate={handleUpdate}
      rules={{
        required: 'Descrição obrigatório',
      }}
    />
  );
};

export default DescriptionCell;
