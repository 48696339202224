/* eslint-disable react/prop-types */
import React, { ReactChild, ReactNode } from 'react';

import Typography from '@project/components/src/atom/Typography';
import { Grid, ListSubheader } from '@project/components';
import {
  MixedOrderInterface,
  OptimizedSortedArrInterface,
} from '../interfaces/SalesOrderInterface';
import { LegacyGestorOrderInterface } from '../../../../interfaces/LegacyGestorOrderInterface';
import GestorOrderInterface from '@project/components-typescript/src/interfaces/Order/GestorOrderInterface';
import { convertCentsToLocale } from '@project/components-typescript/src/helpers/LocalePriceConverter';

interface SalesSubHeaderInterface {
  children: ReactChild | ReactNode | Element[];
  header: OptimizedSortedArrInterface;
}

const SalesSubHeader: React.FC<SalesSubHeaderInterface> = ({
  children,
  header,
}) => {
  const totalRevenueByDay = (orders: MixedOrderInterface[]): string =>
    convertCentsToLocale(
      orders.reduce((acc, val) => {
        if (val.hasOwnProperty('version')) {
          const order: GestorOrderInterface = val as GestorOrderInterface;
          return (acc += val.status !== 'CANCELADO' ? order.grand_total : 0);
        }

        const order: LegacyGestorOrderInterface = val as LegacyGestorOrderInterface;
        return (acc += val.status !== 'CANCELADO' ? order.grand_total : 0);
      }, 0),
    );

  return (
    <>
      <ListSubheader key={`subheader-${header.date}`}>
        <Grid container spacing={3}>
          <Grid item xs>
            <Typography align="left">{header.date}</Typography>
          </Grid>
          <Grid item xs>
            <Typography align="right">
              {totalRevenueByDay(header.orders)}
            </Typography>
          </Grid>
        </Grid>
      </ListSubheader>
      {children}
    </>
  );
};

export default SalesSubHeader;
