import { OckpayPrismicTypesEnum } from './OckpayPrismicInterfaces';

export type PrismicValueType = OckpayPrismicTypesEnum;
export type PrismicPathType = PrismicPathEnum;
export type PrismicFragmentType = string;

export enum PrismicPathEnum {
  document = 'document.type',
}

export interface PrismicPredicateAt {
  path: PrismicPathType | string;
  value: PrismicValueType | string | number | boolean | Date;
}

export interface PrismicPredicateNot {
  path: PrismicPathType;
  value: PrismicValueType | string | number | boolean | Date;
}

export interface PrismicPredicateMissing {
  fragment: PrismicFragmentType;
}

export interface PrismicPredicateHas {
  fragment: PrismicFragmentType;
}

export interface PrismicPredicateAny {
  path: OckpayPrismicTypesEnum;
  fieldKey: string;
  values: (string | number | boolean | Date)[];
}

export interface PrismicPredicateIn {
  fragment: PrismicFragmentType;
  values: string[];
}

export interface PrismicPredicateFulltext {
  fragment: PrismicFragmentType;
  value: string;
}

export interface PrismicPredicateSimilar {
  documentId: string;
  maxResults: number;
}

export interface PrismicPredicatesInterface {
  at: (PrismicPredicateAt) => string;
  not: (PrismicPredicateNot) => string;
  missing: (PrismicPredicateMissing) => string;
  has: (PrismicPredicateHas) => string;
  any: (PrismicPredicateAny) => string;
  in: (PrismicPredicateIn) => string;
  fulltext: (PrismicPredicateFulltext) => string;
  similar: (PrismicPredicateSimilar) => string;
  date: {
    before(fragment: string, before: string | number | boolean | Date): string;
    after(fragment: string, after: string | number | boolean | Date): string;
    between(
      fragment: string,
      before: string | number | boolean | Date,
      after: string | number | boolean | Date,
    ): string;
    dayOfMonth(fragment: string, day: number): string;
    dayOfMonthAfter(fragment: string, day: number): string;
    dayOfMonthBefore(fragment: string, day: number): string;
    dayOfWeek(fragment: string, day: string | number): string;
    dayOfWeekAfter(fragment: string, day: string | number): string;
    dayOfWeekBefore(fragment: string, day: string | number): string;
    month(fragment: string, month: string | number): string;
    monthBefore(fragment: string, month: string | number): string;
    monthAfter(fragment: string, month: string | number): string;
    year(fragment: string, year: number): string;
    hour(fragment: string, hour: number): string;
    hourBefore(fragment: string, hour: number): string;
    hourAfter(fragment: string, hour: number): string;
  };
  dateBefore: (fragment: string, before: string | number | boolean | Date) => string;
  dateAfter: (fragment: string, after: string | number | boolean | Date) => string;
  dateBetween: (
    fragment: string,
    before: string | number | boolean | Date,
    after: string | number | boolean | Date,
  ) => string;
  dayOfMonth: (fragment: string, day: number) => string;
  dayOfMonthAfter: (fragment: string, day: number) => string;
  dayOfMonthBefore: (fragment: string, day: number) => string;
  dayOfWeek: (fragment: string, day: string | number) => string;
  dayOfWeekAfter: (fragment: string, day: string | number) => string;
  dayOfWeekBefore: (fragment: string, day: string | number) => string;
  month: (fragment: string, month: string | number) => string;
  monthBefore: (fragment: string, month: string | number) => string;
  monthAfter: (fragment: string, month: string | number) => string;
  year: (fragment: string, year: number) => string;
  hour: (fragment: string, hour: number) => string;
  hourBefore: (fragment: string, hour: number) => string;
  hourAfter: (fragment: string, hour: number) => string;
  number: {
    gt(fragment: string, value: number): string;
    lt(fragment: string, value: number): string;
    inRange(fragment: string, before: number, after: number): string;
  };
  gt: (fragment: string, value: number) => string;
  lt: (fragment: string, value: number) => string;
  inRange: (fragment: string, before: number, after: number) => string;
  near: (fragment: string, latitude: number, longitude: number, radius: number) => string;
  geopoint: {
    near(fragment: string, latitude: number, longitude: number, radius: number): string;
  };
}

export interface PrismicResultsInterface<T> {
  id: string;
  uid: null;
  type: string;
  href: string;
  tags: any[];
  first_publication_date: string;
  last_publication_date: string;
  slugs: string[];
  linked_documents: any[];
  lang: string;
  alternate_languages: any[];
  data: T;
}

export interface PrismicResponseInterface<T> {
  page: number;
  results_per_page: number;
  results_size: number;
  total_results_size: number;
  total_pages: number;
  next_page: null;
  prev_page: null;
  results: PrismicResultsInterface<T>[];
  firstResult: PrismicResultsInterface<T>;
  version: string;
  license: string;
}

export interface PrismicBodyInterface {
  slice_type: string;
  slice_label: null;
  items: PrismicItemInterface[];
}

export enum PrismicTypesEnum {
  heading1 = 'heading1',
  heading2 = 'heading2',
  heading3 = 'heading3',
  heading4 = 'heading4',
  heading5 = 'heading5',
  heading6 = 'heading6',
  paragraph = 'paragraph',
  list = 'list',
  o = 'o',
  image = 'image',
  embed = 'embed',
  preformatted = 'preformatted',
  strong = 'strong',
  em = 'em',
  hyperlink = 'hyperlink',
  label = 'label',
}

export enum PrismicSpanTypeEnum {
  hyperlink = 'hyperlink',
  strong = 'strong',
}

export interface SpanData {
  link_type: string;
  url: string;
  target?: string;
}

export interface Span {
  start: number;
  end: number;
  type: PrismicSpanTypeEnum;
  data?: SpanData;
}

export interface PrismicFormattedElementInterface {
  type: PrismicTypesEnum;
  text: string;
  url?: string;
  alt?: string;
  spans: Span[];
}

export interface PrismicSpanInterface {
  start: number;
  end: number;
  type: PrismicSpanTypeEnum;
  data?: SpanData;
}

export interface PrismicItemInterface {}
