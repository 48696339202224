import { axiosPUT } from './axiosService';

/**
 * Calls a function to reset the user password
 *
 * @param {*} newPassword The new password to be changed on backend
 * @param {*} endpoint Complete URL to call the request
 * @param {*} token Authentication token from logged user
 */
export async function resetPassword(newPassword, endpoint, token) {
  const body = { password: newPassword };
  const response = await axiosPUT(endpoint, body, token);

  return response;
}
