import React, { useContext, useLayoutEffect } from 'react';
import { AppBarContext } from '@project/components/src/contexts/AppBarContext';
import CouponPage from './CouponPage';
import { Grid, Fab } from '@project/components';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

interface CouponRootPageInterface {}

const CouponRootPage: React.FC<CouponRootPageInterface> = () => {
  const { setPageTitle } = useContext(AppBarContext);
  const history = useHistory();

  useLayoutEffect(() => {
    setPageTitle('Cupons');
  }, [setPageTitle]);

  const handleNewCouponClick = (): void => history.push('/cupom/novo');

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CouponPage />
        </Grid>

        <Fab
          color="secondary"
          variant="extended"
          aria-label="Novo cupom"
          onClick={handleNewCouponClick}
        >
          <Add />
          Novo cupom
        </Fab>
      </Grid>
    </>
  );
};

export default CouponRootPage;
