import {
  axiosPUT,
  CustomAxiosResponse,
  Token,
  VariantEnum,
} from '@project/components-typescript/src/services/axiosService';
import { ConfigInterface } from '@project/components-typescript/src/interfaces/ConfigInterface';
import { OptionalEstablishmentSettingsInterface } from '../../interfaces/EstablishmentSettingsInterface';

export type SettingsPayload = ConfigInterface;

export const updateSettings = async <T>(
  settings: OptionalEstablishmentSettingsInterface,
  token: Token,
): Promise<CustomAxiosResponse<T>> => {
  try {
    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/settings`;

    const response = await axiosPUT<T>(endpoint, settings, token);

    return {
      ...response,
      message: 'Sucesso!',
      variant: VariantEnum.success,
      data: {
        ...response.data,
        ...settings,
      },
    };
  } catch (error) {
    throw new Error('Tivemos um problema ao atualizar o seu estabelecimento');
  }
};

export default updateSettings;
