import firebase from 'firebase/app';
import { CreditcardBandeiras } from '@project/components-typescript/src/interfaces/CreditcardTypes';
import { PossibleSocialMediaEnum } from '@project/components-typescript/src/interfaces/Social/PossibleSocialMediaInterface';

export type ShowSpeedDialMenuType = boolean;

export type ShowOtherOptionsItemCrudType = boolean;

export enum layoutTelaPedidosKanbanEnum {
  new = 'new',
  old = 'old',
}

export type AvailableSocialMediaInterface = SocialMediaInterface[];
export interface SocialMediaInterface {
  slug: PossibleSocialMediaEnum;
  label: string;
  svg: string | null;
  placeholder?: string;
}

export type AvailableCreditCardInterface = CreditCardInterface[];
export interface CreditCardInterface {
  flag: CreditcardBandeiras;
  label: string;
  svg: string | null;
}

export interface RemoteConfigInterface {
  showSpeedDialMenu: ShowSpeedDialMenuType;
  layoutTelaPedidosKanban: layoutTelaPedidosKanbanEnum;
  showOtherOptionsItemCrud: ShowOtherOptionsItemCrudType;
  availableSocialMedia: AvailableSocialMediaInterface;
  availableCreditCards: AvailableCreditCardInterface;
}

export interface GlobalStateContextInterface {
  remoteConfig: RemoteConfigInterface;
}

export interface FirebaseRemoteConfigProviderInterface {
  remoteConfig: firebase.remoteConfig.RemoteConfig;
}

export enum RemoteConfigKeys {
  showSpeedDialMenu = 'showSpeedDialMenu',
  showOtherOptionsItemCrud = 'showOtherOptionsItemCrud',
  availableSocialMedia = 'availableSocialMedia',
  availableCreditCards = 'availableCreditCards',
  layoutTelaPedidosKanban = 'layoutTelaPedidosKanban',
}
