import { Token, CustomAxiosResponse, axiosGET, VariantEnum } from '@project/components-typescript/src/services/axiosService';
import { getHours, getMinutes } from 'date-fns';

export interface OrdersReportInterface {
  length: number;
  order_number:    number;
  link:            string;
  email:           string;
  client_name:     string;
  phone:           string;
  status:          Status;
  modality:        Modality;
  freight:         number;
  payment_method:  PaymentMethod;
  payment_date:    string;
  installment:     number;
  fee:             string;
  total:           number;
  receive_value:   number;
  date_to_release: string;
  items:           ItemInterface[];
}

interface ItemInterface {
  name:     string;
  price:    number;
  quantity: number;
}

enum Modality {
  Delivery = "delivery",
}

enum PaymentMethod {
  Cc = "CC",
}

enum Status {
  EmPreparo = "EM PREPARO",
  Entregue = "ENTREGUE",
}

export interface GetOrdersReportConfigInterface {
  dateAt: string;
  dateTo: string;
  page?: string;
  limit?: string;
  format?: 'CSV';
}

export const getOrdersReport = async (
  token: Token, 
  config: GetOrdersReportConfigInterface,
): Promise<CustomAxiosResponse<OrdersReportInterface[]>> => {
  try {
    const actualDate = new Date();
    const dateAt = config.dateAt += ' 00:00:01'
    const dateTo = config.dateTo += ` ${getHours(actualDate)}:${getMinutes(actualDate)}:00`

    const obj: Record<string, string> = JSON.parse(JSON.stringify({
      ...config,
      dateAt,
      dateTo
    }))
    const params = new URLSearchParams(obj).toString()
    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/establishment/orders/report?${params}`;

    const response = await axiosGET<OrdersReportInterface[]>(endpoint, token);

    return {
      ...response,
      message: 'Pronto!',
      variant: VariantEnum.success
    };
  } catch (error) {
    throw new Error(error?.message ? error.message : "Ocorreu um erro, tente novamente.");
  }
};