import { CircularProgress as MaterialCircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const CircularProgress = ({ size, ...props }) => {
  return <MaterialCircularProgress size={size} {...props} />;
};

CircularProgress.defaultProps = {
  size: 24,
  props: {},
};

CircularProgress.propTypes = {
  size: PropTypes.number,
  props: PropTypes.node,
};

export default CircularProgress;
