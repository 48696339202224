import React from 'react';
import PropTypes from 'prop-types';

import { Card as MaterialCard } from '@material-ui/core';

const Card = ({ ...props }) => {
  return <MaterialCard {...props} />;
};

Card.propTypes = {
  props: PropTypes.node,
};

export default Card;
