import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import Box from '../Box';
import { memo } from 'react';
import Paper from '../Paper';

const CustomBox = styled(Box)`
  /* height: calc(100vh - 168px); */
`;

const OverlayDiv = ({
  children,
  showOverlay,
  showPaper,
  shape,
  mt,
  elevation,
  ...props
}) => {
  const GlobalStyle = createGlobalStyle`
    body {
      background: linear-gradient(180deg, #383d6b 20%, #FFFFFF 20%);
      background-repeat: no-repeat;
      background-attachment: fixed;
      height: 100%;
    }
  `;
  return (
    <>
      <GlobalStyle />
      <Box data-testid="overlayDiv-box" mt={mt ? mt : 0} {...props}>
        {showPaper ? (
          <Paper shape={shape} elevation={elevation}>
            <CustomBox p={2}>{children}</CustomBox>
          </Paper>
        ) : (
          children
        )}
      </Box>
    </>
  );
};

OverlayDiv.defaultProps = {
  showOverlay: true,
  showPaper: true,
  shape: 'squareRounded',
  elevation: 0,
};

OverlayDiv.propTypes = {
  children: PropTypes.node.isRequired,
  showOverlay: PropTypes.bool.isRequired,
  showPaper: PropTypes.bool,
  shape: PropTypes.oneOf(['rounded, squareRounded']),
  props: PropTypes.node,
  elevation: PropTypes.number,
};

export default memo(OverlayDiv);
