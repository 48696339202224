import React, { useContext, useEffect } from 'react';
import usePrismicPage, { PrismicPageID } from '../hooks/usePrismicPages';
import { AppBarContext } from '@project/components/src/contexts/AppBarContext';
import FaqPage from './FaqPage';
import FaqProvider from './contexts/FaqContext/FaqContext';
import HubspotChat from '../../components/HubspotChat/HubspotChat';

interface FaqRootPageInterface {
  isPublic: boolean;
}

const FaqRootPage: React.FC<FaqRootPageInterface> = ({ isPublic }) => {
  const { setPageTitle } = useContext(AppBarContext);
  const { data } = usePrismicPage(PrismicPageID.help);

  useEffect(() => {
    if (data?.firstResult) {
      setPageTitle(data?.firstResult.data.nome_da_pagina[0].text);
    }
  }, [data, setPageTitle]);

  return (
    <>
      <HubspotChat />
      <FaqProvider>
        <FaqPage isPublic={isPublic} />
      </FaqProvider>
    </>
  );
};

export default FaqRootPage;
