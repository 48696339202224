import React from 'react';
import { Typography } from '@material-ui/core';
import { PrismicFormattedElementInterface, PrismicTypesEnum } from '../../interfaces/PrismicInterfaces';
import PrismicRenderType from './PrismicRenderType';
import PrismicImage from './components/PrismicImage';

interface PrismicRenderInterface {
  data: PrismicFormattedElementInterface[] | string;
}

const PrismicRender: React.FC<PrismicRenderInterface> = ({ data }) => {
  function renderPrismicList(prismicElement: PrismicFormattedElementInterface): JSX.Element | null {
    const { text, type } = prismicElement;

    switch (type) {
      case PrismicTypesEnum.heading1:
        return (
          <Typography gutterBottom variant="h1" color="primary">
            <PrismicRenderType prismicElement={prismicElement} />
          </Typography>
        );

      case PrismicTypesEnum.heading2:
        return (
          <Typography gutterBottom variant="h2" color="primary">
            <PrismicRenderType prismicElement={prismicElement} />
          </Typography>
        );

      case PrismicTypesEnum.heading3:
        return (
          <Typography gutterBottom variant="h3" color="secondary">
            <PrismicRenderType prismicElement={prismicElement} />
          </Typography>
        );

      case PrismicTypesEnum.heading4:
        return (
          <Typography gutterBottom variant="h4">
            <PrismicRenderType prismicElement={prismicElement} />
          </Typography>
        );

      case PrismicTypesEnum.heading5:
        return (
          <Typography gutterBottom variant="h5">
            <PrismicRenderType prismicElement={prismicElement} />
          </Typography>
        );

      case PrismicTypesEnum.heading6:
        return (
          <Typography gutterBottom variant="h6">
            <PrismicRenderType prismicElement={prismicElement} />
          </Typography>
        );

      case PrismicTypesEnum.paragraph:
        return <PrismicRenderType prismicElement={prismicElement} />;

      case PrismicTypesEnum.list:
        return (
          <Typography variant="body2" component="li">
            <PrismicRenderType prismicElement={prismicElement} />
          </Typography>
        );

      case PrismicTypesEnum.o:
        return (
          <Typography variant="body2" component="li">
            <PrismicRenderType prismicElement={prismicElement} />
          </Typography>
        );

      case PrismicTypesEnum.image:
        return <PrismicImage prismicElement={prismicElement} />;

      case PrismicTypesEnum.embed:
        return <Typography>{text}</Typography>;

      case PrismicTypesEnum.preformatted:
        return <pre>{text}</pre>;

      case PrismicTypesEnum.strong:
        return <PrismicRenderType prismicElement={prismicElement} />;

      case PrismicTypesEnum.em:
        return <em>{text}</em>;

      // case PrismicTypesEnum.hyperlink:
      //   return <a>{text}</a>;

      case PrismicTypesEnum.label:
        return <Typography variant="caption">{text}</Typography>;

      default:
        return null;
    }
  }

  if (typeof data === 'string') {
    return (
      <Typography gutterBottom variant="body2">
        {data}
      </Typography>
    );
  }

  return <>{data.map(prismicElement => renderPrismicList(prismicElement))}</>;
};

export default React.memo(PrismicRender);
