import { Divider as MaterialDivider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { switchProp, ifProp } from 'styled-tools';

const Wrapper = styled.div`
  ${ifProp(
    { variant: 'full' },
    css`
      margin-bottom: 25px;
    `,
  )}
`;

const CustomDivider = styled(MaterialDivider)`
  height: ${({ height }) => height} !important;

  ${switchProp('variant', {
    standard: css``,
    full: css`
      height: 10px !important;
      background-color: rgba(0, 0, 0, 0.06) !important;
      position: absolute;
      left: 0;
      width: 100vw !important;
      overflow: hidden;
      margin: 0;
      padding: 0;
    `,
  })}
`;

const Divider = ({ mt, mb, variant, height, ...props }) => {
  return (
    <Wrapper variant={variant}>
      <CustomDivider height={height} variant={variant} {...props} />
    </Wrapper>
  );
};

Divider.propTypes = {
  height: PropTypes.string,
  mb: PropTypes.number,
  mt: PropTypes.number,
  variant: PropTypes.oneOf(['standard', 'full']),
  props: PropTypes.node,
};

Divider.propTypes = {
  height: '1px',
  mb: 4,
  mt: 0,
  variant: 'standard',
};

export default Divider;
