/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseChecklistInterface } from './../../pages/completeRegistration/services/getChecklist';

export enum SignupStepEnum {
  confirmation1 = 0,
  businessType2 = 1,
  address3 = 2,
  bankAccount4 = 3,
  plans5 = 4,
  publish6 = 5,
}

export interface SignupFormContextInterface {
  checklist: UseChecklistInterface;
  signupStep: SignupStepEnum;
  setSignupStep: React.Dispatch<React.SetStateAction<SignupStepEnum>>;
  responsibleName: string;
  setResponsibleName: React.Dispatch<React.SetStateAction<string>>;
  fantasyName: string;
  setFantasyName: React.Dispatch<React.SetStateAction<string>>;
  averageTicket: string;
  setAverageTicket: React.Dispatch<React.SetStateAction<string>>;
  businessType: string;
  setBusinessType: React.Dispatch<React.SetStateAction<string>>;
  asDigitalProduct: boolean;
  setAsDigitalProduct: React.Dispatch<React.SetStateAction<boolean>>;
  zipCode: string;
  setZipCode: React.Dispatch<React.SetStateAction<string>>;
  street: string;
  setStreet: React.Dispatch<React.SetStateAction<string>>;
  number: string;
  setNumber: React.Dispatch<React.SetStateAction<string>>;
  neighborhood: string;
  setNeighborhood: React.Dispatch<React.SetStateAction<string>>;
  city: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  fullAddress: string;
  setFullAddress: React.Dispatch<React.SetStateAction<string>>;
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  bankName: string;
  setBankName: React.Dispatch<React.SetStateAction<string>>;
  agency: string;
  setAgency: React.Dispatch<React.SetStateAction<string>>;
  accountType: string;
  setAccountType: React.Dispatch<React.SetStateAction<string>>;
  accountNumber: string;
  setAccountNumber: React.Dispatch<React.SetStateAction<string>>;
  lastStep: any;
  setLastStep: React.Dispatch<React.SetStateAction<any>>;
  vat_number: string;
  setCpfCnpj: React.Dispatch<React.SetStateAction<string>>;
  personType: string;
  setPersonType: React.Dispatch<React.SetStateAction<string>>;
  terms: boolean;
  setTerms: React.Dispatch<React.SetStateAction<boolean>>;
  comefrom: any;
  setComefrom: React.Dispatch<React.SetStateAction<any>>;
  isOnRevision: any;
  setIsOnRevision: React.Dispatch<React.SetStateAction<any>>;
  url: any;
  setUrl: React.Dispatch<React.SetStateAction<any>>;
  companyName: string;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  accountDigit: string;
  setAccountDigit: React.Dispatch<React.SetStateAction<string>>;
  resetForm: Function;
}
