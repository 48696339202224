import React from 'react';
import PropTypes from 'prop-types';
import { isMobile, isBrowser } from 'react-device-detect';
import { Container as MaterialContainer } from '@material-ui/core';

const Container = ({
  children,
  onlyMobile = false,
  onlyDesktop = false,
  ...props
}) => {
  if ((onlyMobile && isBrowser) || (onlyDesktop && isMobile)) return children;

  return <MaterialContainer {...props}>{children}</MaterialContainer>;
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(Container);
