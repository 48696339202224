import { useCookies } from 'react-cookie';

const PrivateComponent = ({ children, loggedUser }) => {
  const [cookies] = useCookies(['token']);

  return loggedUser !== undefined && cookies.token !== undefined
    ? children
    : null;
};

export default PrivateComponent;
