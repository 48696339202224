import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';

import { Paper as MaterialPaper } from '@material-ui/core';
import { isBrowser, isMobile } from 'react-device-detect';

const ShapedPaper = styled(MaterialPaper)`
  ${switchProp('shape', {
    rounded: css`
      border-radius: 12px !important;
    `,
  })}
`;

const Paper = ({
  children,
  elevation,
  shape,
  hideMobile = false,
  hideDesktop = false,
  ...props
}) => {
  if ((hideMobile && isMobile) || (hideDesktop && isBrowser)) return children;

  return (
    <>
      <ShapedPaper elevation={elevation} shape={shape} {...props}>
        {children}
      </ShapedPaper>
    </>
  );
};

Paper.defaultProps = {
  elevation: 3,
  shape: 'squareRounded',
};

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  elevation: PropTypes.number,
  shape: PropTypes.oneOf(['squareRounded', 'rounded']),
  hideMobile: PropTypes.bool,
  hideDesktop: PropTypes.bool,
  props: PropTypes.node,
};

export default memo(Paper);
