import { RootRef, Slide } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const SlideAnimation = ({
  children,
  marginBottom = '60px',
  direction = 'up',
  show,
  domRef,
}) => {
  return (
    <Slide
      style={{ marginBottom: marginBottom }} //'60px'
      direction={direction} //'up'
      in={show}
    >
      <RootRef rootRef={domRef}>{children}</RootRef>
    </Slide>
  );
};

SlideAnimation.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.string,
  marginBottom: PropTypes.string,
  show: PropTypes.bool,
  domRef: PropTypes.node.isRequired,
};

SlideAnimation.defaultProps = {
  direction: 'up',
  marginBottom: '60px',
  show: true,
};

export default SlideAnimation;
