import { EditableCell } from '@project/components-typescript/src/components/MUITSDatatable';
import { ExtendableCellInterface } from '@project/components-typescript/src/components/MUITSDatatable/EditableCell';
import { HTMLElementInputTypeEnum } from '@project/components-typescript/src/components/MUITSDatatable/MUITSDatatableInterface';

import React from 'react';
// import { useCookies } from 'react-cookie';

interface StartDateCellInterface extends ExtendableCellInterface {}

const StartDateCell: React.FC<StartDateCellInterface> = ({
  tableMeta,
  updateValue,
  value,
}): JSX.Element => {
  // const [cookies] = useCookies(['token']);
  // const id = tableMeta.rowData[0];

  // eslint-disable-next-line
  const handleUpdate = async (valueToUpdate: string): Promise<void> => {
    //   // editCoupon(id, { start_date: valueToUpdate }, cookies.token);
  };

  return (
    <>
      {value ? (
        <EditableCell
          type={HTMLElementInputTypeEnum.date}
          value={value.toLocale}
          tableMeta={tableMeta}
          updateValue={updateValue}
          handleUpdate={handleUpdate}
          rules={{
            required: 'Descrição obrigatório',
          }}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default StartDateCell;
