import React from 'react';
import MaskedInput from 'react-text-mask';

const NumberWithDigit = ({ inputRef, ...others }) => (
  <MaskedInput
    {...others}
    ref={inputRef}
    mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/]}
    guide={false}
    showMask
    placeholder="9999-D"
  />
);

const NumberWithoutDigit = ({ inputRef, ...others }) => (
  <MaskedInput {...others} ref={inputRef} mask={[/\d/, /\d/, /\d/, /\d/]} guide={false} showMask placeholder="9999" />
);

const FallbackInput = ({ inputRef, ...others }) => (
  <MaskedInput {...others} ref={inputRef} mask={[]} guide={false} showMask placeholder="" />
);

export const AgencyBancoBrasil = props => <NumberWithDigit {...props} />;
export const AgencyBradesco = props => <NumberWithDigit {...props} />;

export const AgencyBancoOriginal = props => <NumberWithoutDigit {...props} />;
export const AgencyBanestes = props => <NumberWithoutDigit {...props} />;
export const AgencyBanrisul = props => <NumberWithoutDigit {...props} />;
export const AgencyBRB = props => <NumberWithoutDigit {...props} />;
export const AgencyCaixa = props => <NumberWithoutDigit {...props} />;
export const AgencyInter = props => <NumberWithoutDigit {...props} />;
export const AgencyItau = props => <NumberWithoutDigit {...props} />;
export const AgencyModal = props => <NumberWithoutDigit {...props} />;
export const AgencyNeon = props => <NumberWithoutDigit {...props} />;
export const AgencyNubank = props => <NumberWithoutDigit {...props} />;
export const AgencyPagSeguro = props => <NumberWithoutDigit {...props} />;
export const AgencySafra = props => <NumberWithoutDigit {...props} />;
export const AgencySantander = props => <NumberWithoutDigit {...props} />;
export const AgencySicoob = props => <NumberWithoutDigit {...props} />;
export const AgencySicredi = props => <NumberWithoutDigit {...props} />;
export const AgencyUnicred = props => <NumberWithoutDigit {...props} />;
export const AgencyViaCredi = props => <NumberWithoutDigit {...props} />;
export const AgencyBSB = props => <NumberWithoutDigit {...props} />;
export const AgencyFallback = props => <FallbackInput {...props} />;
