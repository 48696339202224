import React from 'react';
import PropTypes from 'prop-types';

import { TwitterShareButton, TwitterIcon } from 'react-share';

const ShareButtonTwitter = ({ children, url, title, ...props }) => {
  return (
    <TwitterShareButton url={url} title={title} {...props}>
      <TwitterIcon size="36px" borderRadius="12px" />
    </TwitterShareButton>
  );
};

ShareButtonTwitter.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  props: PropTypes.node,
};

export default ShareButtonTwitter;
