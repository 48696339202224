import '@firebase/messaging';
import firebase from 'firebase/app';

/**
 * TODO: add description
 *
 * @param {firebase messaging} messaging Firebase messaging object
 *
 * @returns {array} [hasPermission, requestPermission, deleteToken, isTokenSentToServer]
 */
export function useFirebaseMessaging(messaging) {
  async function getToken() {
    if ('Notification' in window) {
      return messaging
        .getToken()
        .then(currentToken => currentToken)
        .catch(err => console.error('Error retrieving Instance ID token. ', err));
    }
  }

  async function deleteToken() {
    try {
      const currentToken = await messaging.getToken();
      const response = await messaging.deleteToken(currentToken);

      return response;
    } catch (error) {
      console.error('Unable to delete token. ', error);
    }
  }

  function hasPermission() {
    if ('Notification' in window) {
      return Notification.permission === 'granted';
    }
  }

  function hasBlockedNotifications() {
    if ('Notification' in window) {
      return Notification.permission === 'denied';
    }
  }

  function requestPermission() {
    if ('Notification' in window) {
      Notification.requestPermission()
        .then(permission => permission === 'granted')
        .catch(error => console.error(error));
    }
  }

  function isSupported() {
    return firebase.messaging.isSupported();
  }

  return [deleteToken, hasPermission, requestPermission, getToken, hasBlockedNotifications, isSupported];
}
