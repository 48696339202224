import * as R from 'ramda';

import '@firebase/analytics';
import { parseCurrencyToABNT } from '../utils/Parser';

/**
 * Create the items object for firebase]
 *
 * @param {items} items
 */
export function buildAnalyticsItem(items) {
  try {
    const itemsFlattened = R.flatten([items]);

    const itemOnArray = itemsFlattened.map(item => {
      const formattedPrice = item.price
        ? parseCurrencyToABNT(item.price)
        : undefined;

      return {
        item_brand: item.brand,
        item_category: item.category,
        item_category2: item.category2,
        item_category3: item.category3,
        item_category4: item.category4,
        item_category5: item.category5,
        item_id: item.id,
        item_list_id: item.listId,
        item_list_name: item.listName,
        item_name: item.name,
        item_variant: item.variant,
        item_price: formattedPrice,
        store: item.store,
        affiliation: item.affiliation,
        creative_name: item.creative_name,
        creative_slot: item.creative_slot,
        discount: item.discount,
        index: item.index,
        promotion_id: item.promotion_id,
        quantity: item.quantity,
      };
    });

    return R.reject(value => value === undefined, itemOnArray[0]);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Create the items object for firebase]
 *
 * @param {items} items
 */
export function buildAnalyticsItems(items) {
  try {
    const itemsFlattened = R.flatten([items]);

    const itemOnArray = itemsFlattened.map(item => {
      const formattedPrice = item.price
        ? parseCurrencyToABNT(item.price)
        : undefined;

      return {
        item_brand: item.brand,
        item_category: item.category,
        item_category2: item.category2,
        item_category3: item.category3,
        item_category4: item.category4,
        item_category5: item.category5,
        item_id: item.id,
        item_list_id: item.listId,
        item_list_name: item.listName,
        item_name: item.name,
        item_variant: item.variant,
        item_price: formattedPrice,
        store: item.store,
        affiliation: item.affiliation,
        creative_name: item.creative_name,
        creative_slot: item.creative_slot,
        discount: item.discount,
        index: item.index,
        promotion_id: item.promotion_id,
        quantity: item.quantity,
      };
    });

    return R.reject(value => value === undefined, itemOnArray);
  } catch (error) {
    console.error(error);
  }
}

/**
 * TODO: add description
 *
 * @param {firebase.analytics} analytics Firebase analytics object
 *
 * @returns {array} [hasPermission, requestPermission, deleteToken, isTokenSentToServer]
 */
export function useFirebaseAnalytics(analytics) {
  /**
   * Sends analytics event with given eventParams.
   * This method automatically associates this logged event with this Firebase
   * web app instance on this device.
   *
   * @see https://firebase.google.com/docs/reference/js/firebase.analytics.Analytics#log-event
   *
   * @param {string} eventName
   * @param {*} [eventParams]
   * @param {*} [options]
   */
  function analyticsLogEvent(eventName, eventParams, options) {
    try {
      analytics.logEvent(eventName, { ...eventParams }, { ...options });
    } catch (error) {
      console.error(error);
    }
  }

  function analyticsSetAnalyticsCollectionEnabled() {
    analytics.setAnalyticsCollectionEnabled();
  }

  function analyticsSetCurrentScreen() {
    analytics.setCurrentScreen();
  }

  function analyticsSetUserId() {
    analytics.setUserId();
  }

  /**
   * @typedef {{
   *   brand: String
   *   category: String
   *   category2: String
   *   category3: String
   *   category4: String
   *   category5: String
   *   id: String
   *   listId: String
   *   listName: String
   *   name: String
   *   variant: String
   *   store: String
   *   affiliation: String
   *   creative_name: String
   *   creative_slot: String
   *   discount: String
   *   index: String
   *   promotion_id: String
   *   price: String
   *   quantity: String
   * }[]} items
   */

  /**
   * Quando um usuário adiciona informações de faturamento
   *
   * @see add_payment_info https://developers.google.com/gtagjs/reference/event
   *
   * @param {*} value
   * @param {*} payment_type
   * @param {*} items
   * @param {*} coupon
   * @param {*} currency
   */
  function analyticsAddPaymentInfo(
    value,
    payment_type,
    items,
    coupon,
    currency,
  ) {
    analyticsLogEvent('add_payment_info', {
      value,
      payment_type,
      coupon,
      ...items,
      currency,
    });
  }

  /**
   * Quando um usuário adiciona itens no carrinho
   *
   * @see add_to_cart https://developers.google.com/gtagjs/reference/event
   *
   * @param {integer} value Valor (ou seja, receita) associado ao evento
   * @param {string} [currency=BRL] Moeda da compra no formato ISO_4217 com três letras
   * @param {items} items
   */
  function analyticsAddToCart(value, items, currency = 'BRL') {
    const formattedItems = buildAnalyticsItem(items);

    analyticsLogEvent('add_to_cart', { value, currency, ...formattedItems });
  }

  /**
   * Quando um usuário remove itens do carrinho
   *
   * @see remove_from_cart https://developers.google.com/gtagjs/reference/event
   *
   * @param {integer} value Valor (ou seja, receita) associado ao evento
   * @param {string} [currency=BRL] Moeda da compra no formato ISO_4217 com três letras
   * @param {items} items
   */
  function analyticsRemoveFromCart(value, items, currency = 'BRL') {
    const formattedItems = buildAnalyticsItem(items);

    analyticsLogEvent('remove_from_cart', {
      value,
      currency,
      ...formattedItems,
    });
  }

  /**
   * Quando um usuário adiciona itens à lista de desejos
   *
   * @see add_to_wishlist https://developers.google.com/gtagjs/reference/event
   *
   * @param {integer} value Valor (ou seja, receita) associado ao evento
   * @param {string} [currency=BRL] Moeda da compra no formato ISO_4217 com três letras
   * @param {items} items
   */
  function analyticsAddToWishlist(value, currency = 'BRL', items) {
    const formattedItems = buildAnalyticsItem(items);

    analyticsLogEvent('add_to_wishlist', {
      value,
      currency,
      items: formattedItems,
    });
  }

  /**
   * Quando um usuário inicia a conclusão da compra
   *
   * @see begin_checkout https://developers.google.com/gtagjs/reference/event
   *
   * @param {integer} value Valor (ou seja, receita) associado ao evento
   * @param {string} [currency=BRL] Moeda da compra no formato ISO_4217 com três letras
   * @param {items} items
   * @param {string} coupon Código de cupom de um item à venda
   */
  function analyticsBeginCheckout(value, items, coupon, currency = 'BRL') {
    const formattedItems = buildAnalyticsItem(items);

    analyticsLogEvent('begin_checkout', {
      value,
      currency,
      items: formattedItems,
      coupon,
    });
  }

  /**
   *
   * @param Quando um usuário finaliza Quando uma etapa de conclusão da compra
   *
   * @see checkout_progress https://developers.google.com/gtagjs/reference/event
   *
   * @param {integer} value Valor (ou seja, receita) associado ao evento
   * @param {string} [currency=BRL] Moeda da compra no formato ISO_4217 com três letras
   * @param {items} items
   * @param {string} couponCódigo de cupom de um item à venda
   * @param {integer} checkout_step Usado para eventos de comércio eletrônico - 1,2,3 etc...
   * @param {string} checkout_option Usado para eventos de comércio eletrônico - Visa, MasterCard, etc...
   *
   * @example
   *   gtag('event', 'checkout_progress', {
   *   "checkout_step": 2,
   *   "items": [...]
   *   })
   *
   */
  function analyticsCheckoutProgress(
    value,
    currency = 'BRL',
    items,
    coupon,
    checkout_step,
    checkout_option,
  ) {
    const formattedItems = buildAnalyticsItem(items);

    analyticsLogEvent('checkout_progress', {
      value,
      currency,
      items: formattedItems,
      coupon,
      checkout_step,
      checkout_option,
    });
  }

  /**
   * Quando um erro fatal	ocorre
   *
   * @see exception https://developers.google.com/gtagjs/reference/event
   *
   * @param {*} description
   * @param {*} fatal
   */
  function analyticsException(description, fatal) {
    analyticsLogEvent('exception', { description, fatal });
  }

  /**
   * value, currency = 'BRL', transaction_id
   *
   * @see generate_lead https://developers.google.com/gtagjs/reference/event
   *
   * @param {integer} value Valor (ou seja, receita) associado ao evento
   * @param {string} [currency=BRL] Moeda da compra no formato ISO_4217 com três letras
   * @param {string} transaction_id  Código da transacao
   */
  function analyticsGenerateLead(value, currency = 'BRL', transaction_id) {
    analyticsLogEvent('generate_lead', { value, currency, transaction_id });
  }

  /**
   * Quando um usuário faz login no site
   *
   * @see login https://developers.google.com/gtagjs/reference/event
   *
   * @param {*} method
   */
  function analyticsLogin(method) {
    analyticsLogEvent('login', { method });
  }

  /**
   * Quando um usuário carrega Quando uma página da Web
   *
   * @see page_view https://developers.google.com/gtagjs/reference/event
   *
   */
  function analyticsPageView() {
    analyticsLogEvent('page_view', {});
  }

  /**
   * Quando um usuário conclui Quando uma compra
   *
   * @see purchase https://developers.google.com/gtagjs/reference/event
   *
   * @param {string} transaction_id Codigo da transacao
   * @param {integer} value Valor (ou seja, receita) associado ao evento
   * @param {string} [currency=BRL] Moeda da compra no formato ISO_4217 com três letras
   * @param {moeda} tax Valor do imposto da transação - 2.43
   * @param {moeda} shipping Custo de envio da transacao - 20.12
   * @param {items} items
   * @param {string} couponCódigo de cupom de um item à venda
   */
  function analyticsPurchase(
    transaction_id,
    value,
    tax,
    shipping,
    items,
    coupon,
    currency = 'BRL',
  ) {
    const formattedItems = buildAnalyticsItem(items);

    analyticsLogEvent('purchase', {
      transaction_id,
      value,
      currency,
      tax,
      shipping,
      items: formattedItems,
      coupon,
    });
  }

  /**
   * when a user submits their shipping information
   *
   * @see login https://developers.google.com/gtagjs/reference/event
   *
   * @param {*} method
   */
  function analyticsAddShippingInfo(
    value,
    shipping_tier,
    items,
    coupon,
    currency = 'BRL',
  ) {
    const formattedItems = buildAnalyticsItem(items);

    analyticsLogEvent('add_shipping_info', {
      coupon,
      currency,
      ...formattedItems,
      shipping_tier,
      value,
    });
  }

  /**
   * Quando um produto é reembolsado
   *
   * @see refund https://developers.google.com/gtagjs/reference/event
   *
   * @param {string} transaction_id Codigo da transacao
   * @param {integer} value Valor (ou seja, receita) associado ao evento
   * @param {string} [currency=BRL] Moeda da compra no formato ISO_4217 com três letras
   * @param {moeda} tax Valor do imposto da transação - 2.43
   * @param {moeda} shipping Custo de envio da transacao - 20.12
   * @param {items} items
   */
  function analyticsRefund(
    transaction_id,
    value,
    currency = 'BRL',
    tax,
    shipping,
    items,
  ) {
    const formattedItems = buildAnalyticsItem(items);

    analyticsLogEvent('refund', {
      transaction_id,
      value,
      currency,
      tax,
      shipping,
      items: formattedItems,
    });
  }

  /**
   * Quando um usuário carrega Quando uma nova tela ou Quando um novo conteúdo
   *
   * @see screen_view https://developers.google.com/gtagjs/reference/event
   *
   * @param {*} screen_name
   */
  function analyticsScreenView(screen_name) {
    analyticsLogEvent('screen_view', { screen_name: screen_name });
  }

  /**
   * search_term	Quando um usuário faz Quando uma pesquisa no site
   *
   * @see search https://developers.google.com/gtagjs/reference/event
   *
   * @param {*} search_term
   */
  function analyticsSearch(search_term) {
    analyticsLogEvent('search', { search_term });
  }

  /**
   * Quando um usuário clica em Quando um produto ou no link de Quando um ou mais produtos
   *
   * @see select_content https://developers.google.com/gtagjs/reference/event
   *
   * @param {items} items
   * @param {*} promotions
   * @param {*} content_type Tipo de conteúdo que foi selecionado - "article"
   * @param {*} content_id
   */
  function analyticsSelectContent(items, promotions, content_type, content_id) {
    const formattedItems = buildAnalyticsItem(items);

    analyticsLogEvent('select_content', {
      items: formattedItems,
      promotions,
      content_type,
      content_id,
    });
  }

  /**
   * Quando um usuário seleciona Quando um valor de opção em Quando uma determinada etapa de conclusão da compra
   *
   * @see set_checkout_option https://developers.google.com/gtagjs/reference/event
   *
   * @param {integer} checkout_step Usado para eventos de comércio eletrônico - 1,2,3 etc...
   * @param {string} checkout_option Usado para eventos de comércio eletrônico - Visa, MasterCard, etc...
   *
   * @example
   *   gtag('event', 'set_checkout_option', {
   *   "checkout_step": 2,
   *   "checkout_option": "payment_method",
   *   "value": "paypal"
   *   })
   */
  function analyticsSetCheckoutOption(checkout_step, checkout_option) {
    analyticsLogEvent('set_checkout_option', {
      checkout_step,
      checkout_option,
    });
  }

  /**
   * Quando um usuário compartilha conteúdo
   *
   * @see share https://developers.google.com/gtagjs/reference/event
   *
   * @param {*} method Google, facebook, twitter, etc...
   * @param {string} content_id Content ID (específico do contexto) - 'article-21512'
   * @param {*} content_type Tipo de conteúdo que foi selecionado - "article" - default website
   */
  function analyticsShare(method, content_id, content_type = 'website') {
    analyticsLogEvent('share', {
      method: method,
      content_id: content_id,
      content_type: content_type,
    });
  }

  /**
   * method	Quando um usuário se inscreve usando Quando uma Conta do Google, Quando um endereço de e-mail ou outro método
   *
   * @see sign_up https://developers.google.com/gtagjs/reference/event
   *
   * @param {*} method
   */
  function analyticsSignUp(method, others) {
    analyticsLogEvent('sign_up', { method: method, ...others });
  }

  /**
   * Quando uma atividade cronometrada é concluída
   *
   * @see timing_complete https://developers.google.com/gtagjs/reference/event
   *
   * @param {*} name
   * @param {integer} value Valor (ou seja, receita) associado ao evento
   */
  function analyticsTimingComplete(name, value) {
    analyticsLogEvent('timing_complete', { name, value });
  }

  /**
   * Quando um usuário visualiza detalhes do produto ou item
   *
   * @see view_item https://developers.google.com/gtagjs/reference/event
   *
   * @param {items} item
   */
  function analyticsViewItem(item) {
    const formattedItems = buildAnalyticsItem(item);

    analyticsLogEvent('view_item', { ...formattedItems });
  }

  /**
   * Quando um usuário visualiza Quando uma lista de itens/impressões
   *
   * @see view_item_list https://developers.google.com/gtagjs/reference/event
   *
   * @param {items} items
   */
  function analyticsViewItemList(items) {
    const formattedItems = buildAnalyticsItem(items);

    analyticsLogEvent('view_item_list', { items: formattedItems });
  }

  /**
   * Quando um usuário clica em Quando uma promoção interna
   *
   * @see view_promotion https://developers.google.com/gtagjs/reference/event
   *
   * @param {*} promotions
   */
  function analyticsViewPromotion(promotions) {
    analyticsLogEvent('view_promotion', { promotions });
  }

  /**
   * search_term	Quando um usuário visualiza os resultados da pesquisa
   *
   * @see view_search_results https://developers.google.com/gtagjs/reference/event
   *
   * @param {*} search_term
   */
  function analyticsViewSearchResults(search_term) {
    analyticsLogEvent('view_search_results', { search_term });
  }

  return {
    analyticsLogEvent,
    analyticsSetAnalyticsCollectionEnabled,
    analyticsSetCurrentScreen,
    analyticsSetUserId,
    analyticsAddPaymentInfo,
    analyticsAddToCart,
    analyticsAddToWishlist,
    analyticsBeginCheckout,
    analyticsCheckoutProgress,
    analyticsException,
    analyticsGenerateLead,
    analyticsLogin,
    analyticsPageView,
    analyticsPurchase,
    analyticsRefund,
    analyticsRemoveFromCart,
    analyticsScreenView,
    analyticsSearch,
    analyticsSelectContent,
    analyticsSetCheckoutOption,
    analyticsShare,
    analyticsSignUp,
    analyticsTimingComplete,
    analyticsViewItem,
    analyticsViewItemList,
    analyticsViewPromotion,
    analyticsViewSearchResults,
    analyticsAddShippingInfo,
  };
}
