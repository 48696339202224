import React from 'react';
import PropTypes from 'prop-types';

import { FacebookShareButton, FacebookIcon } from 'react-share';

const ShareButtonFacebook = ({ quote, hashtag, url, ...props }) => {
  return (
    <FacebookShareButton
      url={url}
      title="bla"
      quote={quote}
      hashtag={hashtag}
      {...props}
    >
      <FacebookIcon size="36px" borderRadius="12px" />
    </FacebookShareButton>
  );
};

ShareButtonFacebook.propTypes = {
  quote: PropTypes.string.isRequired,
  hashtag: PropTypes.string.isRequired,
  props: PropTypes.node,
};

export default ShareButtonFacebook;
