export const validEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

export const onlyText = /^[a-zA-Z ]*$/;

export const onlyNumber = /^[0-9]*$/;

export const onlyNumberWithCommaAndDot = /^[0-9]{1,4}([,.][0-9]{1,2})?$/;
export const onlyNumberWithComma = /^[0-9]{1,4}([,][0-9]{1,2})?$/;
export const onlyNumberWithDot = /^[0-9]{1,4}([.][0-9]{1,2})?$/;
export const onlyNumberWithSpace = /^[0-9 ]*$/;

export const phoneWithDDD = /(\(\d{2}\)\s)(\d{4}-\d{4})/;
export const phoneWithoutDDD = /^\d{4}-\d{4}]*$/;
export const cellPhoneWithDDD = /(\(\d{2}\)\s)(\d{5}-\d{4})/;
export const cellPhoneWithoutDDD = /^\d{5}-\d{4}]*$/;
export const whatsappBusinessPhoneWithDDD = /^[(]\d{2}[)][ ][\d*]{8,9}$/;

export const validCEP = /[0-9]{5}-[\d]{3}/;

export const twoDecimalPlaces = (str = '') => str.split[','].length === 2;

export function validCNPJ(s) {
  if (s === undefined) return false;

  let cnpj = s.replace(/[^\d]+/g, '');

  // Valida a quantidade de caracteres
  if (cnpj.length !== 14) return false;

  // Elimina inválidos com todos os caracteres iguais
  if (/^(\d)\1+$/.test(cnpj)) return false;

  // Cáculo de validação
  let t = cnpj.length - 2,
    d = cnpj.substring(t),
    d1 = parseInt(d.charAt(0)),
    d2 = parseInt(d.charAt(1)),
    calc = x => {
      let n = cnpj.substring(0, x),
        y = x - 7,
        s = 0,
        r = 0;

      for (let i = x; i >= 1; i--) {
        s += n.charAt(x - i) * y--;
        if (y < 2) y = 9;
      }

      r = 11 - (s % 11);
      return r > 9 ? 0 : r;
    };

  return calc(t) === d1 && calc(t + 1) === d2;
}

export function validCPF(cpf) {
  if (cpf === undefined) return false;

  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
}
