import React from 'react';
import PropTypes from 'prop-types';

import { Slider as MaterialSlider } from '@material-ui/core';

const defaultMarks = [
  {
    value: 0,
    label: '0',
  },
];

const Slider = ({
  defaultValue = 0,
  step = 0,
  marks = defaultMarks,
  min = 0,
  max = 30,
  ...props
}) => {
  return (
    <MaterialSlider
      defaultValue={defaultValue}
      step={step}
      marks={marks}
      min={min}
      max={max}
      valueLabelDisplay="auto"
      {...props}
    />
  );
};

Slider.propTypes = {
  props: PropTypes.node,
};

export default Slider;
