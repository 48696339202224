import React from 'react';
import PropTypes from 'prop-types';

import { TelegramShareButton, TelegramIcon } from 'react-share';

const ShareButtonTelegram = ({ title, url, ...props }) => {
  return (
    <TelegramShareButton title={title} /*  */ url={url} {...props}>
      <TelegramIcon size="36px" borderRadius="12px" />
    </TelegramShareButton>
  );
};

ShareButtonTelegram.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  via: PropTypes.string,
  hashtags: PropTypes.arrayOf(PropTypes.string),
  related: PropTypes.arrayOf(PropTypes.string),
  props: PropTypes.node,
};

export default ShareButtonTelegram;
