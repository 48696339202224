import React, { useState } from 'react';

import PropTypes from 'prop-types';

export const FeedbackContext = React.createContext(undefined);

const FeedbackProvider = ({ children }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarInformation, setSnackbarInformation] = useState();

  const [showBackdrop, setShowBackdrop] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [dialogInformation, setDialogInformation] = useState();

  const [showAlert, setShowAlert] = useState(false);
  const [alertInformation, setAlertInformation] = useState({});

  const globals = {
    showSnackbar,
    setShowSnackbar,
    snackbarInformation,
    setSnackbarInformation,

    showDialog,
    setShowDialog,
    dialogInformation,
    setDialogInformation,

    showBackdrop,
    setShowBackdrop,

    showAlert,
    setShowAlert,
    alertInformation,
    setAlertInformation,
  };

  return (
    <FeedbackContext.Provider value={globals}>
      {children}
    </FeedbackContext.Provider>
  );
};

FeedbackProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FeedbackProvider;
