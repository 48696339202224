/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect } from 'react';
import { AuthContext } from '@project/components/src/contexts/AuthContextProvider';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { RHFInput } from 'react-hook-form-input';
import { Button, Box } from '@project/components';
import { OneDigit } from '@project/components/src/molecule/MaskedTextField/types/BankAccount';
import { useForm } from 'react-hook-form';
import { WhiteSelectField, WhiteMaskedTextField } from '../components/Fields';
import { bankSelect, bankAccountTypes, bankMask, isAgencySizeNumberCorrectly } from '../mocks/bankAccounts';
import { useCookies } from 'react-cookie';
import { AppBarContext } from '@project/components/src/contexts/AppBarContext';
import { Grid } from '@project/components';
import { useContext } from 'react';
import { useCallback } from 'react';
import { SignupFormContext } from '../../../contexts/SignupFormContext/SignupFormContextProvider';
import { SignupStepEnum } from '../../../contexts/SignupFormContext/SignupFormContextInterface';
import { completeStep4GatewayBank, CompleteStep4GatewayBankInterface } from '../services/completeSteps';
import { VariantEnum } from '@project/components-typescript/src/services/axiosService';

interface FormData extends CompleteStep4GatewayBankInterface {}

const StepBankAccount: React.FC = () => {
  const [cookies] = useCookies(['token']);
  const signupContext = useContext(SignupFormContext);
  const { setPageTitle } = useContext(AppBarContext);
  const { register, setValue, handleSubmit, triggerValidation, errors } = useForm<FormData>();
  const { setShowBackdrop, setSnackbarInformation, setShowSnackbar } = useContext(FeedbackContext);
  const { loggedUser } = useContext(AuthContext);

  const handleAverageTicketChange = e => {
    setValue('averageTicket', e.target.value);
    signupContext?.setAverageTicket(e.target.value);
    triggerValidation();
  };

  const onSubmit = (values: FormData): void => {
    const payload: CompleteStep4GatewayBankInterface = {
      account_type: values.account_type,
      bank: values.bank,
      bank_ag: values.bank_ag,
      bank_cc: values.bank_cc,
      accountDigit: values.accountDigit,
      physical_products: Boolean(values.physical_products),
      price_range: values.price_range,
    };

    setShowBackdrop(true);

    completeStep4GatewayBank(payload, cookies.token, loggedUser)
      .then(async ({ message, variant }) => {
        await signupContext?.checklist.revalidate();
        signupContext?.setSignupStep(SignupStepEnum.plans5);
        setSnackbarInformation({ message, variant });
      })
      .catch(({ message }) => {
        setSnackbarInformation({ message, variant: VariantEnum.error });
      })
      .finally(() => {
        setShowSnackbar(true);
        setShowBackdrop(false);
      });
  };

  useEffect(() => {
    document.title = 'Informações bancárias | Cadastro Ockpay';
    setPageTitle('Informações bancárias');

    setValue('bankName', signupContext?.bankName);
    setValue('agency', signupContext?.agency);
    // eslint-disable-next-line
  }, []);

  const MaskedInput = useCallback(() => {
    if (signupContext?.bankName !== undefined) {
      const response = bankMask.find(({ bank }) => bank === signupContext?.bankName);

      return response?.maskAgency || <></>;
    }
  }, [signupContext])();

  const MaskedInputAccount = useCallback(() => {
    if (signupContext?.bankName !== undefined) {
      const response = bankMask.find(({ bank }) => bank === signupContext?.bankName);

      return response?.maskAccount || <></>;
    }
  }, [signupContext])();

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RHFInput
          as={
            <WhiteSelectField
              autofocus={true}
              title="Banco"
              options={bankSelect}
              error={errors.bank}
              helperText={(errors.bank && `Esse campo é obrigatório`) || 'Clique para selecionar'}
            />
          }
          register={register}
          rules={{ required: true }}
          name="bank"
          setValue={setValue}
          onChange={(e): void => signupContext?.setBankName(e.target.value)}
        />

        {signupContext?.bankName !== undefined && signupContext?.bankName !== '' && (
          <Box mt={1}>
            <RHFInput
              as={
                <WhiteMaskedTextField
                  id="signup-form-input-agency"
                  customType={MaskedInput}
                  type="tel"
                  autofocus={true}
                  label="Agência bancária"
                  error={errors.bank_ag}
                  helperText={(errors.bank_ag && errors.bank_ag.message) || 'Com dígito se houver'}
                />
              }
              register={register}
              rules={{
                required: 'Esse campo é obrigatório',
                validate: {
                  validAgency: agencyString =>
                    isAgencySizeNumberCorrectly(signupContext?.bankName, agencyString.length) ||
                    'Verifique o número da agência',
                },
              }}
              name="bank_ag"
              setValue={setValue}
              onChange={(e): void => signupContext?.setAgency(e.target.value)}
            />
          </Box>
        )}

        <Grid container>
          <Grid item xs={12}>
            <RHFInput
              as={
                <WhiteSelectField
                  autofocus={true}
                  options={bankAccountTypes}
                  title={`Tipo de conta`}
                  error={errors.account_type}
                  helperText={(errors.account_type && `Esse campo é obrigatório`) || 'Clique para selecionar'}
                />
              }
              register={register}
              rules={{ required: true }}
              name="account_type"
              setValue={setValue}
              onChange={(e): void => signupContext?.setAccountType(e.target.value)}
            />
          </Grid>
        </Grid>
        {signupContext?.accountType !== undefined && signupContext?.accountType !== '' && (
          <Grid container>
            <Grid item xs={7}>
              <RHFInput
                as={
                  <WhiteMaskedTextField
                    id="signup-form-input-accountType"
                    customType={MaskedInputAccount}
                    type="tel"
                    autofocus={true}
                    label={`Número da conta`}
                    error={errors.bank_cc}
                    helperText={errors.bank_cc && errors.bank_cc.message}
                  />
                }
                rules={{ required: 'Esse campo é obrigatório' }}
                register={register}
                name="bank_cc"
                setValue={setValue}
                onChange={(e): void => signupContext?.setAccountNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs>
              <RHFInput
                as={
                  <WhiteMaskedTextField
                    id="signup-form-input-accountType"
                    customType={OneDigit}
                    type="tel"
                    autofocus={true}
                    label={`Dígito da conta`}
                    error={errors.accountDigit}
                    helperText={errors.accountDigit && errors.accountDigit.message}
                  />
                }
                register={register}
                rules={{ required: 'Esse campo é obrigatório' }}
                name="accountDigit"
                setValue={setValue}
                onChange={(e): void => signupContext?.setAccountDigit(e.target.value)}
              />
            </Grid>
            <Grid xs={12} item>
              <RHFInput
                as={
                  <WhiteSelectField
                    id="price_range"
                    title="Valor médio de suas vendas"
                    options={[
                      { value: 'Entre R$10 e R$50', label: 'Entre R$10 e R$50' },
                      {
                        value: 'Entre R$50 e R$200',
                        label: 'Entre R$50 e R$200',
                      },
                      {
                        value: 'Entre R$200 e R$1000',
                        label: 'Entre R$200 e R$1000',
                      },
                      { value: 'Acima de R$1000', label: 'Acima de R$1000' },
                    ]}
                    error={errors.price_range}
                    placeholder="Digite ele aqui"
                    helperText={(errors.price_range && `Esse campo é obrigatório`) || 'Clique para selecionar'}
                  />
                }
                register={register}
                rules={{ required: true }}
                name="price_range"
                setValue={setValue}
                onChange={(e): void => handleAverageTicketChange(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <RHFInput
                as={
                  <WhiteSelectField
                    autofocus={true}
                    title="Tipo de produto vendido"
                    options={[
                      { value: false, label: 'Meu produto é físico' },
                      { value: true, label: 'Meu produto é digital' },
                    ]}
                    error={errors.physical_products}
                    helperText={(errors.physical_products && `Esse campo é obrigatório`) || 'Clique para selecionar'}
                  />
                }
                register={register}
                rules={{ required: true }}
                name="physical_products"
                onChange={(event): void => signupContext.setAsDigitalProduct(event.target.value)}
                setValue={setValue}
              />
            </Grid>
          </Grid>
        )}
        <Box mt={2}>
          <Button type="submit" color="primary">
            Confirmar
          </Button>
        </Box>
      </form>
    </>
  );
};

StepBankAccount.propTypes = {
  // TODO declare props
  // propName: propType
  // example: PropTypes.bool
};

export default StepBankAccount;
