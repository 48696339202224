import React, { useContext, useState } from 'react';
import { Button, ListItem, ListItemText, ListItemSecondaryAction } from '@project/components';

import { CustomAxiosResponse, VariantEnum } from '@project/components-typescript/src/services/axiosService';
import { useCookies } from 'react-cookie';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { FreightMethodsInterface } from '../interfaces/FreightMethodsInterface';
import { Add, Remove } from '@material-ui/icons';
import { installFreightMethod, uninstallFreightMethod } from '../services/updateFreightIntegration';
import { FreightContext } from '../contexts/FreightContext';

interface ConfigFreightMethodsInterface {
  method: FreightMethodsInterface;
}

const ConfigFreightMethods: React.FC<ConfigFreightMethodsInterface> = ({ method }) => {
  const { setDialogInformation, setShowDialog, setShowSnackbar, setSnackbarInformation } = useContext(FeedbackContext);
  const [cookies] = useCookies(['token']);
  const [loading, setLoading] = useState<boolean>(false);
  const { dispatchFreight } = useContext(FreightContext);

  const handleConfirm = async (
    serviceCall: () => Promise<CustomAxiosResponse<FreightMethodsInterface>>,
  ): Promise<void> => {
    setLoading(true);
    serviceCall()
      .then(({ message, variant, data }) => {
        dispatchFreight({ type: 'UPDATE_FREIGHT_METHODS', freightMethod: data });
        setSnackbarInformation({ message, variant });
      })
      .catch(({ message }) => setSnackbarInformation({ message, variant: VariantEnum.error }))
      .finally(() => {
        setShowDialog(false);
        setShowSnackbar(true);
        setLoading(false);
      });
  };

  const handleInstallClickButton = (): void => {
    const serviceCall = method.installed
      ? (): Promise<CustomAxiosResponse<FreightMethodsInterface>> => uninstallFreightMethod(method, cookies.token)
      : (): Promise<CustomAxiosResponse<FreightMethodsInterface>> => installFreightMethod(method, cookies.token);

    if (!method.installed) {
      handleConfirm(serviceCall);
      return;
    }

    setShowDialog(true);
    setDialogInformation({
      contentTitle: 'Desinstalação',
      contentText: `Você realmente deseja desinstalar a integração: "${method.label}"?`,
      disagreText: 'Cancelar',
      agreeText: 'Confirmar',
      loading,
      onAgree: () => handleConfirm(serviceCall),
      onDisagree: () => setShowDialog(false),
    });
  };

  return (
    <>
      <ListItem>
        {/* {method.icon && <ListItemIcon>{icon}</ListItemIcon>} */}
        <ListItemText
          style={{ overflowWrap: 'break-word' }}
          primary={method.label}
          secondary={method.installed ? 'Integração instalada' : 'Integração não instalada'}
        />
        <ListItemSecondaryAction>
          <Button
            onClick={handleInstallClickButton}
            variant="outlined"
            color={method.installed ? 'error' : 'primary'}
            size="small"
            startIcon={method.installed ? <Remove /> : <Add />}
          >
            {method.installed ? 'Desinstalar' : 'Instalar'}
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

export default ConfigFreightMethods;
