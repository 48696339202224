// import { servicePOST } from '@project/components/src/services/service';

import { axiosPUT } from '@project/components-typescript/src/services/axiosService';

/**
 * @deprecated
 *
 * @param {*} data
 * @param {*} token
 */
async function createAddress(address, token) {
  const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/address`;

  const response = await axiosPUT(
    endpoint,
    {
      address: address,
    },
    token,
  );

  return response;
}

export default createAddress;
