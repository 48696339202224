import Prismic from '@prismicio/client';
import { useEffect, useState } from 'react';
import {
  PrismicPredicateAny,
  PrismicPredicateAt,
  PrismicPredicateFulltext,
  PrismicPredicateHas,
  PrismicResponseInterface,
} from '../interfaces/PrismicInterfaces';

const ENDPOINT = 'https://ockpay.prismic.io/api/v2';
const TOKEN =
  'MC5YX09HYlJBQUFDTUE2YkNh.HO-_ve-_ve-_vWHvv73vv70kc3Xvv73vv73vv73vv73vv73v' +
  'v73vv71_AWBbZO-_ve-_ve-_vTnvv73vv71j77-977-9XA';

interface UsePrismicInterface<T> {
  at: PrismicPredicateAt[];
  has?: PrismicPredicateHas;
  any?: PrismicPredicateAny;
  fulltext?: PrismicPredicateFulltext;
}

export interface UsePrismicResponseInterface<T> {
  data?: PrismicResponseInterface<T>;
  isValidating: boolean;
}

export interface PrismicPredicatesInterface {}

const Client = Prismic.client(ENDPOINT, { accessToken: TOKEN });

const usePrismic: <T>(props: UsePrismicInterface<T>) => UsePrismicResponseInterface<T> = ({ at, any, fulltext }) => {
  const [data, setData] = useState<PrismicResponseInterface<any> | undefined>(undefined);
  const [isValidating, setIsValidating] = useState<boolean>(true);

  function handlePrismicSuccessful(response: any): void {
    setData({
      ...response,
      firstResult: response.results[0],
    });
  }

  function handlePrismicError(): void {}

  function handlePrismicFinally(): void {
    setIsValidating(false);
  }

  useEffect(() => {
    setIsValidating(true);

    const QueryArr: string[] = [];

    at.forEach(at => QueryArr.push(Prismic.Predicates.at(at.path, at.value)));

    if (any) {
      QueryArr.push(Prismic.Predicates.any(`my.${any.path}.${any.fieldKey}`, any?.values));
    }

    if (fulltext) {
      QueryArr.push(Prismic.Predicates.fulltext(fulltext.fragment, fulltext.value));
    }

    Client.query(QueryArr)
      .then(handlePrismicSuccessful)
      .catch(handlePrismicError)
      .finally(handlePrismicFinally);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulltext?.value]);

  return {
    data,
    isValidating,
  };
};

export default usePrismic;
