import React from 'react';
import PropTypes from 'prop-types';

import { CardContent as MaterialCardContent } from '@material-ui/core';

const CardContent = ({ ...props }) => {
  return <MaterialCardContent {...props} />;
};

CardContent.propTypes = {
  props: PropTypes.node,
};

export default CardContent;
