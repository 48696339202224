import React, { useContext } from 'react';
import FaqAccordion from '../components/FaqAccordion';
import { FaqContext } from '../contexts/FaqContext/FaqContext';

interface FaqListInterface {}

const FaqList: React.FC<FaqListInterface> = () => {
  const [expanded, setExpanded] = React.useState<string | boolean>(false);
  const { stateFaq } = useContext(FaqContext);

  const list =
    stateFaq.list === undefined || stateFaq.list?.results.length === 0
      ? stateFaq.defaultList?.results
      : stateFaq.list?.results;

  return (
    <>
      {list?.map(faq => (
        // <FaqCard key={faq.id} faq={faq} />
        <FaqAccordion expanded={expanded} setExpanded={setExpanded} key={faq.id} faq={faq} />
      ))}
    </>
  );
};

export default FaqList;
