import { TextField as MaterialTextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const NumberField = ({ variant = 'outlined', fullWidth = true, ...props }) => {
  return (
    <MaterialTextField
      variant={variant}
      fullWidth={fullWidth}
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    ></MaterialTextField>
  );
};

NumberField.propTypes = {
  props: PropTypes.node,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default NumberField;
