import { useCallback } from 'react';

const ACTIVATED = 'ativado';
const DESACTIVATED = 'desativado';

const useDelivery = settings => {
  // const {flag_delivery} = settings

  const deliveryStatus = useCallback(() => {
    return settings && settings.flag_delivery ? ACTIVATED : DESACTIVATED;
  }, [settings])();

  const isLimitedByCities = useCallback(() => {
    if (
      deliveryStatus === ACTIVATED &&
      settings &&
      settings.delivery_km_limit === 0
    )
      return true;

    return false;
  }, [deliveryStatus, settings])();

  const isLimitedByKm = useCallback(() => {
    if (
      deliveryStatus === ACTIVATED &&
      settings &&
      settings.delivery_km_limit > 0
    )
      return true;

    return false;
  }, [deliveryStatus, settings])();

  return {
    deliveryStatus,
    isLimitedByCities,
    isLimitedByKm,
  };
};

export default useDelivery;
