/* eslint-disable react/prop-types */
import React, { createContext, useReducer } from 'react';
import { SalesPageContextInterface } from './SalesPageInterface';
import { SalesPageInitialState as salesPageInitialState } from './SalesPageInit';
import SalesPageReducer from './SalesPageReducer';

export const SalesPageContext = createContext<SalesPageContextInterface>({
  salesPage: salesPageInitialState,
});

const SalesPageProvider: React.FC = ({ children }) => {
  const [salesPage, dispatchSalesPage] = useReducer(
    SalesPageReducer,
    salesPageInitialState,
  );

  const globals = {
    salesPage,
    dispatchSalesPage,
  };

  return (
    <SalesPageContext.Provider value={globals}>
      {children}
    </SalesPageContext.Provider>
  );
};

export default SalesPageProvider;
