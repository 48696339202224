import React from 'react';

import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { useLocalStorage } from '@project/components/src/hooks/useLocalStorage';

export const AuthContext = React.createContext(undefined);

const AuthContextProvider = ({ children }) => {
  const [loggedUser, setloggedUser] = useLocalStorage(
    'loggedUser',
    undefined,
    86000,
  );
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  function logout() {
    window.localStorage.removeItem('loggedUser');
    removeCookie('token');
    setloggedUser(undefined);
  }

  const auth = {
    loggedUser,
    setloggedUser,
    logout,
  };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContextProvider;
