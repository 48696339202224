import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useCookies } from 'react-cookie';

const PrivateRoute = ({ children, component, loggedUser, ...props }) => {
  const [cookies] = useCookies(['token']);

  return (
    <Route
      {...props}
      render={({ location }) =>
        loggedUser !== undefined && cookies.token !== undefined ? (
          component || children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
