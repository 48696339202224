import React, { useState } from 'react';

import PropTypes from 'prop-types';

export const BottomNavigationContext = React.createContext(undefined);

const BottomNavigationContextProvider = ({ children }) => {
  const path = window.location.pathname.split('/');
  const [showBottomNavigation, setShowBottomNavigation] = useState(true);
  const [disableMenu, setDisableMenu] = useState(path[1] === undefined);

  const globals = {
    showBottomNavigation,
    setShowBottomNavigation,
    disableMenu,
    setDisableMenu,
  };

  return (
    <BottomNavigationContext.Provider value={globals}>
      {children}
    </BottomNavigationContext.Provider>
  );
};

BottomNavigationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BottomNavigationContextProvider;
