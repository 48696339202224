import { axiosGetCache } from '@project/components-typescript/src/services/axiosCache';
import { BusinessTypeInterface } from '@project/components-typescript/src/interfaces/BusinessTypeInterface';

async function getBusinessType(): Promise<BusinessTypeInterface[] | []> {
  const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/businessType`;

  const response = await axiosGetCache<BusinessTypeInterface[]>(endpoint);

  return response?.data || [];
}

export default getBusinessType;
