import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';
import Chip from '../Chip';

const CustomChip = styled(Chip)`
  height: 24px !important;
  border-radius: 4px !important;
  padding: 4px 0px !important;

  ${switchProp('status', {
    ENTREGUE: css`
      svg {
        color: #007e33 !important;
      }
      color: #007e33 !important;
      border-color: #007e33 !important;
    `,
    CANCELADO: css`
      svg {
        color: #d32f2f !important;
      }
      color: #d32f2f !important;
      border-color: #d32f2f !important;
    `,
    'EM PREPARO': css`
      svg {
        color: #383d6b !important;
      }
      color: #383d6b !important;
      border-color: #383d6b !important;
    `,
    CONCLUIDO: css`
      svg {
        color: #3da46d !important;
      }
      color: #3da46d !important;
      border-color: #3da46d !important;
    `,
  })}
`;

const Status = ({ label, variant = 'outlined', status, size, ...props }) => {
  return (
    <CustomChip
      data-testid="statusComponent"
      variant={variant}
      status={status}
      size={size}
      label={label || status}
      {...props}
    />
  );
};

Status.defaultProp = {
  variant: 'outlined',
  status: 'EM PREPARO',
  label: 'EM PREPARO',
  size: 'small',
};

Status.propTypes = {
  props: PropTypes.node,
  label: PropTypes.string,
  variant: PropTypes.string,
  status: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default Status;
