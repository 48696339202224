import React, { useContext, useEffect } from 'react';
import usePrismic from '../../hooks/usePrismic';
import { OckpayPrismicTypesEnum } from '../../interfaces/OckpayPrismicInterfaces';
import { PrismicPathEnum } from '../../interfaces/PrismicInterfaces';
import { FaqContext } from './contexts/FaqContext/FaqContext';
import { FaqTypesEnum } from './contexts/FaqContext/FaqInterface';
import { PrismicArticlesResponseInterface } from './interfaces/PrismicArticleInterface';
import PublicFaqPage from './pages/PublicFaqPage';
import PrivateFaqPage from './pages/PrivateFaqPage';

interface FaqPageInterface {
  isPublic: boolean;
}

const FaqPage: React.FC<FaqPageInterface> = ({ isPublic }) => {
  const { dispatchFaq } = useContext(FaqContext);

  const { data } = usePrismic<PrismicArticlesResponseInterface>({
    at: [
      {
        path: PrismicPathEnum.document,
        value: OckpayPrismicTypesEnum.article,
      },
      {
        path: `my.${OckpayPrismicTypesEnum.article}.artigo_ativo`,
        value: true,
      },
      {
        path: `my.${OckpayPrismicTypesEnum.article}.duvida_frequente`,
        value: true,
      },
    ],
  });

  useEffect(() => {
    dispatchFaq({ type: FaqTypesEnum.ADD_DEFAULT_FAQ_LIST, list: data });
  }, [data, dispatchFaq]);

  return isPublic ? <PublicFaqPage /> : <PrivateFaqPage />;
};

export default FaqPage;
