import React from 'react';
import PropTypes from 'prop-types';

import {
  Alert as MaterialAlert,
  AlertTitle as MaterialAlertTitle,
} from '@material-ui/lab';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

const CustomMaterialAlert = styled(MaterialAlert)`
  ${ifProp(
    'noBorder',
    css`
      margin-left: -24px !important;
      width: calc(100% + 16px);
    `,
  )}

  ${ifProp(
    'negateTop',
    css`
      margin-top: -13px !important;
    `,
  )}
`;

const Alert = ({
  children,
  title,
  severity,
  icon,
  noBorder,
  negateTop,
  ...props
}) => {
  return (
    <CustomMaterialAlert
      noBorder={noBorder}
      negateTop={negateTop}
      severity={severity}
      icon={icon}
      {...props}
    >
      {title && <MaterialAlertTitle>{title}</MaterialAlertTitle>}
      {children}
    </CustomMaterialAlert>
  );
};

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  icon: PropTypes.any,
  noBorder: PropTypes.bool,
  negateTop: PropTypes.bool,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
};

Alert.defaultProps = {
  severity: 'info',
  noBorder: false,
  negateTop: false,
};

export default Alert;
