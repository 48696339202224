import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, AppBar as MaterialAppBar, Toolbar as MaterialToolbar } from '@material-ui/core';

import Box from '../../atom/Box';
import Grid from '../../atom/Grid';
import Typography from '../../atom/Typography';
import styled, { createGlobalStyle, css } from 'styled-components';
import { ifProp, ifNotProp } from 'styled-tools';
import ResponsiveImage from '../../atom/ResponsiveImage';
import Avatar from '../../atom/Avatar/Avatar';

const CustomText = styled(Typography)`
  font-size: 11pt !important;
  font-weight: 500 !important;
  text-transform: uppercase;
`;

const CapitalizeTypography = styled(Typography)`
  text-transform: capitalize;
`;

const ImageBox = styled(ResponsiveImage)`
  max-height: 20px;
  width: auto;
`;

const SafeBorder = styled.div`
  margin-bottom: 24px;
  ${ifNotProp(
    'overlay',
    css`
      ${ifProp('position', 'margin-bottom: 16px;', 'margin-bottom: 4em;')}
    `,
  )}
`;

const AppBarAvatar = styled(Avatar)`
  width: 75px !important;
  height: 75px !important;
  /* padding: 4px !important; */
  margin: 4px 4px 0 4px;
`;

const AvatarWrapper = styled.div`
  position: absolute !important;
  top: 15px;
  left: 14px;
  background-color: white;
  border-radius: 50%;
`;

const GlobalStyle = createGlobalStyle`
  body {
    ${ifProp(
      { position: 'fixed' },
      css`
        margin-top: 4.2em;
      `,
    )}
  }
`;

const AppBar = ({
  children,
  title,
  position,
  showGoBack,
  rightIcon,
  rightText,
  overlay,
  logoHeader,
  showClientLogo,
  clientLogoUrl,
  backgroundColor,
  customShowGoback,
}) => {
  return (
    <>
      <GlobalStyle position={position} />
      <SafeBorder overlay={overlay} position={position}>
        <MaterialAppBar overlay={overlay} elevation={0} position={position} backgroundColor={backgroundColor}>
          <MaterialToolbar>
            <Grid item align="left" xs={2}>
              {(showGoBack || customShowGoback) && (
                <span data-testid="appbar-goBack">{customShowGoback || children}</span>
              )}
              {showClientLogo && clientLogoUrl && (
                <AvatarWrapper>
                  <AppBarAvatar src={clientLogoUrl} alt="Avatar"></AppBarAvatar>
                </AvatarWrapper>
              )}
            </Grid>
            <Grid item align="center" xs>
              <Box data-testid="appbar-Center">
                {logoHeader ? (
                  <Box pl={8} pr={8} pt={1}>
                    <ImageBox data-testid="appbar-Logo" src={logoHeader} alt="Ockpay logo" />
                  </Box>
                ) : (
                  <CustomText data-testid="appbar-Text" component="h1" align="center">
                    {title}
                  </CustomText>
                )}
              </Box>
            </Grid>
            <Grid item align="center" xs={2}>
              {rightText && (
                <CapitalizeTypography variant="caption" align="right">
                  {rightText}
                </CapitalizeTypography>
              )}
              {rightIcon.icon !== undefined && (
                <IconButton data-testid="appbar-Right" onClick={rightIcon.onClick} color="inherit">
                  {rightIcon.icon}
                </IconButton>
              )}
            </Grid>
          </MaterialToolbar>
        </MaterialAppBar>
      </SafeBorder>
    </>
  );
};

AppBar.propTypes = {
  children: PropTypes.node,
  logoHeader: PropTypes.node,
  overlay: PropTypes.bool,
  position: PropTypes.string,
  rightIcon: PropTypes.bool,
  rightText: PropTypes.node,
  showClientLogo: PropTypes.bool,
  showGoBack: PropTypes.bool,
  showMenuIcon: PropTypes.bool,
  title: PropTypes.string,
};

AppBar.defaultProps = {
  title: '',
  position: 'fixed',
  showGoBack: true,
  logoHeader: false,
  overlay: false,
  rightIcon: false,
  rightText: false,
  showClientLogo: false,
  showMenuIcon: false,
};

export default React.memo(AppBar);
