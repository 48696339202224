import { ADD_LOGGED_USER, ADD_SETTINGS, GlobalContextInterface, UPDATE_SETTINGS } from './GlobalStateInterface';

export const globalStateInitialState: GlobalContextInterface = {
  loggedUser: undefined,
  settings: undefined,
};

export function globalStateActionAddSettingsMethods(
  state: GlobalContextInterface,
  action: ADD_SETTINGS,
): GlobalContextInterface {
  if (!action.settings) return state;

  return {
    ...state,
    settings: { ...action.settings },
  };
}

export function globalStateActionUpdateSettings(
  state: GlobalContextInterface,
  action: UPDATE_SETTINGS,
): GlobalContextInterface {
  if (!action.settings || !state.settings) return state;

  return {
    ...state,
    settings: {
      ...state.settings,
      ...action.settings,
    },
  };
}

export function globalStateActionAddLoggedUserMethods(
  state: GlobalContextInterface,
  action: ADD_LOGGED_USER,
): GlobalContextInterface {
  return {
    ...state,
    loggedUser: action.loggedUser,
  };
}
