import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// TODO: remove from material-ui
import Checkbox from '@material-ui/core/Checkbox';

import { parseCurrencyToABNT } from '@project/components/src/utils/Parser';
import Box from '../../atom/Box';
import Divider from '@project/components/src/atom/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@project/components/src/atom/List';
import ListItem from '@project/components/src/atom/List/ListItem';
import ListItemSecondaryAction from '@project/components/src/atom/List/ListItemSecondaryAction';
import ListItemText from '@project/components/src/atom/List/ListItemText';
import Typography from '../../atom/Typography';
import Grid from '@project/components/src/atom/Grid';
import { DeliveryInformation } from '../../..';

const OrderPreview = ({
  formattedPedido,
  subTotal,
  serviceTax,
  setServiceTax,
  total,
  config,
  totalText,
  blockCheckbox,
  delivery,
  deliveryFee,
  showDelivery,
  showDeliveryInformation,
  deliveryFeeValue,
  installments,
  moreInformation,
}) => {
  const configServiceFee = useCallback(() => {
    return (
      config.hasOwnProperty('service_fee') && config.flag_service_fee === true
    );
  }, [config]);

  const hasInstallment = useCallback(() => installments > 1, [installments]);

  return (
    <>
      <>
        <List dense={true}>
          {formattedPedido &&
            formattedPedido.map(item => {
              const length = item.quantity || item[1].length;
              const name = item.name || item[0];
              const price =
                item.price === 0 || item.price ? item.price : item[1][0].price;

              return (
                <ListItem>
                  <Grid container>
                    <Grid item xs={8}>
                      <ListItemText primary={`${length} x ${name}`} />
                    </Grid>
                    <Grid item xs>
                      <ListItemSecondaryAction>
                        <Typography
                          variant="overline"
                          align="right"
                        >{`${parseCurrencyToABNT(price)}`}</Typography>
                      </ListItemSecondaryAction>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
          <br />
          <ListItem>
            <ListItemText primary={`Subtotal`} />
            <ListItemSecondaryAction>
              <Typography
                variant="overline"
                align="right"
              >{`${parseCurrencyToABNT(subTotal || 0)}`}</Typography>
            </ListItemSecondaryAction>
          </ListItem>

          {showDelivery && (
            <ListItem>
              <ListItemText primary="Taxa de entrega" />
              <ListItemSecondaryAction>
                <Typography
                  variant="overline"
                  align="right"
                >{`${parseCurrencyToABNT(deliveryFeeValue || 0)}`}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
          )}

          {configServiceFee() && (
            <>
              <Box mt={-1.5}>
                <ListItem>
                  <FormControlLabel
                    value="Taxa de serviço*"
                    control={
                      <Checkbox
                        data-testid="checkbox-service-tax"
                        disabled={blockCheckbox}
                        checked={serviceTax}
                        onClick={() => setServiceTax(!serviceTax)}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body2">Taxa de serviço*</Typography>
                    }
                    labelPlacement="right"
                  />
                  <ListItemSecondaryAction>
                    <Typography
                      variant="overline"
                      align="right"
                    >{`${parseCurrencyToABNT(subTotal / 10)}`}</Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              </Box>{' '}
              {configServiceFee && (
                <Box pl={2} pr={2}>
                  <Typography
                    data-testid="typography-text-procon"
                    variant="caption"
                    color="textSecondary"
                  >
                    {`*A taxa de serviço cobrada pelo estabelecimento é 
            opcional. Em caso de dúvidas, procure o PROCON do seu estado.`}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </List>

        {showDelivery && (
          <>
            <Box mt={2} mb={2}>
              {delivery && delivery.address && <Divider />}
            </Box>

            <DeliveryInformation delivery={delivery} />

            <Box mt={2} mb={2}>
              {delivery && delivery.address && <Divider />}
            </Box>
          </>
        )}

        <Box mt={4} mb={4}>
          <Typography
            data-testid="order-preview-total-price"
            variant="h5"
            align="center"
          >
            {parseCurrencyToABNT(total || 0)}
          </Typography>
          <Typography variant="body2" align="center">
            {hasInstallment() ? `Pago em ${installments}x parcelas` : totalText}
          </Typography>
          {moreInformation && (
            <Grid align="center">
              <Box mt={0.5}>
                <Typography variant="caption" align="center">
                  {moreInformation}
                </Typography>
              </Box>
            </Grid>
          )}
        </Box>
      </>
    </>
  );
};

OrderPreview.defaultProps = {
  blockCheckbox: false,
  totalText: 'Total a pagar',
  config: {},
  moreInformation: undefined,
};

OrderPreview.propTypes = {
  moreInformation: PropTypes.string,
  formattedPedido: PropTypes.node.isRequired,
  subTotal: PropTypes.node.isRequired,
  serviceTax: PropTypes.node.isRequired,
  setServiceTax: PropTypes.node.isRequired,
  total: PropTypes.node.isRequired,
  config: PropTypes.object,
  totalText: PropTypes.string,
  blockCheckbox: PropTypes.bool,
};

export default OrderPreview;
