import React from 'react';
import PropTypes from 'prop-types';

import { FormControl as MaterialFormControl } from '@material-ui/core';

const FormControl = ({ children, ...props }) => {
  return <MaterialFormControl {...props}>{children}</MaterialFormControl>;
};

FormControl.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormControl;
