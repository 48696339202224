import React from 'react';
import PropTypes from 'prop-types';

import { ListSubheader as MaterialListSubheader } from '@material-ui/core';

const ListSubheader = ({ ...props }) => {
  return <MaterialListSubheader {...props} />;
};

ListSubheader.propTypes = {
  props: PropTypes.node,
};

export default ListSubheader;
