import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import {
  AppBar,
  Backdrop,
  Container,
  Dialog,
  IconButton,
  PrivateComponent,
  OverlayPage,
} from '@project/components';
import { createGlobalStyle } from 'styled-components';
import Box from '@project/components/src/atom/Box';
import { AppBarContext } from '@project/components/src/contexts/AppBarContext';
import { AuthContext } from '@project/components/src/contexts/AuthContextProvider';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import Snackbar from '@project/components/src/atom/Snackbar';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AppRouter from '../router/';
import { BrowserView, MobileView } from 'react-device-detect';
import { OverlayContext } from '@project/components/src/contexts/OverlayContext';
import SidebarDesktop from './sections/SidebarDesktop';
import GestorBottomNavigation from '../layouts/GestorBottomNavigation';

const GlobalStyle = createGlobalStyle`
::-webkit-scrollbar{
	width: 10px;
}

::-webkit-scrollbar-track-piece{
	background-color: #FFF;
}

::-webkit-scrollbar-thumb{
	background-color: #CBCBCB;
	outline: 2px solid #FFF;
	outline-offset: -2px;
	border: .1px solid #B7B7B7;
}

::-webkit-scrollbar-thumb:hover{
	background-color: #909090;
}
`;

const RootPage = () => {
  const history = useHistory();
  const { showOverlay, overlayHeight } = useContext(OverlayContext);
  const {
    customShowGoback,
    pageTitle,
    showGoBack,
    rightIcon,
    rightText,
    backgroundColor,
    logoHeader,
    showClientLogo,
  } = useContext(AppBarContext);
  const { loggedUser } = useContext(AuthContext);
  const {
    showSnackbar,
    snackbarInformation,
    showDialog,
    dialogInformation,
    showBackdrop,
  } = useContext(FeedbackContext);

  return (
    <>
      <GlobalStyle />
      <PrivateComponent loggedUser={loggedUser}>
        <MobileView>
          <AppBar
            title={pageTitle}
            showGoBack={showGoBack}
            rightIcon={rightIcon}
            rightText={rightText}
            backgroundColor={backgroundColor}
            customShowGoback={customShowGoback}
            logoHeader={logoHeader}
            overlay={false}
            showClientLogo={showClientLogo}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => history.goBack()}
            >
              <ArrowBackIos />
            </IconButton>
          </AppBar>
        </MobileView>
      </PrivateComponent>

      <MobileView>
        <Box mb={4} pb={5}>
          <OverlayPage
            showOverlay={showOverlay}
            overlayHeight={overlayHeight}
          />
          <Container>
            <AppRouter />
          </Container>
        </Box>
      </MobileView>

      <Container>
        <Backdrop open={showBackdrop} />
        <Snackbar open={showSnackbar} {...snackbarInformation} />
        <Dialog open={showDialog} {...dialogInformation} />
      </Container>

      <PrivateComponent loggedUser={loggedUser}>
        <BrowserView>
          <SidebarDesktop title={pageTitle}>
            <Box mb={7}>
              <AppRouter />
            </Box>
          </SidebarDesktop>
        </BrowserView>

        <MobileView>
          <GestorBottomNavigation />
        </MobileView>
      </PrivateComponent>

      <BrowserView>{!loggedUser && <AppRouter />}</BrowserView>
    </>
  );
};

export default RootPage;
