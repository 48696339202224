import React from 'react';
import PropTypes from 'prop-types';

import { useFirebaseAnalytics } from '@project/components/src/hooks/useFirebaseAnalytics';

export const FirebaseAnalyticsContext = React.createContext(undefined);

const FirebaseAnalyticsProvider = ({ children, analytics }) => {
  const {
    analyticsLogEvent,
    analyticsSetAnalyticsCollectionEnabled,
    analyticsSetCurrentScreen,
    analyticsSetUserId,
    analyticsAddPaymentInfo,
    analyticsAddToCart,
    analyticsAddToWishlist,
    analyticsBeginCheckout,
    analyticsCheckoutProgress,
    analyticsException,
    analyticsGenerateLead,
    analyticsLogin,
    analyticsPageView,
    analyticsPurchase,
    analyticsRefund,
    analyticsRemoveFromCart,
    analyticsScreenView,
    analyticsSearch,
    analyticsSelectContent,
    analyticsSetCheckoutOption,
    analyticsShare,
    analyticsSignUp,
    analyticsTimingComplete,
    analyticsViewItem,
    analyticsViewItemList,
    analyticsViewPromotion,
    analyticsViewSearchResults,
    analyticsAddShippingInfo,
  } = useFirebaseAnalytics(analytics);

  const globals = {
    analyticsLogEvent,
    analyticsSetAnalyticsCollectionEnabled,
    analyticsSetCurrentScreen,
    analyticsSetUserId,
    analyticsAddPaymentInfo,
    analyticsAddToCart,
    analyticsAddToWishlist,
    analyticsBeginCheckout,
    analyticsCheckoutProgress,
    analyticsException,
    analyticsGenerateLead,
    analyticsLogin,
    analyticsPageView,
    analyticsPurchase,
    analyticsRefund,
    analyticsRemoveFromCart,
    analyticsScreenView,
    analyticsSearch,
    analyticsSelectContent,
    analyticsSetCheckoutOption,
    analyticsShare,
    analyticsSignUp,
    analyticsTimingComplete,
    analyticsViewItem,
    analyticsViewItemList,
    analyticsViewPromotion,
    analyticsViewSearchResults,
    analyticsAddShippingInfo,
  };

  return (
    <FirebaseAnalyticsContext.Provider value={globals}>
      {children}
    </FirebaseAnalyticsContext.Provider>
  );
};

FirebaseAnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  analytics: PropTypes.any.isRequired,
};

export default FirebaseAnalyticsProvider;
