import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DialogForm from '../../molecule/DialogForm';
import { useForm } from 'react-hook-form';
import { RHFInput } from 'react-hook-form-input';
import { TextField, Grid, Box } from '@material-ui/core';
import { resetPassword } from '../../services/resetPassword';

const ResetPassword = ({
  endpoint,
  onSuccessCallback,
  onErrorCallback,
  open,
  setOpen,
  token,
  logoutFunction,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { register, setValue, handleSubmit, errors, watch } = useForm();
  const watchPassword = watch('password', undefined);

  const handleResetPassword = async data => {
    setIsLoading(true);

    try {
      onSuccessCallback();
      setValue('password', undefined);
      setValue('passwordConfirmation', undefined);

      await resetPassword(data.password, endpoint, token);

      setOpen(false);
      logoutFunction();
    } catch (error) {
      console.error(error);
      onErrorCallback();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DialogForm
      open={open}
      onDisagree={() => setOpen(false)}
      contentTitle="Trocar senha"
      maxWidth="sm"
      handleSubmit={handleSubmit}
      submitMethod={handleResetPassword}
      loading={isLoading}
    >
      <form
        onSubmit={handleSubmit(data => handleResetPassword(data))}
        noValidate
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <RHFInput
              as={<TextField />}
              register={register({ required: true })}
              label="Nova senha"
              name="password"
              setValue={setValue}
              error={errors.password}
              helperText={
                errors && errors.password && `Informe a nova senha desejada`
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mb={2}>
              <RHFInput
                as={<TextField />}
                register={register({
                  required: true,
                  validate: {
                    samePassword: password => password === watchPassword,
                  },
                })}
                label="Confirme a nova senha"
                name="passwordConfirmation"
                setValue={setValue}
                error={errors.passwordConfirmation}
                helperText={
                  errors &&
                  errors.passwordConfirmation &&
                  `A confirmação de senha não confere com a senha digitada`
                }
              />
            </Box>
          </Grid>
        </Grid>
      </form>
    </DialogForm>
  );
};

ResetPassword.propTypes = {
  endpoint: PropTypes.string.isRequired,
  logoutFunction: PropTypes.func.isRequired,
  onErrorCallback: PropTypes.func.isRequired,
  onSuccessCallback: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  token: PropTypes.oneOfType([PropTypes.string, undefined]),
};

ResetPassword.defaultProps = {};

export default React.memo(ResetPassword);
