import { FreightContextInterface } from '../interfaces/FreightContextInterface';
import { FreightIntegrationsInterface } from '../interfaces/FreightIntegrationsInterface';
import { FreightMethodsInterface } from '../interfaces/FreightMethodsInterface';
import {
  freightActionAddFreightMethods,
  freightActionAddFreightIntegrations,
  freightActionSelectFreightIntegrations,
  freightActionUnselectFreightIntegrations,
  freightActionUpdateFreightMethods,
  freightActionUpdateFreightIntegrations,
} from './FreightActions';

export type ADD_FREIGHT_METHODS = {
  type: 'ADD_FREIGHT_METHODS';
  freightMethods: FreightMethodsInterface[];
};

export type ADD_FREIGHT_INTEGRATIONS = {
  type: 'ADD_FREIGHT_INTEGRATIONS';
  freightIntegrations: FreightIntegrationsInterface[];
};

export type UPDATE_FREIGHT_METHODS = {
  type: 'UPDATE_FREIGHT_METHODS';
  freightMethod: FreightMethodsInterface;
};

export type UPDATE_FREIGHT_INTEGRATIONS = {
  type: 'UPDATE_FREIGHT_INTEGRATIONS';
  freightIntegration: FreightIntegrationsInterface;
};

export type SELECT_FREIGHT_INTEGRATIONS = {
  type: 'SELECT_FREIGHT_INTEGRATIONS';
  freightIntegration: FreightIntegrationsInterface;
};

export type UNSELECT_FREIGHT_INTEGRATIONS = {
  type: 'UNSELECT_FREIGHT_INTEGRATIONS';
};

export type FreightAction =
  | ADD_FREIGHT_METHODS
  | ADD_FREIGHT_INTEGRATIONS
  | UPDATE_FREIGHT_METHODS
  | UPDATE_FREIGHT_INTEGRATIONS
  | SELECT_FREIGHT_INTEGRATIONS
  | UNSELECT_FREIGHT_INTEGRATIONS;

function freightReducer(state: FreightContextInterface, action: FreightAction): FreightContextInterface {
  switch (action.type) {
    case 'ADD_FREIGHT_METHODS':
      return freightActionAddFreightMethods(state, action);

    case 'ADD_FREIGHT_INTEGRATIONS':
      return freightActionAddFreightIntegrations(state, action);

    case 'UPDATE_FREIGHT_METHODS':
      return freightActionUpdateFreightMethods(state, action);

    case 'UPDATE_FREIGHT_INTEGRATIONS':
      return freightActionUpdateFreightIntegrations(state, action);

    case 'SELECT_FREIGHT_INTEGRATIONS':
      return freightActionSelectFreightIntegrations(state, action);

    case 'UNSELECT_FREIGHT_INTEGRATIONS':
      return freightActionUnselectFreightIntegrations(state);

    default:
      return state;
  }
}

export default freightReducer;
