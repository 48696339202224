import React, { useContext, useLayoutEffect } from 'react';
import FreightProvider from './contexts/FreightContext';
import FreightConfiguration from './FreightConfiguration';
import { AppBarContext } from '@project/components/src/contexts/AppBarContext';

interface FreightRootPageInterface {}

const FreightRootPage: React.FC<FreightRootPageInterface> = () => {
  const { setPageTitle, setShowGoBack } = useContext(AppBarContext);

  useLayoutEffect(() => {
    setPageTitle('Delivery');
    setShowGoBack(false);
  }, [setPageTitle, setShowGoBack]);

  return (
    <FreightProvider>
      <FreightConfiguration />
    </FreightProvider>
  );
};

export default FreightRootPage;
