import { SignupStepsEnum } from './../interfaces/ChecklistInterface';
import {
  emitSignupError,
  emitSignupSuccess,
} from './signupDiscordNotification';
/* eslint-disable @typescript-eslint/camelcase */
import {
  axiosDeprecatedPOST,
  CustomAxiosResponse,
  Token,
  VariantEnum,
} from '@project/components-typescript/src/services/axiosService';
import { LoggedUserInterface } from '../../../interfaces/LoggedUserInterface';
import createAddress from '../../../services/address/createAddress';
import { updateEstablishment } from '../../../services/information/updateRestaurantByUID';
import { Field } from '@project/components-typescript/src/services/genericDiscordNotification';

const handleError = (
  error,
  loggedUser: LoggedUserInterface,
  step: SignupStepsEnum,
  extraFields?: Field[],
): Error => {
  const errorToUser =
    error.message || 'Ocorreu um erro, revise seus dados e tente novamente.';
  emitSignupError(error, errorToUser, loggedUser, step, extraFields);
  return new Error(errorToUser);
};

const convertDataToDiscordData = (data: Record<string, any>): Field[] =>
  Object.entries(data).map(entry => ({
    name: entry[0].toString(),
    value: entry[1].toString(),
    inline: true,
  }));

export interface CompleteStep1Confirmation {
  cpf_cnpj: string;
  name: string;
  email: string;
}

export const completeStep1Confirmation = async (
  data: CompleteStep1Confirmation,
  token: Token,
  loggedUser: LoggedUserInterface,
): Promise<CustomAxiosResponse<void>> => {
  try {
    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/establishment/gateway/customer`;
    const extraFields = convertDataToDiscordData(data);
    const response = await axiosDeprecatedPOST<void>(endpoint, data, token);

    if (response.status >= 200 && response.status < 300) {
      const customResponse: CustomAxiosResponse<void> = {
        ...response,
        message: 'Dados confirmados com sucesso!',
        variant: VariantEnum.success,
      };

      emitSignupSuccess(
        customResponse,
        loggedUser,
        SignupStepsEnum.step1,
        extraFields,
      );

      return customResponse;
    } else {
      throw handleError(
        response,
        loggedUser,
        SignupStepsEnum.step1,
        extraFields,
      );
    }
  } catch (error) {
    throw handleError(error, loggedUser, SignupStepsEnum.step1);
  }
};

export interface CompleteStep2BusinessTypeInterface {
  id_business_type: number;
}

export const completeStep2BusinessType = async (
  data: CompleteStep2BusinessTypeInterface,
  token: Token,
  loggedUser: LoggedUserInterface,
): Promise<CustomAxiosResponse<void>> => {
  try {
    const extraFields = convertDataToDiscordData(data);
    const response = await updateEstablishment(data, token);

    if (response.status >= 200 && response.status < 300) {
      const customResponse: CustomAxiosResponse<void> = {
        ...response,
        message: 'Tipo de negócio selecionado com sucesso!',
      };

      emitSignupSuccess(
        customResponse,
        loggedUser,
        SignupStepsEnum.step2,
        extraFields,
      );

      return {
        ...response,
        variant: VariantEnum.success,
      };
    } else {
      throw handleError(
        response,
        loggedUser,
        SignupStepsEnum.step2,
        extraFields,
      );
    }
  } catch (error) {
    throw handleError(error, loggedUser, SignupStepsEnum.step2);
  }
};

export const completeStep3Address = async (
  address: string,
  token: Token,
  loggedUser: LoggedUserInterface,
): Promise<CustomAxiosResponse<void>> => {
  const extraFields: Field[] = [
    {
      name: 'Address',
      inline: false,
      value: address,
    },
  ];

  try {
    const response = await createAddress(address, token);

    if (response.status >= 200 && response.status < 300) {
      const customResponse: CustomAxiosResponse<void> = {
        ...response,
        message: 'Endereço selecionado com sucesso!',
      };

      emitSignupSuccess(
        customResponse,
        loggedUser,
        SignupStepsEnum.step3,
        extraFields,
      );

      return {
        ...customResponse,
        variant: VariantEnum.success,
      };
    } else {
      throw handleError(
        response,
        loggedUser,
        SignupStepsEnum.step3,
        extraFields,
      );
    }
  } catch (error) {
    throw handleError(error, loggedUser, SignupStepsEnum.step3, extraFields);
  }
};

export interface CompleteStep4GatewayBankInterface {
  bank: AvailableBanksEnum;
  bank_ag: string;
  accountDigit: string;
  account_type: string;
  bank_cc: string;
  price_range: string;
  physical_products: boolean;
}

enum AvailableBanksEnum {
  BancoDoBrasil = 'Banco do Brasil',
  BancoOriginal = 'Banco Original',
  Banestes = 'Banestes',
  Banrisul = 'Banrisul',
  Bradesco = 'Bradesco',
  brb = 'BRB',
  CaixaEconômica = 'Caixa Econômica',
  Inter = 'Inter',
  Itaú = 'Itaú',
  Modal = 'Modal',
  Neon = 'Neon',
  Nubank = 'Nubank',
  PagSeguro = 'PagSeguro',
  Safra = 'Safra',
  Santander = 'Santander',
  SicoobBancoob = 'Sicoob (Bancoob)',
  Sicredi = 'Sicredi',
  Unicred = 'Unicred',
  ViaCredi = 'Via Credi',
}

export const completeStep4GatewayBank = async (
  data: CompleteStep4GatewayBankInterface,
  token: Token,
  loggedUser: LoggedUserInterface,
): Promise<CustomAxiosResponse<void>> => {
  try {
    const extraFields = convertDataToDiscordData(data);

    const bankCC = `${data.bank_cc}${
      data.bank === AvailableBanksEnum.Sicredi ? '' : '-'
    }${data.accountDigit}`;

    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/establishment/gateway/bank`;

    const payload: CompleteStep4GatewayBankInterface = {
      ...data,
      bank_cc: bankCC,
    };

    const response = await axiosDeprecatedPOST<void>(endpoint, payload, token);

    if (response.status >= 200 && response.status < 300) {
      const customResponse: CustomAxiosResponse<void> = {
        ...response,
        data: undefined,
        message: 'Dados bancários informados com sucesso!',
        variant: VariantEnum.success,
      };

      emitSignupSuccess(
        customResponse,
        loggedUser,
        SignupStepsEnum.step4,
        extraFields,
      );

      return customResponse;
    } else {
      throw handleError(
        response,
        loggedUser,
        SignupStepsEnum.step4,
        extraFields,
      );
    }
  } catch (error) {
    throw handleError(error, loggedUser, SignupStepsEnum.step4);
  }
};

export interface CompleteStep5Plans {
  plan: string;
}

export const completeStep5Plans = async (
  data: CompleteStep5Plans,
  token: Token,
  loggedUser: LoggedUserInterface,
): Promise<CustomAxiosResponse<void>> => {
  try {
    const extraFields = convertDataToDiscordData(data);

    const endpoint = `${process.env.REACT_APP_ESTABLISHMENT_URL}/establishment/gateway/plan`;

    const response = await axiosDeprecatedPOST<void>(endpoint, data, token);

    if (response.status >= 200 && response.status < 300) {
      const customResponse: CustomAxiosResponse<void> = {
        ...response,
        data: undefined,
        message: 'Plano selecionado com sucesso!',
        variant: VariantEnum.success,
      };

      emitSignupSuccess(
        customResponse,
        loggedUser,
        SignupStepsEnum.step5,
        extraFields,
      );

      return customResponse;
    } else {
      throw handleError(
        response,
        loggedUser,
        SignupStepsEnum.step5,
        extraFields,
      );
    }
  } catch (error) {
    throw handleError(error, loggedUser, SignupStepsEnum.step5);
  }
};

export const completeStep6Publish = async (
  token: Token,
  loggedUser: LoggedUserInterface,
): Promise<CustomAxiosResponse<void>> => {
  try {
    const response = await updateEstablishment(
      {
        flag_publish: true,
      },
      token,
    );

    if (response.status >= 200 && response.status < 300) {
      const customResponse: CustomAxiosResponse<void> = {
        ...response,
        data: undefined,
        message: 'Cadastro finalizado! Seja bem vindo!',
        variant: VariantEnum.success,
      };

      emitSignupSuccess(customResponse, loggedUser, SignupStepsEnum.step6, [
        {
          name: 'Publish',
          inline: false,
          value: 'true',
        },
      ]);

      return customResponse;
    } else {
      throw handleError(response, loggedUser, SignupStepsEnum.step6);
    }
  } catch (error) {
    throw handleError(error, loggedUser, SignupStepsEnum.step6);
  }
};
