/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Grid, Typography, Container, IconButton, Box } from '@material-ui/core';
import styled from 'styled-components';
import { Facebook, LinkedIn, Instagram, PinDropOutlined, EmailOutlined } from '@material-ui/icons';
import { isMobile } from 'react-device-detect';
import { useCookies } from 'react-cookie';

interface FooterInterface {}

const OckpayFooter = styled.footer`
  background-color: #383d6b !important;
  padding-top: 48px;
  padding-bottom: 48px;
`;

const Header = styled(Typography)`
  margin-bottom: ${isMobile ? '24px' : '48px'} !important;
  color: #ffffff !important;
  font-weight: bold !important;
`;

const CustomPinDropOutlined = styled(PinDropOutlined)`
  color: #ffffff !important;
`;
const CustomEmailOutlined = styled(EmailOutlined)`
  color: #ffffff !important;
`;

const CustomIconButton = styled(IconButton)`
  color: #383d6b !important;
  background-color: #ffffff !important;
  padding: 6px !important;
  margin-right: 16px !important;
`;

const Sublink = styled.a`
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font: normal normal normal 17px/35px 'Roboto';
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  :not(:hover) {
    text-decoration: none;
  }
  /* margin-bottom: 16px; */
`;

interface FooterHeaderInterface {
  title: string;
}

interface FooterLinkInterface {
  title: string;
  url?: string;
}

const Footer: React.FC<FooterInterface> = () => {
  const [cookies] = useCookies(['app']);

  const FooterHeader: React.FC<FooterHeaderInterface> = ({ title }) => <Header variant="h1">{title}</Header>;

  const FooterLink: React.FC<FooterLinkInterface> = ({ title, url }) => (
    <Sublink target="_blank" href={url || '#'}>
      {title}
    </Sublink>
  );

  const isNativeApp = useMemo(() => cookies['app'] !== undefined, [cookies]);

  return !isNativeApp ? (
    <>
      <OckpayFooter>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <img src="/ockpay-logo-branco-gradient.png" height="38px" width="auto" alt="Ockpay logo" />
              {/* <Store href="https://play.google.com/store/apps/details?id=com.penseapp.ockpay&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            alt="Disponível no Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png"
          />
        </Store> */}
            </Grid>

            <Grid item xs={12} md={3}>
              <FooterHeader title="Nossos links" />

              <FooterLink title="Contato" url="https://site.ockpay.com.br/#contact" />
              <FooterLink title="Venda na Ockpay" url="https://site.ockpay.com.br/#pricing" />
              <FooterLink title="Seja parceiro" url="https://site.ockpay.com.br/#contact" />
              <FooterLink title="Política de privacidade" url="https://site.ockpay.com.br/politica-de-privacidade" />
              <FooterLink title="Termos e condições" url="https://site.ockpay.com.br/termos-e-condicoes" />
            </Grid>

            <Grid item xs={12} md={3}>
              <FooterHeader title="Suporte" />

              <Box display="flex" flexDirection="row" alignItems="center">
                <Box mr={1}>
                  <CustomPinDropOutlined />
                </Box>
                <FooterLink title="Americana/SP" />
              </Box>

              <Box display="flex" flexDirection="row" alignItems="center">
                <Box mr={1}>
                  <CustomEmailOutlined />
                </Box>
                <FooterLink title="contato@ockpay.com.br" />
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box zIndex={0} mb={isMobile ? 8 : 0}>
                <FooterHeader title="Redes sociais" />

                <a href="https://www.facebook.com/ockpay.oficial" rel="noopener noreferrer" target="_blank">
                  <CustomIconButton>
                    <Facebook />
                  </CustomIconButton>
                </a>

                <a href="https://www.instagram.com/ockpay" rel="noopener noreferrer" target="_blank">
                  <CustomIconButton>
                    <Instagram />
                  </CustomIconButton>
                </a>

                <a href="https://www.linkedin.com/company/ockpay" rel="noopener noreferrer" target="_blank">
                  <CustomIconButton>
                    <LinkedIn />
                  </CustomIconButton>
                </a>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </OckpayFooter>
    </>
  ) : null;
};

export default Footer;
