import { Token, CustomAxiosResponse, VariantEnum } from '@project/components-typescript/src/services/axiosService';
import updateSettings from './updateSettings';

export interface UpdateFlagServiceFeeInterface {
  flag_service_fee: boolean;
}

export const updateFlagServiceFee = async (
  flag_service_fee: boolean,
  token: Token,
): Promise<CustomAxiosResponse<UpdateFlagServiceFeeInterface>> => {
  try {
    const response = await await updateSettings<UpdateFlagServiceFeeInterface>(
      {
        flag_service_fee,
      },
      token,
    );

    return {
      ...response,
      variant: VariantEnum.success,
      message: response.data.flag_service_fee
        ? 'Seu estabelecimento está oferecendo 10% de taxa de serviço'
        : 'Seu estabelecimento parou de oferecer a taxa de serviço',
    };
  } catch (error) {
    throw new Error('Tivemos um problema ao atualizar o seu estabelecimento');
  }
};

export default updateFlagServiceFee;
