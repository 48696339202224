import React from 'react';
import PropTypes from 'prop-types';

import { Button as MaterialButton } from '@material-ui/core';
import { switchProp, ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import { ockTheme } from '../../themes';
import { capitalize } from '@project/components/src/utils/Helpers';

const CustomButton = styled(MaterialButton)`
  ${switchProp('shape', {
    rounded: css`
      border-radius: 20px !important;
    `,
    squareRounded: css`
      border-radius: 4px !important;
    `,
    squareMoreRounded: css`
      border-radius: 12px !important;
    `,
  })}

  ${switchProp('textColor', {
    gradient: css`
      background: ${ockTheme.colors.ockGradient} !important;
    `,
    white: css`
      color: #fff !important;
    `,
  })}

${ifProp(
  'textTransform',
  css`
    text-transform: ${({ textTransform }) =>
      textTransform === 'none' ? capitalize(textTransform) : textTransform} !important};
  `,
)}

${ifProp(
  'fontWeight',
  css`
    font-weight: ${({ fontWeight }) => fontWeight} !important};
  `,
)}

${ifProp(
  'backgroundColor',
  css`
    background-color: ${({ backgroundColor }) => backgroundColor} !important};
  `,
)}
`;

const Button = ({
  children,
  shape,
  textColor,
  backgroundColor,
  textTransform = 'none',
  fontWeight,
  color = 'default',
  onClick,
  ...props
}) => {
  return (
    <CustomButton
      shape={shape}
      textColor={textColor}
      backgroundColor={backgroundColor}
      onClick={onClick}
      variant="contained"
      textTransform={textTransform}
      fontWeight={fontWeight}
      color={color}
      {...props}
    >
      {children}
    </CustomButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  props: PropTypes.node,
  onClick: PropTypes.func,
};

export default Button;
