import { addDefaultFaqListAction } from './actions/addDefaultFaqListAction';
import { updateFaqListAction } from './actions/updateFaqListAction';
import { UPDATE_FAQ_LIST, FaqContextInterface, FaqTypesEnum, ADD_DEFAULT_FAQ_LIST } from './FaqInterface';

export type FaqAction = UPDATE_FAQ_LIST | ADD_DEFAULT_FAQ_LIST; // | OTHER_TYPE_GOES_HERE;

function faqReducer(state: FaqContextInterface, action: FaqAction): FaqContextInterface {
  switch (action.type) {
    case FaqTypesEnum.UPDATE_FAQ_LIST:
      return updateFaqListAction(state, action);

    case FaqTypesEnum.ADD_DEFAULT_FAQ_LIST:
      return addDefaultFaqListAction(state, action);

    default:
      return state;
  }
}

export default faqReducer;
