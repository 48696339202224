import { PossibleSocialMediaEnum } from '@project/components-typescript/src/interfaces/Social/PossibleSocialMediaInterface';
import {
  AvailableCreditCardInterface,
  AvailableSocialMediaInterface,
  layoutTelaPedidosKanbanEnum,
  RemoteConfigInterface,
  ShowOtherOptionsItemCrudType,
  ShowSpeedDialMenuType,
} from './FirebaseRemoteConfigInterfaces';

const availableSocialMediaInitialState: AvailableSocialMediaInterface = [
  { slug: PossibleSocialMediaEnum.facebook, label: 'Facebook', svg: null, placeholder: 'Insira o link' },
  { slug: PossibleSocialMediaEnum.instagram, label: 'Instagram', svg: null, placeholder: 'Insira o link' },
  { slug: PossibleSocialMediaEnum.site, label: 'Site', svg: null, placeholder: 'Insira o link' },
  { slug: PossibleSocialMediaEnum.twitter, label: 'Twitter', svg: null, placeholder: 'Insira o link' },
];

const acceptedCreditCardsInitialState: AvailableCreditCardInterface = [
  { flag: 'amex', label: 'Amex', svg: null },
  { flag: 'dankort', label: 'Dankort', svg: null },
  { flag: 'hipercard', label: 'Hipercard', svg: null },
  { flag: 'dinersclub', label: 'Dinersclub', svg: null },
  { flag: 'discover', label: 'Discover', svg: null },
  { flag: 'jcb', label: 'Jcb', svg: null },
  { flag: 'laser', label: 'Laser', svg: null },
  { flag: 'maestro', label: 'Maestro', svg: null },
  { flag: 'mastercard', label: 'Mastercard', svg: null },
  { flag: 'unionpay', label: 'Unionpay', svg: null },
  { flag: 'visaelectron', label: 'Visaelectron', svg: null },
  { flag: 'elo', label: 'Elo', svg: null },
  { flag: 'visa', label: 'Visa', svg: null },
  { flag: 'outros', label: 'Outros', svg: null },
];

const showSpeedDialMenuInitialState: ShowSpeedDialMenuType = true;
const layoutTelaPedidosKanbanInitialState: layoutTelaPedidosKanbanEnum = layoutTelaPedidosKanbanEnum.new;
const showOtherOptionsItemCrudInitialState: ShowOtherOptionsItemCrudType = false;

export const remoteConfigInitialState: RemoteConfigInterface = {
  showSpeedDialMenu: showSpeedDialMenuInitialState,
  layoutTelaPedidosKanban: layoutTelaPedidosKanbanInitialState,
  showOtherOptionsItemCrud: showOtherOptionsItemCrudInitialState,
  availableSocialMedia: availableSocialMediaInitialState,
  availableCreditCards: acceptedCreditCardsInitialState,
};
