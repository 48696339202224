import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { BottomNavigation } from '@project/components';
import { Settings, Receipt } from '@material-ui/icons';
import { AppBarContext } from '@project/components/src/contexts/AppBarContext';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { OrdersContext } from '../pages/orders/OrdersContext/OrdersContext';

interface GestorBottomNavigationInterface {}

const GestorBottomNavigation: React.FC<GestorBottomNavigationInterface> = () => {
  const { showBottomNavigation } = useContext(AppBarContext);
  const { hasNewOrders } = useContext(OrdersContext);
  const history = useHistory();

  return (
    <>
      <BottomNavigation
        show={showBottomNavigation}
        actions={[
          {
            label: 'Menu',
            path: {
              value: 0,
              urls: ['catalogo'],
            },
            icon: <FormatListBulletedIcon />,
            showDot: false,
            onClick: () => history.push('/catalogo'),
          },
          {
            label: 'Pedidos',
            path: {
              value: 1,
              urls: ['pedidos'],
            },
            icon: <Receipt />,
            showDot: hasNewOrders,
            onClick: () => history.push('/pedidos'),
          },
          {
            label: 'Configurações',
            path: {
              value: 2,
              urls: [
                'configuracoes',
                'informacoes',
                'categoria',
                'vendas',
                'notificacoes',
                'ajuda',
              ],
            },
            icon: <Settings />,
            showDot: false,
            onClick: () => history.push('/configuracoes'),
          },
        ]}
      />
    </>
  );
};

export default GestorBottomNavigation;
