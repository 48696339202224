// import { EditableCell } from '@project/components-typescript/src/components/MUITSDatatable';
import { ExtendableCellInterface } from '@project/components-typescript/src/components/MUITSDatatable/EditableCell';
// import { HTMLElementInputTypeEnum } from '@project/components-typescript/src/components/MUITSDatatable/MUITSDatatableInterface';

import React from 'react';
// import { useCookies } from 'react-cookie';
// import { editCoupon } from '../services/editCoupon';

interface CreatedAtCellInterface extends ExtendableCellInterface {}

const CreatedAtCell: React.FC<CreatedAtCellInterface> = ({
  // tableMeta,
  // updateValue,
  value,
}): JSX.Element => {
  // const [cookies] = useCookies(['token']);
  // const id = tableMeta.rowData[0];

  // const handleUpdate = async (valueToUpdate: string): Promise<void> => {
  //   editCoupon(id, { createdAt: valueToUpdate }, cookies.token);
  // };

  return (
    <>
      {new Date(value).toLocaleString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })}
    </>
  );
};

export default CreatedAtCell;
