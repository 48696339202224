import Payment from 'payment';

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`;
      break;
    case 'maestro':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(
        12,
        19,
      )}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(
        12,
        16,
      )}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, number) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (number) {
    const issuer = Payment.fns.cardType(number);
    maxLength = issuer === 'amex' ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(target) {
  const htmlFormatedExpiry = Payment.formatCardExpiry(target);

  return htmlFormatedExpiry.value.split(' ').join('');
}

export function formatName(name) {
  return removeAccents(name);
}

export function formatFormData(data) {
  return Object.keys(data).map(d => `${d}: ${data[d]}`);
}

export function isCardValid(creditCardNumber) {
  return Payment.fns.validateCardNumber(creditCardNumber);
}

export function isCardExpiryValid(creditCardExpiry) {
  const onlyNumbersExpiry = clearNumber(creditCardExpiry);
  const month = onlyNumbersExpiry.substr(0, 2);
  const year = onlyNumbersExpiry.substr(2);

  return Payment.fns.validateCardExpiry(month, year);
}

export function isCardCvcValid(creditCardCvc, creditCardNumber) {
  const cardBrand = getCardType(creditCardNumber);
  return Payment.fns.validateCardCVC(creditCardCvc, cardBrand);
}

export function isValidMonth(creditCardExpiry) {
  const month = parseInt(creditCardExpiry.substr(0, 2));

  if (creditCardExpiry.substr(0, 2).length === 2) {
    return month >= 1 && month <= 12;
  } else {
    return false;
  }
}

export function isValidYear(creditCardExpiry) {
  const year = creditCardExpiry.split('/');
  if (year.length > 1) {
    const numberYear = parseInt(year);
    const YYYY = new Date().getFullYear();
    const YY = new Date()
      .getFullYear()
      .toString()
      .substr(-2);

    return numberYear <= parseInt(YY) || numberYear <= parseInt(YYYY);
  } else {
    return false;
  }
}

export function getCardType(creditCardNumber) {
  return Payment.fns.cardType(creditCardNumber);
}

function removeAccents(str) {
  let accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  let accentsOut = 'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
  str = str.split('');
  str.forEach((letter, index) => {
    let i = accents.indexOf(letter);
    if (i !== -1) {
      str[index] = accentsOut[i];
    }
  });
  return str.join('');
}

export const removeSpacesFromCreditCard = number => number.replace(/\D+/g, '');

export const removeSlashFromDate = strDate => strDate.split('/').join('');

export const getCreditCardExpiryDate = strDate => {
  const removedSlashString = removeSlashFromDate(strDate);

  return removedSlashString.length === 4
    ? removedSlashString.substr(0, 4)
    : `${removedSlashString.substr(0, 2)}${removedSlashString.substr(4, 2)}`;
};

export const convertMoneyToCents = money => Math.round(parseFloat(money) * 100);

export const convertCentsToMoney = cents => parseFloat(cents / 100);
