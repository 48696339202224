export default {
  apiKey: 'AIzaSyD2X_1cSJJrMSH1BKqUoLqDdTR_UH1Q6MQ',
  authDomain: 'ockpay-estabelecimento.firebaseapp.com',
  databaseURL: 'https://ockpay-estabelecimento.firebaseio.com',
  projectId: 'ockpay-estabelecimento',
  storageBucket: 'ockpay-estabelecimento.appspot.com',
  messagingSenderId: '678513311448',
  appId: '1:678513311448:web:8320963ccb3643e838a1ad',
  measurementId: 'G-KT297CQ34Y',
};
