import React, { useContext, useState } from 'react';
import MapsAutocomplete from '@project/components/src/molecule/MapsAutocomplete';
import { AuthContext } from '@project/components/src/contexts/AuthContextProvider';
import { Typography, Box, Button } from '@project/components';
import { useCookies } from 'react-cookie';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { VariantEnum } from '@project/components-typescript/src/services/axiosService';
import { completeStep3Address } from '../services/completeSteps';
import { SignupFormContext } from '../../../contexts/SignupFormContext/SignupFormContextProvider';
import { SignupStepEnum } from '../../../contexts/SignupFormContext/SignupFormContextInterface';

interface Step3GoogleMapsInterface {}

const Step3GoogleMaps: React.FC<Step3GoogleMapsInterface> = () => {
  const [cookies] = useCookies(['token']);
  const [chosenAddress, setChosenAddress] = useState<string | undefined>(undefined);
  const { loggedUser } = useContext(AuthContext);
  const signupContext = useContext(SignupFormContext);
  const { setShowBackdrop, setShowSnackbar, setSnackbarInformation } = useContext(FeedbackContext);

  const onSubmit = (): void => {
    if (!chosenAddress) {
      setSnackbarInformation({ message: 'Você não selecionou nenhum endereço', variant: VariantEnum.warning });
      setShowSnackbar(true);
      return;
    }

    setShowBackdrop(true);

    completeStep3Address(chosenAddress, cookies.token, loggedUser)
      .then(async ({ message, variant }) => {
        await signupContext?.checklist.revalidate();
        signupContext?.setSignupStep(SignupStepEnum.bankAccount4);
        setSnackbarInformation({ message, variant });
      })
      .catch(({ message }) => {
        setSnackbarInformation({ message, variant: VariantEnum.error });
      })
      .finally(() => {
        setShowBackdrop(false);
        setShowSnackbar(true);
      });
  };

  return (
    <>
      <MapsAutocomplete setChosenAddress={setChosenAddress} />
      <Typography variant="body2">Endereço escolhido:</Typography>
      <Typography variant="body1">{chosenAddress || 'Nenhum selecionado'}</Typography>

      <Box mt={4}>
        <Button onClick={onSubmit} type="submit" disabled={!chosenAddress} color="primary">
          Confirmar
        </Button>
      </Box>
    </>
  );
};

export default Step3GoogleMaps;
