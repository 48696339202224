import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Dialog, Grow, Grid } from '@material-ui/core';
import Typography from '../../atom/Typography';
import Avatar from '../../atom/Avatar';
import Box from '../../atom/Box';
import { PhotoCamera, CloseOutlined } from '@material-ui/icons';
import IconButton from '../../atom/IconButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const OverrideMargin = styled.div`
  margin: -16px !important;
`;

const CustomAvatar = styled(Avatar)`
  width: 100% !important;
  height: auto !important;
  margin: auto !important;
`;

const CustomCloseButton = styled(IconButton)`
  color: #252525 !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
`;

const ItemPreview = ({
  image = '',
  title = undefined,
  description = undefined,
  open,
  setOpen,
}) => {
  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        scroll="paper"
        transitionDuration={250}
        TransitionComponent={Transition}
        onBackdropClick={() => setOpen(false)}
      >
        <CustomCloseButton onClick={() => setOpen(false)}>
          <CloseOutlined />
        </CustomCloseButton>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={8}>
            <OverrideMargin>
              <CustomAvatar variant="rounded" src={image}>
                <PhotoCamera fontSize="large" />
              </CustomAvatar>
            </OverrideMargin>
          </Grid>
          {(title !== undefined || description !== undefined) && (
            <Grid item xs={12} sm={6} md={4}>
              <Box mt={2}>
                {title && (
                  <Box mt={2} mb={1}>
                    <Typography variant="body1" align="center">
                      {title}
                    </Typography>
                  </Box>
                )}
                {description && (
                  <Box mt={2} mb={1}>
                    <Typography variant="body2" align="center">
                      {description}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Dialog>
    </>
  );
};

ItemPreview.propTypes = {
  description: PropTypes.any,
  image: PropTypes.string,
  open: PropTypes.any,
  setOpen: PropTypes.func,
  showTitle: PropTypes.any,
  title: PropTypes.any,
  updatedAt: PropTypes.string,
};

export default React.memo(ItemPreview);
