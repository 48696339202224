import React from 'react';
import SignupStep from './components/SignupStep';
import { Grid, Alert } from '@project/components';
import CheckList from './sections/Checklist';
import Step5Plans from './steps/Step5Plans';
import Step6Confirm from './steps/Step6Confirm';
import Step4BankAccount from './steps/Step4BankAccount';
import { SignupStepEnum } from '../../contexts/SignupFormContext/SignupFormContextInterface';
import Step1Confirmation from './steps/Step1Confirmation';
import Step2BusinessType from './steps/Step2BusinessType';
import Step3GoogleMaps from './steps/Step3GoogleMaps';

interface CompleteRegistrationInterface {}

const CompleteRegistration: React.FC<CompleteRegistrationInterface> = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert title="Falta pouco!">
            Precisamos de mais algumas informações para começar sua jornada de sucesso.
          </Alert>
        </Grid>

        <Grid item xs={12} md={8}>
          <SignupStep title="Confirmação" step={SignupStepEnum.confirmation1}>
            <Step1Confirmation />
          </SignupStep>

          <SignupStep title="Tipo de negócio" step={SignupStepEnum.businessType2}>
            <Step2BusinessType />
          </SignupStep>

          <SignupStep title="Endereço" step={SignupStepEnum.address3}>
            <Step3GoogleMaps />
          </SignupStep>

          <SignupStep
            title="Dados bancários"
            subtitle="A conta em que você deseja receber as vendas realizadas com o cartão de crédito online Ockpay."
            step={SignupStepEnum.bankAccount4}
          >
            <Step4BankAccount />
          </SignupStep>

          <SignupStep title="Confirme seu plano" step={SignupStepEnum.plans5}>
            <Step5Plans />
          </SignupStep>

          <SignupStep title="Finalização" step={SignupStepEnum.publish6}>
            <Step6Confirm />
          </SignupStep>
        </Grid>

        <Grid item xs={12} md={4}>
          <CheckList />
        </Grid>
      </Grid>
    </>
  );
};

export default CompleteRegistration;
