/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import {
  InputBase,
  IconButton,
  InputAdornment,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core';
import SearchSVG from './Search.svg';
import { Cancel as MaterialCancel } from '@material-ui/icons';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { ViewContext } from '@project/components-typescript/src/contexts/ViewContext/ViewContext';
import { BusinessTypeInterface } from '@project/components-typescript/src/interfaces/BusinessTypeInterface';
// import EstablishmentInterface from '../../interfaces/EstablishmentInterface';
// import { FirebaseAnalyticsContext } from '../../contexts/Firebase/FirebaseAnalyticsProvider';

// type OnLoadSearchType<T> = Function<T>
type setSuggestionsInterface<T> = React.Dispatch<React.SetStateAction<T | []>>;

export type orderType = 'asc' | 'desc';

export enum ColorSearchEnum {
  transparent = '',
  white = '',
}

export interface PromiseInterface {
  term?: string | undefined;
  businessType?: BusinessTypeInterface | undefined;
  establishment?: any; // TODO: Remove this any
  slug?: string;
  order?: orderType;
}

interface SearchInterface<T> {
  placeholder: string;
  setSuggestions?: setSuggestionsInterface<T>;
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>;
  isLoading?: boolean;
  setIsLoading?: Function;
  promise?: (props: PromiseInterface) => Promise<T | undefined>;
  svg?: string;
  showHelpLabel?: boolean;
  autoFocus?: boolean;
  showClearTextButton?: boolean;
  promiseProps?: PromiseInterface;
  color?: ColorSearchEnum;
  fullWidth: boolean;
  showEndAdornment?: boolean;
  showStartAdornment?: boolean;
  iconStyle?: React.CSSProperties;
}

interface FullWidthInterface {
  fullWidth: boolean;
}

const RoundedDiv = styled.div<FullWidthInterface>`
  width: ${(props: FullWidthInterface): string =>
    props.fullWidth ? '100%' : 'inherit'} !important;

  div {
    border-radius: 18px !important;
  }
`;

const CustomForm = styled.form<FullWidthInterface>`
  width: ${(props: FullWidthInterface): string =>
    props.fullWidth ? '100%' : 'inherit'} !important;

  div {
    border-radius: 18px !important;
  }
`;

interface SearchInputInterface {
  variant: ColorSearchEnum;
}

const CustomLoading = styled(CircularProgress)`
  width: 20px !important;
  height: auto !important;
  padding: 12px !important;
`;

const SearchInput = styled(InputBase)<SearchInputInterface>`
  background-color: ${(props: SearchInputInterface): string =>
    props.variant === ColorSearchEnum.transparent
      ? 'transparent'
      : '#FFFFFF'} !important;

  color: ${(props: SearchInputInterface): string =>
    props.variant === ColorSearchEnum.transparent
      ? '#D4D4D4'
      : '#FF00FF'} !important;

  svg {
    color: ${(props: SearchInputInterface): string =>
      props.variant === ColorSearchEnum.transparent
        ? '#383d6b'
        : '#0000FF'} !important;
  }

  input {
    padding: 8px;
  }
`;

const CustomIconButton = styled(IconButton)`
  .MuiSvgIcon-colorSecondary {
    color: #00b9ad !important;
  }
`;

const KEY_PRESS_TIMEOUT = 1000;

const Search: <T>(props: SearchInterface<T>) => React.ReactElement = ({
  placeholder,
  setSuggestions,
  isLoading,
  setIsLoading,
  setSearchTerm,
  promise,
  showHelpLabel,
  autoFocus,
  svg,
  showClearTextButton = true,
  promiseProps,
  color = ColorSearchEnum.transparent,
  fullWidth = false,
  showEndAdornment = true,
  showStartAdornment = true,
  iconStyle,
}) => {
  // const { analyticsSearch } = useContext(FirebaseAnalyticsContext);
  const { formState, handleSubmit } = useForm();
  const [emptyResponse, setEmptyResponse] = useState<boolean>(false);
  const [localTerm, setLocalTerm] = useState<string>('');
  const [blockReload, setBlockReload] = useState<boolean>(false);
  const [ruleTimeout, setRuleTimeout] = useState<boolean>(false);
  const [keypressDelay, setKeypressDelay] = useState<any | undefined>(
    undefined,
  );
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [touched, setIsTouched] = useState<boolean>(false);
  const { dispatchView } = useContext(ViewContext);

  useEffect(() => {
    if (setSearchTerm) setSearchTerm(localTerm);
  }, [localTerm, setSearchTerm]);

  useEffect(() => {
    if (
      ruleTimeout &&
      !blockReload &&
      setIsLoading &&
      promise &&
      setSuggestions
    ) {
      setEmptyResponse(false);
      setIsLoading(true);
      setIsTyping(false);

      // analyticsSearch && analyticsSearch({ search_term: localTerm });

      promise({ term: localTerm, ...promiseProps })
        .then(response => {
          setSuggestions(response || []);
          Array.isArray(response) && setEmptyResponse(response?.length === 0);
          typeof response === undefined && setEmptyResponse(true);
        })
        .catch((): void => {
          setSuggestions([]);
          dispatchView &&
            dispatchView({
              type: 'SHOW_SNACKBAR',
              snackbar: {
                message:
                  'Não foi possível realizar a sua busca, tente novamente.',
                variant: 'error',
              },
            });
        })
        .finally(() => setIsLoading(false));
    }

    setBlockReload(false);
    // eslint-disable-next-line
  }, [ruleTimeout]);

  useEffect(() => {
    if (emptyResponse && dispatchView) {
      dispatchView({
        type: 'SHOW_SNACKBAR',
        snackbar: {
          message: 'Não encontramos resultados para a sua busca.',
          variant: 'info',
        },
      });
    }
    // eslint-disable-next-line
  }, [emptyResponse]);

  const startTimeout = (): void => {
    setRuleTimeout(false);
    setIsTyping(true);
    clearTimeout(keypressDelay);
    const timeout = setTimeout(() => setRuleTimeout(true), KEY_PRESS_TIMEOUT);
    setKeypressDelay(timeout);
  };

  const handleOnChange = (value: string): void => {
    setLocalTerm(value);
    if (formState.isDirty && value === '' && setSuggestions) setSuggestions([]);
  };

  const handleClearSearch = (): void => {
    setLocalTerm('');
    setSuggestions && setSuggestions([]);
  };

  const onSubmit = (): void => {
    console.log('search submit');
  };

  return (
    <CustomForm onSubmit={handleSubmit(onSubmit)} fullWidth={fullWidth}>
      <RoundedDiv fullWidth={fullWidth}>
        <SearchInput
          onKeyUp={startTimeout}
          onKeyDown={(): void => setIsTouched(true)}
          onChange={(event): void => handleOnChange(event.target.value)}
          placeholder={placeholder}
          value={localTerm}
          name="search"
          color={'primary'}
          variant={color}
          fullWidth={fullWidth}
          autoFocus={autoFocus}
          startAdornment={
            showStartAdornment ? (
              <InputAdornment position="start">
                <img style={iconStyle} src={svg || SearchSVG} alt="Lupa" />
              </InputAdornment>
            ) : null
          }
          endAdornment={
            showEndAdornment ? (
              isLoading ? (
                <CustomLoading size={15} disableShrink />
              ) : showClearTextButton && localTerm !== '' ? (
                <InputAdornment position="end">
                  <CustomIconButton
                    aria-label="Limpar texto"
                    onClick={handleClearSearch}
                    disabled={localTerm === ''}
                  >
                    <MaterialCancel color="secondary" />
                  </CustomIconButton>
                </InputAdornment>
              ) : null
            ) : null
          }
        />
        {showHelpLabel && (
          <>
            {!touched && (
              <FormHelperText>Use a busca acima para procurar!</FormHelperText>
            )}
            {touched && isTyping && !isLoading && (
              <FormHelperText>Continue escrevendo...</FormHelperText>
            )}
            {touched && isTyping && isLoading && (
              <FormHelperText>Procurando sugestões para você!</FormHelperText>
            )}
            {touched && !isTyping && !isLoading && emptyResponse && (
              <FormHelperText>
                Não encontramos nenhum resultado, tente outras palavras!
              </FormHelperText>
            )}
          </>
        )}
      </RoundedDiv>
    </CustomForm>
  );
};

export default Search;
