import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import ConfigSwitch from '../../../../components/ConfigSwitch';
import { FreightIntegrationsInterface } from '../interfaces/FreightIntegrationsInterface';
import { Box, Button } from '@project/components';
import { FreightContext } from '../contexts/FreightContext';
import { updateFreightIntegration } from '../services/updateFreightIntegration';
import { FreightMethodsSlugEnum } from '@project/components-typescript/src/interfaces/FreightInterface';
// import { FreightMethodsSlugEnum } from '../interfaces/FreightMethodsInterface';

interface ConfigFreightIntegrationInterface {
  integration: FreightIntegrationsInterface;
}

const ConfigFreightIntegration: React.FC<ConfigFreightIntegrationInterface> = ({ integration }) => {
  const { stateFreight, dispatchFreight } = useContext(FreightContext);
  const [cookies] = useCookies(['token']);

  const selectedIntegration = stateFreight.freightIntegrations.find(({ id }) => integration.id === id);

  if (!selectedIntegration) return null;

  const handleFreightUpdate = async (): Promise<void> => {
    const { data } = await updateFreightIntegration(
      integration,
      { active: !selectedIntegration.active },
      cookies.token,
    );
    dispatchFreight({ type: 'UPDATE_FREIGHT_INTEGRATIONS', freightIntegration: data });
  };

  const handleConfigFreightIntegration = (): void =>
    dispatchFreight({ type: 'SELECT_FREIGHT_INTEGRATIONS', freightIntegration: integration });

  const isDisabled: boolean =
    selectedIntegration.freight_methods_slug === FreightMethodsSlugEnum.motoboydotcom
      ? selectedIntegration.api_key === null || selectedIntegration.username === null
      : false;

  return (
    <>
      <ConfigSwitch
        disabled={isDisabled}
        checked={selectedIntegration.active}
        onChange={handleFreightUpdate}
        text={integration.freight_methods_label}
        subtitle={
          integration.active ? 'Integração ativa' : isDisabled ? 'Necessita configuração' : 'Integração inativa'
        }
      />
      <Box align="right" mt={-2}>
        <Button onClick={handleConfigFreightIntegration} color="primary" variant="text">
          Configurar
        </Button>
      </Box>
    </>
  );
};

export default ConfigFreightIntegration;
