import React from 'react';
import {
  // EditableCell,
  MUITSDatatable,
} from '@project/components-typescript/src/components/MUITSDatatable';
import { MUIDataTableColumnDef } from 'mui-datatables';
// import { HTMLElementInputTypeEnum } from '@project/components-typescript/src/components/MUITSDatatable/MUITSDatatableInterface';
import {
  CouponInterface,
  CouponKeyEnum,
  CouponLabelEnum,
} from '../interfaces/CouponInterface';
import ActiveCell from '../layouts/ActiveCell';
import CodeCell from '../layouts/CodeCell';
import DescriptionCell from '../layouts/DescriptionCell';
import DiscountCell from '../layouts/DiscountCell';
import QtyUsedCell from '../layouts/QtyUsedCell';
import NameCell from '../layouts/NameCell';
import CreatedAtCell from '../layouts/CreatedAtCell';
import StartDateCell from '../layouts/StartDateCell';
import EndDateCell from '../layouts/EndDateCell';

interface CouponTableInterface {
  coupons: CouponInterface[];
}

const columns: MUIDataTableColumnDef[] = [
  {
    name: CouponKeyEnum.id,
    label: CouponLabelEnum.id,
    options: {
      searchable: false,
      display: false,
    },
  },
  {
    name: CouponKeyEnum.active,
    label: CouponLabelEnum.active,
    options: {
      customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
        <ActiveCell
          value={value}
          tableMeta={tableMeta}
          updateValue={updateValue}
          handleUpdate={async (): Promise<void> => {}}
        />
      ),
    },
  },
  {
    name: CouponKeyEnum.code,
    label: CouponLabelEnum.code,
    options: {
      customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
        <CodeCell
          value={value}
          tableMeta={tableMeta}
          updateValue={updateValue}
        />
      ),
    },
  },
  {
    name: CouponKeyEnum.description,
    label: CouponLabelEnum.description,
    options: {
      customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
        <DescriptionCell
          value={value}
          tableMeta={tableMeta}
          updateValue={updateValue}
        />
      ),
    },
  },
  {
    name: CouponKeyEnum.discount,
    label: CouponLabelEnum.discount,
    options: {
      customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
        <DiscountCell
          value={value}
          tableMeta={tableMeta}
          updateValue={updateValue}
        />
      ),
    },
  },
  {
    name: CouponKeyEnum.name,
    label: CouponLabelEnum.name,
    options: {
      customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
        <NameCell
          value={value}
          tableMeta={tableMeta}
          updateValue={updateValue}
        />
      ),
    },
  },
  {
    name: CouponKeyEnum.startDate,
    label: CouponLabelEnum.startDate,
    options: {
      customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
        <StartDateCell
          value={value}
          tableMeta={tableMeta}
          updateValue={updateValue}
        />
      ),
    },
  },
  {
    name: CouponKeyEnum.endDate,
    label: CouponLabelEnum.endDate,
    options: {
      customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
        <EndDateCell
          value={value}
          tableMeta={tableMeta}
          updateValue={updateValue}
        />
      ),
    },
  },
  {
    name: CouponKeyEnum.limit,
    label: CouponLabelEnum.limit,
    options: {
      customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
        <DiscountCell
          value={value}
          tableMeta={tableMeta}
          updateValue={updateValue}
        />
      ),
    },
  },
  {
    name: CouponKeyEnum.timesUsed,
    label: CouponLabelEnum.timesUsed,
    options: {
      customBodyRender: (value): JSX.Element => <QtyUsedCell value={value} />,
    },
  },
  {
    name: CouponKeyEnum.createdAt,
    label: CouponLabelEnum.createdAt,
    options: {
      display: false,
      customBodyRender: (value, tableMeta, updateValue): JSX.Element => (
        <CreatedAtCell
          value={value}
          tableMeta={tableMeta}
          updateValue={updateValue}
        />
      ),
    },
  },
];

const CouponTable: React.FC<CouponTableInterface> = ({ coupons }) => {
  return (
    <MUITSDatatable
      title="Cupons"
      data={coupons}
      columns={columns}
      options={{}}
    />
  );
};

export default CouponTable;
