import { POPULATE_ORDERS, OrdersContextInterface } from '../OrdersInterface';

export function populateOrdersAction(
  state: OrdersContextInterface,
  action: POPULATE_ORDERS,
): OrdersContextInterface {
  return {
    ...state,
    orders: action.orders.map(order => ({ order, isNew: false })),
  };
}
