import React, { useContext, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Paper, Box, Button } from '@project/components';
import { getOrdersReport } from '../services/getOrdersReport';
import { useCookies } from 'react-cookie';
import { FeedbackContext } from '@project/components/src/contexts/FeedbackContext';
import { CloudDownloadOutlined, DescriptionRounded } from '@material-ui/icons';
import DateRangeSelector from './DateRangeSelector';
import { SalesPageContext } from '../contexts/SalesPageContext';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

interface SalesToolbarInterface {}

const CustomButton = styled(Button)`
  padding: 6px 21px !important;
  margin-top: -1px !important;
`;

const SalesToolbar: React.FC<SalesToolbarInterface> = () => {
  const { salesPage } = useContext(SalesPageContext);
  const [isDownloadCSV, setIsDownloadCSV] = useState<boolean>(false);
  const [cookies] = useCookies(['token']);
  const { setShowSnackbar, setSnackbarInformation } = useContext(
    FeedbackContext,
  );

  function save(filename, data): void {
    const blob = new Blob([data], { type: 'text/csv' });

    try {
      window.navigator.msSaveBlob(blob, filename);
    } catch (error) {
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }

  const downloadCSV = async (): Promise<void> => {
    setIsDownloadCSV(true);
    const { data } = await getOrdersReport(cookies.token, {
      dateAt: salesPage.dateAt,
      dateTo: salesPage.dateTo,
      limit: '10000',
      page: '0',
      format: 'CSV',
    });
    setIsDownloadCSV(false);

    if (typeof data === 'string') save('relatorio-vendas-completo.csv', data);
    else {
      setSnackbarInformation({
        message: 'Não foi possível baixar o CSV, tente novamente.',
        variant: 'error',
      });
      setShowSnackbar(true);
    }
  };

  const ButtonCsvDownload = (): JSX.Element => (
    <CustomButton
      variant="outlined"
      size="large"
      startIcon={
        isMobile ? (
          <CloudDownloadOutlined />
        ) : (
          <DescriptionRounded color="secondary" />
        )
      }
      shape="squareRounded"
      onClick={downloadCSV}
      disabled={isDownloadCSV}
    >
      {isDownloadCSV ? (
        <Box display="flex" alignItems="center" textOverflow="ellipsis">
          Baixando...
          <Box ml={2} display="flex" alignItems="center">
            <CircularProgress color="secondary" size={20} />
          </Box>
        </Box>
      ) : (
        <Box textOverflow="ellipsis" flexWrap="nowrap">
          {isMobile ? 'Download do relatório' : 'Relatório com produtos'}
        </Box>
      )}
    </CustomButton>
  );

  return (
    <Box mb={2}>
      <Paper hideMobile>
        <Box
          mr={1}
          display="flex"
          justifyContent="row"
          alignItems="flex-start"
          flexWrap="wrap"
        >
          <DateRangeSelector />
          <Box alignSelf="center" mt={isMobile ? 1 : 0}>
            <ButtonCsvDownload />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default SalesToolbar;
