import React from 'react';
import PropTypes from 'prop-types';

import { CardHeader as MaterialCardHeader } from '@material-ui/core';

const CardHeader = ({ ...props }) => {
  return <MaterialCardHeader {...props} />;
};

CardHeader.propTypes = {
  props: PropTypes.node,
};

export default CardHeader;
