import React from 'react';
import PropTypes from 'prop-types';

import { Chip as MaterialChip } from '@material-ui/core';
import { switchProp, ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';

const CustomChip = styled(MaterialChip)`
  ${switchProp('shape', {
    squareRounded: css`
      border-radius: 4px !important;
    `,
  })}
  
  background-color: ${ifProp(
    'backgroundColor',
    ({ backgroundColor }) => backgroundColor,
  )} !important;

  color: ${ifProp('textColor', ({ textColor }) => textColor)} !important;
`;

const Chip = ({ shape, backgroundColor, textColor, ...props }) => {
  return (
    <CustomChip
      shape={shape}
      textColor={textColor}
      backgroundColor={backgroundColor}
      {...props}
    />
  );
};

Chip.propTypes = {
  shape: PropTypes.oneOf(['squareRounded']),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  props: PropTypes.node,
};

export default Chip;
