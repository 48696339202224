import { Restaurant as RestaurantIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import Avatar from '../../atom/Avatar';
import Divider from '../../atom/Divider';
import ListItem from '../../atom/List/ListItem';
import ListItemAvatar from '../../atom/List/ListItemAvatar';
import ListItemSecondaryAction from '../../atom/List/ListItemSecondaryAction';
import ListItemText from '../../atom/List/ListItemText';
import Box from '../../atom/Box';
import Typography from '../../atom/Typography';
import Grid from '../../atom/Grid';

const FotoCardapio = styled(Avatar)`
  width: 4em !important;
  height: 4em !important;
`;

const CustomListItem = styled(ListItem)`
  display: flex !important;
  padding-top: 4px !important;
  align-items: end !important;
`;

const Description = styled(Typography)`
  height: 40px;
  overflow: hidden;
`;

const CustomListItemSecondaryAction = styled(ListItemSecondaryAction)`
  top: 0 !important;
  right: 0 !important;
  position: relative !important;
  transform: initial !important;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column;
`;

const CustomListItemText = styled(ListItemText)`
  padding-right: 5px !important;
  margin-top: 0 !important;
  margin-left: 1em !important;
`;

const CardapioCard = ({
  name,
  description,
  price,
  image,
  imageOriginal,
  stock = 0,
  button = true,
  action,
  showDivider = true,
  showDisabled = false,
  analyticsViewItem = () => {},
  analyticsItem = {},
  onClick,
  ...props
}) => {
  const CardapioFoto = useCallback(
    () => (
      <FotoCardapio variant="rounded" alt={name} src={image}>
        <RestaurantIcon fontSize="large" />
      </FotoCardapio>
    ),
    [image, name],
  );

  const RenderProductTitle = ({ name }) => (
    <>
      <Typography component="div" variant="body2" color="textPrimary">
        {name}
      </Typography>
    </>
  );

  const RenderDescription = ({ description, price }) => (
    <>
      <Description component="div" variant="caption" color="textSecondary">
        {description}
      </Description>
      <Typography component="div" variant="overline" color="primary">
        {price}
      </Typography>
    </>
  );

  return (
    <>
      <Grid container>
        <Grid item xs={11}>
          <CustomListItem
            onClick={onClick}
            button
            disableGutters
            disabled={!stock > 0 && showDisabled}
            {...props}
          >
            <Grid mr={2}>
              <Box mt={'4px'}>
                <ListItemAvatar>
                  <CardapioFoto />
                </ListItemAvatar>
              </Box>
            </Grid>
            <CustomListItemText
              primary={<RenderProductTitle name={name} />}
              secondary={
                <RenderDescription description={description} price={price} />
              }
            />
          </CustomListItem>
        </Grid>
        <Grid item xs={1}>
          <CustomListItemSecondaryAction>
            {action}
          </CustomListItemSecondaryAction>
        </Grid>
        {showDivider && <Divider component="li" />}
      </Grid>
    </>
  );
};

CardapioCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  image: PropTypes.string,
  props: PropTypes.node,
};

export default CardapioCard;
