import React, { useContext, useEffect } from 'react';
import { AppBarContext } from '@project/components/src/contexts/AppBarContext';
import SalesPageList from './containers/SalesPageList';
import { isMobile } from 'react-device-detect';
import SalesPageTable from './layouts/SalesPageTable';
import SalesRootProvider from './contexts/SalesPageContext';

const SalesRootPage: React.FC = () => {
  const { setPageTitle, setShowGoBack } = useContext(AppBarContext);

  useEffect(() => {
    setPageTitle('Minhas vendas');
    setShowGoBack(true);
  }, [setPageTitle, setShowGoBack]);

  return (
    <>
      <SalesRootProvider>
        {isMobile ? <SalesPageList /> : <SalesPageTable />}
      </SalesRootProvider>
    </>
  );
};

export default SalesRootPage;
